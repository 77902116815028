<template>
  <div class="px-4 pt-3 pb-2 d-flex flex-row flex-wrap align-items-start">
    <div class="d-flex flex-row flex-wrap align-items-center">
      <template v-if="showStore">
        <store-multiselect v-if="allowMultiStores" :store_ids.sync="queries.store_ids" class="mb-2 mr-2" />
        <b-input-group v-else class="mb-2 mr-2" style="width: 90px">
          <b-form-select v-model="store_id">
            <b-form-select-option :value="1">{{ $t('uk') }}</b-form-select-option>
            <b-form-select-option :value="2">{{ $t('address.mexico') }}</b-form-select-option>
          </b-form-select>
        </b-input-group>
      </template>
      <b-input-group v-if="showId" class="mb-2 mr-2" style="width: 135px">
        <b-form-input v-model="queries.id" type="search" :placeholder="$t('general.id')" @keyup.enter="onSearch" />
      </b-input-group>
      <b-input-group v-if="showTitle" class="mb-2 mr-2" style="width: 200px">
        <b-form-input v-model="queries.title" type="search" :placeholder="$t('general.title')" @keyup.enter="onSearch" />
      </b-input-group>
      <b-input-group v-if="showStatus" class="mb-2 mr-2" style="width: 122px">
        <b-form-select v-model="queries.status">
          <b-form-select-option :value="null">All Status</b-form-select-option>
          <b-form-select-option v-for="(option, index) in $t('competition.status_list')" :key="index" :value="index - 1">
            {{ option }}
          </b-form-select-option>
        </b-form-select>
      </b-input-group>
      <b-input-group v-if="showApproval" class="mb-2 mr-2" style="width: 180px">
        <b-form-select v-model="queries['is_approval']">
          <b-form-select-option :value="null">All Approval Status</b-form-select-option>
          <b-form-select-option :value="-1">NOT REQUIRED</b-form-select-option>
          <b-form-select-option :value="0">APPROVAL REQUIRED</b-form-select-option>
          <b-form-select-option :value="1">APPROVED</b-form-select-option>
        </b-form-select>
      </b-input-group>
    </div>
    <!-- Buttons -->
    <div class="d-flex flex-row flex-wrap align-items-start justify-content-xl-between flex-grow-1">
      <button-group :onSearch="onSearch" :onReset="onReset" class="mb-2 mr-2">
        <template #export>
          <b-button variant="reset" @click="onExport" class="ml-2"><b-icon-file-spreadsheet class="mr-1" />{{ isLoading ? $t('status.loading') : $t('action.export') }}</b-button>
        </template>
        <template #sort>
          <SortBySelect ref="SortBySelect" :fields="fields" :queryParams="queries" :onSearch="onSearch" />
        </template>
      </button-group>
      <div v-if="isListRoute" class="mb-2">
        <template v-if="selfStore == 1 || allowMultiStores">
          <b-button variant="basic" v-b-modal.rank-competitions><b-icon-arrow-down-up class="mr-1" />{{ $t('competition.rank_comp') }}</b-button>
          <RankCompetitions />
        </template>
        <b-button variant="basic" @click="$router.push({ path: '/competitions/competition-list/create' })"><b-icon-plus class="mr-1" />{{ $t('competition.create_comp') }}</b-button>
      </div>
    </div>
  </div>
</template>
<script>
import RankCompetitions from '@/components/Competitions/RankCompetitions.vue'
import SortBySelect from '@/components/SortBySelect.vue'
import { API_LIST } from '@/utils/consts'
import { formatLocalDateTime, formatUtcDateTime } from '@/utils/dateTimeUtils'
import { exportAsExcel } from '@/utils/fileUtils'
import { formatWithCurrency } from '@/utils/numberUtils'
import { mapGetters } from 'vuex'

export default {
  name: 'CompetitionSearchInput',
  components: { SortBySelect, RankCompetitions },
  props: { fields: Array, queries: Object, getList: Function, isLoading: Boolean },
  data() {
    return {
      showId: false,
      showStatus: false,
      showApproval: false,
      showStore: false,
      showTitle: false,
      store_id: this.$store.getters['store'],
    }
  },
  computed: {
    ...mapGetters({ competitionList: 'request/competitionList', allowMultiStores: 'allowMultiStores', selfStore: 'store' }),
    isListRoute() {
      return this.$route.path.indexOf('/competitions/competition-list') > -1
    },
  },
  methods: {
    onSearch() {
      this.queries.page = 1
      if (!this.allowMultiStores) {
        this.queries.store_ids = `[${this.store_id}]`
        if (this.store_id !== this.selfStore) {
          this.queries['allow_multi_stores'] = 1
        } else if (this.queries.allow_multi_stores) {
          delete this.queries.allow_multi_stores
        }
      }
      this.getList()
    },
    onReset() {
      this.queries.status = null
      this.queries.is_approval = null
      this.queries.store_ids = this.allowMultiStores ? null : `[${this.selfStore}]`
      this.store_id = this.selfStore
      this.queries.title = null
      this.queries.id = null
      this.$refs.SortBySelect.reset()
      this.onSearch()
    },
    multiStoreFilter(item, key, filter) {
      const tmp = [
        { id: item.store_id, filtered: item[key] },
        { id: item.stores[0]?.store_id, filtered: item.stores[0]?.[key] },
      ]
      return tmp.find(({ id }) => id == filter)?.filtered
    },
    csvFormatter(data, _filter) {
      for (let i = 0; i < data.length; i++) {
        const obj = data[i]
        const filter = _filter ?? obj.store_id
        const needFilter = ['status', 'start_time', 'end_time', 'created_at']
        const filtered = {}
        needFilter.forEach((key) => (filtered[key] = this.multiStoreFilter(obj, key, filter)))
        data[i].status = this.$t('competition.status_list')[filtered['status'] + 1]
        data[i].store_id = [this.$t('uk'), this.$t('address.mexico')][filter - 1]
        const filteredPrice = obj.prices.find((x) => x.store_id == filter && x.type == 0)
        data[i].prices = filteredPrice ? formatWithCurrency(filteredPrice.amount, filteredPrice.currency_code) : ''
        const filteredCash = obj.cash_alt.find((x) => x.currency == ['GBP', 'MXN'][filter - 1])
        data[i].cash_alt = filteredCash ? formatWithCurrency(filteredCash.amount, filteredCash.currency) : ''
        data[i].start_time = formatLocalDateTime(filtered.start_time)
        data[i].end_time = formatLocalDateTime(filtered.end_time)
        data[i].created_at = formatLocalDateTime(filtered.created_at)
        data[i].available_ticket_amount = `${obj.available_ticket_amount} / ${obj.stock_amount}`
        data[i].draw_date = formatLocalDateTime(obj.draw_date)
        data[i].admin = obj.admin ? obj.admin.email : ''
        let arr = ['user_limit_amount', 'short_name', 'url', 'image', 'stock_amount', 'updated_at', 'admin_user_id', 'rewards', 'rewards_settled', 'stores']
        arr.forEach((key) => delete data[i][key])
      }
      return data
    },
    csvPreProcess(data) {
      if (this.allowMultiStores) {
        const toAppend = []
        for (let i = 0; i < data.length; i++) {
          const arr = ['user_limit_amount', 'short_name', 'url', 'image', 'admin_user_id', 'rewards', 'rewards_settled']
          arr.forEach((key) => delete data[i][key])
          if (data[i].stores?.length > 0) {
            toAppend.push({
              ...data[i],
              store_id: data[i].stores[0].store_id,
              status: data[i].stores[0].status,
              start_time: data[i].stores[0].start_time,
              end_time: data[i].stores[0].end_time,
              created_at: data[i].stores[0].created_at,
            })
          }
        }
        data = data.concat(toAppend)
        return this.csvFormatter(data)
      } else {
        return this.csvFormatter(data, this.selfStore)
      }
    },
    async onExport() {
      return exportAsExcel(
        this.$t('competition.list_filename') + `_${formatUtcDateTime(undefined, 2, true)}`,
        API_LIST.get.competitionList,
        { ...this.queries, page: 1, perPage: 1000 },
        this.competitionList.total,
        this.csvPreProcess,
        ['id', 'title', 'store_id', 'status', 'tag', 'prices', 'cost', 'cash_alt', 'available_ticket_amount', 'start_time', 'end_time', 'draw_date', 'created_at', 'admin'],
        [
          [
            this.$t('general.id'),
            this.$t('general.title'),
            this.$tc('general.store', 1),
            this.$t('general.status'),
            this.$tc('competition.tag', 1),
            this.$tc('competition.price', 1) + ' (' + this.$t('competition.price_type[0]') + ')',
            this.$t('competition.prize_cost'),
            this.$t('competition.cash_alt'),
            this.$t('sidebar.tickets'),
            this.$t('competition.opening_date'),
            this.$t('competition.closing_date'),
            this.$t('competition.draw_date'),
            this.$t('general.created_at'),
            this.$t('general.updated_by'),
          ],
        ],
        (bool) => this.$emit('update:isLoading', bool)
      )
    },
  },
  mounted() {
    this.showId = this.queries.id === null
    this.showStatus = this.queries.status == null || this.$route.path.includes('competition-list')
    this.showApproval = this.queries.is_approval !== undefined
    this.showStore = this.queries.store_ids !== undefined
    this.showTitle = this.queries.title !== undefined
    this.store_id = this.selfStore
    this.$refs.SortBySelect.reset()
  },
}
</script>
<style>
.drop-menu {
  width: max-content !important;
  min-width: 12rem;
  max-width: 30rem;
}
.sort-checkbox {
  padding-left: 2rem;
}
.sort-checkbox label::before,
.sort-checkbox label::after {
  left: -2rem;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.drag-list {
  min-height: 20px;
}
.drag-list-item {
  cursor: move;
}
.flip-list-move {
  transition: transform 0.5s;
}
</style>
