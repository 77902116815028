var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('card',{staticClass:"table-custom",attrs:{"body-classes":"p-0 d-flex flex-column"}},[_c('loading',{attrs:{"active":_vm.isLoading,"can-cancel":false,"is-full-page":false}}),_c('ProductSearchInput',{attrs:{"queries":_vm.queries,"getList":_vm.getList,"productList":_vm.productList,"isLoading":_vm.isLoading,"extraCls":"px-4 pt-3 pb-2"},on:{"update:isLoading":function($event){_vm.isLoading=$event},"update:is-loading":function($event){_vm.isLoading=$event}}}),_c('b-table',{staticClass:"m-0 p-0",attrs:{"fields":_vm.fields,"items":_vm.items,"show-empty":"","empty-text":_vm.$t('notify.table_no_records'),"small":"","hover":"","responsive":"","head-variant":"light","sticky-header":"100%"},scopedSlots:_vm._u([{key:"cell(name)",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [(item.nameLang)?_c('div',{staticClass:"w-auto d-flex flex-row flex-nowrap align-items-center"},[_c('div',{staticClass:"action",staticStyle:{"white-space":"normal","min-width":"8rem"},on:{"click":function($event){return _vm.onViewDetails(item)}}},[_vm._v(_vm._s(value))]),_c('icon-translate',{attrs:{"onClick":function () { return _vm.pushProductEdit(item); },"classes":"pl-1"}})],1):_c('div',{staticClass:"action",staticStyle:{"white-space":"normal","min-width":"12rem"},on:{"click":function($event){return _vm.onViewDetails(item)}}},[_vm._v(_vm._s(value))])]}},{key:"cell(topImage)",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [(value)?_c('b-img-lazy',{staticClass:"hover-image",staticStyle:{"max-height":"4rem"},attrs:{"src":value,"alt":_vm.shortenString(value, 13, true),"block":"","rounded":""}}):_c('b-badge',{staticClass:"font-weight-bold",staticStyle:{"cursor":"pointer"},attrs:{"variant":"danger"},on:{"click":function($event){return _vm.pushProductEdit(item)}}},[_vm._v("⚠ "+_vm._s(_vm.$t('notify.no_image')))])]}},{key:"cell(store_id)",fn:function(ref){
var value = ref.value;
return [_c('store-badge',{attrs:{"store_id":value}})]}},{key:"head(stock_amount)",fn:function(ref){
var label = ref.label;
return [_vm._v(" "+_vm._s(label)+" "),_c('b-icon-question-circle-fill',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",value:(_vm.$t('product.initial_amount') + ' / ' + _vm.$t('product.stock_amount') + ' / ' + _vm.$t('product.available_amount')),expression:"$t('product.initial_amount') + ' / ' + $t('product.stock_amount') + ' / ' + $t('product.available_amount')",modifiers:{"hover":true,"right":true}}],staticClass:"ml-1"})]}},{key:"cell(stock_amount)",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_c('div',{staticClass:"w-100 d-flex flex-row align-items-center justify-content-end"},[_c('span',{staticClass:"action flex-grow-1",on:{"click":function($event){return _vm.onViewHistory(item)}}},[_vm._v(_vm._s(value))]),(!item.has_invoice)?_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",value:(_vm.$t('notify.no_invoice')),expression:"$t('notify.no_invoice')",modifiers:{"hover":true,"right":true}}],staticClass:"fa fa-receipt text-xl text-danger flex-grow-0 ml-1",staticStyle:{"cursor":"pointer","width":"20px"},on:{"click":function($event){return _vm.pushProductEdit(item)}}}):_c('div',{staticStyle:{"width":"20px"}})])]}},{key:"cell(status)",fn:function(ref){
var value = ref.value;
return [_c('bool-badge',{attrs:{"value":value,"text":_vm.$t('product.stock_status_list')[value]}})]}},{key:"cell(action)",fn:function(ref){
var item = ref.item;
return [_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.topleft",value:(_vm.$t('action.edit')),expression:"$t('action.edit')",modifiers:{"hover":true,"topleft":true}}],on:{"click":function($event){return _vm.pushProductEdit(item)}}},[_c('b-icon-pencil')],1),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.topright",value:(_vm.$t('action.duplicate')),expression:"$t('action.duplicate')",modifiers:{"hover":true,"topright":true}}],on:{"click":function($event){return _vm.$router.push({ name: 'Product Create', params: item })}}},[_c('b-icon-plus')],1)]}}])}),(_vm.items.length > 0)?_c('paginate',{staticClass:"card-footer",attrs:{"queries":_vm.queries,"fetcher":_vm.getList,"total":_vm.productList.total}}):_vm._e(),(_vm.items.length > 0)?_c('b-modal',{attrs:{"lazy":"","centered":"","id":"product-details","title":_vm.$t('product.product_details') + (_vm.actionTarget ? ' #' + _vm.actionTarget.id : ''),"title-class":"w-100 d-flex justify-content-center align-self-center","header-class":"py-2","body-class":"pt-0","dialog-class":"product-details-modal","hide-footer":""}},[(_vm.actionTarget)?_c('ProductDetails',{attrs:{"product":_vm.actionTarget}}):_vm._e()],1):_vm._e(),(_vm.items.length > 0)?_c('ProductInventory',{attrs:{"product":_vm.actionTarget},on:{"update:product":function($event){_vm.actionTarget=$event}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }