<template>
  <b-badge pill :variant="['light', 'warning', 'reset'][value + 1]" @click="onClick">
    <b-icon-eye v-if="icon" class="mr-1" />
    {{ abbrev ? $t('competition.approval_status_abbrev')[value + 1] : $t('competition.approval_status_list')[value + 1] }}
  </b-badge>
</template>
<script>
export default {
  name: 'CompetitionApprovalStatus',
  props: {
    value: Number,
    icon: Boolean,
    onClick: { type: Function, default: () => {} },
    abbrev: Boolean,
  },
}
</script>
