<template>
  <b-modal
    lazy
    centered
    id="catalog-competitions"
    :title="$t('catalog.catalog_competitions')"
    title-class="w-100 d-flex justify-content-center align-self-center"
    header-class="py-2"
    footer-class="py-2"
    body-class="py-0"
    dialog-class="catalog-competitions-modal"
    cancel-variant="outline-secondary"
    ok-variant="outline-primary"
    :ok-title="$t('action.submit')"
    :cancel-title="$t('action.cancel')"
    @ok="onSubmit"
    @shown="onShown"
    @hidden="onHidden">
    <loading :active="isLoading" :can-cancel="false" :is-full-page="false" />
    <b-table
      v-if="catalog"
      show-empty
      :empty-text="$t('notify.table_no_records')"
      small
      hover
      responsive
      head-variant="light"
      class="m-0 p-0"
      :fields="[
        { key: 'title', label: $t('catalog.catalog_competitions'), tdClass: 'w-100' },
        { key: 'position', label: $t('general.position') },
        { key: 'end_time', label: $t('general.end_time'), formatter: (v) => formatLocalDateTime(v) },
      ]"
      :items="items"
      :busy="isLoading">
      <template #cell(position)="data">
        <b-form-input v-model="data.item.pivot.position" type="number" min="0" :placeholder="$t('general.position')" number required style="width: 3.5rem" />
      </template>
    </b-table>
    <paginate v-if="items.length > 0" :queries="queries" :fetcher="getCatalogComps" :total="cataComps.total" class="card-footer" />
  </b-modal>
</template>
<script>
import { formatLocalDateTime } from '@/utils/dateTimeUtils'
import { mapGetters } from 'vuex'
export default {
  name: 'CatalogCompetitions',
  props: { catalog: Object, getCatalogs: Function },
  data() {
    return {
      isLoading: false,
      queries: {
        id: null,
        is_active: 1,
        page: 1,
        perPage: 10,
      },
      lastReq: null,
    }
  },
  computed: {
    ...mapGetters({ cataComps: 'request/catalogCompetitions' }),
    items() {
      return this.cataComps ? this.cataComps.data : []
    },
  },
  methods: {
    getCatalogComps(time) {
      this.isLoading = true
      this.lastReq = time ?? Number(this.$moment().unix())
      this.$store
        .dispatch('request/getCatalogCompetitions', this.queries)
        .then(() => (this.isLoading = false))
        .catch(() => (this.isLoading = false))
    },
    onShown(evt) {
      const currentReq = Number(this.$moment().unix())
      const timeDiff = currentReq - (this.lastReq ?? 0)
      if (this.catalog?.id && (this.catalog.id !== this.queries.id || timeDiff > 5)) {
        this.queries.id = this.catalog.id
        this.getCatalogComps(currentReq)
      }
    },
    onHidden(evt) {
      this.$emit('update:catalog', null)
    },
    onSubmit() {
      this.isLoading = true
      const payload = this.cataComps.data.map((item) => item.pivot)
      this.$store
        .dispatch('request/updateCatalogCompetitions', {
          payload: payload,
          successMsg: this.$t('catalog.change_position_notice[0]'),
          errorMsg: this.$t('catalog.change_position_notice[1]'),
        })
        .then((res) => {
          this.isLoading = false
          if (res?.status === 200) {
            this.getCatalogs()
            this.$bvModal.hide('catalog-competitions')
          }
        })
        .catch(() => (this.isLoading = false))
    },
    formatLocalDateTime,
  },
}
</script>
<style>
.catalog-competitions-modal {
  width: max-content !important;
  min-width: 56rem !important;
  max-width: 90vw !important;
}
</style>
