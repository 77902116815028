<template>
  <div>
    <div class="text-xs" v-if="!hideTips && triggers.length > 0">
      <p class="text-xs my-0">⚠️ An approval is needed before publishing if:</p>
      <p v-for="(trigger, index) in $t('competition.approval_triggers')" :key="index" class="text-xs my-0 ml-4">
        {{ trigger }}
      </p>
    </div>
    <div class="w-100 d-flex align-items-center mt-2">
      Expected Sales
      <b-icon-question-circle-fill class="ml-2" v-b-tooltip.hover.top="`EXPECTED SALES = THE LOWEST PRICE * TOTAL TICKET AMOUNT`" />
      <span class="text-success ml-auto">{{ formatWithCurrency(income, 'GBP') }}</span>
    </div>
    <div class="w-100 d-flex align-items-center mt-2">
      Expected Cost
      <b-icon-question-circle-fill class="ml-2" v-b-tooltip.hover.top="`EXPECTED COST = LIVE DRAW COST + INSTANT WIN COST`" />
      <span class="text-success ml-auto">{{ formatWithCurrency(competition['total_cost'], 'GBP') }}</span>
    </div>
    <div class="w-100 d-flex align-items-center mt-2">
      Expected Profit
      <b-icon-question-circle-fill class="ml-2" v-b-tooltip.hover.top="`EXPECTED PROFIT = EXPECTED SALES - EXPECTED COST`" />
      <span class="ml-auto">{{ formatWithCurrency(profit, 'GBP') }}</span>
    </div>
    <div class="w-100 d-flex align-items-center mt-2">
      Expected Profit Margin
      <b-icon-question-circle-fill class="ml-2" v-b-tooltip.hover.top="`EXPECTED PROFIT MARGIN = (EXPECTED SALES - EXPECTED COST) / EXPECTED SALES * 100%`" />
      <span class="ml-auto">{{ notNaN(margin) ? margin.toLocaleString('en-US', { style: 'percent', maximumFractionDigits: 2 }) : '0%' }}</span>
    </div>
    <template v-if="!hideWarnings && breaches.length > 0">
      <p v-for="(item, index) in breaches" :key="item.key" :class="`text-xs text-warning ${index == 0 ? 'mt-2 mb-0' : 'my-0'}`">⚠️ {{ item.description }} : {{ item.comparer }}</p>
    </template>
  </div>
</template>
<script>
import { notifyError } from '@/utils/index'
import { floatDivision, floatMultiplication, floatSubtraction, formatWithCurrency, notNaN } from '@/utils/numberUtils'
import axios from '../../plugins/axios'
import { API_LIST } from '../../utils/consts'

export default {
  name: 'FinancialSum',
  props: {
    competition: Object,
    store: Object,
    hideTips: Boolean,
    hideWarnings: Boolean,
  },
  data() {
    return {
      triggers: [],
      isLoading: false,
    }
  },
  computed: {
    price() {
      let tmp = 0
      this.store.prices?.map((item) => {
        if (notNaN(item.amount) && item.amount > 0 && (tmp == 0 || item.amount < tmp)) {
          tmp = item.amount
        }
      })
      return tmp
    },
    income() {
      return Number(this.price) > 0 ? floatMultiplication(this.price, this.competition.stock_amount ?? 0) : 0
    },
    profit() {
      return floatSubtraction(this.income, this.competition.total_cost ?? 0)
    },
    margin() {
      const val = floatDivision(this.profit, this.income)
      this.$set(this.competition, 'expected_profit_margin', val)
      return val
    },
    // tooltips() {
    //   let arr = []
    //   for (let i = this.triggers.length - 1; i >= 0; i--) {
    //     const trigger = this.triggers[i]
    //     if (trigger.price && trigger.cost) {
    //       arr.push(`${trigger.cost.description} and ${trigger.price.description}`)
    //     }
    //   }
    //   return arr
    // },
    breaches() {
      let arr = []
      for (let i = this.triggers.length - 1; i >= 0; i--) {
        const trigger = this.triggers[i]
        if (trigger.price && trigger.cost) {
          const invalid1 = Number(this.competition['total_cost']) >= Number(trigger.cost.value)
          const invalid2 = this.price < Number(trigger.price.value)
          if (invalid1 && invalid2) {
            arr = [
              { ...trigger.cost, comparer: formatWithCurrency(this.competition['total_cost'], 'GBP'), invalid: invalid1 },
              { ...trigger.price, comparer: formatWithCurrency(this.price, 'GBP'), invalid: invalid2 },
            ]
          }
        }
      }
      return arr
    },
  },
  methods: {
    getTriggers() {
      const saved = this.$store.getters['request/compApprovalTriggers']
      if (saved?.length > 0) {
        this.triggers = saved
        return
      } else if (!this.isLoading) {
        this.isLoading = true
        axios
          .get(API_LIST.get.compApprovalTriggers)
          .then((res) => {
            if (res?.data?.data?.length > 0) {
              this.triggers = res.data.data
              this.$store.dispatch('request/mutateState', { property: 'compApprovalTriggers', with: res.data.data })
            }
          })
          .catch((err) => notifyError(err, this.$t('notify.unknown_err')))
          .finally(() => (this.isLoading = false))
      }
    },
    formatWithCurrency,
    notNaN,
  },
  created() {
    this.getTriggers()
    if (typeof this.competition.total_cost == 'undefined') {
      this.$set(this.competition, 'total_cost', 0)
    }
  },
}
</script>
