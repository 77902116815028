<template>
  <b-badge pill :variant="Number(value) == 1 ? 'success' : 'basic'">{{ text }}</b-badge>
</template>
<script>
export default {
  name: 'QuizAnswer',
  props: {
    value: [String, Number],
    text: String,
  },
}
</script>
