<template>
  <b-badge pill :variant="['primary', 'warning', 'success', 'danger'][value]">{{ $t('ticket.ticket_types')[value] }}</b-badge>
</template>
<script>
export default {
  name: 'TicketType',
  props: {
    value: [Number, String],
  },
  computed: {
    val() {
      return typeof this.value == 'string' ? Number(this.value) : this.value
    },
  },
}
</script>
