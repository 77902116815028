<template>
  <card body-classes="p-0 d-flex flex-column" class="table-custom">
    <loading :active="isLoading" :can-cancel="false" :is-full-page="false" />
    <OrderSearchInput :queryParams="queryParams" :onSearch="onSearch" extraCls="px-4 pt-3 pb-2" />
    <b-table :fields="fields" :items="items" show-empty :empty-text="$t('notify.table_no_records')" small hover responsive head-variant="light" class="m-0 p-0">
      <template #cell(id)="{ value, item }">
        <span
          class="action"
          @click="
            $router.push({
              name: 'Order Details',
              query: { orderDetailsId: item.id, userId: item.user.id, display_name: item.user.display_name ? item.user.display_name : '-' },
            })
          ">
          {{ value }}
        </span>
      </template>
      <template #cell(status)="{ value }">
        <OrderStatus :value="value" />
      </template>
      <template #cell(payments)="{ value }">
        <b-badge
          v-for="(item, key) in value"
          :key="key"
          pill
          :variant="{ failed: 'danger', succeeded: 'success', pending: 'primary' }[item.status]"
          :class="`d-block ${value.length > 1 ? 'mr-1 my-1' : ''}`">
          {{ item.gateway.includes('google') ? 'barclay gpay' : item.gateway.includes('apple') ? 'barclay apple pay' : item.gateway.replaceAll('barclaycard', 'barclay card').replaceAll('_', ' ') }}
        </b-badge>
      </template>
      <template #cell(user)="{ value }">
        <span @click="openUserDetails(value)" class="action">{{ value.display_name }}</span>
      </template>
      <template #cell(store_id)="{ value }"><store-badge :store_id="value" /></template>
      <template #cell(type)="{ value }"><TicketType :value="value" /></template>
    </b-table>
    <paginate v-if="items.length > 0" :queries="queryParams" :fetcher="getOrderList" :total="orderList.total" class="card-footer" />

    <b-modal
      lazy
      centered
      id="user-details"
      :title="$t('user.user_details', { id: '' })"
      title-class="w-100 d-flex justify-content-center align-self-center"
      header-class="w-100 py-2"
      body-class="pt-0"
      hide-footer
      ><UserDetails v-if="userDetails" :user="userDetails">
        <template #address="{ address }">
          <br v-if="address" />
          <AddressDetails v-if="address" :userStore="userDetails.store_id" :address="address" />
        </template>
      </UserDetails>
    </b-modal>
  </card>
</template>
<script>
import OrderSearchInput from '@/components/Orders/OrderSearchInput.vue'
import OrderStatus from '@/components/Orders/OrderStatus.vue'
import TicketType from '@/components/TicketType.vue'
import AddressDetails from '@/components/Users/AddressDetails.vue'
import UserDetails from '@/components/Users/UserDetails.vue'
import { formatLocalDateTime } from '@/utils/dateTimeUtils'
import { formatWithCurrency } from '@/utils/numberUtils'
import { mapGetters } from 'vuex'

export default {
  components: {
    OrderSearchInput,
    UserDetails,
    AddressDetails,
    OrderStatus,
    TicketType,
  },
  data() {
    return {
      isLoading: true,
      fields: [
        { key: 'id', stickyColumn: true, label: this.$t('order.order_id'), formatter: (val) => '#' + val.toString() },
        { key: 'status', label: this.$t('general.status'), class: 'text-center' },
        { key: 'payments', label: this.$tc('general.payment', 2), class: 'text-center', tdAttr: { style: 'white-space: normal' } },
        { key: 'grand_total', label: this.$t('order.grand_total'), formatter: (v, k, i) => formatWithCurrency(v, i.currency_code), sortable: true, class: 'text-right' },
        { key: 'subtotal', label: this.$t('order.subtotal'), formatter: (v, k, i) => formatWithCurrency(v, i.currency_code), sortable: true, class: 'text-right' },
        { key: 'created_at', label: this.$t('order.order_date'), formatter: (val) => formatLocalDateTime(val), sortable: true, class: 'text-center' },
        { key: 'user', label: this.$t('user.display_name'), class: 'text-center' },
        { key: 'store_id', label: this.$tc('general.store', 1), class: 'text-center' },
        { key: 'type', label: this.$t('general.type'), class: 'text-center' },
        { key: 'updated_at', class: 'text-center', label: this.$t('general.updated_at'), formatter: (val) => formatLocalDateTime(val), sortable: true },
      ],
      queryParams: {
        user_id: null,
        id: null,
        email: null,
        store_id: null,
        type: null,
        status: null,
        start: null,
        end: null,
        perPage: 10,
        page: 1,
        ...this.$route.params?.prev,
      },
      userDetails: null,
    }
  },
  computed: {
    ...mapGetters({ orderList: 'request/orderList', allowMultiStores: 'allowMultiStores', selfStore: 'store' }),
    items() {
      return this.orderList?.data ?? []
    },
  },
  methods: {
    getOrderList(_params) {
      this.isLoading = true
      const params = { ..._params }
      for (const key in this.queryParams) {
        if (this.queryParams[key] || typeof this.queryParams[key] == 'number') {
          params[key] = this.queryParams[key]
        }
      }
      this.$store
        .dispatch('request/getOrderList', params)
        .then(() => (this.isLoading = false))
        .catch(() => (this.isLoading = false))
    },
    onSearch() {
      this.queryParams['page'] = 1
      this.getOrderList({ errorMsg: this.$t('order.get_list_failed') })
    },
    openUserDetails(obj) {
      this.userDetails = { ...obj }
      this.$bvModal.show('user-details')
    },
  },
  created() {
    this.queryParams.store_id = this.allowMultiStores ? null : this.selfStore
    this.getOrderList()
  },
  beforeRouteLeave(to, from, next) {
    this.$set(from.params, 'preservedParams', this.queryParams)
    return next()
  },
}
</script>
