<template>
  <b-card class="px-1 py-3">
    <b-card-title>{{ $t('order.email_list') }}</b-card-title>
    <div class="mt-4 overflow-auto">
      <b-table-lite :items="emailList ? emailList.data : []" :fields="emailListFields" outlined responsive class="paymentTable">
        <template #cell(status)="{ value }">
          <bool-badge :value="value" :text="value == 1 ? $t('status.succeed') : $t('status.failed')" />
        </template>
        <template #cell(id)="{ value }">
          <span class="action" @click="resendEmail(value)">{{ $t('order.resend_email[0]') }}</span>
        </template>
      </b-table-lite>
    </div>
  </b-card>
</template>

<script>
import { formatLocalDateTime } from '@/utils/dateTimeUtils'
import { mapGetters } from 'vuex'

export default {
  name: 'email-list',
  props: {
    // emailList: Object,
  },
  data() {
    return {
      emailListFields: [
        { key: 'notification_type', label: this.$t('order.notification_type'), tdClass: 'text-wrap' },
        { key: 'status', label: this.$t('general.status') },
        { key: 'created_at', label: this.$t('general.created_at'), formatter: (val) => formatLocalDateTime(val) },
        { key: 'id', label: this.$tc('general.action', 1), tdClass: 'text-wrap' },
      ],
    }
  },
  computed: {
    ...mapGetters({ emailList: 'request/emailList' }),
  },
  methods: {
    resendEmail(val) {
      this.isLoading = true
      const params = {
        id: val,
        successMsg: this.$t('order.resend_email[1]'),
        errorMsg: this.$t('order.resend_email[2]'),
      }
      this.$store
        .dispatch('request/resendEmail', params)
        .then(() => (this.isLoading = false))
        .catch(() => (this.isLoading = false))
    },
  },
}
</script>
