var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"lazy":"","centered":"","id":"user-orders","title":_vm.$t('user.user_orders') + (_vm.userDetails ? ' #' + _vm.userDetails.id : ''),"title-class":"w-100 d-flex justify-content-center align-self-center","header-class":"py-2","body-class":"pt-0","hide-footer":""},on:{"shown":_vm.onShown,"hidden":_vm.onHidden}},[_c('loading',{attrs:{"active":_vm.isLoading,"can-cancel":false,"is-full-page":false}}),(_vm.userDetails)?[_c('OrderSearchInput',{attrs:{"queryParams":_vm.queryParams,"onSearch":_vm.onSearch}}),_c('b-table',{attrs:{"show-empty":"","empty-text":_vm.$t('notify.table_no_records'),"small":"","hover":"","responsive":"","sticky-header":"16rem","head-variant":"light","fields":[
        { key: 'id', label: _vm.$t('order.order_id'), sortable: true },
        { key: 'store_id', label: _vm.$tc('general.store', 1), sortable: true },
        { key: 'status', label: _vm.$t('general.status'), sortable: true },
        { key: 'created_at', label: _vm.$t('general.created_at'), formatter: function (v) { return _vm.formatLocalDateTime(v); }, sortable: true },
        { key: 'grand_total', label: _vm.$t('order.grand_total'), formatter: function (v, k, i) { return _vm.formatWithCurrency(v, i.currency_code); }, class: 'text-right', sortable: true },
        { key: 'use_balance_amount', label: _vm.$t('user.balance_used'), formatter: function (v, k, i) { return _vm.formatWithCurrency(v, i.currency_code); }, class: 'text-right', sortable: true } ],"items":_vm.items},scopedSlots:_vm._u([{key:"cell(id)",fn:function(ref){
      var value = ref.value;
      var item = ref.item;
return [_c('router-link',{staticClass:"text-decoration-none action",attrs:{"to":{
            name: 'Order Details',
            query: { orderDetailsId: value, display_name: item.user.display_name },
          }}},[_vm._v(_vm._s('#' + value))])]}},{key:"cell(store_id)",fn:function(ref){
          var value = ref.value;
return [_c('store-badge',{attrs:{"store_id":value}})]}},{key:"cell(status)",fn:function(ref){
          var value = ref.value;
return [_c('OrderStatus',{attrs:{"value":value}})]}}],null,false,419669367)}),(_vm.items.length > 0)?_c('paginate',{staticClass:"card-footer",attrs:{"queries":_vm.queryParams,"fetcher":_vm.getOrderList,"total":_vm.orders.total}}):_vm._e()]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }