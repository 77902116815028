<template>
  <card body-classes="p-0 d-flex flex-column" class="table-custom">
    <loading :active="isLoading" :can-cancel="false" :is-full-page="false" />
    <ReportSearchInput type="multi-pay" :queryParams="queryParams" showDatePicker="First Payment Date" :onSearch="getList" :onReset="onReset">
      <template #other-search>
        <b-form-input v-model="queryParams['competition_id']" type="search" :placeholder="$t('competition.comp_id')" @keyup.enter="getList" class="mb-2 mr-2" style="width: 140px" />
        <b-form-input v-model="queryParams['title']" type="search" :placeholder="$t('competition.comp_title')" @keyup.enter="getList" class="mb-2 mr-2" style="width: 160px" />
        <b-form-select v-model="queryParams['multi_cash_progress']" class="mr-2 mb-2" style="width: 110px">
          <b-form-select-option :value="null" class="text-placeholder">All Status</b-form-select-option>
          <b-form-select-option v-for="({ value, text }, index) in statusOptions" :key="index" :value="value">{{ text }}</b-form-select-option>
        </b-form-select>
        <b-form-input v-model="queryParams['user_name']" type="search" :placeholder="$t('general.username')" @keyup.enter="getList" class="mb-2 mr-2" style="width: 110px" />
      </template>
    </ReportSearchInput>
    <b-table :fields="fields" :items="items" show-empty :empty-text="$t('notify.table_no_records')" hover responsive head-variant="light" sticky-header="100%" class="m-0 p-0">
      <template #head()="{ label, field }">{{ label }}<b-icon-question-circle-fill v-if="field.tooltip" class="ml-1" v-b-tooltip.hover.topright="field.tooltip" /></template>
      <template #cell(competition_name)="{ value }"><tooltip-span :content="value" /></template>
      <template #cell(paid_times)="{ value, item }">
        <div v-b-tooltip.hover.top="'Paid Times / Pending Times / Total Times'">
          <span class="text-success">{{ value }}</span> / <span class="text-warn">{{ item.pending_times }}</span> /
          <span>{{ item.total_times }}</span>
        </div>
      </template>
      <template #cell(multi_cash_progress)="{ value }">
        <b-badge :variant="value.badge">{{ value.text }}</b-badge>
      </template>
      <template #bottom-row v-if="items.length > 0">
        <td colspan="1" class="text-left b-table-sticky-column">Total</td>
        <td colspan="3"></td>
        <td v-for="key in ['prize_amount', 'convert_amount', 'amount_per_time', 'paid_amount']" :key="key" colspan="1" class="text-right">{{ formatWithGBP(sumTotal(items, key)) }}</td>
        <td colspan="3"></td>
      </template>
    </b-table>
    <div class="card-footer" v-if="items.length > 0"></div>
  </card>
</template>
<script>
import ReportSearchInput from '@/components/Reports/ReportSearchInput.vue'
import axios from '@/plugins/axios'
import { API_LIST } from '@/utils/consts'
import { formatLocalDateTime } from '@/utils/dateTimeUtils'
import { notifyError } from '@/utils/index'
import { formatWithGBP, sumTotal } from '@/utils/numberUtils'
import { mapGetters } from 'vuex'

export default {
  name: 'MultiPayReport',
  components: { ReportSearchInput },
  data() {
    return {
      isLoading: false,
      queryParams: {
        multi_cash_progress: null,
        competition_id: null,
        title: null,
        user_name: null,
        start: null,
        end: null,
        timezone: null,
      },
      initialParams: {},
      statusOptions: [
        { value: -1, text: 'Converted', badge: 'info' },
        { value: 1, text: 'Processing', badge: 'primary' },
        { value: 2, text: 'Completed', badge: 'success' },
      ],
    }
  },
  computed: {
    ...mapGetters({ multiPayReport: 'request/multiPayReport' }),
    fields() {
      return [
        { key: 'competition_id', label: this.$t('competition.comp_id'), stickyColumn: true, sortable: true },
        { key: 'competition_name', label: this.$t('competition.comp_title') },
        { key: 'multi_cash_progress', label: this.$t('general.status'), formatter: (v) => this.statusOptions.find((x) => x.value == v), sortable: true },
        // { key: 'id', label: 'Winner ID' },
        { key: 'user_name', label: this.$t('general.username') },
        { key: 'prize_amount', formatter: (v) => (v ? formatWithGBP(v) : '-'), tdClass: 'text-right' },
        { key: 'convert_amount', formatter: (v) => (v ? formatWithGBP(v) : '-'), tdClass: 'text-right' },
        { key: 'amount_per_time', formatter: (v) => (v ? formatWithGBP(v) : '-'), tdClass: 'text-right' },
        { key: 'paid_amount', formatter: (v) => (v ? formatWithGBP(v) : '-'), tdClass: 'text-right' },
        {
          key: 'paid_times',
          // formatter: (v, k, item) => `${v} / ${item.pending_times} / ${item.total_times}`,
          tooltip: 'Paid Times / Pending Times / Total Times',
        },
        // { key: 'reward_id' },
        { key: 'first_date', formatter: (v) => formatLocalDateTime(v) },
        { key: 'last_date', formatter: (v) => formatLocalDateTime(v) },
      ]
    },
    items() {
      return this.multiPayReport?.data ?? []
    },
  },
  methods: {
    getList() {
      this.isLoading = true
      axios
        .get(API_LIST.get.multiPayReport, { params: this.queryParams })
        .then((res) => {
          this.$store.dispatch('request/mutateState', { property: 'multiPayReport', with: res?.data?.data })
        })
        .catch((err) => notifyError(err, 'Failed to get multiple payments report'))
        .finally(() => (this.isLoading = false))
    },

    onReset() {
      this.queryParams = { ...this.initialParams }
      this.getList()
    },

    formatWithGBP,
    sumTotal,
  },
  // queryParams will be initialized when ReportSearchInput created
  mounted() {
    this.initialParams = { ...this.queryParams }
    this.getList()
  },
}
</script>
