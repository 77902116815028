var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('card',{staticClass:"table-custom",attrs:{"body-classes":"p-0 d-flex flex-column"}},[_c('loading',{attrs:{"active":_vm.isLoading,"can-cancel":false,"is-full-page":false}}),_c('ReportSearchInput',{attrs:{"queryParams":_vm.queryParams,"showStore":"","showCurrency":"","showMonthPicker":"","showCatalog":"","onExport":_vm.onExport,"onSearch":_vm.onSearch,"onReset":_vm.onReset}}),_c('b-table',{staticClass:"m-0 p-0",attrs:{"fields":_vm.fields,"items":_vm.monthlyCompReport,"show-empty":"","empty-text":_vm.$t('notify.table_no_records'),"small":"","hover":"","responsive":"","sticky-header":"100%","head-variant":"light"},scopedSlots:_vm._u([{key:"head()",fn:function(ref){
var label = ref.label;
var field = ref.field;
return [_vm._v(" "+_vm._s(label)+" "),(field.tooltip)?_c('b-icon-question-circle-fill',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.topleft",value:(field.tooltip),expression:"field.tooltip",modifiers:{"hover":true,"topleft":true}}],staticClass:"ml-1"}):_vm._e()]}},{key:"cell(competition_id)",fn:function(ref){
var value = ref.value;
return [_c('router-link',{staticClass:"action text-decoration-none",attrs:{"to":{ name: 'Competition Details', query: { id: value } }}},[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"cell(competition_title)",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [(value)?_c('div',{staticClass:"action",staticStyle:{"white-space":"normal"},on:{"click":function($event){return _vm.openDailyReport(item.competition_id, value)}}},[_vm._v(" "+_vm._s(value)+" ")]):_vm._e()]}},(_vm.monthlyCompReport.length > 0)?{key:"bottom-row",fn:function(){return [_c('td',{staticClass:"table-b-table-default b-table-sticky-column",attrs:{"colspan":"1"}},[_vm._v(_vm._s(_vm.$t('general.total')))]),_c('td',{attrs:{"colspan":"2"}}),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.formatWithCurrency(_vm.totalNetSales, _vm.queryParams.currency)))]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.formatWithCurrency(_vm.totalCouponAmt, _vm.queryParams.currency)))]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.formatWithCurrency(_vm.totalCost, _vm.queryParams.currency)))]),_c('td',{staticClass:"text-right pr-6",attrs:{"colspan":"2"}},[_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:("After Removing Duplicates"),expression:"`After Removing Duplicates`",modifiers:{"hover":true}}]},[_vm._v(_vm._s(_vm.totalUserAmount)+" (RD)")])])]},proxy:true}:null],null,true),model:{value:(_vm.visibleRows),callback:function ($$v) {_vm.visibleRows=$$v},expression:"visibleRows"}}),(_vm.monthlyCompReport.length > 0)?_c('div',{staticClass:"card-footer"}):_vm._e(),_c('CompetitionDailyReport',{attrs:{"competitionId":_vm.competitionId,"competitionTitle":_vm.competitionTitle},on:{"update:competitionId":function($event){_vm.competitionId=$event},"update:competition-id":function($event){_vm.competitionId=$event},"update:competitionTitle":function($event){_vm.competitionTitle=$event},"update:competition-title":function($event){_vm.competitionTitle=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }