<template>
  <card body-classes="p-0 d-flex flex-column" class="table-custom">
    <loading :active="isLoading" :can-cancel="false" :is-full-page="false" />
    <ReportSearchInput :queryParams="queryParams" showStore showDatePicker :onExport="onExport" :onSearch="getReport" :onReset="onReset">
      <template #other-search>
        <b-form-select v-model="queryParams.type" :options="typeOpts" class="text-capitalize mb-2 mr-2" style="width: 130px">
          <b-form-select-option selected disabled hidden :value="null">{{ $t('product.product_type') }}</b-form-select-option>
          <b-form-select-option :value="''">{{ $t('general.all') }}</b-form-select-option>
        </b-form-select>
      </template>
      <template #other-button v-if="showAllRowToggler">
        <b-button @click="expandAll" class="ml-2"><b-icon-caret-down-fill class="mr-1" />{{ $t('action.expand_all') }}</b-button>
        <b-button @click="collapseAll" class="ml-2"><b-icon-caret-up-fill class="mr-1" />{{ $t('action.collapse_all') }}</b-button>
      </template>
    </ReportSearchInput>

    <b-table
      id="stock-report-table"
      :fields="fields"
      :items="items"
      @row-clicked="rowClicked"
      :hover="hover"
      :busy="isLoading"
      :tbody-transition-props="{ name: 'fade', appear: true, duration: 200 }"
      primary-key="product_id"
      show-empty
      :empty-text="$t('notify.table_no_records')"
      small
      responsive
      head-variant="light"
      class="m-0 p-0"
      details-td-class="p-0">
      <!-- Additional header above headers -->
      <template #thead-top>
        <b-tr>
          <b-th colspan="3" class="v-thead-top-th sticky-top text-center border-right">
            <span class="sr-only">{{ $t('product.product_id') }}</span>
          </b-th>
          <b-th colspan="3" class="v-thead-top-th sticky-top text-center border-right border-left">{{ $t('report.stock.opening_balance_at', { time: queryParams.start }) }}</b-th>
          <b-th colspan="3" class="v-thead-top-th sticky-top text-center border-right border-left">{{ $t('report.stock.in_during', [queryParams.start, queryParams.end]) }}</b-th>
          <b-th colspan="3" class="v-thead-top-th sticky-top text-center border-right border-left">{{ $t('report.stock.out_during', [queryParams.start, queryParams.end]) }}</b-th>
          <b-th colspan="3" class="v-thead-top-th sticky-top text-center border-left">{{ $t('report.stock.closing_balance_at', { time: queryParams.end }) }}</b-th>
        </b-tr>
      </template>
      <!-- Add tooltip to headers -->
      <template #head()="{ label, field }">{{ label }}<b-icon-question-circle-fill v-if="field.tooltip" class="ml-1" v-b-tooltip.hover.right="field.tooltip" /></template>

      <template #cell(product_id)="{ value }">
        <span class="action" @click="onViewDetails({ id: value })">#{{ value }}</span>
      </template>
      <template #cell(product_name)="{ value, item }">
        <div v-if="item.product_nameLang" class="w-auto d-flex flex-row flex-nowrap align-items-center">
          <div class="action" @click="onViewHistory(item)" style="white-space: normal">{{ value }}</div>
          <icon-translate :onClick="() => onViewHistory(item)" classes="pl-1" />
        </div>
        <div v-else class="action" @click="onViewHistory(item)" style="white-space: normal">{{ value }}</div>
      </template>

      <!-- toggle button -->
      <!-- <template #cell(actions)="row">
        <span class="action" v-if="row.value.length > 0" @click="onToggle(row)"
          >{{ row.detailsShowing ? $t('action.collapse') : $t('action.expand') }}<b-icon :icon="`${row.detailsShowing ? 'caret-up-fill' : 'caret-down-fill'}`" class="ml-1" />
        </span>
      </template> -->
      <template #row-details="row">
        <b-table
          :fields="detailsFields"
          :items="row.item.actions"
          :busy="row.detailsShowing"
          @row-hovered="rowHovered"
          @row-unhovered="rowUnhovered"
          table-variant="light"
          thead-class="d-none"
          :tbody-transition-props="{ name: 'fade', appear: true, duration: 200 }"
          primary-key="id"
          small
          responsive
          striped
          class="m-0 p-0">
          <!-- created_at, amount, price, cost-->
          <template #cell()="{ field, value }">
            <div v-b-tooltip.hover.topright="field.label" class="w-100">{{ value }}</div>
          </template>

          <template #cell(id)><b-icon-arrow-return-right /></template>
          <template #cell(action)="{ value }">
            <b-badge
              :variant="actionColors[actionTypes.indexOf(value)]"
              pill
              class="text-uppercase"
              style="min-width: fit-content; width: fit-content"
              v-b-tooltip.hover.right="$t(`report.stock.action_tips.${value}`)"
            >
              {{ $t(`report.stock.action_types.${value}`) }}
            </b-badge>
          </template>
          <template #cell(competition_id)="{ field, value, item }">
            <span v-if="value" class="action" @click="pushCompDetails(item.competition)" v-b-tooltip.hover.right="field.label">
              {{ '#' + value }}
            </span>
          </template>
          <template #cell(status)="{ field, value }">
            <CompetitionStatus v-if="value" :value="value" style="min-width: fit-content; width: fit-content" v-b-tooltip.hover.right="field.label" />
          </template>
          <template #cell(title)="{ field, value, item }">
            <template v-if="value">
              <span v-b-tooltip.hover="field.label">{{ value }}</span>
              <icon-translate v-if="item.competition.titleLang" :onClick="() => pushCompEdit(item.competition)" />
            </template>
          </template>
        </b-table>
      </template>
    </b-table>

    <paginate v-if="items.length > 0" :queries="queryParams" :fetcher="getReport" :total="stockReport.total" class="card-footer" />

    <b-modal
      v-if="items.length > 0"
      lazy
      centered
      id="product-details"
      :title="$t('product.product_details', { id: actionTarget ? ' #' + actionTarget.id : '' })"
      title-class="w-100 d-flex justify-content-center align-self-center"
      header-class="py-2"
      body-class="pt-0"
      dialog-class="product-details-modal"
      hide-footer
      ><ProductDetails v-if="actionTarget" :product="actionTarget" />
    </b-modal>

    <ProductInventory :product.sync="actionTarget" />
  </card>
</template>
<script>
import ProductDetails from '@/components/Product/ProductDetails.vue'
import ProductInventory from '@/components/Product/ProductInventory.vue'
import ReportSearchInput from '@/components/Reports/ReportSearchInput.vue'
import routerMixin from '@/mixins/router-mixin'
import storeMixin from '@/mixins/store-mixin'
import { API_LIST } from '@/utils/consts'
import { exportAsExcel } from '@/utils/fileUtils'
import { noTransFormatter } from '@/utils/index'
import { mapGetters } from 'vuex'
import CompetitionStatus from '../../components/Competitions/CompetitionStatus.vue'

export default {
  mixins: [
    routerMixin,
    storeMixin, // storeOpts, currencyOpts, getCurrencySignByStoreId
  ],
  components: {
    ReportSearchInput,
    ProductDetails,
    ProductInventory,
    CompetitionStatus,
  },

  data() {
    return {
      isLoading: false,
      queryParams: {
        store_id: null,
        // currency: null,
        type: null, // product type
        timezone: null,
        start: null,
        end: null,
        page: 1,
        perPage: 10,
        ...this.$route.params?.prev,
      },
      initialParams: {},
      actionTarget: null,
      actionTypes: ['in_stock', 'remove', 'hold', 'out_stock', 'revert'],
      actionColors: ['success', 'danger', 'info', 'warning', 'primary'],
      hover: true,
    }
  },

  computed: {
    ...mapGetters({
      stockReport: 'request/stockReport',
      productEntityList: 'request/productEntityList',
      productDetails: 'request/productDetails',
    }),
    typeOpts() {
      return this.productEntityList?.map((x) => ({ value: x, text: this.$t(`product.types.${x.replace('-', '_')}`) })) ?? []
    },
    currency() {
      return this.queryParams.store_id ? this.getCurrencySignByStoreId(this.queryParams.store_id) : null
    },
    fields() {
      const tmp = [
        { key: 'product_id', label: this.$t('product.product_id'), tooltip: this.$t('notify.click_for', { name: this.$t('product.product_details') }), tdAttr: { style: 'min-width: 170px' } },
        {
          key: 'product_name',
          label: this.$t('product.product_name'),
          tooltip: this.$t('notify.click_for', { name: this.$t('product.product_inventory', { id: '' }) }),
          formatter: noTransFormatter,
          tdAttr: { style: 'min-width: 240px' },
        },
        {
          key: 'product_type',
          label: this.$t('general.type'),
          formatter: (v) => (!v ? this.$t('no_data') : this.$t(`product.types.${v.replace('-', '_')}`)),
          class: 'text-center border-right',
          tdAttr: { style: 'min-width: 140px; cursor: pointer' },
        },
        { key: 'start_qty' },
        { key: 'start_unit_cost' },
        { key: 'start_cost' },
        { key: 'in_qty' },
        { key: 'in_unit_cost' },
        { key: 'in_cost' },
        { key: 'out_qty' },
        { key: 'out_unit_cost' },
        { key: 'out_cost' },
        { key: 'end_qty' },
        { key: 'end_unit_cost' },
        { key: 'end_cost' },
      ]
      tmp.forEach((field, j) => {
        this.$set(field, 'class', `${field.class ?? ''}${j == 0 ? ' pr-2' : j == 14 ? ' pl-2' : ' px-2'}`)
        this.$set(field, 'thClass', `${field.thClass ?? ''}${j > 2 ? ' text-right' : ''} sticky-top`)
        this.$set(field, 'tdClass', `${field.tdClass ?? ''}${j > 2 ? ' text-right' : ''}`)
        this.$set(field, 'formatter', j > 2 ? (v) => Number(v).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : field.formatter)
        if ([5, 8, 11, 14].indexOf(j) > -1) {
          field['class'] += ' border-right'
          this.$set(field, 'label', `${this.$t('general.cost')}${this.currency ? ' (' + this.currency + ')' : ''}`)
          this.$set(field, 'tdAttr', { style: 'min-width: 142px; cursor: pointer' })
        } else if ([3, 6, 9, 12].indexOf(j) > -1) {
          field['class'] += ' border-left'
          this.$set(field, 'label', this.$tc('report.stock.qty', 1))
          this.$set(field, 'tdAttr', { style: 'min-width: 128px; cursor: pointer' })
        } else if (j > 2) {
          this.$set(field, 'label', `${this.$t('report.stock.unit_cost')}${this.currency ? ' (' + this.currency + ')' : ''}`)
          this.$set(field, 'tdAttr', (v, k, item) => ({ style: `min-width: 164px; ${item.actions.length > 0 ? 'cursor: pointer' : ''}` }))
        } // 4, 7, 10, 13
      })
      return tmp
    },
    detailsFields() {
      var tmp = [
        { key: 'id', label: 'ID', tdAttr: { style: 'width: 170px' }, tdClass: 'pr-2 text-right' },
        {
          key: 'created_at',
          label: this.$t('general.created_at'),
          formatter: (v) => this.$moment.utc(v).tz(this.queryParams.timezone).format('YYYY-MM-DD HH:mm:ss'),
          tdAttr: { style: 'width: 240px' },
          tdClass: 'px-2',
        },
        { key: 'action', label: this.$tc('general.action', 1), tdAttr: { style: 'width: 140px' }, tdClass: 'px-2 text-center' },
        { key: 'amount', label: this.$tc('report.stock.qty', 1), tdAttr: { style: 'width: 128px' }, tdClass: 'px-2 text-right' },
        { key: 'price', label: this.$t('report.stock.unit_cost'), tdAttr: { style: 'width: 164px' }, tdClass: 'px-2 text-right' },
        { key: 'cost', label: this.$t('general.cost'), tdAttr: { style: 'width: 142px' }, tdClass: 'px-2 text-right' },
        { key: 'competition_id', label: this.$t('competition.comp_id'), tdAttr: { style: 'width: 128px' }, tdClass: 'px-2 text-right' },
        {
          key: 'status',
          label: this.$tc('general.competition', 1) + ' ' + this.$t('general.status'),
          formatter: (v, k, i) => (i.competition ? i.competition.status : null),
          tdAttr: { style: 'width: 164px' },
          tdClass: 'px-2 text-right',
        },
        {
          key: 'title',
          label: this.$t('competition.comp_title'),
          formatter: (v, k, i) => (i.competition ? noTransFormatter(i.competition.title, k, i.competition) : null),
          tdClass: 'pl-2 text-truncate',
        },
      ]
      return tmp
    },
    items() {
      return this.stockReport ? this.stockReport.data.map((x) => ({ ...x, _showDetails: false })) : []
    },
    showAllRowToggler() {
      return !this.items.every((x) => x.actions.length < 1)
    },
  },

  methods: {
    getProductTypes() {
      this.isLoading = true
      this.$store
        .dispatch('request/getProductEntityList')
        .then(() => (this.isLoading = false))
        .catch(() => (this.isLoading = false))
    },
    getReport() {
      this.isLoading = true
      this.$store
        .dispatch('request/getStockReport', this.queryParams)
        .then(() => (this.isLoading = false))
        .catch(() => (this.isLoading = false))
    },
    onReset() {
      this.queryParams = { ...this.initialParams }
      this.getReport()
    },
    csvPreProcess(data) {
      for (let i = 0; i < data.length; i++) {
        delete data[i].currency
        delete data[i].actions
      }
      return data
    },
    onExport() {
      if (this.items.length > 0) {
        const headings = this.fields.map((x) => x.key)
        const headingsFormat = [
          [
            this.$t('product.product_id'),
            this.$t('product.product_name'),
            this.$t('general.type'),
            this.$t('report.stock.start_qty'),
            this.$t('report.stock.start_unit_cost') + ' (' + this.currency + ')',
            this.$t('report.stock.start_cost') + ' (' + this.currency + ')',
            this.$t('report.stock.in_qty'),
            this.$t('report.stock.in_unit_cost') + ' (' + this.currency + ')',
            this.$t('report.stock.in_cost') + ' (' + this.currency + ')',
            this.$t('report.stock.out_qty'),
            this.$t('report.stock.out_unit_cost') + ' (' + this.currency + ')',
            this.$t('report.stock.out_cost') + ' (' + this.currency + ')',
            this.$t('report.stock.end_qty'),
            this.$t('report.stock.end_unit_cost') + ' (' + this.currency + ')',
            this.$t('report.stock.end_cost') + ' (' + this.currency + ')',
          ],
        ]
        return exportAsExcel(
          this.$t('report.stock.list_filename') + `_${this.queryParams.start}-${this.queryParams.end}_${this.queryParams.timezone}`, // filename
          API_LIST.get.stockReport,
          { ...this.queryParams, page: 1, perPage: 1000 },
          this.stockReport.total,
          this.csvPreProcess,
          headings,
          headingsFormat,
          (bool) => (this.isLoading = bool)
        )
      } else {
        this.$notify({ group: 'root', type: 'error', text: this.$t('notify.no_records') })
      }
    },
    onViewDetails(row) {
      this.actionTarget = row
      this.$bvModal.show('product-details')
    },
    onViewHistory(row) {
      this.actionTarget = { id: row.product_id, name: row.product_name, type: row.product_type }
      this.$bvModal.show('product-inventory')
      this.$store.dispatch('request/getProductDetails', { id: row.product_id }).then(() => (this.actionTarget = { ...this.productDetails }))
    },
    onToggle(row) {
      // this.$set(row.item, '_rowVariant', row.detailsShowing ? '' : 'light')
      row.toggleDetails()
    },
    rowClicked(item, index, evt) {
      if (item.actions.length > 0 && evt.target.cellIndex > 1) {
        this.$set(item, '_showDetails', !item._showDetails)
        // this.$set(item, '_rowVariant', item._showDetails ? 'light' : '')
      }
    },
    rowHovered(evt) {
      this.hover = false
    },
    rowUnhovered(evt) {
      this.hover = true
    },
    expandAll() {
      for (const item of this.items) {
        if (item.actions.length > 0) {
          this.$set(item, '_showDetails', true)
          // this.$set(item, '_rowVariant', item._showDetails ? 'light' : '')
        }
      }
    },
    collapseAll() {
      for (const item of this.items) {
        if (item.actions.length > 0) {
          this.$set(item, '_showDetails', false)
          // this.$set(item, '_rowVariant', item._showDetails ? 'light' : '')
        }
      }
    },
  },

  created() {
    if (!this.productEntityList) {
      this.getProductTypes()
    }
  },

  // queryParams will be initialized when ReportSearchInput created
  mounted() {
    this.initialParams = { ...this.queryParams }
    this.getReport()
  },
  beforeRouteLeave(to, from, next) {
    this.$set(from.params, 'preservedParams', this.queryParams)
    return next()
  },
}
</script>

<style lang="scss">
#stock-report-table > thead > tr > th:not(.v-thead-top-th) {
  top: 50px;
}
</style>
