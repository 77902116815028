var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"lazy":"","centered":"","id":_vm.id ? _vm.id : 'product-inventory',"title":_vm.title ? _vm.title : _vm.$t('product.product_inventory', { id: _vm.product ? ' #' + _vm.product.id : '' }),"title-class":"w-100 d-flex justify-content-center align-self-center","header-class":"py-2","body-class":("pt-0" + (_vm.confirmMsg ? ' pb-0' : '')),"dialog-class":"product-inventory-modal","hide-footer":!_vm.confirmMsg,"footer-class":"py-2"},on:{"shown":_vm.onShown,"hidden":_vm.onHidden},scopedSlots:_vm._u([(_vm.confirmMsg)?{key:"modal-footer",fn:function(ref){
var hide = ref.hide;
return [_c('b-button',{staticClass:"px-4",attrs:{"variant":"secondary"},on:{"click":hide}},[_vm._v(_vm._s(_vm.$t('no')))]),_c('b-button',{staticClass:"px-4",attrs:{"variant":"primary","type":"submit","form":"product-upsert-form"}},[_vm._v(_vm._s(_vm.$t('yes')))])]}}:null],null,true)},[_c('loading',{attrs:{"active":_vm.isLoading,"can-cancel":false,"is-full-page":false}}),(_vm.product)?[(_vm.confirmMsg)?_c('p',[_vm._v(_vm._s(_vm.confirmMsg))]):_c('b-row',[_c('b-col',{staticClass:"d-flex flex-row flex-nowrap align-items-center",attrs:{"cols":"12"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.product.name))]),(_vm.product.nameLang)?_c('icon-translate',{attrs:{"onClick":function () { return _vm.pushProductEdit(_vm.product); }}}):_vm._e(),_c('bool-badge',{staticClass:"ml-2",attrs:{"value":_vm.product.status,"text":_vm.$t('product.stock_status_list')[_vm.product.status]}})],1),_vm._l(([
          { label: _vm.$t('product.initial_amount'), key: 'initial_amount', name: null, tip: null },
          {
            label: _vm.$t('product.stock_amount'),
            key: 'stock_amount',
            name: 'StockAmt',
            tip: _vm.$t('product.stock_amount_notice'),
          },
          {
            label: _vm.$t('product.available_amount'),
            key: 'available_amount',
            name: 'AvailableAmt',
            tip: _vm.$t('product.available_amount_notice'),
          } ]),function(ref,index){
        var label = ref.label;
        var key = ref.key;
        var name = ref.name;
        var tip = ref.tip;
return _c('b-col',{key:'amount-' + index,staticClass:"d-inline-flex align-items-center mt-3"},[_c('span',{staticClass:"font-weight-normal text-sm"},[_vm._v(_vm._s(label))]),(name)?_c('b-icon-question-circle-fill',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",value:(tip),expression:"tip",modifiers:{"hover":true,"right":true}}],staticClass:"ml-1"}):_vm._e(),_c('span',{staticClass:"ml-3 font-weight-light"},[_vm._v(_vm._s(_vm.product[key]))])],1)})],2),_c('b-table',{staticClass:"mt-4 mb-0 p-0",attrs:{"show-empty":"","empty-text":_vm.$t('notify.table_no_records'),"small":"","hover":"","responsive":"","head-variant":"light","fields":_vm.fields,"items":_vm.inventory ? _vm.inventory.inventory : []},scopedSlots:_vm._u([{key:"cell(competition_id)",fn:function(ref){
        var value = ref.value;
        var item = ref.item;
return [_c('router-link',{staticClass:"text-decoration-none",staticStyle:{"white-space":"normal","width":"max-content","min-width":"8rem"},attrs:{"to":{ name: 'Competition Details', query: { id: value } }}},[_vm._v(" "+_vm._s(item.competition_title)+" ")])]}},{key:"cell(selected)",fn:function(ref){
        var value = ref.value;
return [_c('b-badge',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:(_vm.$t(("product.inventory_status_tips." + (value + 1)))),expression:"$t(`product.inventory_status_tips.${value + 1}`)",modifiers:{"hover":true}}],staticClass:"text-uppercase",attrs:{"variant":['primary', 'info', 'success'][value + 1]}},[_vm._v(" "+_vm._s(_vm.$t('product.inventory_status')[value + 1])+" ")])]}}],null,false,2242332358)})]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }