<template>
  <BannerUpsert v-if="details" :initVal="details" />
</template>
<script>
import { mapGetters } from 'vuex'
import BannerUpsert from '@/components/Banners/BannerUpsert.vue'

export default {
  components: { BannerUpsert },
  data() {
    return {
      details: null,
    }
  },
  computed: {
    ...mapGetters({ bannerDetails: 'request/bannerDetails' }),
  },
  created() {
    const query = this.$route.query
    this.$store.dispatch('request/getCompetitionBanners', query).then(() => {
      const { banners, ...rest } = this.bannerDetails
      this.details = {
        configurable: banners ? rest : this.$route.params.configurable,
        banners: banners ?? this.bannerDetails,
      }
    })
  },
}
</script>
