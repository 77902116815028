var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"lazy":"","centered":"","id":"assign-free","title":_vm.$t('ticket.assign_free_tickets'),"title-class":"w-100 d-flex justify-content-center align-self-center","header-class":"py-2","footer-class":"py-2","body-class":"py-0","busy":_vm.isLoading},on:{"hide":_vm.hideModal},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var cancel = ref.cancel;
return [_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":cancel}},[_vm._v(_vm._s(_vm.$t('action.cancel')))]),_c('b-button',{attrs:{"variant":"outline-primary","type":"submit","form":"free-tickets-form"}},[_vm._v(_vm._s(_vm.$t('action.submit')))])]}}])},[_c('loading',{attrs:{"active":_vm.isLoading,"can-cancel":false,"is-full-page":false}}),_c('b-form',{staticClass:"m-0",attrs:{"id":"free-tickets-form"},on:{"submit":function($event){$event.preventDefault();return _vm.assignFreeTickets.apply(null, arguments)}}},[_c('b-form-group',{staticClass:"d-inline-block w-50 pr-2 mb-0",attrs:{"label-for":"tickets-username","label-class":"text-sm"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(_vm._s(_vm.$t('general.user'))),_c('span',{staticClass:"ml-1 text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('b-form-input',{attrs:{"id":"tickets-username","placeholder":_vm.$t('input.select_user'),"disabled":"","required":""},model:{value:(_vm.form['username']),callback:function ($$v) {_vm.$set(_vm.form, 'username', $$v)},expression:"form['username']"}})],1),_c('b-form-group',{staticClass:"d-inline-block w-50 pl-2 mb-0",attrs:{"label-for":"tickets-type","label-class":"text-sm"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(_vm._s(_vm.$t('general.type'))),_c('span',{staticClass:"ml-1 text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('b-form-select',{attrs:{"id":"tickets-type","options":[
          { value: 1, text: _vm.$t('ticket.ticket_types[1]') },
          { value: 2, text: _vm.$t('ticket.ticket_types[2]') } ],"required":""},model:{value:(_vm.form['type']),callback:function ($$v) {_vm.$set(_vm.form, 'type', $$v)},expression:"form['type']"}})],1),_c('b-form-group',{staticClass:"mt-2",attrs:{"label-for":"tickets-tickets","label-class":"text-sm"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(_vm._s(_vm.$tc('general.ticket', 2))),_c('span',{staticClass:"ml-1 text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('b-form-input',{attrs:{"id":"tickets-tickets","placeholder":_vm.$t('ticket.select_tickets_number'),"disabled":"","required":""},model:{value:(_vm.form['ticket_ids']),callback:function ($$v) {_vm.$set(_vm.form, 'ticket_ids', $$v)},expression:"form['ticket_ids']"}})],1),_c('b-tabs',{attrs:{"lazy":""},model:{value:(_vm.tabIndex),callback:function ($$v) {_vm.tabIndex=$$v},expression:"tabIndex"}},[_c('b-tab',{attrs:{"title":_vm.$t('ticket.step1'),"active":"","title-item-class":"text-sm"}},[_c('UserList',{attrs:{"fields":[
            { key: 'id', label: _vm.$t('user.user_id'), sortable: true },
            { key: 'name', label: _vm.$t('general.username'), sortable: true },
            { key: 'profile.first_name', label: _vm.$t('user.first_name'), sortable: true },
            { key: 'profile.last_name', label: _vm.$t('user.last_name'), sortable: true },
            { key: 'is_active', label: _vm.$t('general.status') },
            { key: 'actions', label: _vm.$tc('general.action', 1) } ],"queries":{ store_id: _vm.$store.getters['store'], name: null, page: 1, perPage: 10 },"noInitOnMount":true},scopedSlots:_vm._u([{key:"name",fn:function(ref){
          var user = ref.user;
return [_c('span',{staticClass:"action",on:{"click":function($event){return _vm.viewUser(user)}}},[_vm._v(_vm._s(user.name))])]}},{key:"actions",fn:function(ref){
          var user = ref.user;
return [(user.profile && user.profile.first_name && user.profile.last_name)?_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":function($event){return _vm.selectFreeUser(user)}}},[_vm._v(_vm._s(_vm.$t('action.select'))+" ")]):_c('b-badge',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.left",value:(_vm.$t('ticket.no_names_notice')),expression:"$t('ticket.no_names_notice')",modifiers:{"hover":true,"left":true}}],attrs:{"pill":"","variant":"danger"}},[_vm._v(_vm._s(_vm.$t('user.no_names')))])]}}])})],1),_c('b-tab',{ref:"tab1",attrs:{"title":_vm.$t('ticket.step2'),"title-item-class":"text-sm","disabled":!_vm.form['user'],"active":_vm.tabIndex === 1}},[_c('p',{staticClass:"mt-3 mb-0 text-slack text-sm"},[_vm._v("⚠ "+_vm._s(_vm.$t('ticket.user_limit_notice', { num: _vm.$route.query.limit }))+".")]),_c('b-input-group',{staticClass:"w-50 mt-3"},[_c('b-input-group-prepend',[_c('b-button',{attrs:{"variant":"outline-primary","disabled":""}},[_vm._v(_vm._s(_vm.$t('ticket.quantity')))])],1),_c('b-form-input',{staticClass:"pl-2",attrs:{"type":"number","min":"1","step":"1","max":_vm.$route.query.limit,"placeholder":_vm.$t('ticket.quantity_notice')[0]},model:{value:(_vm.form['numbers']),callback:function ($$v) {_vm.$set(_vm.form, 'numbers', $$v)},expression:"form['numbers']"}}),_c('b-input-group-append',[_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":_vm.getShuffleTickets}},[_vm._v(_vm._s(_vm.$t('ticket.shuffle_tickets[0]')))])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }