var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',{attrs:{"cols":"1","cols-lg":"2"}},[_c('b-col',_vm._l(([
        { key: 'status', label: _vm.$t('general.status') },
        { key: 'comment', label: _vm.$tc('general.comment', 1) },
        { key: 'source_currency', label: _vm.$t('general.currency') },
        { key: 'source_amount', label: _vm.$t('cashback.total_payment') },
        { key: 'cashback_rate', label: _vm.$t('cashback.cashback_rate') },
        { key: 'cashback_amount', label: _vm.$t('cashback.cashback_amount') },
        { key: 'created_at', label: _vm.$t('general.created_at') },
        { key: 'updated_at', label: _vm.$t('general.updated_at') },
        { key: 'admin', label: _vm.$t('general.updated_by') } ]),function(ref,i){
      var key = ref.key;
      var label = ref.label;
return _c('div',{key:("cashback-trans-details-" + i),staticClass:"mt-3 row"},[_c('span',{staticClass:"font-weight-bold text-sm col-4 text-nowrap"},[_vm._v(_vm._s(label))]),_c('span',{staticClass:"ml-3 font-weight-light col-7"},[(key == 'status')?[_c('CreditTransStatus',{attrs:{"value":_vm.details.status}}),[_vm._t("change")]]:[_vm._v(_vm._s(_vm.details[key] == null || _vm.details[key] == undefined ? _vm.$t('no_data') : key.indexOf('rate') > -1 ? _vm.details[key] * 100 + '%' : key.indexOf('_at') > -1 ? _vm.formatLocalDateTime(_vm.details[key]) : key === 'admin' ? _vm.admin.email : _vm.details[key]))]],2)])}),0),_c('b-col',_vm._l(([
        { key: 'is_active', label: _vm.$t('user.user_status') },
        { key: 'id', label: _vm.$t('user.user_id') },
        { key: 'name', label: _vm.$t('general.username') },
        { key: 'balance_amount', label: _vm.$t('user.wallet_balance') },
        { key: 'balance_before', label: _vm.$t('cashback.balance_before') },
        { key: 'balance_after', label: _vm.$t('cashback.balance_after') },
        { key: 'created_at', label: _vm.$t('user.registered_at') },
        { key: 'display_name', label: _vm.$t('user.display_name') },
        { key: 'email', label: _vm.$t('user.user_email') } ]),function(ref,i){
      var key = ref.key;
      var label = ref.label;
return _c('div',{key:("cashback-trans-user-" + i),staticClass:"mt-3 row"},[_c('span',{staticClass:"font-weight-bold text-sm col-4 text-nowrap"},[_vm._v(_vm._s(label))]),_c('span',{staticClass:"ml-3 font-weight-light col-7"},[(key == 'is_active')?_c('bool-badge',{attrs:{"value":_vm.user.is_active,"text":_vm.user.is_active == 1 ? _vm.$t('status.active') : _vm.$t('status.inactive')}}):[_vm._v(" "+_vm._s(key.indexOf('_at') > -1 ? _vm.formatLocalDateTime(_vm.details[key]) : key.indexOf('balance_') > -1 ? _vm.details[key] : _vm.user[key] ? _vm.user[key] : _vm.$t('no_data'))+" ")]],2)])}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }