<template>
  <b-badge pill :variant="(isReward ? ['primary', 'danger', 'warning', 'success'] : ['info', 'warning', 'primary', 'success', 'danger', 'secondary', 'success'])[value]">
    {{ $t(isReward ? 'competition.prize_status' : abbrev ? 'ticket.ticket_status_abbrev' : 'ticket.ticket_status')[value] }}
  </b-badge>
</template>
<script>
export default {
  name: 'TicketStatus',
  props: {
    value: Number,
    abbrev: Boolean,
    isReward: Boolean,
  },
}
</script>
