<template>
  <div>
    <div class="font-weight-normal">
      {{ $tc('competition.price', 2) }}<span class="text-danger ml-1">*</span>
      <b-button v-if="isEditRoute" @click="confPriceChangeable(index)" variant="outline-primary" class="ml-2"> <b-icon-pencil class="mr-1" />{{ $t('competition.edit_price') }} </b-button>
    </div>
    <b-table :fields="['type', 'amount', 'start_at', 'expired_at', 'status']" :items="store.prices" small striped hover responsive head-variant="light" class="mt-2 mb-0 p-0">
      <template #cell(type)="{ value }"> {{ $t('competition.price_type')[value] }}<span v-if="value == 0" class="text-danger ml-1">*</span></template>
      <template #head(amount)="{ label }">{{ label }} ({{ ['GBP', 'MXN'][store.value - 1] }})<span class="text-danger ml-1">*</span></template>
      <template #cell(amount)="{ item }">
        <b-form-input
          v-model="item['amount']"
          :disabled="isEditRoute && !store.isPriceChangeable"
          :required="item.type === 0"
          :state="store.priceValid"
          number
          type="number"
          step="0.01"
          min="0"
          @input="priceValidation(store, index)"
          :placeholder="$tc('general.amount', 1)" />
      </template>
      <template #cell(start_at)="{ item }">
        <template v-if="item.type === 0">{{ item.start_at || $t('competition.same_opening_date') }}</template>
        <b-form-input
          v-else
          v-model="item['start_at']"
          type="datetime-local"
          :placeholder="$t('input.choose_date')"
          :required="!!item.amount"
          :disabled="isEditRoute && !store.isPriceChangeable"
          style="width: 200px" />
      </template>
      <template #cell(expired_at)="{ item }">
        <template v-if="item.type === 0">{{ item.expired_at || $t('competition.same_closing_date') }}</template>
        <b-form-input
          v-else
          v-model="item['expired_at']"
          type="datetime-local"
          :placeholder="$t('input.choose_date')"
          :required="!!item.amount"
          :disabled="isEditRoute && !store.isPriceChangeable"
          style="width: 200px" />
      </template>
      <template #cell(status)="{ value }">
        <bool-badge :value="value" :text="value == 1 ? $t('status.active') : $t('status.inactive')" />
      </template>
    </b-table>
    <b-form-invalid-feedback :state="store.priceValid">{{ $t('competition.price_validation') }}</b-form-invalid-feedback>
  </div>
</template>
<script>
export default {
  name: 'Catalogs',
  props: {
    store: Object,
    index: Number,
    isEditRoute: Boolean,
    confPriceChangeable: Function,
    priceValidation: Function,
  }
}
</script>
