var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('card',{staticClass:"table-custom",attrs:{"body-classes":"p-0 d-flex flex-column"}},[_c('loading',{attrs:{"active":_vm.isLoading,"can-cancel":false,"is-full-page":false}}),_c('ReportSearchInput',{attrs:{"queryParams":_vm.queryParams,"showCurrency":"","showDatePicker":"","onExport":_vm.onExport,"onSearch":_vm.onSearch,"onReset":_vm.onReset},scopedSlots:_vm._u([{key:"other-search2",fn:function(){return [_c('b-form-input',{staticClass:"mb-2 mr-2",staticStyle:{"width":"150px"},attrs:{"type":"search","placeholder":"Competition ID"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onSearch.apply(null, arguments)}},model:{value:(_vm.queryParams['competition_id']),callback:function ($$v) {_vm.$set(_vm.queryParams, 'competition_id', $$v)},expression:"queryParams['competition_id']"}}),_c('b-form-checkbox',{staticClass:"impact-checkbox d-flex align-items-center mb-2 mr-2",staticStyle:{"padding-top":"6px"},attrs:{"value":true,"unchecked-value":false},model:{value:(_vm.queryParams['show_competition_ids']),callback:function ($$v) {_vm.$set(_vm.queryParams, 'show_competition_ids', $$v)},expression:"queryParams['show_competition_ids']"}},[_vm._v(" Show Competition IDs ")]),_c('SortBySelect',{ref:"SortBySelect",attrs:{"fields":_vm.fields,"queryParams":_vm.queryParams,"onSearch":_vm.onSearch,"disabled":!!_vm.queryParams.competition_id}})]},proxy:true}])}),_c('b-table',{attrs:{"show-empty":"","small":"","hover":"","responsive":"","head-variant":"light","fields":_vm.fields,"items":_vm.items,"sticky-header":"100%"},scopedSlots:_vm._u([{key:"head()",fn:function(ref){
var label = ref.label;
var field = ref.field;
return [_vm._v(_vm._s(label)),(field.tooltip)?_c('b-icon-question-circle-fill',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",value:(field.tooltip),expression:"field.tooltip",modifiers:{"hover":true,"right":true}}],staticClass:"ml-1"}):_vm._e()]}},{key:"cell(user_id)",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_c('span',{staticClass:"action",on:{"click":function($event){_vm.viewUser({ id: item.user_id, store_id: ['GBP', 'MXN'].indexOf(_vm.queryParams.currency) + 1 })}}},[_vm._v(_vm._s(value))])]}},{key:"cell(user_name)",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_c('span',{staticClass:"action",on:{"click":function($event){return _vm.viewOrders({ id: item.user_id })}}},[_vm._v(_vm._s(value))])]}}])}),(_vm.items.length > 0)?_c('paginate',{staticClass:"card-footer",attrs:{"queries":_vm.queryParams,"fetcher":_vm.getReport,"total":_vm.usersWithPurchaseReport.total}}):_vm._e(),(_vm.items.length > 0)?_c('UserOrders',{attrs:{"id":"user-orders","userDetails":_vm.actionTarget},on:{"update:userDetails":function($event){_vm.actionTarget=$event},"update:user-details":function($event){_vm.actionTarget=$event}}}):_vm._e(),_c('b-modal',{attrs:{"lazy":"","centered":"","id":"user-details","title":_vm.$t('user.user_details', { id: _vm.actionTarget ? ' #' + _vm.actionTarget.id : '' }),"title-class":"w-100 d-flex justify-content-center align-self-center","header-class":"py-2","body-class":"pt-0","hide-footer":""}},[(_vm.actionTarget)?_c('UserDetails',{key:_vm.actionTarget.id,attrs:{"user":_vm.actionTarget},scopedSlots:_vm._u([{key:"address",fn:function(ref){
var address = ref.address;
return [_c('br'),_c('AddressDetails',{attrs:{"userStore":_vm.actionTarget.store_id,"address":address}})]}}],null,false,515461103)}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }