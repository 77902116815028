<template>
  <div style="display: contents">
    <loading :active="isLoading" :can-cancel="false" :is-full-page="false" />
    <CompetitionSearchInput :queries="queries" :fields="fields" :getList="getList" :isLoading.sync="isLoading" :afterSearch="afterSearch" />
    <b-table
      :fields="fields"
      :items="items"
      :busy="isLoading"
      @row-clicked="rowClicked"
      show-empty
      :empty-text="$t('notify.table_no_records')"
      hover
      responsive
      head-variant="light"
      class="m-0 p-0"
      sort-by-formatted
      sticky-header="100%">
      <template #cell(title)="{ value, item }">
        <div v-if="item.titleLang" class="w-auto d-flex flex-row flex-nowrap align-items-center" style="width: 14rem">
          <router-link :to="{ name: 'Competition Details', query: { id: item.id }, params: { competition: item } }" class="text-decoration-none action d-block w-auto" style="white-space: normal"
            >{{ value }}
          </router-link>
          <icon-translate :onClick="() => pushCompEdit(item)" />
        </div>
        <router-link
          v-else
          :to="{ name: 'Competition Details', query: { id: item.id }, params: { competition: item } }"
          class="text-decoration-none action d-block"
          style="white-space: normal; width: 14rem"
          >{{ value }}
        </router-link>
      </template>

      <template #head(image)="{ label }">{{ label }}<b-icon-question-circle-fill class="ml-1" v-b-tooltip.hover.topright="$t('competition.top_image_notice')" /></template>
      <template #cell(image)="{ value, item }">
        <a v-if="value" :href="`${item.href}`" target="_blank">
          <b-img-lazy
            :src="value"
            :alt="shortenString(value, 13, true)"
            fluid
            block
            rounded
            class="hover-image position-relative"
            style="min-width: 148px; min-height: auto; max-width: 148px"
            v-b-tooltip.hover.left="item.href" />
        </a>
        <b-badge v-else variant="danger" @click="pushCompEdit(item)" class="font-weight-bold" style="cursor: pointer"> ⚠ {{ $t('notify.no_image') }} </b-badge>
      </template>

      <template #head(store_id)="{ label }">{{ label }}<b-icon-question-circle-fill v-if="allowMultiStores" class="ml-1" v-b-tooltip.hover.topright="$t('competition.multi_store_notice')" /></template>
      <template #cell(store_id)="{ value, item }">
        <template v-for="(val, index) in [value, item.stores[0] ? item.stores[0].store_id : null]">
          <store-badge v-if="val" :key="'status-store-' + index" :store_id="val" :class="`d-block ${index > 0 ? 'mt-1' : ''} ${val == afterSearch ? 'font-weight-bold' : ''}`" />
        </template>
      </template>

      <template #cell(status)="{ value, item }">
        <CompetitionStatus :value="value" class="d-block" style="min-width: fit-content; width: fit-content" />
        <CompetitionStatus v-if="item.stores[0] && item.stores[0].status !== value" :value="item.stores[0].status" class="d-block mt-1" style="min-width: fit-content; width: fit-content" />
        <slot name="is_approval" :competition="item"></slot>
      </template>

      <template #cell(tag)="{ value, item }">
        <template v-if="hasSlot('tag')"><slot name="tag" :competition="item"></slot></template>
        <template v-else>{{ value }}</template>
      </template>

      <template #cell(prices)="{ value, item }">
        <template v-if="hasSlot('prices')"><slot name="prices" :competition="item"></slot></template>
        <template v-else-if="value.length > 0" v-for="store in storeOpts">
          <p v-if="(store.value == selfStore || allowMultiStores) && value.filter((x) => x.store_id == store.value).length > 0" :key="'comp-price-store-' + store.value" class="text-sm m-0">
            <span
              v-for="price in value.filter((x) => x.store_id == store.value)"
              :key="'comp-price-' + price.id"
              :style="value.filter((x) => x.store_id == store.value).length > 1 && price.type == 0 ? `text-decoration: line-through` : ''"
              >{{ formatWithCurrency(price.amount, price.currency_code) }}
            </span>
          </p>
        </template>
        <template v-else>{{ $t('no_data') }}</template>
      </template>

      <template #cell(cash_alt)="{ value, item }">
        <template v-if="hasSlot('cash')"><slot name="cash" :competition="item"></slot></template>
        <template v-else-if="value.length > 0">
          <template v-for="(cash, index) in value">
            <span v-if="['GBP', 'MXN'].indexOf(cash.currency) == selfStore - 1 || allowMultiStores" :key="'cash-alt-' + index" class="d-block">
              {{ (index > 0 && allowMultiStores ? 'or ' : '') + formatWithCurrency(cash.amount, cash.currency) }}
            </span>
          </template>
        </template>
        <template v-else>{{ $t('no_data') }}</template>
      </template>

      <template #cell(tickets)="{ value, item }">
        <template v-if="hasSlot('tickets')"><slot name="tickets" :competition="item"></slot></template>
        <template v-else>{{ value }}</template>
      </template>

      <template #cell(reward_amount)="{ value, item }">
        <template v-if="hasSlot('reward_amount')"><slot name="reward_amount" :competition="item"></slot></template>
        <template v-else>{{ value }}</template>
      </template>

      <template #cell(start_time)="{ value, item }">
        <div :class="['text-warning', 'text-info'][item.store_id - 1]">{{ formatLocalDateTime(value) }}</div>
        <div v-if="item.stores[0]" :class="['text-warning', 'text-info'][item.stores[0].store_id - 1]">{{ formatLocalDateTime(item.stores[0].start_time) }}</div>
      </template>
      <template #cell(end_time)="{ value, item }">
        <div :class="['text-warning', 'text-info'][item.store_id - 1]">{{ formatLocalDateTime(value) }}</div>
        <div v-if="item.stores[0]" :class="['text-warning', 'text-info'][item.stores[0].store_id - 1]">{{ formatLocalDateTime(item.stores[0].end_time) }}</div>
      </template>
      <template #cell(created_at)="{ value, item }">
        <div :class="['text-warning', 'text-info'][item.store_id - 1]">{{ formatLocalDateTime(value) }}</div>
        <div v-if="item.stores[0]" :class="['text-warning', 'text-info'][item.stores[0].store_id - 1]">{{ formatLocalDateTime(item.stores[0].created_at) }}</div>
      </template>

      <template #cell(action)="{ item }" v-if="hasSlot('actions')"><slot name="actions" :competition="item"></slot></template>
    </b-table>
    <paginate v-if="items.length > 0" :queries="queries" :fetcher="getList" :total="competitions.total" class="card-footer" />
  </div>
</template>
<script>
import CompetitionSearchInput from '@/components/Competitions/CompetitionSearchInput.vue'
import routerMixin from '@/mixins/router-mixin'
import storeMixin from '@/mixins/store-mixin'
import utilsMixin from '@/mixins/utils-mixin'
import { formatLocalDateTime } from '@/utils/dateTimeUtils'
import { noTransFormatter, shortenString } from '@/utils/index'
import { formatWithCurrency } from '@/utils/numberUtils.js'
import { mapGetters } from 'vuex'
import CompetitionStatus from './CompetitionStatus.vue'

export default {
  name: 'CompetitionList',
  mixins: [utilsMixin, routerMixin, storeMixin],
  components: { CompetitionSearchInput, CompetitionStatus },
  props: { fields: Array, queries: Object, getter: Function, isLoading: Boolean, rowClicked: { type: Function, default: (item, index, evt) => {} } },
  data() {
    return {
      afterSearch: null,
    }
  },
  computed: {
    ...mapGetters({ competitions: 'request/competitionList' }),
    items() {
      return this.competitions?.data ?? []
    },
  },
  methods: {
    setIsLoading(val) {
      this.$emit('update:isLoading', val)
    },
    localGetter(callback) {
      this.setIsLoading(true)
      this.$store
        .dispatch('request/getCompetitionList', this.queries)
        .then(() => {
          this.setIsLoading(false)
          if (callback) {
            callback()
          }
        })
        .catch(() => this.setIsLoading(false))
    },
    callback() {
      this.afterSearch = this.allowMultiStores && this.queries.store_ids && this.queries.store_ids.length == 3 ? parseInt(this.queries.store_ids[1]) : null
    },
    getList() {
      if (!this.allowMultiStores && !this.queries.store_ids) {
        this.queries.store_ids = `[${this.selfStore}]`
      }
      if (this.getter) {
        return this.getter(this.callback)
      } else {
        return this.localGetter(this.callback)
      }
    },
    shortenString,
    formatWithCurrency,
    formatLocalDateTime,
    multiStoreFilter(value, key, item) {
      const tmp = [
        { id: item.store_id, filtered: value },
        { id: item.stores[0]?.store_id, filtered: item.stores[0]?.[key] },
      ]
      return tmp.find(({ id }) => id == (this.allowMultiStores ? this.afterSearch ?? item.store_id : this.selfStore))?.filtered ?? value
    },
  },
  created() {
    this.fields.forEach((field) => {
      if (field.key == 'title') {
        field['formatter'] = noTransFormatter
        field['thStyle'] = 'width: 14rem'
        field['tdAttr'] = { style: 'width: 14rem' }
      } else if (field.key == 'status') {
        field['formatter'] = (v, k, i) => this.multiStoreFilter(v, k, i)
      } else if (field.key == 'image') {
        field['thClass'] = 'text-center'
        field['thStyle'] = 'width: 100%; min-width: max-content;'
        field['tdAttr'] = { style: 'width: 100%; min-width: max-content; padding-top: 8px; padding-bottom: 8px;' }
        field['formatter'] = (v, k, i) => {
          i['href'] = i.url ? i.url + (i.status === 0 && i.url.indexOf('token=') > -1 ? '&ticketsLeft=' + i.stock_amount : '') : v
          return v
        }
      }
    })

    this.getList()
  },
}
</script>
