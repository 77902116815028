<template>
  <b-badge pill :variant="['secondary', 'danger', 'info', 'warning', 'success', 'primary'][value + 2]">
    {{ $t('transaction.credit_status')[value + 2] }}
  </b-badge>
</template>
<script>
export default {
  name: 'CreditTransStatus',
  props: {
    value: Number,
  },
}
</script>
