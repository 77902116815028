<template>
  <card body-classes="p-0 d-flex flex-column" class="table-custom">
    <loading :active="isLoading" :can-cancel="false" :is-full-page="false" />
    <ReportSearchInput :queryParams="queryParams" showStore showCurrency :showDatePicker="dateOption.text" showCatalog :onExport="onExport" :onSearch="getList" :onReset="onReset">
      <template #other-search>
        <b-form-input v-model="queryParams.title" type="search" :placeholder="$t('competition.comp_title')" @keyup.enter="getList" class="mb-2 mr-2" style="width: 160px" />
        <b-form-select v-model="queryParams['date_field']" class="mb-2 mr-2" style="width: 120px" :options="dateOptions" />
      </template>
    </ReportSearchInput>
    <b-table :fields="fields" :items="items" show-empty striped hover responsive sticky-header="100%" head-variant="light" class="m-0 p-0">
      <template #head()="{ label, field }">{{ label }}<b-icon-question-circle-fill v-if="field.tooltip" class="ml-1" v-b-tooltip.hover.topright="field.tooltip" /></template>
      <template #cell(competition_id)="{ value, item }">
        <router-link :to="{ name: 'Competition Details', query: { id: value }, params: { competition: item } }">{{ value }}</router-link>
      </template>
      <template #cell(competition_title)="{ value, item }">
        <span v-if="value" @click="openDailyReport(item.competition_id, value)" class="action">{{ value }}</span>
      </template>
      <template #bottom-row v-if="items.length > 0">
        <td colspan="1" class="table-b-table-default b-table-sticky-column">{{ $t('general.total') }}</td>
        <td colspan="3"></td>
        <td class="text-right">{{ totalTickets }}</td>
        <td class="text-right">{{ totalTicketsSold }}</td>
        <td class="text-right">{{ formatWithCurrency(totalSales, queryParams.currency) }}</td>
        <td colspan="1"></td>
        <td class="text-right">{{ formatWithCurrency(totalCoupons, queryParams.currency) }}</td>
        <td class="text-right">{{ formatWithCurrency(totalNetSales, queryParams.currency) }}</td>
        <td class="text-right">{{ formatWithCurrency(totalExpectedCost, queryParams.currency) }}</td>
        <td class="text-right">{{ formatWithCurrency(totalLiveCost, queryParams.currency) }}</td>
        <td colspan="3" class="text-right">{{ formatWithCurrency(totalTargetDailySales, queryParams.currency) }}</td>
        <td colspan="3"></td>
        <td colspan="1" class="text-right">{{ totalRewards }}</td>
        <td colspan="1" class="text-right">{{ totalWinners }}</td>
        <td colspan="2"></td>
      </template>
    </b-table>
    <CompetitionDailyReport v-if="items.length > 0" :competitionId.sync="competitionId" :competitionTitle.sync="competitionTitle" />
  </card>
</template>
<script>
import CompetitionDailyReport from '@/components/Reports/CompetitionDailyReport.vue'
import ReportSearchInput from '@/components/Reports/ReportSearchInput.vue'
import { exportAsExcel } from '@/utils/fileUtils'
import { formatWithCurrency, sumTotal } from '@/utils/numberUtils'
import { mapGetters } from 'vuex'

export default {
  components: { ReportSearchInput, CompetitionDailyReport },
  data() {
    return {
      isLoading: false,
      queryParams: {
        store_id: null,
        date_field: 'sales',
        start_date: null,
        end_date: null,
        currency: null,
        timezone: null,
        catalog_id: null,
        title: null,
      },
      dateOptions: [
        { value: 'sales', text: 'Sales Date' },
        { value: 'start', text: 'Opening Date' },
        { value: 'close', text: 'Closing Date' },
      ],
      initialParams: {},
      competitionId: null,
      competitionTitle: null,
    }
  },
  computed: {
    ...mapGetters({ competitionReport: 'request/competitionReport' }),
    items() {
      return this.competitionReport ?? []
    },
    fields() {
      const tmp = [
        { key: 'competition_id', label: this.$t('report.competition.competition_id'), stickyColumn: true, tooltip: this.$t('report.competition.id_notice'), class: 'pr-0' },
        {
          key: 'competition_title',
          label: 'Competition Title',
          stickyColumn: true,
          tooltip: this.$t('report.competition.title_notice'),
          tdClass: 'text-wrap',
          tdAttr: { style: 'min-width: 320px' },
        },
        { key: 'live_date', label: 'Opening Date', sortable: true },
        { key: 'end_date', label: 'Closing Date', sortable: true },
        { key: 'total_tickets', label: this.$t('report.competition.total_tickets'), thStyle: 'min-width: 150px' },
        { key: 'sold_tickets', label: this.$t('report.competition.sold_tickets'), tooltip: this.$t('report.competition.total_sales_notice'), thStyle: 'min-width: 150px' }, // 5
        { key: 'total_sales', label: this.$t('report.competition.total_sales'), tooltip: this.$t('report.competition.total_sales_notice'), thStyle: 'min-width: 150px' },
        {
          key: 'wrong_rate',
          label: this.$t('report.competition.wrong_answers'),
          sortable: true,
          tooltip: this.$t('report.competition.wrong_answers_notice'),
          thStyle: 'min-width: 140px',
        },
        { key: 'coupon_amount', label: this.$t('report.competition.coupon_amount') },
        { key: 'net_sales', label: this.$t('report.competition.net_sales'), tooltip: this.$t('report.competition.net_sales_notice'), thStyle: 'min-width: 100px' },
        { key: 'original_cost', label: 'Expected Cost' }, // 10
        { key: 'cost', label: 'Live Cost' },
        {
          key: 'budget_margin',
          label: 'Expected Profit Margin',
          tooltip: 'EXPECTED PROFIT MARGIN = (EXPECTED SALES - EXPECTED COST) / EXPECTED SALES * 100%',
          thStyle: 'min-width: 180px',
        },
        {
          key: 'actual_margin',
          label: 'Live Profit Margin',
          tooltip: 'LIVE PROFIT MARGIN = (NET SALES - LIVE COST) / NET SALES * 100%',
          thStyle: 'min-width: 120px',
        },
        { key: 'target_daily_sales', label: this.$t('report.competition.target_daily_sales'), thStyle: 'min-width: 160px' },
        { key: 'ticket_price', label: this.$t('report.competition.ticket_price') }, // 15
        { key: 'ticket_sale_price', label: 'Ticket Sales Price' },
        { key: 'available_tickets', label: this.$t('report.competition.available_tickets') },
        { key: 'remaining_days', label: this.$t('report.competition.remaining_days') },
        { key: 'total_rewards', label: 'Expected Winners' },
        { key: 'total_winners', label: 'Live Winners' }, // 20
        { key: 'expected_win_rate', label: 'Expected Winner Rate', tooltip: 'EXPECTED WINNER RATE = EXPECTED WINNERS / THE NUMBER OF TOTAL TICKETS * 100%', thStyle: 'min-width: 150px' },
        { key: 'gross_win_rate', label: 'Live Winner Rate', tooltip: 'LIVE WINNER RATE = LIVE WINNERS / THE NUMBER OF SOLD TICKETS * 100%', thStyle: 'min-width: 140px' },
      ]
      for (let i = 4; i < tmp.length; i++) {
        if (['6', '8', '9', '10', '11', '14', '15', '16'].includes(String(i))) {
          tmp[i]['formatter'] = (v) => (v ? formatWithCurrency(v, this.queryParams.currency) : '-')
        } else if (['7', '12', '13', '21', '22'].includes(String(i))) {
          tmp[i]['formatter'] = (v) => (v ? `${v}%` : '-')
        }
        tmp[i]['thClass'] = 'text-center text-wrap'
        tmp[i]['tdClass'] = 'text-right'
        tmp[i]['sortable'] = true
      }

      return tmp
    },
    dateOption() {
      return this.dateOptions.find((x) => x.value == this.queryParams.date_field)
    },
    totalTickets() {
      return sumTotal(this.items, 'total_tickets')
    },
    totalTicketsSold() {
      return sumTotal(this.items, 'sold_tickets')
    },
    totalLiveCost() {
      return sumTotal(this.items, 'cost')
    },
    totalExpectedCost() {
      return sumTotal(this.items, 'original_cost')
    },
    totalSales() {
      return sumTotal(this.items, 'total_sales')
    },
    totalCoupons() {
      return sumTotal(this.items, 'coupon_amount')
    },
    totalNetSales() {
      return sumTotal(this.items, 'net_sales')
    },
    totalTargetDailySales() {
      return sumTotal(this.items, 'target_daily_sales')
    },
    totalRewards() {
      return sumTotal(this.items, 'total_rewards')
    },
    totalWinners() {
      return sumTotal(this.items, 'total_winners')
    },
  },
  methods: {
    getList() {
      this.isLoading = true
      this.$store
        .dispatch('request/getCompetitionReport', this.queryParams)
        .then(() => (this.isLoading = false))
        .catch(() => (this.isLoading = false))
    },
    onReset() {
      this.queryParams = { ...this.initialParams }
      this.getList()
    },
    csvPreProcess(data) {
      const keys = this.fields.map((x) => x.key)
      for (let i in data) {
        for (const key in data[i]) {
          if (!keys.includes(key)) {
            delete data[i][key]
          }
        }
      }
    },
    onExport() {
      if (this.items.length > 0) {
        let headings = []
        let headingsFormat = [[]]
        this.fields.forEach((x, i) => {
          headings.push(x.key)
          headingsFormat[0].push(x.label + (['7', '12', '13', '20', '21'].includes(String(i)) ? ' (%)' : ''))
        })
        let data = [...this.items]
        data.push({
          competition_id: 'Total',
          total_tickets: this.totalTickets,
          sold_tickets: this.totalTicketsSold,
          total_sales: this.totalSales,
          coupon_amount: this.totalCoupons,
          net_sales: this.totalNetSales,
          cost: this.totalLiveCost,
          original_cost: this.totalExpectedCost,
          target_daily_sales: this.totalTargetDailySales,
          total_rewards: this.totalRewards,
          total_winners: this.totalWinners,
        })
        return exportAsExcel(
          this.$t('report.competition.list_filename') + `_${this.queryParams.start_date}-${this.queryParams.end_date}_${this.queryParams.timezone}`,
          null, // no need to fetch data from url because no pagination
          data, // pass data as params because no pagination
          this.items.length, // total
          this.csvPreProcess,
          headings,
          headingsFormat,
          (bool) => (this.isLoading = bool)
        )
      } else {
        this.$notify({ group: 'root', type: 'error', text: this.$t('notify.no_records') })
      }
    },
    openDailyReport(id, title) {
      this.competitionId = id
      this.competitionTitle = title
      this.$bvModal.show('comp-daily-report')
    },
    formatWithCurrency,
  },
  // queryParams will be initialized when ReportSearchInput created
  mounted() {
    this.initialParams = { ...this.queryParams }
    this.getList()
  },
}
</script>
<style scoped>
::v-deep .modal-dialog {
  width: fit-content;
  min-width: 50%;
  max-width: 75%;
}
::v-deep .modal-dialog .search {
  padding-left: 0px !important;
}
::v-deep .modal-dialog .card-border {
  border: none;
}
</style>
