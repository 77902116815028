<template>
  <b-modal
    lazy
    centered
    id="catalog-upsert"
    :title="$t(`action.${catalog && catalog.id ? 'edit' : 'create'}`) + ' ' + $tc('general.catalog', 1)"
    title-class="w-100 d-flex justify-content-center align-self-center"
    header-class="py-2"
    footer-class="py-2"
    body-class="py-0"
    :busy="isLoading"
    @shown="onShown"
    @hidden="onHidden"
  >
    <b-form @submit.prevent="onSubmit" id="catalog-upsert-form">
      <b-form-group label-for="catalog-upsert-input-1" label-class="text-sm" class="m-0">
        <template #label>{{ $t('catalog.catalog_name') }}<span class="text-danger ml-1">*</span></template>
        <b-form-input id="catalog-upsert-input-1" v-model="form['name']" type="text" :placeholder="$t('catalog.catalog_name')" required />
      </b-form-group>

      <b-form-group label-for="catalog-upsert-input-2" label-class="text-sm" class="mt-3 mb-0">
        <template #label>{{ $t('catalog.catalog_short_name') }}<span class="text-danger ml-1">*</span></template>
        <b-form-input id="catalog-upsert-input-2" v-model="form['short_name']" type="text" :placeholder="$t('catalog.catalog_short_name')" required />
      </b-form-group>

      <b-form-group label-for="catalog-upsert-input-3" label-class="text-sm" class="mt-3 mb-0">
        <template #label>{{ $t('catalog.catalog_position') }}<span class="text-danger ml-1">*</span></template>
        <b-form-input id="catalog-upsert-input-3" v-model="form['position']" type="number" min="0" :placeholder="$t('catalog.catalog_position')" required />
      </b-form-group>
      <b-form-group label-for="catalog-upsert-input-4" label-class="text-sm" class="d-inline-block w-40 mt-3 mb-0">
        <template #label>{{ $tc('general.store', 1) }}<span class="text-danger ml-1">*</span></template>
        <b-form-select id="catalog-upsert-input-4" v-model="form['store_id']" :options="storeOpts" required :disabled="!allowMultiStores" />
      </b-form-group>
      <b-form-group label-for="catalog-upsert-input-5" label-class="text-sm" class="d-inline-block w-30 pl-1 mt-3 mb-0 text-center">
        <template #label>{{ $t('catalog.visibility') }}<span class="text-danger ml-1">*</span></template>
        <b-form-checkbox id="catalog-upsert-input-5" v-model="form['is_active']" :value="1" :unchecked-value="0" required switch />
      </b-form-group>
      <b-form-group label-for="catalog-upsert-input-6" label-class="text-sm" class="d-inline-block w-30 pl-1 mt-3 mb-0 text-center">
        <template #label>{{ $t('catalog.display_all') }}<span class="text-danger ml-1">*</span></template>
        <b-form-checkbox id="catalog-upsert-input-6" v-model="form['display_all']" :value="1" :unchecked-value="0" required switch />
      </b-form-group>
    </b-form>
    <template #modal-footer="{ hide }">
      <b-button variant="outline-secondary" @click="hide">{{ $t('action.cancel') }}</b-button>
      <b-button variant="outline-primary" type="submit" form="catalog-upsert-form">{{ $t('action.submit') }}</b-button>
    </template>
  </b-modal>
</template>
<script>
import storeMixin from '@/mixins/store-mixin'

export default {
  name: 'CatalogUpsert',
  mixins: [storeMixin], // storeOpts, allowMultiStores
  props: { catalog: Object, getCatalogs: Function },
  data() {
    const template = {
      id: null,
      name: '',
      short_name: '',
      position: null,
      is_active: 1,
      display_all: 0,
      store_id: this.$store.getters['store'],
    }
    return {
      isLoading: false,
      form: { ...template },
      template: { ...template },
      keys: ['id', 'name', 'short_name', 'position', 'is_active', 'display_all', 'store_id'],
    }
  },
  methods: {
    onShown(evt) {
      if (this.catalog) {
        this.keys.forEach((key) => (this.form[key] = this.catalog[key]))
      } else {
        this.form = { ...this.template }
      }
    },
    onHidden(evt) {
      this.$emit('update:catalog', null)
    },
    onSubmit() {
      this.isLoading = true
      const params = {
        ...this.form,
        successMsg: this.$t('catalog.upsert_catalog_notice[0]', { action: this.$t('action.update') }),
        errorMsg: this.$t('catalog.upsert_catalog_notice[1]', { action: this.$t('action.update') }),
      }
      this.$store
        .dispatch('request/upsertCatalog', params)
        .then(() => {
          this.isLoading = false
          this.$bvModal.hide('catalog-upsert')
          this.getCatalogs()
        })
        .catch(() => (this.isLoading = false))
    },
  },
}
</script>
