<template>
  <b-modal
    lazy
    centered
    id="allocate-rewards"
    :title="$t('competition.allocate_rewards')"
    title-class="w-100 d-flex justify-content-center align-self-center"
    header-class="py-2"
    footer-class="py-2"
    body-class="py-0"
    dialog-class="allocate-rewards-modal"
    @shown="onShown"
    @hidden="onHidden"
    ><loading :active="isLoading" :can-cancel="false" :is-full-page="false" />
    <b-form v-if="details" @submit.prevent="onSubmit" id="allocate-rewards-form">
      <b-tabs v-model="tabIndex" lazy>
        <b-tab :title="$tc('general.winner', 1)" active title-item-class="text-sm">
          <b-form-radio-group v-model="selected" class="px-3 mt-4">
            <b-form-radio value="settle" :disabled="!costCurrency || !costAmount" class="allocate-checkbox w-100">
              <span class="flex-grow-0 flex-shrink-0" style="width: 80px">{{ $t('action.settle') }}</span>
              <b-input-group class="flex-grow-1 flex-shrink-1 pl-2 m-0">
                <b-form-select :value="costCurrency" disabled style="width: 80px; max-width: 80px">
                  <b-form-select-option value="GBP">{{ $t('gbp') }}</b-form-select-option>
                  <b-form-select-option value="MXN">{{ $t('mxn') }}</b-form-select-option>
                </b-form-select>
                <b-form-input :value="costAmount" disabled number type="number" min="0.00" step="0.01" :placeholder="$t('general.cost')" class="px-1" />
              </b-input-group>
            </b-form-radio>
            <b-form-radio value="cash" :disabled="noCash" class="allocate-checkbox w-100 mt-3">
              <span class="flex-grow-0 flex-shrink-0" style="width: 80px">{{ $t('competition.cash') }}</span>
              <b-input-group class="flex-grow-1 flex-shrink-1 pl-2 m-0">
                <b-form-select v-model="cashCurr" :disabled="noCash" style="width: 80px; max-width: 80px">
                  <!-- <b-form-select-option disabled hidden :value="null">{{ $t('general.currency') }}</b-form-select-option> -->
                  <b-form-select-option value="GBP">{{ $t('gbp') }}</b-form-select-option>
                  <b-form-select-option value="MXN">{{ $t('mxn') }}</b-form-select-option>
                </b-form-select>
                <template v-for="(opt, index) in details.cash_alt">
                  <b-form-input v-if="opt.currency == cashCurr" :key="'cash-option-' + index" :value="opt.amount" disabled :placeholder="$t('competition.cash_alt')" class="px-1" />
                </template>
                <b-form-input v-if="details.cash_alt.length < 1" disabled :placeholder="$t('competition.cash_alt')" class="px-1" />
              </b-input-group>
            </b-form-radio>
            <b-form-radio value="credit" class="allocate-checkbox w-100 mt-3">
              <span class="flex-grow-0 flex-shrink-0" style="width: 80px">{{ $t('competition.credit') }}</span>
              <b-input-group class="flex-grow-1 flex-shrink-1 pl-2 m-0">
                <b-form-select v-model="creditCurr" style="width: 80px; max-width: 80px">
                  <b-form-select-option value="GBP">{{ $t('gbp') }}</b-form-select-option>
                  <b-form-select-option value="MXN">{{ $t('mxn') }}</b-form-select-option>
                </b-form-select>
                <b-form-input
                  v-model="creditAmt"
                  :required="selected == 'credit'"
                  number
                  type="number"
                  :min="selected == 'credit' ? 0.01 : 0.0"
                  step="0.01"
                  :placeholder="$t('competition.credit')"
                  class="px-1" />
              </b-input-group>
            </b-form-radio>
          </b-form-radio-group>
        </b-tab>
        <!-- <b-tab :title="$t('competition.consolidation')" title-item-class="text-sm" ref="tab1" disabled :active="tabIndex === 1"></b-tab> -->
      </b-tabs>
      <b-table
        show-empty
        :empty-text="$t('notify.table_no_records')"
        small
        hover
        responsive
        sticky-header="20rem"
        head-variant="light"
        class="mt-4 mb-0 mx-0 p-0"
        tbody-class="h-100"
        :fields="[
          { key: 'id', label: $t('product.product_id') },
          { key: 'name', label: $t('product.product_name'), formatter: noTransFormatter, tdClass: 'text-truncate' },
          { key: 'cost', label: $t('general.cost'), formatter: (v, k, i) => formatWithCurrency(v, i.currency), class: 'text-right' },
          {
            key: 'stock_amount',
            label: $tc('general.amount', 2),
            formatter: (v, k, i) => i.initial_amount + ' / ' + v + ' / ' + i.amount,
            class: 'text-center',
          },
        ]"
        :items="items">
        <template #head(stock_amount)="{ label }">
          {{ label }}
          <b-icon-question-circle-fill v-b-tooltip.hover.topright="$t('product.initial_amount') + ' / ' + $t('product.available_amount') + ' / ' + $t('product.reserved_amount')" />
        </template>
        <template #cell(id)="{ value }">
          <span class="action" @click="viewProduct(value)">#{{ value }}</span>
        </template>
        <template #cell(name)="{ value, item }">
          <div v-if="item.nameLang" class="w-auto d-flex flex-row flex-nowrap align-items-center">
            <div style="white-space: normal; min-width: 8rem">{{ value }}</div>
            <icon-translate :onClick="() => pushProductEdit(item)" classes="pl-1" />
          </div>
          <div v-else style="white-space: normal; min-width: 12rem">{{ value }}</div>
        </template>
        <template #bottom-row v-if="items.length >= 1">
          <td colspan="2" class="font-weight-bold">{{ $t('general.total') }}</td>
          <td colspan="1" class="font-weight-bold text-right">{{ totalCost }}</td>
          <td colspan="1"></td>
        </template>
      </b-table>

      <confirm-modal id="confirm-allocation" dialogClass="confirm-allocation-modal" :onSubmit="onConfirm" :message="comp ? $t('competition.allocate_rewards_notice[0]', [comp.title, comp.id]) : ''">
        <template #form>
          <div v-for="reward in rewards" :key="'confirm-rewards' + reward ? reward.id : ''" class="mt-3 w-100 d-flex flex-row flex-nowrap align-items-center">
            <span>{{ '#' + reward.product_id }}</span>
            <span class="ml-2 text-truncate">{{ reward.product.name }}</span>
            <span class="ml-2">--></span>
            <b-badge class="ml-2 text-uppercase" style="min-width: max-content" :variant="['primary', 'success', 'info'][['cash', 'settle', 'credit'].indexOf(selected)]">
              {{
                [
                  $t('competition.cash') + ` ${formatWithCurrency(selectedCash.amount, selectedCash.currency)}`,
                  $t('competition.settle'),
                  $t('competition.credit') + ` ${formatWithCurrency(creditAmt, creditCurr)}`,
                ][['cash', 'settle', 'credit'].indexOf(selected)]
              }}
            </b-badge>
          </div>
        </template>
      </confirm-modal>
    </b-form>
    <template #modal-footer="{ hide }">
      <b-button variant="outline-secondary" @click="hide">{{ $t('action.cancel') }}</b-button>
      <b-button variant="outline-primary" type="submit" form="allocate-rewards-form">{{ $t('action.submit') }}</b-button>
    </template>

    <b-modal
      lazy
      centered
      id="product-details"
      :title="$t('product.product_details', { id: productDetails ? ' #' + productDetails.id : '' })"
      title-class="w-100 d-flex justify-content-center align-self-center"
      header-class="py-2"
      body-class="pt-0"
      dialog-class="product-details-modal"
      hide-footer
      ><ProductDetails v-if="productDetails" :product="productDetails" />
    </b-modal>
  </b-modal>
</template>
<script>
import ProductDetails from '@/components/Product/ProductDetails.vue'
import routerMixin from '@/mixins/router-mixin'
import { CASH_ALT_OPTIONS } from '@/utils/consts'
import { noTransFormatter } from '@/utils/index'
import { extractWithCurrency, formatWithCurrency } from '@/utils/numberUtils'
import { mapGetters } from 'vuex'
export default {
  name: 'AllocateRewards',
  mixins: [routerMixin],
  components: { ProductDetails },
  props: { comp: Object, getList: Function },
  data() {
    const defCurr = ['GBP', 'MXN'][this.$store.getters['store'] - 1]
    return {
      isLoading: false,
      details: null,
      selected: null,
      cash_alt: [...CASH_ALT_OPTIONS],
      cashCurr: defCurr,
      creditCurr: defCurr,
      creditAmt: null,
      tabIndex: 0,
      noCash: false,
    }
  },
  computed: {
    ...mapGetters({ competitionDetails: 'request/competitionDetails', productDetails: 'request/productDetails', allowMultiStores: 'allowMultiStores', selfStore: 'store' }),
    rewards() {
      return this.details?.rewards.filter((v) => v.type == 'winner') ?? []
    },
    items() {
      return this.rewards.map((x) => ({ ...x.product, amount: x.amount }))
    },
    costCurrency() {
      return this.details ? extractWithCurrency(this.details.cost).currency ?? null : null
    },
    costAmount() {
      return this.rewards.length < 1 ? null : this.rewards.reduce((acc, x) => acc + Number(x.product.cost), 0.0)
    },
    totalCost() {
      const tmp =
        this.rewards.length < 1
          ? null
          : `${formatWithCurrency(
              this.rewards.reduce((acc, x) => acc + Number(x.product.cost), 0.0),
              this.rewards[0].currency
            )}`
      return tmp
    },
    selectedCash() {
      return this.cash_alt.find((x) => x.currency == this.cashCurr)
    },
  },
  methods: {
    formatWithCurrency,
    noTransFormatter,
    onShown(evt) {
      if (this.comp?.id) {
        this.isLoading = true
        this.$store
          .dispatch('request/getCompetitionDetails', { id: this.comp.id })
          .then(() => {
            this.details = { ...this.comp, ...this.competitionDetails }
            this.cash_alt.forEach((x) => (x.amount = this.details.cash_alt.find((y) => y.currency == x.currency)?.['amount'] ?? null))
            const defCurr = ['GBP', 'MXN'][(this.allowMultiStores ? this.details.store_id : this.selfStore) - 1]
            this.cashCurr = this.creditCurr = defCurr
            this.noCash = !this.details?.cash_alt.find((cash) => cash.currency === this.cashCurr)
            this.isLoading = false
          })
          .catch(() => (this.isLoading = false))
      }
    },
    onHidden(evt) {
      this.$emit('update:comp', null)
    },
    onSubmit(evt) {
      this.$bvModal.show('confirm-allocation')
    },
    onConfirm(evt) {
      this.$bvModal.hide('confirm-allocation')
      const tmp = { competition_id: this.comp.id, option: this.selected }
      if (this.selected === 'cash') {
        tmp['cash_alt'] = this.selectedCash
      } else if (this.selected === 'credit') {
        tmp['credit_alt'] = { currency: this.creditCurr, amount: this.creditAmt }
      }
      this.isLoading = true
      this.$store
        .dispatch('request/allocateRewards', {
          ...tmp,
          successMsg: this.$t('competition.allocate_rewards_notice[1]'),
          errorMsg: this.$t('competition.allocate_rewards_notice[2]'),
        })
        .then((res) => {
          this.isLoading = false
          if (res.status === 200) {
            this.getList()
            this.$bvModal.hide('allocate-rewards')
          }
        })
        .catch(() => (this.isLoading = false))
    },
    viewProduct(id) {
      this.isLoading = true
      this.$store
        .dispatch('request/getProductDetails', { id: id, errorMsg: this.$t('product.product_details_notice') })
        .then((res) => {
          this.isLoading = false
          if (res.status === 200) {
            this.$bvModal.show('product-details')
          }
        })
        .catch(() => (this.isLoading = false))
    },
  },
  watch: {
    cashCurr(newVal, oldVal) {
      this.noCash = !this.details?.cash_alt.find((cash) => cash.currency === newVal)
      if (this.noCash && this.selected == 'cash') {
        this.selected = null
      }
    },
  },
  mounted() {
    const defCurr = ['GBP', 'MXN'][this.selfStore - 1]
    this.cashCurr = this.creditCurr = defCurr
  },
}
</script>
<style lang="scss" scoped>
.confirm-allocation-modal {
  width: max-content !important;
  min-width: 40rem !important;
  max-width: 70vw !important;
}
.allocate-rewards-modal,
.product-details-modal {
  width: max-content !important;
  min-width: 50vw !important;
  max-width: 90vw !important;
}
</style>
<style lang="scss">
.allocate-checkbox {
  padding-left: 2rem;
  label {
    width: 100%;
    display: flex;
  }
  label::before,
  label::after {
    left: -2rem;
    top: 0.5rem;
  }
}
</style>
