<template>
  <b-form-select v-model="inputVal" :options="options" class="mb-2 mr-2" style="width: 124px">
    <b-form-select-option v-if="options.length == 0" selected :value="null">{{ $t('report.all_catalogs') }}</b-form-select-option>
  </b-form-select>
</template>
<script>
export default {
  name: 'CatalogSelect',

  props: {
    storeId: String || Number,
    catalogId: Number,
    catalog: Object,
  },

  computed: {
    options() {
      let selectedStore = String(this.storeId).match(/[0-9]/g)
      let filtered = this.$store.getters['request/catalogList'] || []
      if (selectedStore?.length == 1) {
        filtered = filtered.filter((x) => String(x.store_id) === selectedStore[0])
      }
      return filtered.sort((a, b) => (a.short_name == 'home' ? -1 : b.short_name == 'home' ? 1 : a.id - b.id)).map((x) => ({ ...x, text: x.name, value: x.id }))
    },

    inputVal: {
      get() {
        const id = this.catalogId || this.catalog?.id
        return id || null
      },
      set(v) {
        if (typeof this.catalogId != 'undefined') {
          this.$emit('update:catalogId', v || null)
        }
        if (typeof this.catalog != 'undefined') {
          this.$emit('update:catalog', v ? this.options.find((x) => x.id == v) : null)
        }
        return v
      },
    },
  },

  methods: {
    getCatalogList() {
      if (!this.$store.getters['request/catalogList']) {
        this.$store.dispatch('request/getCatalogList')
      }
    },
  },

  created() {
    this.getCatalogList()
  },
}
</script>
