<template>
  <div>
    <b-dropdown
      id="dropdown-form"
      ref="dropdown"
      :text="$t('action.sort_by')"
      variant="primary"
      :disabled="disabled"
      lazy
      no-caret
      menu-class="drop-menu"
      class="d-inline-flex align-items-center mr-2">
      <template #button-content><b-icon-caret-down-fill class="mr-1" />{{ $t('action.sort_by') }}</template>
      <draggable v-model="sortOpts" @start="drag = true" @end="drag = false" group="orderBy" :animation="200" ghostClass="ghost" v-bind="dragOptions">
        <!-- <transition-group type="transition" :name="!drag ? 'flip-list' : null"> -->
        <b-dropdown-form v-for="(opt, i) in sortOpts" :key="opt.order" inline form-class="drag-list-item w-100 d-flex flex-row align-items-center justify-content-between">
          <span class="action text-capitalize d-inline-block" @click="clearOpt(opt, i)">{{ opt.label }}</span>
          <b-form-group class="m-0 row" v-slot="{ ariaDescribedby }">
            <b-form-radio name="radio" value="asc" v-model="opt.dir" :aria-describedby="ariaDescribedby" class="sort-checkbox d-inline-block ml-3"><b-icon-arrow-up class="mr-1" />ASC</b-form-radio>
            <b-form-radio name="radio" value="desc" v-model="opt.dir" :aria-describedby="ariaDescribedby" class="sort-checkbox d-inline-block ml-2">
              <b-icon-arrow-down class="mr-1" />DESC
            </b-form-radio>
            <b-button class="border-0 ml-2" variant="outline-secondary" style="cursor: move"><b-icon-grip-vertical /></b-button>
          </b-form-group>
        </b-dropdown-form>
        <!-- </transition-group> -->
      </draggable>
      <b-dropdown-divider />
      <b-dropdown-form form-class="w-100 d-flex flex-row align-items-center justify-content-end">
        <b-button variant="outline-secondary" size="sm" @click="reset"><b-icon-x class="mr-1" />{{ $t('action.clear') }}</b-button>
        <b-button variant="outline-primary" size="sm" class="ml-2" @click="onSort">{{ $t('action.confirm') }}</b-button>
      </b-dropdown-form>
    </b-dropdown>
  </div>
</template>
<script>
import draggable from 'vuedraggable'

export default {
  name: 'SortBySelect',

  components: { draggable },

  props: {
    fields: Array,
    queryParams: Object,
    onSearch: Function,
    disabled: Boolean,
  },

  data() {
    return {
      drag: false,
      sortOpts: this.fields.filter((f) => f?.sortKey).map((f, i) => ({ key: f.key, label: f.label ?? f.key.replace('_', ' '), sortKey: f.sortKey, order: i + 1, dir: '' })),
    }
  },

  computed: {
    dragOptions() {
      return {
        animation: 250,
        group: 'orderBy',
        disabled: false,
        ghostClass: 'ghost',
      }
    },
  },

  watch: {
    disabled(newVal, oldVal) {
      if (newVal) {
        this.reset()
      }
    },
  },

  methods: {
    clearOpt(opt, i) {
      this.sortOpts.find((x) => x.sortKey == opt.sortKey).dir = ''
    },
    reset() {
      this.sortOpts.forEach((opt, i) => this.clearOpt(opt, i))
      this.sortOpts = this.sortOpts.sort((a, b) => a.order - b.order)
      this.queryParams.orderBy = ''
    },
    onSort() {
      const tmp = {}
      this.sortOpts.forEach((opt) => {
        if (opt.dir) {
          tmp[opt.sortKey] = opt.dir
        }
      })
      this.queryParams.orderBy = JSON.stringify(tmp)
      this.onSearch()
    },
  },
}
</script>
<style>
.drop-menu {
  width: max-content !important;
  min-width: 12rem;
  max-width: 30rem;
}

.sort-checkbox {
  padding-left: 2rem;
}

.sort-checkbox label::before,
.sort-checkbox label::after {
  left: -2rem;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.drag-list {
  min-height: 20px;
}

.drag-list-item {
  cursor: move;
}

/* .flip-list-move {
  transition: transform 0.5s;
} */
</style>
