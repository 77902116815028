<template>
  <card type="default" header-classes="bg-transparent">
    <loading :active="isLoading" :can-cancel="false" :is-full-page="false" />

    <TimeSelect :displayInfo="{ name: $t('dashboard.chart_name[6]') }" :initBigChart="initBigChart" type="failedPayments" :queryParams="queryParams" />
    <BarChart :height="350" ref="bigChart" :chart-data="chartModel.chartData" :options="chartModel.extraOptions" />

    <b-modal
      id="failed-payments"
      lazy
      centered
      :title="`${$t('dashboard.chart_name[6]')} ${details ? details.day : ''}`"
      title-class="w-100 d-flex justify-content-center align-self-center"
      header-class="py-2"
      body-class="py-0"
      footer-class="py-2"
      ok-only
      ok-variant="outline-secondary"
      :ok-title="$t('action.close')"
      @hidden="details = null"
      ><template v-if="details">
        <div v-for="(key, index) in ['axcess_reasons', 'applepay_reasons', 'googlepay_reasons', 'barclaycard_reasons']" :key="key + index" style="display: contents">
          <div :class="'text-md' + (index == 0 ? '' : ' mt-4')">
            {{ $t('dashboard.failed_payments')[index] }}:
            <span class="ml-2 text-primary font-weight-bold">{{ details[key.replaceAll('_reasons', '')] }}</span>
          </div>
          <b-table-lite v-if="details[key].length > 0" :fields="fields" :items="details[key]" small bordered hover head-variant="light" class="mt-1" />
        </div>
        <div class="text-md mt-4">
          {{ $t('dashboard.failed_payments[4]') }}:<span class="ml-2 text-primary font-weight-bold">{{ details.paypal }}</span>
        </div>
      </template>
    </b-modal>
  </card>
</template>
<script>
import BarChart from '@/components/Charts/BarChart'
import TimeSelect from '@/components/Charts/TimeSelect.vue'
import { barChartOptions } from '@/components/Charts/config'

export default {
  name: 'FailedPayments',
  components: { TimeSelect, BarChart },
  data() {
    // customize failed payment chart tooltip options to show detailed failed reasons
    const extraOptions = {}
    for (const key in barChartOptions) {
      extraOptions[key] = barChartOptions[key]
      if (key == 'tooltips') {
        extraOptions[key]['xAlign'] = 'center'
        extraOptions[key]['yAlign'] = 'center'
        extraOptions[key]['position'] = 'average'
        extraOptions[key]['callbacks'] = {
          afterLabel: function (tooltipItem, data) {
            const details = data.datasets[tooltipItem.datasetIndex].details
            if (details) {
              const tmp = details[tooltipItem.index]?.sort((a, b) => b.amount - a.amount).map((x, i) => (i > 2 ? null : i < 2 ? x.reason + ': ' + x.amount : '… Click for details.'))
              return tmp?.filter((x) => !!x)
            }
          },
        }
      }
    }

    return {
      queryParams: {},
      isLoading: false,
      chartModel: { chartData: {}, extraOptions: extraOptions },
      rawData: [],
      details: null,
      fields: [
        { key: 'reason', label: this.$t('general.reason'), tdAttr: { style: 'white-space: normal' } },
        { key: 'amount', label: this.$tc('general.amount', 1), thStyle: 'width: 100px', tdClass: 'text-primary font-weight-bold', class: 'text-center' },
      ],
      colors: ['#DB4437', '#bc32dd', '#44e3ef', '#4267B2', '#F4B400'],
    }
  },
  methods: {
    async getFailedPayments() {
      this.isLoading = true
      await this.$store
        .dispatch('request/getFailedPayments', this.queryParams)
        .then(() => (this.isLoading = false))
        .catch(() => (this.isLoading = false))
      this.rawData = this.$store.getters['request/failedPayments'] ?? []
    },
    initBigChart() {
      // failed payments bar chart set
      this.getFailedPayments().then(() => {
        const keys = ['applepay', 'googlepay', 'barclaycard', 'paypal']
        const showAxcess = this.rawData.reduce((res, item) => {
          if (!res && Number(item.axcess) > 0) {
            res = true
          }
          return res
        }, false)
        if (showAxcess) {
          keys.splice(0, 0, 'axcess')
        } // else axcess has no value > 0, do not show axcess data
        const datasets = keys.map((key, index) => {
          const i = showAxcess ? index : index + 1
          return ({
            label: this.$t('dashboard.failed_payments')[i],
            data: this.rawData.map((val) => val[key]),
            details: this.rawData.map((val) => (val[key + '_reasons']?.length > 0 ? [...val[key + '_reasons']] : null)),
            borderColor: this.colors[i],
            backgroundColor: this.colors[i],
          })
        })
        this.chartModel.chartData = {
          datasets: datasets,
          labels: this.rawData.map((val) => this.$moment(val.day).format('DD/MM')),
        }
      })
    },
  },
  created() {
    this.chartModel.extraOptions['onClick'] = (evt, els) => {
      if (els?.[0]) {
        this.details = this.rawData[els[0]._index]
        if (this.details && (this.details.axcess_reasons?.length > 0 || this.details.barclaycard_reasons?.length > 0)) {
          this.$bvModal.show('failed-payments')
        }
      }
    }
  },
  // queryParams will be initialized when TimeSelect created
  mounted() {
    this.initBigChart()
  },
}
</script>
