<template>
  <b-modal
    lazy
    centered
    id="video-draw-upsert"
    :title="title"
    title-class="w-100 d-flex justify-content-center align-self-center"
    header-class="w-100 py-2"
    body-class="py-0"
    footer-class="py-2"
    @shown="onShown"
    @hidden="onHidden"
    :busy="isLoading"
    ><loading :active="isLoading" :can-cancel="false" :is-full-page="false" />
    <b-form @submit.prevent="onSubmit" id="video-draw-edit">
      <b-form-group id="input-comp-title" label-class="text-sm" label-for="comp-title" class="m-0">
        <template #label>{{ $t('competition.comp_title') }}<span class="text-danger ml-1">*</span></template>
        <b-form-input id="comp-title" :value="compTitle" disabled />
      </b-form-group>
      <b-form-group id="input-title" label-class="text-sm" label-for="title" class="mt-3 mb-0">
        <template #label>{{ $t('draw.vid_draw_title') }}<span class="text-danger ml-1">*</span></template>
        <b-form-input id="title" v-model="form.title" required />
      </b-form-group>
      <b-form-group id="input-draw-date" label-class="text-sm" label-for="draw-date" class="mt-3 mb-0 d-inline-block w-50 pr-1">
        <template #label>{{ $t('draw.draw_date') }}<span class="text-danger ml-1">*</span></template>
        <b-form-input id="draw-date" v-model="form.draw_date" type="datetime-local" :placeholder="$t('input.choose_date')" disabled />
      </b-form-group>
      <b-form-group id="input-store" label-class="text-sm" label-for="store-id" class="mt-3 mb-0 d-inline-block w-50 pl-1">
        <template #label>{{ $tc('general.store', 1) }}<span class="text-danger ml-1">*</span></template>
        <b-form-select id="store-id" :value="targetStore" :options="storeOpts" required disabled />
      </b-form-group>
      <b-form-group id="input-url" label-class="text-sm" label-for="url" class="mt-3 mb-0">
        <template #label>{{ $t('draw.vid_draw_url') }}<span class="text-danger ml-1">*</span></template>
        <b-form-input id="url" v-model="form.url" required />
      </b-form-group>
      <b-form-group id="input-iframe" label-class="text-sm" label-for="iframe" class="mt-3 mb-0">
        <template #label>{{ $t('draw.vid_draw_iframe') }}</template>
        <b-form-textarea id="iframe" v-model="form.iframe" :rows="6" :max-rows="6" style="resize: auto !important" />
      </b-form-group>
    </b-form>
    <template #modal-footer="{ cancel }">
      <b-button v-if="draw && draw.status == 0" type="submit" form="video-draw-edit" @click="onPublish" size="sm" variant="outline-primary">{{ $t('action.publish') }}</b-button>
      <b-button type="submit" form="video-draw-edit" @click="onUpdate" size="sm" variant="outline-primary">{{ $t('action.save') }}</b-button>
      <b-button @click.prevent="cancel" size="sm" variant="reset">{{ $t('action.cancel') }}</b-button>
    </template>
  </b-modal>
</template>
<script>
import storeMixin from '@/mixins/store-mixin'
import { formatLocalDateTime, formatUtcDateTime } from '@/utils/dateTimeUtils'
import { mapGetters } from 'vuex'

export default {
  name: 'VideoDrawUpsert',
  mixins: [storeMixin],
  props: {
    title: String,
    drawDetails: Object,
    compDetails: Object,
    getList: Function,
  },
  data() {
    return {
      isLoading: false,
      action: '',
      statusForm: {},
      form: {
        id: null,
        title: '',
        draw_date: '',
        store_id: this.targetStore,
        url: '',
        iframe: '',
        competition_id: '',
      },
      draw: null,
    }
  },
  computed: {
    ...mapGetters({ videoDrawList: 'videoDrawList' }),
    compTitle() {
      return this.draw?.competition?.title ?? null
    },
    targetStore() {
      return this.drawDetails?.targetStore ?? this.compDetails?.targetStore ?? this.selfStore
    },
  },
  methods: {
    onShown() {
      if (this.drawDetails) {
        this.draw = this.drawDetails
        this.form.id = this.drawDetails.id
        this.form.title = this.drawDetails.title
        this.form.draw_date = formatLocalDateTime(this.drawDetails.draw_date, 'YYYY-MM-DDTHH:mm')
        this.form.store_id = this.drawDetails.targetStore
        this.form.url = this.drawDetails.url
        this.form.iframe = this.drawDetails.iframe
        this.form.competition_id = this.drawDetails.competition?.id ?? null
      }
      if (this.compDetails) {
        this.form.competition_id = this.compDetails.id
        this.form.store_id = this.compDetails.targetStore
        this.form.draw_date = formatLocalDateTime(this.compDetails.draw_date, 'YYYY-MM-DDTHH:mm')
        this.form.title = this.compDetails.title
        this.isLoading = true
        this.$store
          .dispatch('request/getVideoDrawList', { competition_id: this.compDetails.id })
          .then((res) => {
            this.isLoading = false
            const target = res?.data?.data?.data?.find((x) => x.store_id == this.targetStore)
            if (target) {
              this.draw = target
              this.form.title = target.title
              this.form.draw_date = formatLocalDateTime(target.draw_date, 'YYYY-MM-DDTHH:mm')
              this.form.url = target.url
              this.form.iframe = target.iframe
              this.form.id = target.id
            } else {
              this.draw = { id: null, status: 0, competition: this.compDetails }
              this.form.id = null
              this.form.url = 'https://www.facebook.com/plugins/video.php?height=314&href='
            }
          })
          .catch(() => (this.isLoading = false))
      }
      this.isLoading = false
    },
    onSubmit() {
      this.isLoading = true
      const params = {
        ...this.form,
        draw_date: formatUtcDateTime(this.form.draw_date),
        errorMsg: this.$t('save_notice[0]', { action: this.action }),
      }
      this.$store
        .dispatch('request/updateVideoDrawList', params)
        .then((res) => {
          if (this.action === 'publish') {
            this.statusForm['id'] = res.data.data.id
            this.statusForm['status'] = 1
            this.statusForm['successMsg'] = this.$t('draw.publish_notice[0]')
            this.statusForm['errorMsg'] = this.$t('draw.publish_notice[1]')
            this.$store
              .dispatch('request/updateVideoDrawStatus', this.statusForm)
              .then(() => {
                this.isLoading = false
                this.getList()
                this.$bvModal.hide('video-draw-upsert')
              })
              .catch(() => (this.isLoading = false))
          } else {
            this.isLoading = false
            this.$notify({ group: 'root', type: 'success', text: this.$t('draw.save_notice[0]') })
            this.getList()
            this.$bvModal.hide('video-draw-upsert')
          }
        })
        .catch(() => (this.isLoading = false))
    },
    onUpdate() {
      this.action = 'submit'
    },
    onPublish() {
      this.action = 'publish'
    },
    onHidden() {
      this.isLoading = true
      this.draw = null
      this.form.id = null
      this.form.title = ''
      this.form.draw_date = ''
      this.form.store_id = this.targetStore
      this.form.url = ''
      this.form.iframe = ''
      this.form.competition_id = null
      this.$emit('update:compDetails', null)
      this.$emit('update:drawDetails', null)
    },
  },
  mounted() {
    this.draw = this.drawDetails
  },
}
</script>
