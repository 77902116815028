<template>
  <div class="px-4 pt-3 pb-2 d-flex flex-row flex-wrap align-items-start">
    <b-form-select v-if="showStore" v-model="localParams['store_id']" :options="storeOpts" class="mb-2 mr-2" style="width: 90px" />
    <b-form-select v-if="showCurrency" v-model="localParams['currency']" :options="currencyOpts" class="mb-2 mr-2" style="width: 80px" />
    <CatalogSelect v-if="showCatalog" :storeId="localParams['store_id']" :catalogId.sync="queryParams['catalog_id']" class="mb-2 mr-2" />
    <slot name="other-search"></slot>
    <!-- <b-form-select v-if="showTimezone" v-model="localParams['timezone']" :options="tzOpts" class="mb-2 mr-2" style="width: 140px" /> -->
    <b-form-input v-if="showMonthPicker" v-model="monthRange" type="month" :placeholder="$t('report.select_month')" :max="maxMonth" class="mb-2 mr-2" style="width: 155px" />
    <date-range-picker
      v-if="showDatePicker"
      v-model="dateRange"
      :locale-data="{ format: 'yyyy-mm-dd' }"
      :auto-apply="true"
      :show-dropdowns="true"
      :ranges="ranges"
      :max-date="maxDate"
      class="mb-2 mr-2"
      style="width: max-content; min-width: 220px"
      v-b-tooltip.hover.top="typeof showDatePicker == 'string' ? $t('notify.date_range_notice', { name: showDatePicker }) : null" />
    <slot name="other-search2"></slot>
    <div :class="`d-flex flex-row flex-wrap align-items-center flex-grow-1 ${hasSlot('other-button') ? 'justify-content-start' : 'justify-content-between'}`">
      <button-group :onSearch="_onSearch" :onReset="onReset" :showExport="true" :onExport="onExport" class="mb-2 mr-2" />
      <!-- <b-icon-info-circle-fill v-if="showTimezone" class="mb-2 text-lg text-twitter" v-b-tooltip.hover.left="$t('report.hourly_data_notice') + `${updateTime} ${queryParams.timezone}.`" /> -->
      <b-icon-info-circle-fill
        v-if="type != 'multi-pay'"
        class="mb-2 text-lg text-twitter"
        v-b-tooltip.hover.left="(type == 'sales' ? 'The data for today will be generated at ' : $t('report.hourly_data_notice')) + `${updateTime} (${localTz}).`" />
    </div>
    <slot name="other-button"></slot>
  </div>
</template>
<script>
import CatalogSelect from '@/components/Catalog/CatalogSelect.vue'
import storeMixin from '@/mixins/store-mixin'
import utilsMixin from '@/mixins/utils-mixin'
import { setupDateRanges } from '@/utils/dateTimeUtils'
import { mapGetters } from 'vuex'

export default {
  name: 'ReportSearchInput',
  components: { CatalogSelect },
  mixins: [
    utilsMixin, // hasSlot,
    storeMixin, // storeOpts, currencyOpts, selfStore, selfCurrency
  ],
  props: {
    type: String,
    queryParams: Object,
    showStore: Boolean,
    showCurrency: Boolean,
    showDatePicker: [Boolean, String],
    showMonthPicker: Boolean,
    showCatalog: Boolean,
    showTimezone: Boolean,
    onExport: Function,
    onSearch: Function,
    onReset: Function,
  },
  data() {
    return {
      ranges: null,
      updateTime: null,
      localParams: {
        store_id: null,
        currency: null,
        // timezone: null,
      },
    }
  },
  computed: {
    ...mapGetters({
      // tzOpts: 'settings/tzOpts',
      localTz: 'settings/timezone',
    }),
    maxDate() {
      return this.$moment.utc().tz(this.localTz).endOf('day').format('YYYY-MM-DD')
    },
    maxMonth() {
      return this.$moment.utc().tz(this.localTz).endOf('month').format('YYYY-MM')
    },
    startKey() {
      return this.getKeyName('start', 'start_date')
    },
    endKey() {
      return this.getKeyName('end', 'end_date')
    },
    dateRange: {
      get() {
        return {
          startDate: this.queryParams[this.startKey] ? this.$moment(this.queryParams[this.startKey]).format('YYYY-MM-DDTHH:mm:ss') : null,
          endDate: this.queryParams[this.endKey] ? this.$moment(this.queryParams[this.endKey]).format('YYYY-MM-DDTHH:mm:ss') : null,
        }
      },
      set(newVal) {
        this.queryParams[this.startKey] = newVal ? this.$moment(newVal.startDate).format('YYYY-MM-DD') : null
        this.queryParams[this.endKey] = newVal ? this.$moment(newVal.endDate).format('YYYY-MM-DD') : null
        return newVal
      },
    },
    monthRange: {
      get() {
        return this.$moment(this.queryParams[this.startKey]).format('YYYY-MM')
      },
      set(newVal) {
        this.queryParams[this.startKey] = this.$moment(newVal).startOf('month').format('YYYY-MM-DD')
        this.queryParams[this.endKey] = this.$moment(newVal).endOf('month').format('YYYY-MM-DD')
        return newVal
      },
    },
  },
  methods: {
    getUpdateTime() {
      var now = this.$moment.utc()
      if (this.type == 'sales') {
        this.updateTime = now.add(1, 'day').tz(this.localTz).format('YYYY-MM-DD 01:30:00 Z')
      } else if (now.minutes() <= 31) {
        this.updateTime = now.subtract(1, 'hour').tz(this.localTz).format('YYYY-MM-DD HH:00:00 Z')
      } else {
        this.updateTime = now.tz(this.localTz).format('YYYY-MM-DD HH:00:00 Z')
      }
    },
    getKeyName(startOrEnd, def) {
      var tmp = def
      for (let key in this.queryParams) {
        if (tmp != key && key.includes(startOrEnd)) {
          tmp = key
        }
      }
      return tmp
    },
    _onSearch() {
      if (this.showStore) {
        this.queryParams['store_id'] = this.localParams.store_id
      } else {
        delete this.queryParams.store_id
      }

      if (this.showCurrency) {
        this.queryParams['currency'] = this.localParams.currency
      } else {
        delete this.queryParams.currency
      }

      // if (this.showTimezone) {
      //   this.queryParams['timezone'] = this.localParams.timezone
      //   this.getUpdateTime()
      // } else {
      //   delete this.queryParams.timezone
      // }

      this.onSearch()
      this.getUpdateTime()
    },
  },
  created() {
    if (this.showStore) {
      this.localParams['store_id'] = this.queryParams['store_id'] = this.selfStore
    } else {
      delete this.queryParams.store_id
    }

    if (this.showCurrency) {
      this.localParams['currency'] = this.queryParams['currency'] = this.selfCurrency
    } else {
      delete this.queryParams.currency
    }

    // if (this.showTimezone) {
    //   this.localParams['timezone'] = this.queryParams['timezone'] = this.localTz
    //   this.getUpdateTime()
    // } else {
    //   delete this.queryParams.timezone
    // }

    this.queryParams['timezone'] = this.localTz
    var now = this.$moment.utc().tz(this.localTz)
    if (this.showMonthPicker) {
      this.queryParams[this.startKey] = now.clone().startOf('month').format('YYYY-MM-DD')
      this.queryParams[this.endKey] = now.clone().endOf('month').format('YYYY-MM-DD')
    } else if (this.showDatePicker) {
      this.ranges = setupDateRanges(true)
      this.queryParams[this.endKey] = this.type == 'multi-pay' ? null : this.type == 'sales' ? now.clone().subtract(1, 'day').format('YYYY-MM-DD') : now.clone().format('YYYY-MM-DD')
      this.queryParams[this.startKey] = this.type == 'multi-pay' ? null : now.clone().subtract(7, 'day').format('YYYY-MM-DD')
    }
  },
  mounted() {
    this.getUpdateTime()
  },
}
</script>
