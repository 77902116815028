<template>
  <card body-classes="p-0 d-flex flex-column" class="table-custom">
    <loading :active="isLoading" :can-cancel="false" :is-full-page="false" />
    <div class="px-4 pt-3 pb-2 d-flex flex-row align-items-center justify-content-xl-between">
      <b-form-select v-model="store_id" :options="storeOpts" required :disabled="!allowMultiStores" class="mt-0 mb-0 ml-0 mr-2" style="width: 90px" />
      <b-button variant="basic" @click="openUpsert(null)"><b-icon-plus class="mr-1" />{{ $t('catalog.create_new_catalog') }}</b-button>
    </div>
    <b-table
      :fields="fields"
      :items="items"
      :busy="isLoading"
      show-empty
      :empty-text="$t('notify.table_no_records')"
      small
      hover
      responsive
      class="m-0 p-0"
      head-variant="light"
      primary-key="id"
      sticky-header="100%">
      <template #cell(is_active)="{ value }">
        <bool-badge :value="value" :text="value == 1 ? $t('status.shown') : $t('status.hidden')" />
      </template>
      <template #cell(display_all)="{ value }">
        <bool-badge :value="value" :text="value == 1 ? $t('yes') : $t('no')" />
      </template>
      <template #cell(store_id)="{ value }"><store-badge :store_id="value" /></template>
      <template #cell(action)="{ item }">
        <!-- <b-button @click="openComps(item)" v-b-tooltip.hover.top="`Change Competition Positions`"><b-icon-list-ul class="mr-1" />{{ $tc('general.competition', 2) }}</b-button> -->
        <b-button @click="openUpsert(item)" :disabled="item.name == 'Popup'" v-b-tooltip.hover.topright="$t('action.edit')"><b-icon-pencil /></b-button>
      </template>
    </b-table>
    <CatalogUpsert :catalog.sync="actionTarget" :getCatalogs="getCatalogList" />
    <CatalogCompetitions :catalog.sync="actionTarget" :getCatalogs="getCatalogList" />
  </card>
</template>
<script>
import CatalogCompetitions from '@/components/Catalog/CatalogCompetitions.vue'
import CatalogUpsert from '@/components/Catalog/CatalogUpsert.vue'
import storeMixin from '@/mixins/store-mixin'
import utilsMixin from '@/mixins/utils-mixin'
import { formatLocalDateTime } from '@/utils/dateTimeUtils'
import { mapGetters } from 'vuex'
export default {
  mixins: [utilsMixin, storeMixin],
  components: { CatalogUpsert, CatalogCompetitions },
  data() {
    return {
      isLoading: false,
      store_id: this.$store.getters['store'], // to filter catalog list table
      items: [],
      action: '',
      actionTarget: null,
    }
  },
  computed: {
    ...mapGetters({ catalogList: 'request/catalogList' }),
    fields() {
      return [
        { key: 'name', label: this.$t('catalog.catalog_name') },
        { key: 'short_name', label: this.$t('catalog.short_name') },
        { key: 'is_active', label: this.$t('catalog.visibility'), class: 'text-center' },
        { key: 'display_all', label: this.$t('catalog.display_all'), class: 'text-center' },
        { key: 'store_id', label: this.$tc('general.store', 1), class: 'text-center' },
        { key: 'position', label: this.$t('general.position'), class: 'text-center' },
        { key: 'action', label: this.$tc('general.action', 1), class: 'text-center px-2' },
        { key: 'created_at', label: this.$t('general.created_at'), sortable: true, formatter: (v) => formatLocalDateTime(v) },
        { key: 'updated_at', label: this.$t('general.updated_at'), sortable: true, formatter: (v) => formatLocalDateTime(v) },
      ]
    },
  },
  methods: {
    openUpsert(row) {
      this.actionTarget = row
      this.$bvModal.show('catalog-upsert')
    },
    openComps(row) {
      this.actionTarget = row
      this.$bvModal.show('catalog-competitions')
    },
    getCatalogList() {
      this.isLoading = true
      this.$store
        .dispatch('request/getCatalogList')
        .then(() => (this.isLoading = false))
        .catch(() => (this.isLoading = false))
    },
    formatLocalDateTime,
    filterFn(store_id) {
      return (x) => x.store_id == store_id && !(x.store_id == 1 && x.short_name == 'home')
    },
  },
  watch: {
    catalogList(val) {
      this.items = val.filter(this.filterFn(this.store_id)).sort((a, b) => b.updated_at - a.updated_at)
    },
    store_id(val) {
      this.items = this.catalogList?.filter(this.filterFn(val)) ?? []
    },
  },
  created() {
    this.getCatalogList()
  },
}
</script>
