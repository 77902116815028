<template>
  <b-modal
    lazy
    centered
    id="user-trans"
    :title="userDetails ? $t(userDetails.isCash ? 'user.cash_trans' : 'user.credit_trans') + ' #' + userDetails.id : ''"
    title-class="w-100 d-flex justify-content-center align-self-center"
    header-class="py-2"
    body-class="pt-0"
    hide-footer
    @shown="onShown"
    @hidden="onHidden">
    <loading :active="isLoading" :can-cancel="false" :is-full-page="false" />
    <template v-if="userDetails && userDetails['id']">
      <b-table show-empty small hover responsive sticky-header="70vh" head-variant="light" class="m-0 p-0" :fields="fields" :items="items">
        <template #cell(status)="{ value }">
          <CashTransStatus v-if="userDetails.isCash" :value="value" />
          <CreditTransStatus v-else :value="value" />
        </template>
        <template #cell(comment)="{ value }">
          <span v-b-tooltip.hover.left="value">{{ value }}</span>
        </template>
      </b-table>
      <paginate v-if="items.length > 0" :queries="queries" :fetcher="getList" :total="total" class="card-footer" />
    </template>
  </b-modal>
</template>
<script>
import CashTransStatus from '@/components/CashTransStatus.vue'
import CreditTransStatus from '@/components/CreditTransStatus.vue'
import { API_LIST } from '@/utils/consts'
import { formatLocalDateTime } from '@/utils/dateTimeUtils'
import { floatDivision, formatWithCurrency } from '@/utils/numberUtils'
import axios from '../../plugins/axios'
import { formatWithGBP } from '../../utils/numberUtils'
export default {
  name: 'UserTransactions',
  components: { CashTransStatus, CreditTransStatus },
  props: {
    userDetails: Object,
  },
  data() {
    return {
      isLoading: true,
      queries: { page: 1, perPage: 10 },
    }
  },
  computed: {
    fields() {
      const isCash = this.userDetails?.isCash
      return [
        { key: 'id', label: this.$t('general.id'), sortable: true },
        { key: 'status', label: this.$t('general.status'), sortable: true },
        isCash ? { key: 'action', label: this.$t('general.type'), sortable: true, formatter: (v) => this.$t('transaction.cash_actions.' + v) ?? v } : null,
        { key: isCash ? 'amount_auth' : 'balance_amount', label: this.$tc('general.amount', 1), formatter: (v) => (isCash ? formatWithGBP(v) : v), class: 'text-right', sortable: true },
        {
          key: isCash ? 'amount_after' : 'total_points_after',
          label: 'Balance After',
          class: 'text-right',
          tdAttr: isCash ? (v, k, item) => ({ style: ['canceled', 'failed'].includes(item.status) ? 'text-decoration: line-through' : '' }) : null,
          sortable: true,
          formatter: isCash ? (v) => formatWithGBP(v) : (v) => formatWithCurrency(floatDivision(v ?? 0, 10000), ['GBP', 'MXN'][this.userDetails?.store_id - 1]),
        },
        { key: isCash ? 'additional_info' : 'comment', label: this.$tc('general.comment', 1), tdClass: 'text-truncate' },
        isCash ? { key: 'user.name', label: this.$t('general.username') } : null,
        { key: 'created_at', label: this.$t('general.created_at'), formatter: (v) => formatLocalDateTime(v), sortable: true },
        { key: 'admin_user_id', label: this.$t('general.created_by'), formatter: (v) => v || 'SYSTEM' },
      ]
    },
    items: {
      get() {
        return this.$store.getters[`request/${this.userDetails?.isCash ? 'cashTransList' : 'creditTransList'}`]?.data || []
      },
      set: (v) => v,
    },
    total: {
      get() {
        return this.$store.getters[`request/${this.userDetails?.isCash ? 'cashTransList' : 'creditTransList'}`]?.total || 0
      },
      set: (v) => v,
    },
  },
  methods: {
    onShown(evt) {
      if (this.userDetails?.id) {
        this.getList()
      }
    },
    getList() {
      this.isLoading = true
      const isCash = this.userDetails.isCash
      axios
        .get(isCash ? API_LIST.get.userCashTrans : API_LIST.get.userCreditTrans, {
          params: {
            ...this.queries,
            [isCash ? 'user_id' : 'id']: this.userDetails.id,
            currency: ['GBP', 'MXN'][this.userDetails.store_id - 1],
          },
        })
        .then((res) => {
          this.items = res?.data?.data?.data || []
          this.total = res?.data?.data?.total || 0
          this.$store.dispatch('request/mutateState', { property: isCash ? 'cashTransList' : 'creditTransList', with: res?.data?.data })
        })
        .finally(() => (this.isLoading = false))
    },
    onHidden(evt) {
      this.$emit('update:userDetails', null)
    },
    formatLocalDateTime,
    formatWithCurrency,
    floatDivision,
  },
}
</script>
