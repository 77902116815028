<template>
  <b-row cols="1" cols-lg="2">
    <b-col>
      <div
        v-for="({ key, label }, i) in [
          { key: 'status', label: $t('general.status') },
          { key: 'comment', label: $tc('general.comment', 1) },
          { key: 'source_currency', label: $t('general.currency') },
          { key: 'source_amount', label: $t('cashback.total_payment') },
          { key: 'cashback_rate', label: $t('cashback.cashback_rate') },
          { key: 'cashback_amount', label: $t('cashback.cashback_amount') },
          { key: 'created_at', label: $t('general.created_at') },
          { key: 'updated_at', label: $t('general.updated_at') },
          { key: 'admin', label: $t('general.updated_by') },
        ]"
        :key="`cashback-trans-details-${i}`"
        class="mt-3 row">
        <span class="font-weight-bold text-sm col-4 text-nowrap">{{ label }}</span>
        <span class="ml-3 font-weight-light col-7">
          <template v-if="key == 'status'">
            <CreditTransStatus :value="details.status" />
            <template><slot name="change"></slot></template>
          </template>
          <template v-else>{{
            details[key] == null || details[key] == undefined
              ? $t('no_data')
              : key.indexOf('rate') > -1
              ? details[key] * 100 + '%'
              : key.indexOf('_at') > -1
              ? formatLocalDateTime(details[key])
              : key === 'admin'
              ? admin.email
              : details[key]
          }}</template>
        </span>
      </div>
    </b-col>
    <b-col>
      <div
        v-for="({ key, label }, i) in [
          { key: 'is_active', label: $t('user.user_status') },
          { key: 'id', label: $t('user.user_id') },
          { key: 'name', label: $t('general.username') },
          { key: 'balance_amount', label: $t('user.wallet_balance') },
          { key: 'balance_before', label: $t('cashback.balance_before') },
          { key: 'balance_after', label: $t('cashback.balance_after') },
          { key: 'created_at', label: $t('user.registered_at') },
          { key: 'display_name', label: $t('user.display_name') },
          { key: 'email', label: $t('user.user_email') },
        ]"
        :key="`cashback-trans-user-${i}`"
        class="mt-3 row">
        <span class="font-weight-bold text-sm col-4 text-nowrap">{{ label }}</span>
        <span class="ml-3 font-weight-light col-7">
          <bool-badge v-if="key == 'is_active'" :value="user.is_active" :text="user.is_active == 1 ? $t('status.active') : $t('status.inactive')" />
          <template v-else>
            {{ key.indexOf('_at') > -1 ? formatLocalDateTime(details[key]) : key.indexOf('balance_') > -1 ? details[key] : user[key] ? user[key] : $t('no_data') }}
          </template>
        </span>
      </div>
    </b-col>
  </b-row>
</template>
<script>
import CreditTransStatus from '@/components/CreditTransStatus.vue'
import { formatLocalDateTime } from '@/utils/dateTimeUtils'

export default {
  name: 'CashbackTransDetails',
  components: { CreditTransStatus },
  data() {
    return {}
  },
  props: {
    details: Object,
  },
  computed: {
    admin() {
      return this.details?.admin
    },
    user() {
      return this.details?.user
    },
  },
  methods: {
    formatLocalDateTime,
  },
}
</script>
