<template>
  <b-badge pill :variant="['danger', 'secondary', 'danger', 'warning', 'primary', 'info', 'success', 'danger'][value + 3]">
    {{ $t('order.status_list')[value + 3] }}
  </b-badge>
</template>
<script>
export default {
  name: 'OrderStatus',
  props: {
    value: Number,
  },
}
</script>
