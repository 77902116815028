<template>
  <card type="default" header-classes="bg-transparent">
    <loading :active="isLoading" :can-cancel="false" :is-full-page="false" />
    <TimeSelect :displayInfo="{ name: $t('dashboard.chart_name[2]') }" :initBigChart="initBigChart" type="dailyCompetitionSales" :queryParams="queryParams" />
    <BarChart :height="350" ref="bigChart" :chart-data="chartModel.chartData" :options="chartModel.extraOptions" />
  </card>
</template>
<script>
import { stackedBarChartOptions } from '@/components/Charts/config'
import BarChart from '@/components/Charts/BarChart'
import TimeSelect from '@/components/Charts/TimeSelect.vue'

export default {
  name: 'DailyCompetitionSales',
  components: { TimeSelect, BarChart },
  data() {
    return {
      isLoading: false,
      queryParams: {},
      chartModel: { chartData: {}, extraOptions: stackedBarChartOptions },
      rawData: [],
      colors: ['#DB4437', '#00457C', '#F4B400'],
    }
  },
  methods: {
    async getDailyCompetitionSales() {
      this.isLoading = true
      await this.$store
        .dispatch('request/getDailyCompetitionSales', this.queryParams)
        .then(() => (this.isLoading = false))
        .catch(() => (this.isLoading = false))
      this.rawData = this.$store.getters['request/dailyCompetitionSales']
    },

    initBigChart() {
      this.getDailyCompetitionSales().then(() => {
        this.chartModel.chartData = {
          datasets: [
            {
              label: this.$t('dashboard.competition_sales[0]'),
              data: this.rawData.map((val) => val.row_total),
              borderColor: this.colors[0],
              backgroundColor: this.colors[0],
              title: this.rawData.map((val) => val),
            },
            {
              label: this.$t('dashboard.competition_sales[1]'),
              data: this.rawData.map((val) => (val.discount_amount ? val.discount_amount : 0)),
              borderColor: this.colors[1],
              backgroundColor: this.colors[1],
            },
            {
              label: this.$t('dashboard.competition_sales[2]'),
              data: this.rawData.map((val) => (val.coupon_amount ? val.coupon_amount : 0)),
              borderColor: this.colors[2],
              backgroundColor: this.colors[2],
            },
          ],
          labels: this.rawData.map((val) => val.short_name),
        }
      })
    },
  },
  // queryParams will be initialized when TimeSelect created
  mounted() {
    this.initBigChart()
  },
}
</script>
