<template>
  <b-form @submit.prevent="onSubmit" id="product-upsert-form" class="card m-3 px-3 py-0 d-flex flex-column">
    <loading :active="isLoading" :can-cancel="false" :is-full-page="false" />
    <b-row>
      <b-col cols="12" md="6" class="px-4 mt-4">
        <b-form-group class="mb-0 w-100" :label-class="labelClass">
          <template #label>
            {{ $t('general.name') }}<span class="text-danger ml-1">*</span>
            <b-icon-question-circle-fill class="ml-1" v-b-tooltip.hover.topright="$t('notify.translate_notice[0]')" />
            <icon-translate v-if="initVal && initVal.nameLang" />
          </template>
          <b-form-input v-model="form['name']" type="text" :placeholder="$t('input.enter_name')" :title="form.name" required />
        </b-form-group>

        <b-form-group class="mt-3 mb-0 d-inline-block w-25 pr-1" :label="$t('general.type')" :label-class="'form-label-required ' + labelClass">
          <b-form-select v-model="form['type']" :options="typeOpts" required class="text-capitalize" />
        </b-form-group>
        <b-form-group class="mt-3 mb-0 d-inline-block w-25 pl-1" :label="$tc('general.store', 1)" :label-class="'form-label-required ' + labelClass">
          <b-form-select :disabled="!allowMultiStores" v-model="form['store_id']" :options="storeOpts" required />
        </b-form-group>
        <b-form-group class="mt-3 mb-0 d-inline-block w-50 pl-3" :label-class="labelClass">
          <template #label>
            {{ $t('product.stock_status') }}
            <b-icon-question-circle-fill class="ml-1" v-b-tooltip.hover.topright="$t('product.status_notice')" />
          </template>
          <bool-badge :value="form.status" :text="$t('product.stock_status_list')[form.status]" class="w-100" />
        </b-form-group>
        <b-form-invalid-feedback :state="form.type !== 'car'" class="text-slack">⚠ {{ $t('product.type_notice') }}</b-form-invalid-feedback>

        <b-form-group class="mt-3 mb-0 d-inline-block w-50" :label="$t('general.cost')" :label-class="'form-label-required ' + labelClass">
          <b-input-group>
            <b-form-select v-model="form['currency']" required style="width: fit-content; max-width: 80px" :disabled="!allowMultiStores">
              <b-form-select-option selected disabled hidden :value="null">{{ $t('general.currency') }}</b-form-select-option>
              <b-form-select-option value="GBP">{{ $t('gbp') }}</b-form-select-option>
              <b-form-select-option value="MXN">{{ $t('mxn') }}</b-form-select-option>
            </b-form-select>
            <b-form-input v-model="form['cost']" type="number" min="0.01" step="0.01" :placeholder="$t('general.cost')" required />
          </b-input-group>
        </b-form-group>
        <b-form-group class="mt-3 mb-0 d-inline-block w-50 pl-3" :label="$t('product.initial_amount')" :label-class="'form-label-required ' + labelClass">
          <b-form-input
            ref="initAmt"
            v-model="form['initial_amount']"
            type="number"
            min="0"
            step="1"
            :placeholder="$t('product.initial_amount')"
            number
            required
            :state="form.stock_amount >= 0 && form.available_amount >= 0" />
        </b-form-group>

        <b-form-group class="mt-3 mb-0 d-inline-block w-50" :label-class="labelClass">
          <template #label>
            {{ $t('product.stock_amount') }}
            <b-icon-question-circle-fill class="ml-1" v-b-tooltip.hover.topright="$t('product.stock_amount_notice')" />
          </template>
          <b-form-input v-model="form['stock_amount']" type="number" min="0" step="1" :placeholder="$t('product.stock_amount_notice')" number required disabled :state="form.stock_amount >= 0" />
        </b-form-group>
        <b-form-group class="mt-3 mb-0 d-inline-block w-50 pl-3" :label-class="labelClass">
          <template #label>
            {{ $t('product.available_amount') }}
            <b-icon-question-circle-fill class="ml-1" v-b-tooltip.hover.topright="$t('product.available_amount_notice')" />
          </template>
          <b-form-input
            v-model="form['available_amount']"
            type="number"
            min="0"
            step="1"
            :placeholder="$t('product.available_amount_notice')"
            number
            required
            disabled
            :state="form.available_amount >= 0" />
        </b-form-group>
        <b-form-invalid-feedback :state="form.stock_amount >= 0 && form.available_amount >= 0">⚠ {{ $t('product.amounts_notice') }}</b-form-invalid-feedback>

        <b-form-group class="mt-3 mb-0 d-inline-block w-50" :label-class="labelClass">
          <template #label>
            {{ $t('product.storing_time') }}<span class="text-danger ml-1">*</span>
            <b-icon-question-circle-fill class="ml-1" v-b-tooltip.hover.topright="$t('product.storing_time_notice[0]')" />
          </template>
          <b-form-input v-model="form['storing_time']" type="datetime-local" :placeholder="$t('input.choose_date')" required @input="validateStoringTime" :state="storingTimeValid" />
        </b-form-group>
        <b-form-group class="mt-3 mb-0 d-inline-block w-50 pl-3" :label="$t('product.delivering_time')" :label-class="labelClass">
          <b-form-input :value="!initVal ? $t('no_data') : formatLocalDateTime(initVal.delivering_time, 'DD/MM/YYYY HH:mm')" type="text" :placeholder="$t('no_data')" readonly disabled />
        </b-form-group>
        <b-form-invalid-feedback :state="storingTimeValid">
          ⚠{{
            $t(`product.storing_time_notice[${isEditRoute ? 2 : 1}]`, {
              time: isEditRoute && productDetails ? formatLocalDateTime(productDetails.created_at, 'YYYY-MM-DD HH:mm') : formatLocalDateTime(undefined, 'YYYY-MM-DD HH:mm', true),
            })
          }}
        </b-form-invalid-feedback>

        <b-form-group v-if="form.type == 'cash_multiple'" label="Payment Frequency" label-class="form-label-required" class="mt-3 mb-0 d-inline-block w-50">
          <b-form-select
            v-model="form['payment_frequency']"
            :options="[
              // { value: 'one-off', text: 'One-off' },
              { value: 'weekly', text: 'Weekly' },
              { value: 'monthly', text: 'Monthly' },
            ]"
            required />
        </b-form-group>

        <b-form-group v-if="form.type == 'cash_multiple'" label="Payment Times" label-class="form-label-required" class="mt-3 mb-0 d-inline-block w-50 pl-3">
          <b-form-input v-model="form['payment_numbers']" required number type="number" :step="1" :min="1" placeholder="Number of Payments" />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6" class="px-4 mt-4">
        <b-input-group class="mb-0 w-100 d-flex align-items-center" :label-class="labelClass">
          {{ $tc('general.image', imageItems.length) }}
          <b-form-file
            v-model="imageFiles"
            ref="imageFiles"
            accept=".png, .jpg, .jpeg, .gif"
            :placeholder="$t('input.no_file_chosen')"
            :browse-text="$t('action.browse')"
            :multiple="true"
            :file-name-formatter="formatNames"
            :state="imageSizeValid"
            size="sm"
            class="ml-2"
            style="max-width: 240px" />
          <b-button variant="outline-danger" @click="initImages" class="ml-2"><b-icon-x class="mr-1" />{{ $t('action.reset') }}</b-button>
        </b-input-group>
        <b-form-invalid-feedback :state="false" class="text-slack">⚠ {{ $t('image.ratio_notice') }}</b-form-invalid-feedback>
        <b-form-invalid-feedback :state="imageItems.length >= 1">⚠ {{ $t('product.no_image_notice') }}</b-form-invalid-feedback>
        <b-form-invalid-feedback :state="imageSizeValid">⚠ {{ $t('image.size_notice') }}</b-form-invalid-feedback>
        <b-form-invalid-feedback :state="imagePosValid">⚠ {{ $t('image.position_notice') }}</b-form-invalid-feedback>
        <b-table
          show-empty
          :empty-text="$t('notify.table_no_records')"
          small
          hover
          striped
          responsive
          sticky-header="18rem"
          head-variant="light"
          class="mx-0 mt-3 mb-0 p-0 flex-grow-1"
          tbody-class="h-100"
          :fields="imageFields"
          :items="imageItems">
          <template #head()="{ label, field }">{{ label }}<b-icon-question-circle-fill v-if="field.tooltip" class="ml-1" v-b-tooltip.hover.topright="field.tooltip" /></template>
          <template #cell(src)="{ value, item }">
            <a :href="`${value}`" target="_blank" v-if="item.uploaded">
              <b-img-lazy :src="value" :alt="value" fluid block rounded style="max-height: 4rem" class="hover-image" />
            </a>
            <b-img-lazy v-else :src="value" :alt="value" fluid block rounded style="max-height: 4rem" class="hover-image" />
          </template>
          <template #cell(position)="{ value, index }">
            <b-form-input
              :value="value"
              @change="updateImagePosition(index, $event)"
              @input="updateImagePosition(index, $event)"
              :state="imagePosValid"
              :placeholder="$t('general.position')"
              class="w-100"
              style="max-width: 3.5rem" />
          </template>
          <template #cell(file)="{ value, index }">
            <b-button variant="outline-danger" @click="deleteImage(index, value)"><b-icon-trash /></b-button>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="px-4 mt-5">
        <b-form-group class="mb-0" :label-class="labelClass">
          <template #label>
            {{ $t('general.description') + ' ' + $t('input.optional') }}
            <b-icon-question-circle-fill class="ml-1" v-b-tooltip.hover.topright="$t('notify.translate_notice[0]')" />
            <icon-translate v-if="initVal && initVal.descrLang" />
          </template>
          <b-form-textarea v-model="form.description" :placeholder="$t('general.description')" rows="12" no-resize />
        </b-form-group>
      </b-col>
      <b-col class="px-4 mt-5">
        <b-input-group class="mb-0 w-100 d-flex align-items-center" :label-class="labelClass">
          {{ $tc('product.invoice', invoiceItems.length) }}
          <b-form-file
            v-model="invoiceFiles"
            ref="invoiceFiles"
            accept=".png, .jpg, .jpeg, .gif, .pdf"
            :placeholder="$t('input.no_file_chosen')"
            :browse-text="$t('action.browse')"
            :multiple="true"
            :file-name-formatter="formatNames"
            size="sm"
            class="ml-2"
            style="max-width: 240px" />
          <b-button variant="outline-danger" @click="initInvoices" class="ml-2"><b-icon-x class="mr-1" />{{ $t('action.reset') }}</b-button>
          <b-button variant="outline-primary" v-b-modal.invoice-lookup class="ml-2">{{ $t('product.invoice_lookup') }}</b-button>
        </b-input-group>
        <b-table
          show-empty
          small
          hover
          striped
          responsive
          sticky-header="11rem"
          head-variant="light"
          class="mx-0 mt-3 mb-0 p-0 flex-grow-1"
          tbody-class="h-100"
          :empty-text="$t('notify.table_no_records')"
          :fields="invoiceFields"
          :items="invoiceItems">
          <template #cell(src)="{ value, item }">
            <template v-if="(item.file && item.file.type === 'application/pdf') || value.indexOf('.pdf') > -1">
              <b-link v-if="item.uploaded" :href="`${value}`" target="_blank">
                <i class="fa fa-file-pdf"> {{ item.file ? item.file.name : 'PDF' }}</i>
              </b-link>
              <i v-else class="fa fa-file-pdf"> {{ item.file ? item.file.name : 'PDF' }}</i>
            </template>
            <template v-else>
              <b-link v-if="item.uploaded" :href="`${value}`" target="_blank"><b-img-lazy :src="value" :alt="value" fluid block rounded style="max-height: 4rem" class="hover-image" /></b-link>
              <b-img-lazy v-else :src="value" :alt="value" fluid block rounded style="max-height: 4rem" class="hover-image" />
            </template>
          </template>
          <template #cell(file)="{ index, value }">
            <b-button variant="outline-danger" @click="deleteInvoice(index, value)"><b-icon-trash /></b-button>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <div class="row w-100 d-flex justify-content-end my-3">
      <b-button variant="primary" type="submit">{{ $t('action.submit') }}</b-button>
      <b-button variant="basic" @click="$router.back()">{{ $t('action.cancel') }}</b-button>
    </div>
    <ProductInventory id="confirm-cost" :title="$t('product.cost_change_confirmation[0]')" :product.sync="productDetails" :confirmMsg.sync="confirmMsg" />
    <InvoiceLookup :selected="invoiceItems" />
  </b-form>
</template>
<script>
import InvoiceLookup from '@/components/Product/InvoiceLookup.vue'
import ProductInventory from '@/components/Product/ProductInventory.vue'
import productMixin from '@/mixins/product-mixin'
import storeMixin from '@/mixins/store-mixin'
import { AwsUpdate } from '@/store/services/aws'
import { formatLocalDateTime, formatUtcDateTime } from '@/utils/dateTimeUtils'
import { base64Encode } from '@/utils/fileUtils.js'
import { notifyError } from '@/utils/index'
import { formatBytes } from '@/utils/numberUtils.js'
import { mapGetters } from 'vuex'

export default {
  name: 'ProductUpsert',
  mixins: [storeMixin, productMixin],
  props: { initVal: Object },
  components: { ProductInventory, InvoiceLookup },
  data() {
    return {
      isLoading: false,
      form: {
        store_id: this.selfStore,
        name: '',
        description: '',
        type: null,
        status: 0,
        initial_amount: null,
        stock_amount: null,
        available_amount: null,
        cost: 0,
        currency: ['GBP', 'MXN'][this.selfStore - 1],
        storing_time: null,
        delivering_time: null,
        attributes: [],
      },
      imageFiles: [],
      imageItems: [],
      invoiceFiles: [],
      invoiceItems: [],
      confirmMsg: '',
      productDetails: null,
      storingTimeValid: null,
      labelClass: 'd-flex font-weight-bold align-items-center',
      cashImg: 'https://cdn.rkingscompetitions.com/rkings/220830194913-20000-1.jpeg',
    }
  },
  computed: {
    ...mapGetters({ productEntityList: 'request/productEntityList' }),
    isEditRoute() {
      return this.$route.path.indexOf('/competitions/product-management/edit') > -1
    },
    typeOpts() {
      return this.productEntityList?.map((x) => ({ value: x, text: this.$t(`product.types.${x.replace('-', '_')}`) })).sort((a, b) => a.text.localeCompare(b.text)) ?? []
    },
    existingMultiPay() {
      return this.getExistingMultiPay(this.initVal)
    },
    existingImages() {
      return this.initVal?.attributes.filter((x) => x.code == 'image').sort((a, b) => a.position - b.position) ?? []
    },
    existingInvoices() {
      return this.initVal?.attributes.filter((x) => x.code == 'invoice') ?? []
    },
    imageSizeValid() {
      if (this.imageItems.length < 1) {
        return null
      }
      let tmp = true
      this.imageFiles.forEach((x) => (tmp = tmp && x.size < 614400))
      return tmp
    },
    imagePosValid() {
      let tmp = true
      const pos = this.imageItems.map((x) => x.position)
      pos.forEach((x, i) => (tmp = tmp && pos.indexOf(x) === i))
      return tmp
    },
    allUploaded() {
      return !this.imageItems.find((image) => !image.uploaded) && !this.invoiceItems.find((invoice) => !invoice.uploaded)
    },
    formattedName() {
      return (
        this.$moment.utc().format('YYYYMMDDHHmmss') +
        '-' +
        this.form.name
          .toLowerCase()
          .split(' ')
          .splice(0, 3)
          .join('-')
          .replace(/[^a-z0-9-]/gi, '')
      )
    },
    imageFields() {
      return [
        { key: 'src', label: this.$t('general.preview'), tooltip: this.$t('image.ratio_notice') },
        { key: 'position', label: this.$t('general.position'), tooltip: this.$t('image.position_notice') },
        { key: 'size', label: this.$t('general.size'), formatter: (v) => formatBytes(v), tdClass: (v) => (v >= 614400 ? 'text-danger' : ''), tooltip: this.$t('image.size_notice') },
        { key: 'file', label: this.$t('action.delete'), formatter: (v) => (v ? v.name : null) },
        { key: 'name', label: this.$t('image.formatted_name') },
      ]
    },
    invoiceFields() {
      return [
        { key: 'src', label: 'Preview' },
        this.imageFiles.length > 0 ? { key: 'size', formatter: (v) => formatBytes(v) } : null,
        { key: 'file', label: 'delete', formatter: (v) => (v ? v.name : null) },
        { key: 'name', label: 'Formatted Name' },
      ]
    },
    costChanged: {
      get() {
        return this.isEditRoute && this.initVal?.cost && parseFloat(this.initVal.cost) !== parseFloat(this.form.cost)
      },
      set(val) {
        return val
      },
    },
  },
  watch: {
    imageFiles(newVal, oldVal) {
      const tmp = this.imageItems.filter((x) => x.uploaded)
      if (newVal.length > 0) {
        newVal.forEach((x, i) => {
          base64Encode(x).then((res) => {
            tmp.push({
              id: null,
              status: null,
              src: res,
              position: this.imageItems.length + 1,
              size: x.size,
              uploaded: false,
              file: x,
              name: this.formattedName + '-' + (this.imageItems.length + 1) + x.name.slice(x.name.lastIndexOf('.')),
            })
          })
        })
      }
      this.imageItems = tmp
    },
    invoiceFiles(newVal, oldVal) {
      const tmp = this.invoiceItems.filter((x) => x.uploaded)
      if (newVal.length > 0) {
        newVal.forEach((x, i) => {
          base64Encode(x).then((res) => {
            tmp.push({
              id: null,
              status: null,
              src: res,
              position: 0,
              size: x.size,
              uploaded: false,
              file: x,
              name: this.formattedName + '-invoice-' + (this.invoiceItems.length + 1) + x.name.slice(x.name.lastIndexOf('.')),
            })
          })
        })
      }
      this.invoiceItems = tmp
    },
    'form.initial_amount'(newVal, oldVal) {
      if (!this.isEditRoute) {
        this.form.stock_amount = newVal
        this.form.available_amount = newVal
      } else if (newVal !== null && oldVal !== null) {
        const diff = newVal - oldVal
        this.form.stock_amount += diff
        this.form.available_amount += diff
      }
    },
    'form.stock_amount'(val) {
      this.form.status = val > 0 ? 1 : 0
    },
    'form.type'(newVal, oldVal) {
      if (newVal == 'cash' && this.imageItems.findIndex((x) => x.src == this.cashImg) == -1) {
        this.imageItems.splice(0, 0, {
          id: null,
          status: 1,
          src: this.cashImg,
          position: 0,
          size: NaN,
          uploaded: true,
          file: null,
          name: this.cashImg.slice(this.cashImg.lastIndexOf('/') + 1),
        })
      }
      if (oldVal != 'cash_multiple' && newVal == 'cash_multiple') {
        this.$set(this.form, 'payment_frequency', this.existingMultiPay?.code ?? 'weekly')
        this.$set(this.form, 'payment_numbers', Number(this.existingMultiPay?.value ?? 1))
      }
    },
    // 'form.payment_frequency'(val) {
    //   if (val == 'one-off' && this.form.payment_numbers != 1) {
    //     this.$set(this.form, 'payment_numbers', 1)
    //   }
    // },
  },
  methods: {
    formatLocalDateTime,
    formatBytes,
    formatNames(files) {
      return this.$tc('input.file_selected', files.length, { num: files.length })
    },
    initImages() {
      this.imageFiles = []
      this.imageItems = this.existingImages.map((x) => ({
        id: x.id,
        status: x.status,
        src: x.value,
        position: x.position,
        size: NaN,
        uploaded: true,
        file: null,
        name: x.value.substring(x.value.lastIndexOf('/') + 1),
      }))
    },
    initInvoices() {
      this.invoiceFiles = []
      this.invoiceItems = this.existingInvoices.map((x) => ({
        id: x.id,
        status: x.status,
        src: x.value,
        position: x.position,
        size: NaN,
        uploaded: true,
        file: null,
        name: x.value.substring(x.value.lastIndexOf('/') + 1),
      }))
    },
    initForm(val) {
      this.productDetails = val
      if (val) {
        // val['name'] = noTransFormatter(val.name, 'name', val)
        // val['description'] = noTransFormatter(val.description, 'descr', val)
        this.form = {
          ...val,
          storing_time: val.storing_time ? formatLocalDateTime(val.storing_time, 'YYYY-MM-DDTHH:mm') : null,
          // delivering_time: val.delivering_time ? formatLocalDateTime(val.delivering_time, 'YYYY-MM-DDTHH:mm') : null,
        }
      } else {
        this.form = {
          store_id: this.selfStore,
          name: '',
          description: '',
          type: '',
          status: 0,
          initial_amount: null,
          stock_amount: null,
          available_amount: null,
          cost: 0,
          currency: ['GBP', 'MXN'][this.selfStore - 1],
          storing_time: null,
          delivering_time: null,
          attributes: [],
        }
      }
      this.initImages()
      this.initInvoices()
    },
    deleteImage(i, filename) {
      if (!filename) {
        this.imageItems.splice(i, 1)
      } else {
        const j = this.imageFiles.findIndex((x) => x.name === filename)
        this.imageFiles.splice(j, 1)
      }
    },
    deleteInvoice(i, filename) {
      if (!filename) {
        this.invoiceItems.splice(i, 1)
      } else {
        const j = this.invoiceFiles.findIndex((x) => x.name === filename)
        this.invoiceFiles.splice(j, 1)
      }
    },
    updateImagePosition(i, evt) {
      const j = this.imageItems.findIndex((x) => x.position == parseInt(evt))
      if (j > -1) {
        this.imageItems[j].position = this.imageItems[i].position
      }
      this.imageItems[i].position = parseInt(evt)
    },
    validateStoringTime(evt) {
      if (this.isEditRoute) {
        const created_at = formatLocalDateTime(this.productDetails?.created_at, 'YYYY-MM-DDTHH:mm')
        return (this.storingTimeValid = !this.form.storing_time ? null : evt <= created_at)
      } else {
        const curr = formatLocalDateTime(undefined, 'YYYY-MM-DDTHH:mm', true)
        return (this.storingTimeValid = !this.form.storing_time ? null : evt <= curr)
      }
    },
    async onSubmit() {
      if (this.costChanged && !this.confirmMsg) {
        this.confirmMsg = this.$t('product.cost_change_confirmation[1]')
        this.$bvModal.show('confirm-cost')
        return
      }
      if (this.form.stock_amount < 0 || this.form.available_amount < 0 || this.imageSizeValid === false) {
        return
      }
      this.isLoading = true
      let cnt = 0
      const oldAttr = this.form.attributes
      const newAttr = []
      try {
        this.imageItems.forEach(async (image) => {
          if (image.id) {
            newAttr.push({ ...oldAttr.find((x) => x.id === image.id), position: image.position })
          } else if (image.uploaded) {
            newAttr.push({ code: 'image', value: image.src, position: image.position })
          } else if (!image.uploaded && image.file) {
            await AwsUpdate('rkings', image.file, image.name).then((res) => {
              image.src = res.Location
              image.uploaded = true
              newAttr.push({ code: 'image', value: res.Location, position: image.position })
            })
          }
          cnt += 1
          if (this.imageItems.length + this.invoiceItems.length === cnt) {
            return this.upsertProduct(newAttr)
          }
        })
        this.invoiceItems.forEach(async (invoice) => {
          if (invoice.id) {
            newAttr.push({ ...oldAttr.find((x) => x.id === invoice.id), position: invoice.position })
          } else if (invoice.uploaded) {
            newAttr.push({ code: 'invoice', value: invoice.src, position: invoice.position })
          } else if (!invoice.uploaded && invoice.file) {
            await AwsUpdate('rkings', invoice.file, invoice.name, invoice.file.type).then((res) => {
              invoice.src = res.Location
              invoice.uploaded = true
              newAttr.push({ code: 'invoice', value: res.Location, position: invoice.position })
            })
          }
          cnt += 1
          if (this.imageItems.length + this.invoiceItems.length === cnt) {
            return this.upsertProduct(newAttr)
          }
        })

        if (this.imageItems.length + this.invoiceItems.length === 0) {
          return this.upsertProduct(newAttr)
        }
      } catch (error) {
        notifyError(error, this.$t('notify.unknown_err'))
      }
    },
    upsertProduct(newAttr) {
      this.isLoading = true
      this.form.attributes = newAttr
      if (this.form.type == 'cash_multiple' && this.form.payment_frequency != 'one-off') {
        this.form.attributes.push({ ...this.existingMultiPay, code: this.form.payment_frequency, value: this.form.payment_numbers })
      }
      const params = {
        storing_time: this.form.storing_time ? formatUtcDateTime(this.form.storing_time) : '',
        // delivering_time: this.form.delivering_time ? formatUtcDateTime(this.form.delivering_time) : '',
        delivering_time: this.form.delivering_time,
        successMsg: this.$t('product.upsert_notice[0]', { action: this.isEditRoute ? this.$t('action.update') : this.$t('action.create') }),
      }
      for (const key in this.form) {
        if (['id', 'name', 'description', 'type', 'store_id', 'status', 'initial_amount', 'stock_amount', 'available_amount', 'cost', 'currency', 'attributes'].indexOf(`${key}`) > -1) {
          params[key] = this.form[key]
        }
      }
      this.$store
        .dispatch(this.isEditRoute ? 'request/updateProductInline' : 'request/addProduct', params)
        .then((res) => {
          this.isLoading = false
          if (res.status === 200) {
            this.$router.push({ path: '/competitions/product-management' })
          }
        })
        .catch(() => (this.isLoading = false))
    },
  },
  created() {
    if (!this.productEntityList) {
      this.isLoading = true
      this.$store
        .dispatch('request/getProductEntityList')
        .then(() => (this.isLoading = false))
        .catch(() => (this.isLoading = false))
    }
  },
  mounted() {
    this.initForm(this.initVal)
  },
}
</script>
