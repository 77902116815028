<template>
  <div slot="header" class="d-flex flex-row align-items-center justify-content-between my-3">
    <div class="flex-grow-0">
      <h6 class="text-light text-uppercase ls-1 mb-1">{{ displayInfo.title }}</h6>
      <h5 class="h3 text-white mb-0">{{ displayInfo.name }}</h5>
    </div>
    <div class="flex-grow-1 d-flex justify-content-end">
      <b-form-select v-model="queryParams['store_id']" :disabled="!allowMultiStores" @change="initBigChart" style="width: 85px">
        <b-form-select-option value="1">{{ $t('uk') }}</b-form-select-option>
        <b-form-select-option value="2">{{ $t('address.mexico') }}</b-form-select-option>
      </b-form-select>
      <!-- <b-form-select v-model="queryParams['timezone']" :options="tzOpts" @change="initBigChart(type)" class="ml-2" style="width: 140px" /> -->
      <date-range-picker
        v-model="dateRange"
        :locale-data="{ format: 'yyyy-mm-dd' }"
        :auto-apply="true"
        :ranges="ranges"
        :timePicker24Hour="true"
        :show-dropdowns="true"
        class="ml-2"
        style="width: max-content; min-width: 210px; max-width: 300px"
        opens="left"
        singleDatePicker="range"
        :append-to-body="true"
        :max-date="maxDate"
        :min-date="minDate"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { setupDateRanges } from '@/utils/dateTimeUtils'
import { isEqual } from 'lodash'
export default {
  name: 'time-select',
  props: {
    displayInfo: Object,
    initBigChart: Function,
    type: String,
    queryParams: Object,
  },
  data() {
    return {
      maxDate: null,
      minDate: null,
      chosenDate: null,
      ranges: setupDateRanges(true),
    }
  },
  computed: {
    ...mapGetters({
      allowMultiStores: 'allowMultiStores',
      selfStore: 'store',
      // tzOpts: 'settings/tzOpts',
      localTz: 'settings/timezone',
    }),
    dateRange: {
      get() {
        return {
          startDate: this.$moment(this.queryParams.start_date).format('YYYY-MM-DDTHH:mm:ss'),
          endDate: this.$moment(this.queryParams.end_date).format('YYYY-MM-DDTHH:mm:ss'),
        }
      },
      set(newVal) {
        const newStart = this.$moment(newVal.startDate).format('YYYY-MM-DD')
        const newEnd = this.$moment(newVal.endDate).format('YYYY-MM-DD')
        if (!isEqual(this.queryParams.start_date, newStart) || !isEqual(this.queryParams.end_date, newEnd)) {
          this.queryParams['start_date'] = newStart
          this.queryParams['end_date'] = newEnd
          this.initBigChart()
        }
        return newVal
      },
    },
  },
  created() {
    this.queryParams['store_id'] = this.selfStore
    this.queryParams['timezone'] = this.localTz
    const today = this.$moment.utc().tz(this.localTz)
    this.queryParams['end_date'] = today.format('YYYY-MM-DD')
    if (this.type == 'dailyCompetitionSales') {
      this.queryParams['start_date'] = today.format('YYYY-MM-DD')
    } else {
      this.queryParams['start_date'] = today.clone().subtract(7, 'days').format('YYYY-MM-DD')
    }
    this.minDate = today.clone().subtract(90, 'days').format('YYYY-MM-DDT00:00:00')
    this.maxDate = today.clone().endOf('day').format('YYYY-MM-DDTHH:mm:ss')
  },
}
</script>
