<template>
  <b-badge pill :variant="{ canceled: 'secondary', failed: 'danger', pending: 'warning', succeeded: 'success', contacted: 'info' }[value]">
    {{ $t(`transaction.cash_status.${value}`) }}
  </b-badge>
</template>
<script>
export default {
  name: 'CashTransStatus',
  props: {
    value: String,
  },
}
</script>
