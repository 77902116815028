<template>
  <card type="default" header-classes="bg-transparent">
    <loading :active="isLoading" :can-cancel="false" :is-full-page="false" />
    <TimeSelect :displayInfo="{ name: $t('dashboard.chart_name[1]') }" :initBigChart="initBigChart" type="orders" :queryParams="queryParams" />
    <BarChart :height="350" ref="bigChart" :chart-data="chartModel.chartData" :options="chartModel.extraOptions" />
  </card>
</template>
<script>
import { barChartOptions } from '@/components/Charts/config'
import BarChart from '@/components/Charts/BarChart'
import TimeSelect from '@/components/Charts/TimeSelect.vue'

export default {
  name: 'DailyOrders',
  components: { TimeSelect, BarChart },
  data() {
    return {
      isLoading: false,
      queryParams: {},
      chartModel: { chartData: {}, extraOptions: barChartOptions },
      rawData: [],
      colors: ['#2dce89', '#f5365c'],
    }
  },
  methods: {
    async getDailyOrders() {
      this.isLoading = true
      await this.$store
        .dispatch('request/getDailyOrders', this.queryParams)
        .then(() => (this.isLoading = false))
        .catch(() => (this.isLoading = false))
      this.rawData = this.$store.getters['request/dailyOrders']
    },
    initBigChart() {
      this.getDailyOrders().then(() => {
        this.chartModel.chartData = {
          datasets: [
            {
              label: this.$t('dashboard.daily_orders[0]'),
              data: this.rawData.map((val) => val.ordersCompleted),
              borderColor: this.colors[0],
              backgroundColor: this.colors[0],
            },
            {
              label: this.$t('dashboard.daily_orders[1]'),
              data: this.rawData.map((val) => val.ordersCanceled),
              borderColor: this.colors[1],
              backgroundColor: this.colors[1],
            },
          ],
          labels: this.rawData.map((val) => this.$moment(val.day).format('DD/MM')),
        }
      })
    },
  },
  // queryParams will be initialized when TimeSelect created
  mounted() {
    this.initBigChart()
  },
}
</script>
