<template>
  <card body-classes="p-0 d-flex flex-column" class="table-custom">
    <loading :active="isLoading" :can-cancel="false" :is-full-page="false" />
    <b-table :fields="fields" :items="items" show-empty small hover responsive class="my-5 mx-0 p-0" head-variant="light">
      <!-- <template #cell(value)="{ item }">
        <B-form-input v-model="item['value']" number :disabled="!edit || edit.key !== item.key" />
      </template>
      <template #cell(actions)="{ item }">
        <span class="action" @click="toggleEdit(item)"><b-icon-pencil class="mr-1" />Edit</span>
        <span class="divider divider-vertical"></span>
        <span class="action"><b-icon-check2 class="mr-1" />Save</span>
      </template> -->
    </b-table>
  </card>
</template>
<script>
import { notifyError } from '@/utils/index'
import { formatWithCurrency } from '@/utils/numberUtils'
import axios from '../../plugins/axios'
import { API_LIST } from '../../utils/consts'

export default {
  data() {
    return {
      isLoading: false,
      fields: [
        { key: 'key', label: 'Trigger Name', formatter: (v) => v == 'price' ? 'Ticket Price' : v.replaceAll('_', ' '), class: 'text-capitalize' },
        { key: 'value', label: 'Threshold', formatter: (v, k, i) => i.key.includes('margin') ? (v * 100) + '%' : formatWithCurrency(v, 'GBP') },
        { key: 'description', label: 'Conditions' },
        // { key: 'actions' }
      ],
      items: [],
      beforeEdit: null,
      edit: null,
    }
  },
  methods: {
    getList() {
      this.isLoading = true
      axios
        .get(API_LIST.get.compApprovalTriggers)
        .then((res) => {
          if (res?.data?.data) {
            this.items = res.data.data
            this.$store.dispatch('request/mutateState', { property: 'compApprovalTriggers', with: res.data.data })
          }
        })
        .catch((err) => notifyError(err, this.$t('notify.unknown_err')))
        .finally(() => (this.isLoading = false))
    },
    toggleEdit(item) {
      if (this.edit) {
        for (const key in this.beforeEdit) {
          item[key] = this.beforeEdit[key]
        }
        this.edit = null
      } else {
        this.beforeEdit = { ...item }
        this.edit = item
      }
    }
  },
  created() {
    this.getList()
  },
}
</script>
