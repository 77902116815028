<template>
  <card body-classes="p-0 d-flex flex-column" class="table-custom">
    <loading :active="isLoading" :can-cancel="false" :is-full-page="false" />
    <ReportSearchInput :queryParams="queryParams" showStore showCurrency showMonthPicker showCatalog :onExport="onExport" :onSearch="onSearch" :onReset="onReset" />
    <b-table
      :fields="fields"
      :items="monthlyCompReport"
      v-model="visibleRows"
      show-empty
      :empty-text="$t('notify.table_no_records')"
      small
      hover
      responsive
      sticky-header="100%"
      head-variant="light"
      class="m-0 p-0">
      <template #head()="{ label, field }">
        {{ label }}
        <b-icon-question-circle-fill v-if="field.tooltip" class="ml-1" v-b-tooltip.hover.topleft="field.tooltip" />
      </template>
      <template #cell(competition_id)="{ value }">
        <router-link :to="{ name: 'Competition Details', query: { id: value } }" class="action text-decoration-none">
          {{ value }}
        </router-link>
      </template>
      <template #cell(competition_title)="{ value, item }">
        <div v-if="value" @click="openDailyReport(item.competition_id, value)" class="action" style="white-space: normal">
          {{ value }}
        </div>
      </template>
      <template #bottom-row v-if="monthlyCompReport.length > 0">
        <td colspan="1" class="table-b-table-default b-table-sticky-column">{{ $t('general.total') }}</td>
        <td colspan="2"></td>
        <td class="text-right">{{ formatWithCurrency(totalNetSales, queryParams.currency) }}</td>
        <td class="text-right">{{ formatWithCurrency(totalCouponAmt, queryParams.currency) }}</td>
        <td class="text-right">{{ formatWithCurrency(totalCost, queryParams.currency) }}</td>
        <td colspan="2" class="text-right pr-6">
          <span v-b-tooltip.hover="`After Removing Duplicates`">{{ totalUserAmount }}&nbsp;(RD)</span>
        </td>
      </template>
    </b-table>
    <div class="card-footer" v-if="monthlyCompReport.length > 0"></div>
    <CompetitionDailyReport :competitionId.sync="competitionId" :competitionTitle.sync="competitionTitle" />
  </card>
</template>
<script>
import CompetitionDailyReport from '@/components/Reports/CompetitionDailyReport'
import ReportSearchInput from '@/components/Reports/ReportSearchInput.vue'
import { exportAsExcel } from '@/utils/fileUtils'
import { formatWithCurrency, sumTotal } from '@/utils/numberUtils'
import { mapGetters } from 'vuex'

export default {
  components: { ReportSearchInput, CompetitionDailyReport },
  data() {
    return {
      isLoading: false,
      visibleRows: [],
      queryParams: {
        store_id: null,
        start_date: null,
        end_date: null,
        currency: null,
        timezone: null,
        catalog_id: null,
        title: null,
        monthly: true,
      },
      initialParams: {},
      competitionId: null,
      competitionTitle: null,
    }
  },
  computed: {
    ...mapGetters({ competitionReport: 'request/competitionReport' }),
    fields() {
      return [
        { key: 'competition_id', label: this.$t('report.competition.competition_id'), stickyColumn: true, thStyle: 'width: 200px', tooltip: this.$t('report.competition.id_notice') },
        { key: 'competition_title', tooltip: this.$t('report.competition.title_notice') },
        { key: 'closed_at', label: this.$t('report.competition.closed_at'), sortable: true, thStyle: 'width:8rem' },
        {
          key: 'net_sales',
          label: this.$t('report.competition.net_sales'),
          formatter: (v) => formatWithCurrency(v, this.queryParams.currency),
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-right',
        },
        {
          key: 'coupon_amount',
          label: this.$t('report.competition.coupon_amount'),
          formatter: (v) => formatWithCurrency(v, this.queryParams.currency),
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-right',
        },
        {
          key: 'cost',
          label: this.$t('general.cost'),
          formatter: (v) => formatWithCurrency(v, this.queryParams.currency),
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-right',
        },
        {
          key: 'actual_margin',
          label: 'Live Profit Margin',
          formatter: (val) => `${val}%`,
          class: 'text-center',
          thClass: 'text-wrap text-center',
          thStyle: 'width: 9rem',
          tooltip: 'LIVE PROFIT MARGIN = (NET SALES - LIVE COST) / NET SALES * 100%',
        },
        { key: 'users', label: this.$t('report.competition.users_amount'), sortable: true, class: 'pr-4', thStyle: 'background-position-x: 8rem', tdClass: 'text-center' },
      ]
    },
    monthlyCompReport() {
      const arr = this.competitionReport?.filter((x) => !!x.competition_id) ?? []
      return arr
    },
    totalUserAmount() {
      const tmp = this.competitionReport?.find((x) => !!x.monthly_all_users)?.monthly_all_users ?? null
      return tmp
    },
    totalNetSales() {
      return sumTotal(this.visibleRows, 'net_sales')
    },
    totalCouponAmt() {
      return sumTotal(this.visibleRows, 'coupon_amount')
    },
    totalCost() {
      return sumTotal(this.visibleRows, 'cost')
    },
  },
  methods: {
    getList() {
      this.isLoading = true
      this.$store
        .dispatch('request/getCompetitionReport', this.queryParams)
        .then(() => (this.isLoading = false))
        .catch(() => (this.isLoading = false))
    },
    onSearch() {
      this.getList()
    },
    onReset() {
      this.queryParams = { ...this.initialParams }
      this.onSearch()
    },
    csvPreProcess(data) {
      for (let i in data) {
        delete data[i].budget_sales
        delete data[i].budget_gross_profit
        delete data[i].profits
        delete data[i].live_date
        delete data[i].sold_tickets
        delete data[i].ticket_price
        delete data[i].budget_margin
        delete data[i].remaining_days
        delete data[i].total_sales
        delete data[i].total_tickets
        delete data[i].target_daily_sales
        delete data[i].available_tickets
        delete data[i].end_date
      }
    },
    onExport() {
      if (this.monthlyCompReport.length > 0) {
        const headings = ['competition_id', 'competition_title', 'closed_at', 'net_sales', 'coupon_amount', 'cost', 'actual_margin', 'users']
        const headingsFormat = [
          [
            this.$t('report.competition.competition_id'),
            'Competition Title',
            this.$t('report.competition.closed_at'),
            this.$t('report.competition.net_sales'),
            this.$t('report.competition.coupon_amount'),
            this.$t('general.cost'),
            'Live Profit Margin (%)',
            this.$t('report.competition.users_amount'),
          ],
        ]
        const data = [...this.monthlyCompReport]
        data.push({
          competition_id: 'Total',
          net_sales: this.totalNetSales,
          coupon_amount: this.totalCouponAmt,
          cost: this.totalCost,
          users: this.totalUserAmount,
        })
        return exportAsExcel(
          this.$t('report.competition.monthly_filename') + `_${this.queryParams.start_date}-${this.queryParams.end_date}_${this.queryParams.timezone}`,
          null, // no need to fetch data from url because no pagination
          data, // pass data as params because no pagination
          this.monthlyCompReport.length, // total
          this.csvPreProcess,
          headings,
          headingsFormat,
          (bool) => (this.isLoading = bool)
        )
      } else {
        this.$notify({ group: 'root', type: 'error', text: this.$t('notify.no_records') })
      }
    },
    openDailyReport(id, title) {
      this.competitionId = id
      this.competitionTitle = title
      this.$bvModal.show('comp-daily-report')
    },
    formatWithCurrency,
  },
  // queryParams will be initialized when ReportSearchInput created
  mounted() {
    this.initialParams = { ...this.queryParams }
    this.getList()
  },
}
</script>
<style scoped>
::v-deep .modal-dialog {
  width: fit-content;
  min-width: 50%;
  max-width: 75%;
}
::v-deep .modal-dialog .search {
  padding-left: 0px !important;
}
::v-deep .modal-dialog .card-border {
  border: none;
}
</style>
