<template>
  <card body-classes="p-0 d-flex flex-column" class="table-custom">
    <loading :active="isLoading" :can-cancel="false" :is-full-page="false" />
    <BannerSearchInput :queryParams="queryParams" :getter="getBannerList" extraCls="px-4 pt-3 pb-2" />
    <b-table
      show-empty
      :empty-text="$t('notify.table_no_records')"
      small
      hover
      responsive
      class="m-0 p-0"
      head-variant="light"
      primary-key="id"
      :fields="fields"
      :items="items"
      :busy="isLoading"
      ref="banners-table"
      sticky-header="100%">
      <template #cell(image_url)="{ index, value, item }">
        <b-img-lazy
          :ref="'banner' + item.id"
          :src="value"
          :alt="shortenString(value, 13, true)"
          fluid
          block
          style="height: 3rem; object-fit: contain"
          @load.native="updateDimension(item, index)"
          v-b-tooltip.hover.left="value" />
      </template>
      <template #cell(details)="{ value }">
        <b-link v-if="value" :href="`${value}`" target="_blank" v-b-tooltip.hover.right="value"><b-icon-link45deg class="mr-1" />{{ value }}</b-link>
      </template>
      <template #cell(store_id)="{ value }"><store-badge :store_id="value" /></template>
      <template #cell(status)="{ value }">
        <b-badge pill :variant="['danger', 'info', 'success'][value + 1]">{{ $t('banner.status_list')[value + 1] }}</b-badge>
      </template>
      <template #cell(reference_name)="{ item }">
        <span v-if="item.configurable" @click="pushCompDetails(item.configurable)" class="action" v-b-tooltip.hover.right="item.configurable.title">
          {{ item.configurable.title }}
        </span>
      </template>
      <template #cell(configurable)="{ value }"><CompetitionStatus v-if="value" :value="value.status" /></template>
      <template #cell(action)="{ item }">
        <b-button
          @click="
            $router.push({
              name: 'Banner Edit',
              query: item.configurable_id ? { competition_id: item.configurable_id, store_id: item.store_id } : { refer: encodeURIComponent(item.refer) },
              params: { configurable: item.configurable },
            })
          "
          v-b-tooltip.hover.top="$t('action.edit')">
          <b-icon-pencil />
        </b-button>
      </template>
    </b-table>
    <paginate v-if="items.length > 0" :queries="queryParams" :fetcher="getBannerList" :total="bannerList.total" class="card-footer" />
  </card>
</template>
<script>
import BannerSearchInput from '@/components/Banners/BannerSearchInput.vue'
import routerMixin from '@/mixins/router-mixin'
import utilsMixin from '@/mixins/utils-mixin'
import { formatLocalDateTime } from '@/utils/dateTimeUtils'
import { shortenString } from '@/utils/index'
import { mapGetters } from 'vuex'
import CompetitionStatus from '../../../components/Competitions/CompetitionStatus.vue'

export default {
  mixins: [utilsMixin, routerMixin],
  components: {
    BannerSearchInput,
    CompetitionStatus,
  },
  data() {
    return {
      prevRoute: null,
      isLoading: false,
      queryParams: {
        status: null,
        store_id: null,
        created_start: null,
        created_end: null,
        page: 1,
        perPage: 10,
        ...this.$route.params?.prev,
      },
    }
  },
  computed: {
    ...mapGetters({ bannerList: 'request/bannerList' }),
    prevPath() {
      const temp = this.prevRoute ? this.prevRoute.path.replace(/\//gim, '-') : '-list'
      return temp
    },
    fields() {
      return [
        { key: 'image_url', label: this.$tc('general.image', 1), tdAttr: { style: 'min-width: 14rem' }, stickyColumn: true },
        {
          key: 'device',
          label: this.$t('banner.device'),
          class: 'text-uppercase',
          formatter: (v, k, item) => item.image_url.substring(item.image_url.lastIndexOf('/') + 1, item.image_url.length).match(/\w+/)[0],
        },
        { key: 'pixel', label: this.$t('image.dimensions'), formatter: (v, k, i) => (i.loaded ? v : '...') },
        { key: 'details', label: this.$t('banner.link'), tdClass: 'text-truncate' },
        { key: 'store_id', label: this.$tc('general.store', 1), class: 'text-center' },
        { key: 'status', label: this.$t('general.status') },
        { key: 'action', label: this.$tc('general.action', 1), class: 'text-center px-2' },
        { key: 'configurable_type', label: this.$t('banner.ref_type'), formatter: (v) => (v && v.length > 2 ? this.$t('banner.ref_options[0]') : this.$t('banner.ref_options[1]')) },
        { key: 'reference_name', label: this.$t('banner.ref_name'), tdClass: 'text-truncate' },
        { key: 'configurable', label: this.$t('banner.ref_status') },
        { key: 'start_at', label: this.$t('general.start_at'), sortable: true, formatter: (v) => formatLocalDateTime(v) },
        { key: 'end_at', label: this.$t('general.end_at'), sortable: true, formatter: (v) => formatLocalDateTime(v) },
        { key: 'created_at', label: this.$t('general.created_at'), sortable: true, formatter: (v) => formatLocalDateTime(v) },
      ]
    },
    items() {
      return this.bannerList?.data ?? []
    },
  },
  methods: {
    getBannerList(_params) {
      this.isLoading = true
      const params = { ...this.queryParams, ..._params }
      this.$store
        .dispatch('request/getBannerList', params)
        .then(() => (this.isLoading = false))
        .catch(() => (this.isLoading = false))
    },
    updateDimension(item, index) {
      if (item.image_url) {
        const domEl = this.$refs[`banner${item.id}`]?.$el
        item['pixel'] = domEl ? domEl.naturalWidth + '\u00D7' + domEl.naturalHeight + 'px' : ''
        item['loaded'] = true
        this.$refs['banners-table']?.refresh()
      }
    },
    shortenString,
  },
  watch: {
    prevRoute(val) {
      if (val && val.path && val.path.indexOf('/settings/banners/') > -1 && this.$route.params.refresh) {
        this.$router.go()
      } else {
        this.getBannerList()
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from
    })
  },
  beforeRouteLeave(to, from, next) {
    this.$set(from.params, 'preservedParams', this.queryParams)
    return next()
  },
}
</script>
