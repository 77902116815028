<template>
  <card body-classes="p-0 d-flex flex-column" class="table-custom">
    <loading :active="isLoading" :can-cancel="false" :is-full-page="false" />
    <ReportSearchInput :queryParams="queryParams" showStore showDatePicker :onExport="onExport" :onSearch="onSearch" :onReset="onReset" />
    <b-table :fields="fields" :items="items" v-model="visibleRows" show-empty :empty-text="$t('notify.table_no_records')" small hover responsive head-variant="light" class="m-0 p-0">
      <template #cell(title)="{ value, item }">
        <div v-if="item.titleLang" class="w-auto d-flex flex-row flex-nowrap align-items-center">
          <router-link :to="{ name: 'Competition Details', query: { id: item.competition_id } }" class="text-decoration-none action d-block w-auto">{{ value }}</router-link>
          <icon-translate :onClick="() => onEdit(item)" />
        </div>
        <router-link v-else :to="{ name: 'Competition Details', query: { id: item.competition_id } }" class="text-decoration-none action d-block">{{ value }}</router-link>
      </template>
      <template #bottom-row v-if="items.length > 0">
        <td class="table-b-table-default b-table-sticky-column">{{ $t('general.total') }}</td>
        <td></td>
        <td class="text-center">{{ totalUsers }}</td>
        <td class="text-right">{{ formatWithCurrency(totalRowTotals, ['£', 'Mex$'][queryParams.store_id - 1]) }}</td>
        <td class="text-center">{{ totalQtys }}</td>
        <td class="text-right"></td>
      </template>
    </b-table>
    <div class="card-footer" v-if="items.length > 0"></div>
  </card>
</template>
<script>
import ReportSearchInput from '@/components/Reports/ReportSearchInput.vue'
import { exportAsExcel } from '@/utils/fileUtils'
import { noTransFormatter } from '@/utils/index'
import { formatWithCurrency, sumTotal } from '@/utils/numberUtils'
import { mapGetters } from 'vuex'

export default {
  components: { ReportSearchInput },
  data() {
    return {
      isLoading: false,
      visibleRows: [],
      initialParams: {},
      queryParams: {
        store_id: null,
        timezone: null,
        start_date: null,
        end_date: null,
      },
    }
  },
  computed: {
    ...mapGetters({ newUsrCompReport: 'request/newUsrCompReport' }),
    fields() {
      return [
        { key: 'competition_id', label: this.$t('report.competition.competition_id'), thStyle: 'width: 150px' },
        { key: 'title', label: 'Competition Title', formatter: noTransFormatter, tdAttr: { style: 'white-space: normal; min-width: 310px' } },
        { key: 'new_users', label: this.$t('report.competition.new_users'), sortable: true, class: 'text-center', thStyle: 'width: 150px' },
        {
          key: 'row_totals',
          label: this.$t('report.competition.row_total'),
          formatter: (val) => formatWithCurrency(val, ['£', 'Mex$'][this.queryParams.store_id - 1]),
          sortable: true,
          tdClass: 'text-right',
          thClass: 'text-center',
          thStyle: 'width: 150px',
        },
        { key: 'qtys', label: this.$t('report.competition.qty'), sortable: true, class: 'text-center', thStyle: 'width: 150px' },
        {
          key: 'price',
          label: this.$t('report.competition.price'),
          formatter: (val) => formatWithCurrency(val, ['£', 'Mex$'][this.queryParams.store_id - 1]),
          sortable: true,
          class: 'pr-5',
          tdClass: 'text-right',
          thClass: 'text-center',
          thStyle: 'width: 120px',
        },
      ]
    },
    items() {
      return this.newUsrCompReport ?? []
    },
    totalUsers() {
      return sumTotal(this.visibleRows, 'new_users')
    },
    totalRowTotals() {
      return sumTotal(this.visibleRows, 'row_totals')
    },
    totalQtys() {
      return sumTotal(this.visibleRows, 'qtys')
    },
  },
  methods: {
    getList() {
      this.isLoading = true
      this.$store
        .dispatch('request/getNewUsrCompReport', this.queryParams)
        .then(() => (this.isLoading = false))
        .catch(() => (this.isLoading = false))
    },
    onSearch() {
      this.getList()
    },
    onReset() {
      this.queryParams = { ...this.initialParams }
      this.onSearch()
    },
    onExport() {
      if (this.items.length > 0) {
        const headings = ['competition_id', 'title', 'new_users', 'row_totals', 'qtys', 'price']
        const headingsFormat = [
          [
            this.$t('report.competition.competition_id'),
            'Competition Title',
            this.$t('report.competition.new_users'),
            this.$t('report.competition.row_total'),
            this.$t('report.competition.qty'),
            this.$t('report.competition.price'),
          ],
        ]
        // bottom-row
        const data = [...this.items]
        data.push({ competition_id: 'Total', new_users: this.totalUsers, row_totals: this.totalRowTotals, qtys: this.totalQtys })

        return exportAsExcel(
          this.$t('report.user.usr_comp_filename') + `_${this.queryParams.start_date}-${this.queryParams.end_date}_${this.queryParams.timezone}`,
          null, // no need to fetch data from url because no pagination
          data, // pass data as params because no pagination
          this.items.length, // total
          null, // data pre-process
          headings,
          headingsFormat,
          (bool) => (this.isLoading = bool)
        )
      } else {
        this.$notify({ group: 'root', type: 'error', text: this.$t('notify.no_records') })
      }
    },
    formatWithCurrency,
  },
  // queryParams will be initialized when ReportSearchInput created
  mounted() {
    this.initialParams = { ...this.queryParams }
    this.getList()
  },
}
</script>
