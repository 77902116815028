<template>
  <card body-classes="p-0 d-flex flex-column" class="table-custom">
    <loading :active="isLoading" :can-cancel="false" :is-full-page="false" />
    <div class="px-4 pt-3 pb-2">
      <div v-if="$route.query" class="w-100 d-flex flex-row flex-nowrap align-items-center mb-2">
        <p class="my-0 mr-2">
          {{ $t('competition.comp_title') }}:
          <span class="font-weight-bold mr-3">{{ title }}</span>
          <icon-translate v-if="titleLang" classes="mr-3" />
          {{ $t('competition.comp_id') }}:
          <span class="font-weight-bold mr-3">{{ id }}</span>
        </p>
        <CompetitionStatus :value="status" />
      </div>
      <!-- Search Filter Input -->
      <div class="w-100 d-flex flex-row flex-wrap align-items-start">
        <div class="d-flex flex-row flex-nowrap justify-content-center align-items-center mb-2 mr-2">
          <b-input-group class="m-0">
            <b-form-input
              v-model="queryParams.numbers"
              type="search"
              :placeholder="$tc('order.ticket_number', 1)"
              @keyup.enter="onSearch"
              style="width: 136px"
              v-b-tooltip.hover="`Use comma to separate multiple ticket numbers`" />
          </b-input-group>
          <b-input-group class="ml-2 my-0">
            <b-form-select v-model="queryParams.status" :disabled="status == 0" style="width: 200px">
              <b-form-select-option selected :value="null">All Status</b-form-select-option>
              <b-form-select-option v-for="(option, index) in $t('ticket.ticket_status')" :key="index" :value="index">{{ option }}</b-form-select-option>
            </b-form-select>
          </b-input-group>
          <b-input-group class="ml-2 my-0">
            <b-form-select v-model="queryParams.type" :disabled="status == 0" style="width: 136px">
              <b-form-select-option selected :value="null">All Order Types</b-form-select-option>
              <b-form-select-option v-for="(option, index) in $t('ticket.ticket_types')" :key="index" :value="index">{{ option }}</b-form-select-option>
            </b-form-select>
          </b-input-group>
        </div>
        <div class="d-flex flex-row flex-nowrap align-items-center justify-content-between flex-grow-1">
          <button-group :disabled="status === 0" :onSearch="onSearch" :onReset="onReset">
            <template #export>
              <b-button variant="reset" class="ml-2 mr-0" @click="onExport" :disabled="isLoading">
                <b-icon-file-spreadsheet class="mr-1" />{{ isLoading ? $t('status.loading') : $t('action.export') }}
              </b-button>
              <b-button v-if="status !== 0" variant="reset" class="ml-2" @click="onExportEntryList">
                <b-icon-file-spreadsheet class="mr-1" />{{ isLoading ? $t('status.loading') : $t('ticket.generate_entry_list') }}
              </b-button>
            </template>
          </button-group>
          <b-button v-if="$store.getters['store'] == 1" class="ml-2" variant="basic" size="md" v-b-modal.assign-free :disabled="status !== 1 || $route.query.left < 1">
            <b-icon-plus class="mr-1" />{{ $t('ticket.assign_free_tickets') }}
          </b-button>
        </div>
      </div>
    </div>
    <b-table :fields="fields" :items="items" primary-key="id" show-empty small hover responsive head-variant="light" sticky-header="100%" class="m-0 p-0">
      <template #head()="{ label, field }">{{ label }}<b-icon-question-circle-fill v-if="field.tooltip" class="ml-1" v-b-tooltip.hover.topright="field.tooltip" /></template>
      <template #cell(status)="{ value }">
        <b-badge v-if="localParams.tracking_status == 1" pill variant="success"> {{ $t('ticket.ticket_status_abbrev')[6] }}</b-badge>
        <b-badge v-else-if="Boolean(typeof localParams.contacted != 'undefined')" pill :variant="['danger', 'secondary'][localParams.contacted]">
          {{ $t('ticket.ticket_status_abbrev')[localParams.contacted + 4] }}
        </b-badge>
        <TicketStatus v-else :value="value" abbrev />
      </template>
      <template #cell(product)="{ value, item }">
        <span v-if="item.prize_type == 'product' && value" @click="item['status'] == 6 ? openInstantSetStatus(item) : null" :class="item['status'] == 6 ? 'action' : ''">
          {{ value.name }}
        </span>
        <span v-else-if="!!item.prize_type" @click="item['status'] == 6 ? openInstantSetStatus(item) : null" :class="`text-uppercase${item['status'] == 6 ? ' action' : ''}`">
          {{ item.prize_type }}
        </span>
        <template v-else>-</template>
      </template>
      <template #cell(alternative_cash_amount)="{ value, item }">
        <span v-if="value != '-'" class="action" @click="viewConvertPrize(item, 'alternative_cash_amount')">{{ value }}</span>
        <template v-else>-</template>
      </template>
      <template #cell(alternative_credit_amount)="{ value, item }">
        <span v-if="value != '-'" class="action" @click="viewConvertPrize(item, 'alternative_credit_amount')">{{ value }}</span>
        <template v-else>-</template>
      </template>
      <template #cell(type)="{ value }">
        <TicketType :value="value" />
      </template>
      <template #cell(order)="{ value, item }">
        <router-link
          v-if="value"
          :to="{
            name: 'Order Details',
            query: { orderDetailsId: value.id, userId: item.user.id, userEmail: item.user.email, display_name: item.user.display_name, useBalance: item.use_balance, ip: value.remote_ip },
          }"
          class="action text-decoration-none"
          >{{ '#' + value.id }}
        </router-link>
        <b-badge v-else pill variant="danger">{{ $t('order.no_order') }}</b-badge>
      </template>
      <template #cell(user)="{ value }">
        <span v-if="value" class="action" @click="viewUser(value)">{{ value.name }}</span>
        <b-badge v-else pill variant="danger">{{ $t('user.no_user') }}</b-badge>
      </template>
      <template #cell(action)="{ index, item }">
        <!-- disabled when: 1) this competition is not drawing or, 2) this ticket is not sold nor instant win or, 3) this ticket is owned by rkings redraw account -->
        <b-button
          :key="'select-winner-btn-' + index"
          variant="primary"
          @click="confirmSelect(item)"
          :disabled="status != 2 || item.user_id == 1 || ['0', '1', '3'].includes(String(item.status))"
          v-b-tooltip.hover.topleft="`Select as Live Draw Winner`">
          <b-icon-trophy />
        </b-button>
        <b-button :key="'convert-prize-btn-' + index" variant="primary" @click="viewConvertPrize(item)" :disabled="disableConvertPrize(item)" v-b-tooltip.hover.topleft="`Convert Instant Win Prize`">
          <b-icon-cash-coin />
        </b-button>
        <b-button variant="primary" @click="openInstantSetStatus(item, 5)" :disabled="item.status != 4" v-b-tooltip.hover.topright="`Set as Contacted Instant Win`">
          <b-icon-telephone-plus />
        </b-button>
        <b-button variant="primary" @click="openInstantSetStatus(item, 6)" :disabled="item.status != 5" v-b-tooltip.hover.topright="`Set as Delivered Instant Win`">
          <b-icon-truck />
        </b-button>
      </template>
      <template #cell(admin)="{ value }">
        <template v-if="value">{{ value }}</template>
        <b-badge v-else variant="danger">{{ $t('admin.no_admin') }}</b-badge>
      </template>
    </b-table>
    <paginate :queries="queryParams" :fetcher="getTicketList" :total="total" showBack class="card-footer" />

    <AssignFreeTickets v-if="$store.getters['store'] == 1" :competitionId="id" :viewUser="viewUser" :getList="getTicketList" />

    <ConvertPrize v-if="items.length > 0" :winner.sync="convertPrizeTarget" :getList="getTicketList" :readonly="convertPrizeReadonly" />

    <InstantWinSetStatus v-if="items.length > 0" :ticket.sync="instantStatusTarget" :getList="getTicketList" />

    <confirm-modal id="confirm" :message="confirmMsg" :isLoading.sync="isLoading" :onSubmit="onOk" :onHidden="hideModal" />

    <b-modal
      lazy
      centered
      id="user-details"
      :title="$t('user.user_details', { id: '' })"
      title-class="w-100 d-flex justify-content-center align-self-center"
      header-class="py-2"
      body-class="pt-0"
      hide-footer
      @hidden="hideModal">
      <UserDetails v-if="userDetailTarget" :user="userDetailTarget">
        <template #address="{ address }">
          <br />
          <AddressDetails :userStore="userDetailTarget.store_id" :address="address" />
        </template>
      </UserDetails>
    </b-modal>
  </card>
</template>
<script>
import AssignFreeTickets from '@/components/Competitions/AssignFreeTickets.vue'
import CompetitionStatus from '@/components/Competitions/CompetitionStatus.vue'
import InstantWinSetStatus from '@/components/Competitions/InstantWinSetStatus.vue'
import TicketStatus from '@/components/TicketStatus.vue'
import TicketType from '@/components/TicketType.vue'
import AddressDetails from '@/components/Users/AddressDetails.vue'
import UserDetails from '@/components/Users/UserDetails.vue'
import ConvertPrize from '@/components/Winners/ConvertPrize.vue'
import utilsMixin from '@/mixins/utils-mixin'
import axios from '@/plugins/axios'
import { API_LIST } from '@/utils/consts'
import { formatLocalDateTime, formatUtcDateTime } from '@/utils/dateTimeUtils'
import { exportAsExcel } from '@/utils/fileUtils'
import { noTransFormatter, notifyError } from '@/utils/index'
import { formatWithGBP } from '@/utils/numberUtils'
import { mapGetters } from 'vuex'

export default {
  name: 'CompetitionTickets',
  mixins: [
    utilsMixin, // formatNumbersInput
  ],
  components: {
    UserDetails,
    AddressDetails,
    CompetitionStatus,
    AssignFreeTickets,
    ConvertPrize,
    TicketStatus,
    InstantWinSetStatus,
    TicketType,
  },
  data() {
    const titleObj = {}
    const titleVal = noTransFormatter(decodeURIComponent(this.$route.query.title), 'title', titleObj)
    return {
      isLoading: true,
      id: parseInt(this.$route.query.id),
      status: parseInt(this.$route.query.status),
      title: titleVal,
      titleLang: titleObj.titleLang,
      queryParams: { numbers: null, status: null, type: null, page: 1, perPage: 10 },
      localParams: {},
      keysToConvert: ['alternative_cash_amount', 'alternative_credit_amount'],
      showExport: false,
      showSelect: false,
      confirmMsg: null,
      selectWinnerTarget: null,
      userDetailTarget: null,
      convertPrizeTarget: null,
      convertPrizeReadonly: '',
      instantStatusTarget: null,
    }
  },
  computed: {
    ...mapGetters({ ticketList: 'request/ticketList' }),
    items: {
      get() {
        return this.ticketList?.data ?? []
      },
      set: (v) => v,
    },
    total() {
      return this.ticketList?.total || 0
    },
    fields() {
      const tmp = [
        { key: 'number', label: 'Ticket Number', sortable: true, stickyColumn: true },
        { key: 'status', label: this.$t('general.status'), sortable: true },
        { key: 'product', label: 'Prize', tooltip: 'Click for Delivery Info' },
        {
          key: 'prize_amount',
          sortable: true,
          formatter: (v, k, i) => {
            const arg = i.prize_type == 'product' ? i.product?.cost : v
            return arg ? formatWithGBP(arg) : '-'
          },
          tdAttr: (v, k, i) => ({ style: Number(i.alternative_cash_amount) > 0 || Number(i.alternative_credit_amount) > 0 ? 'text-decoration: line-through' : '' }),
          thClass: 'text-center',
          tdClass: 'text-right',
        },
        { key: 'alternative_cash_amount', label: 'Converted Cash', tooltip: 'Clicking the amount to see Convert Prize Details' },
        { key: 'alternative_credit_amount', label: 'Converted Credit', tooltip: 'Clicking the amount to see Convert Prize Details' },
        { key: 'type', label: this.$t('order.order_type'), sortable: true },
        { key: 'order', label: this.$tc('general.order', 1), sortable: true },
        { key: 'user', label: this.$t('general.username') },
        {
          key: 'action',
          label: this.$tc('general.action', 1),
          tooltip: `An instant win ticket needs to be set as contacted before setting as delivered. And the instant win prize cannot be converted once delivered.`,
        },
        { key: 'created_at', label: this.$t('general.created_at'), sortable: true, formatter: (v) => formatLocalDateTime(v) },
        { key: 'updated_at', label: this.$t('general.updated_at'), sortable: true, formatter: (v) => formatLocalDateTime(v) },
        { key: 'admin', label: this.$t('general.updated_by'), formatter: (v, k, i) => (v && v.email ? v.email : i.admin_user_ud) },
      ]
      for (let i = 4; i < 6; i++) {
        tmp[i]['sortable'] = true
        tmp[i]['formatter'] = (v, k, i) => (v ? formatWithGBP(v) : '-')
        tmp[i]['thClass'] = 'text-center'
        tmp[i]['tdClass'] = 'text-right'
      }
      return tmp
    },
  },
  methods: {
    getTicketList() {
      this.isLoading = true
      this.localParams = { id: this.id, type: this.queryParams.type, page: this.queryParams.page, perPage: this.queryParams.perPage }
      if (this.queryParams.status == 6) {
        this.localParams['tracking_status'] = 1
      } else if (this.queryParams.status > 3) {
        this.localParams['contacted'] = this.queryParams.status - 4
      } else {
        this.localParams['status'] = this.queryParams.status
      }
      this.formatNumbersInput(this.queryParams, 'numbers', this.localParams, 'numbers')
      axios
        .get(API_LIST.get.ticketList, { params: this.localParams })
        .then((res) => {
          if (res?.data?.data?.data?.length > 0) {
            res.data.data.data.forEach((item) => {
              this.$set(item, 'status', item.reward?.tracking_status == 1 ? 6 : item.reward?.contacted == 1 ? 5 : item.status)
              this.keysToConvert.forEach((key) => this.$set(item, key, item.reward?.profiles?.find((x) => x['type'] === key)?.content))
            })
          }
          this.items = res?.data?.data?.data || []
          this.$store.dispatch('request/mutateState', { property: 'ticketList', with: res?.data?.data })
        })
        .catch((err) => notifyError(err, this.$t('notify.unknown_err')))
        .finally(() => (this.isLoading = false))
    },
    onSearch() {
      this.queryParams.page = 1
      this.getTicketList()
    },
    onReset() {
      this.queryParams.numbers = null
      this.queryParams.status = null
      this.queryParams.type = null
      this.onSearch()
    },
    csvPreProcess(data) {
      for (let i in data) {
        data[i].username = data[i].user ? data[i].user.name : this.$t('user.no_user')
        delete data[i].admin_user_id
        delete data[i].competition_id
        delete data[i].order
        delete data[i].id
        delete data[i].user
        delete data[i].user_id
        data[i].status = this.$t('ticket.ticket_status')[data[i].status]
        data[i].type = this.$t('ticket.ticket_types')[data[i].type] ?? ''
        data[i].order_id = data[i].order_id ? `#${data[i].order_id}` : this.$t('order.no_order')
        data[i].admin = data[i].admin ? data[i].admin.name : this.$t('admin.no_admin')
        data[i].created_at = formatLocalDateTime(data[i].created_at)
        data[i].updated_at = formatLocalDateTime(data[i].updated_at)
        data[i].prize = data[i].prize_type == 'product' && data[i].product?.name ? data[i].product?.name : data[i].prize_type
        const amt = data[i].prize_type == 'product' ? data[i].product?.cost : data[i].prize_amount
        data[i].prize_amount = amt ? formatWithGBP(amt) : '-'
        this.keysToConvert.forEach((key) => {
          const val = data[i].reward?.profiles?.find((x) => x['type'] === key)?.content
          data[i][key] = val ? formatWithGBP(val) : '-'
        })
        delete data[i].prize_type
      }
    },
    onExport() {
      const params = { id: this.id, status: this.queryParams.status, type: this.queryParams.type, page: 1, perPage: 1000 }
      this.formatNumbersInput(this.queryParams, 'numbers', params, 'numbers')
      return exportAsExcel(
        `${this.$t('ticket.list_filename')}_#${this.id}_${formatUtcDateTime(undefined, 2, true)}`, // title + generated at
        API_LIST.get.ticketList,
        params,
        this.total,
        this.csvPreProcess,
        ['number', 'status', 'prize', 'prize_amount', 'alternative_cash_amount', 'alternative_credit_amount', 'type', 'order_id', 'username', 'created_at', 'updated_at', 'admin'],
        [
          [
            this.$tc('order.ticket_number', 1),
            this.$t('general.status'),
            'Prize',
            'Prize Amount',
            'Converted Cash',
            'Converted Credit',
            this.$t('order.order_type'),
            this.$tc('general.order', 1),
            this.$t('general.username'),
            this.$t('general.created_at'),
            this.$t('general.updated_at'),
            this.$t('general.updated_by'),
          ],
        ],
        (bool) => (this.isLoading = bool)
      )
    },
    async exportEntryList() {
      const res = await this.$store.dispatch('request/exportEntryList', { competition_id: this.id, errorMsg: this.$t('notify.export_excel_failed') })
      if (res.status == 200) {
        const url = window.URL.createObjectURL(new Blob([res.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${this.title}_${formatUtcDateTime(undefined, 2, true)}.xlsx`) // title + generated at
        document.body.appendChild(link)
        link.click()
      }
    },
    onExportEntryList() {
      if (this.status < 2) {
        return this.confirmExport()
      } else {
        this.isLoading = true
        return this.exportEntryList().then(() => (this.isLoading = false))
      }
    },
    selectAsWinner(id) {
      this.isLoading = true
      this.$store
        .dispatch('request/selectWinner', {
          competition_ticket_id: id,
          successMsg: this.$t('ticket.select_winner_notice[1]'),
          errorMsg: this.$t('ticket.select_winner_notice[2]'),
        })
        .then((res) => {
          this.isLoading = false
          if (res?.status === 200) {
            this.status = res.data?.data?.competition.status ?? 3
            this.ticketList.data.find((x) => x.id === id).status = 3
          }
        })
        .catch(() => (this.isLoading = false))
    },
    viewUser(item) {
      this.userDetailTarget = item
      this.$bvModal.show('user-details')
    },
    confirmExport() {
      this.confirmMsg = this.$t('ticket.generate_entry_list_notice')
      this.showExport = true
      this.$bvModal.show('confirm')
    },
    confirmSelect(obj) {
      this.selectWinnerTarget = { id: obj.id }
      this.confirmMsg = this.$t('ticket.select_winner_notice[0]', [obj.user.name, obj.user.id])
      this.showSelect = true
      this.$bvModal.show('confirm')
    },
    onOk() {
      if (this.showExport) {
        this.isLoading = true
        return this.exportEntryList().then(() => (this.isLoading = false))
      }
      if (this.showSelect) {
        return this.selectAsWinner(this.selectWinnerTarget.id)
      }
    },
    hideModal() {
      this.userDetailTarget = null
      this.selectWinnerTarget = null
      this.confirmMsg = null
      this.showExport = false
      this.showSelect = false
    },
    viewConvertPrize(row, readonly) {
      this.convertPrizeTarget = { ...row.reward, ticket_number: row.number, competition: { title: this.title } }
      this.convertPrizeReadonly = readonly
      this.$bvModal.show('convert-prize')
    },
    disableConvertPrize(item) {
      return Boolean(
        item.status == 6 ||
          !item.reward ||
          !item.reward.competition_reward ||
          item.reward.competition_reward.type != 'consolation' ||
          Number(item.reward.competition_reward.alternative_credit_amount) > 0 ||
          Number(item.alternative_credit_amount) > 0 ||
          (!!item.reward.product && Number(item.alternative_cash_amount) > 0)
      )
    },
    openInstantSetStatus(item, statusAfter) {
      this.instantStatusTarget = item
      this.instantStatusTarget['statusAfter'] = statusAfter
      this.$bvModal.show('instant-set-status')
    },
  },
  created() {
    this.id = parseInt(this.$route.query.id)
    this.status = parseInt(this.$route.query.status)
    this.getTicketList()
  },
}
</script>
<style scoped>
::v-deep .modal-dialog {
  width: max-content;
  min-width: 50%;
  max-width: 75%;
}
</style>
