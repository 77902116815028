<template>
  <div class="px-4 pt-3 pb-2 d-flex flex-row flex-wrap align-items-start">
    <b-form-select v-model="queryParams.store_id" :options="storeOpts" :disabled="!allowMultiStores" class="mb-2 mr-2" style="width: 90px" />
    <b-form-select v-model="queryParams.status" class="mb-2 mr-2" style="width: 105px">
      <b-form-select-option selected :value="null">All Status</b-form-select-option>
      <b-form-select-option v-for="value in [-1, 0, 1]" :key="value" :value="value">{{ $t('banner.status_list')[value + 1] }}</b-form-select-option>
    </b-form-select>
    <date-range-picker
      v-model="dateRange"
      :locale-data="{ format: 'yyyy-mm-dd' }"
      :auto-apply="true"
      :timePicker24Hour="true"
      :show-dropdowns="true"
      class="mb-2 mr-2"
      style="width: max-content; min-width: 220px"
      :max-date="formatLocalDateTime(null, 'YYYY-MM-DD', true, true)"
      v-b-tooltip.hover.top="$t('notify.date_range_notice', { name: $t('general.created_at') })" />
    <div class="d-flex flex-row flex-wrap align-items-center justify-content-lg-between flex-grow-1">
      <button-group :onSearch="onSearch" :onReset="onReset" class="mb-2 mr-2" />
      <b-button class="mb-2" variant="basic" @click="$router.push({ name: 'Banner Create' })"> <b-icon-plus class="mr-1" />{{ $t('banner.create_new_banner') }} </b-button>
    </div>
  </div>
</template>
<script>
import storeMixin from '@/mixins/store-mixin'
import { formatDayEndUtc, formatDayStartUtc, formatLocalDateTime } from '@/utils/dateTimeUtils'

export default {
  name: 'BannerSearchInput',
  mixins: [
    storeMixin, // changeStorePlaceholder
  ],
  props: {
    queryParams: Object,
    getter: Function,
  },
  computed: {
    dateRange: {
      get() {
        return {
          startDate: formatLocalDateTime(this.queryParams.created_start, 1, false, true),
          endDate: formatLocalDateTime(this.queryParams.created_end, 1, false, true),
        }
      },
      set(newVal) {
        this.queryParams['created_start'] = formatDayStartUtc(newVal.startDate, 0, false, true)
        this.queryParams['created_end'] = formatDayEndUtc(newVal.endDate, 0, false, true)
        return newVal
      },
    },
  },
  methods: {
    onSearch() {
      this.queryParams.page = 1
      this.getter({ errorMsg: this.$t('banner.search_failed') })
    },
    onReset() {
      this.queryParams.store_id = this.allowMultiStores ? null : this.selfStore
      this.queryParams.status = null
      this.dateRange['startDate'] = null
      this.dateRange['endDate'] = null
      this.queryParams['created_start'] = null
      this.queryParams['created_end'] = null
      this.onSearch()
    },
    formatLocalDateTime,
  },
  created() {
    this.changeStorePlaceholder(true, true)
    this.queryParams.store_id = this.allowMultiStores ? null : this.selfStore
  },
}
</script>
