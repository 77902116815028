<template>
  <card body-classes="p-0 d-flex flex-column" class="table-custom">
    <loading :active="isLoading" :can-cancel="false" :is-full-page="false" />

    <CashbackTrans
      key="cashback-page-list"
      :fields="(selfStore == 1 || allowMultiStores) && queryParams.sourceable_type == defaultType ? fields : fieldsWithoutAction"
      :localParams="localParams"
      :getList="getList"
      :isLoading="isLoading"
      isSearchable
      showExport>
      <template #id="{ val, row }">
        <span class="action" @click="viewDetails(row)">{{ val }}</span>
      </template>
      <template #create v-if="(selfStore == 1 || allowMultiStores) && queryParams.sourceable_type == defaultType">
        <b-button variant="basic" @click="onApprove" class="ml-auto"><b-icon-check-all class="mr-1" />{{ $t('cashback.approve_all') }}</b-button>
      </template>
      <template #action="{ row }" v-if="(selfStore == 1 || allowMultiStores) && queryParams.sourceable_type == defaultType">
        <b-button @click="onAudit(row)" :disabled="row.status != 0" variant="success"><b-icon-check class="mr-1" />{{ $t('action.approve') }}</b-button>
        <b-button @click="onReject(row)" :disabled="row.status != 0" variant="danger">{{ $t('action.reject') }}<b-icon-x class="ml-1" /></b-button>
      </template>
    </CashbackTrans>

    <b-modal
      lazy
      centered
      id="cashback-trans"
      :title="confirmMsg ? $t('action.confirm_action') : $t('cashback.cashback_transaction', { id: actionTarget ? ' #' + actionTarget.id : '' })"
      title-class="w-100 d-flex justify-content-center align-self-center"
      header-class="py-2"
      footer-class="py-2"
      body-class="py-0"
      dialog-class="cashback-trans-modal"
      cancel-variant="outline-secondary"
      ok-variant="outline-primary"
      :ok-only="!confirmMsg"
      :ok-title="confirmMsg ? $t('action.confirm') : $t('action.close')"
      @ok="generalMutator"
      @cancel="hideModal"
      @hide="hideModal"
      ><p v-if="confirmMsg" class="text-slack m-0">⚠ {{ confirmMsg }}</p>
      <CashbackTransDetails v-if="actionTarget" :details="actionTarget">
        <template #change>
          <template v-if="confirmMsg">
            -->
            <b-badge pill :variant="confirmMsg.includes('approve') ? 'success' : 'secondary'">
              {{ confirmMsg.includes('approve') ? $t('transaction.credit_status[4]') : $t('transaction.credit_status[0]') }}
            </b-badge>
          </template>
        </template>
      </CashbackTransDetails>
      <CashbackTrans key="cashback-modal-list" v-else-if="selfStore == 1" :fields="fieldsWithoutAction" :localParams="localParams" :getList="getList" :isLoading="isLoading" />
    </b-modal>
  </card>
</template>
<script>
import CashbackTrans from '@/components/Cashback/CashbackTrans.vue'
import CashbackTransDetails from '@/components/Cashback/CashbackTransDetails.vue'
import { formatLocalDateTime } from '@/utils/dateTimeUtils'
import { mapGetters } from 'vuex'

export default {
  components: {
    CashbackTrans,
    CashbackTransDetails,
  },
  data() {
    const type = 'App\\Models\\CashbackConfig'
    const params = {
      id: null,
      status: null,
      rate: null,
      name: null,
      created_start: null,
      created_end: null,
      page: 1,
      perPage: 10,
      sourceable_type: type,
      ...this.$route.params?.prev,
    }
    return {
      isLoading: false,
      defaultType: type,
      localParams: params,
      queryParams: { ...params },
      fieldsWithoutAction: [
        { key: 'id', label: this.$t('general.id'), sortable: true, stickyColumn: true },
        { key: 'status', label: this.$t('general.status'), sortable: true },
        { key: 'comment', label: this.$tc('general.comment', 1), stickyColumn: true, tdClass: 'text-truncate' },
        { key: 'source_amount', label: this.$t('cashback.total_payment'), sortable: true, class: 'text-right' },
        { key: 'cashback_rate', label: this.$t('cashback.rate'), sortable: true, formatter: (v) => (v && v > 0 ? v * 100 + '%' : this.$t('no_data')), class: 'text-right' },
        { key: 'cashback_amount', label: this.$tc('general.amount', 1), sortable: true, class: 'text-right' },
        { key: 'user.name', label: this.$t('general.username') },
        { key: 'created_at', label: this.$t('general.created_at'), sortable: true, formatter: (v) => formatLocalDateTime(v) },
        { key: 'updated_at', label: this.$t('general.updated_at'), sortable: true, formatter: (v) => formatLocalDateTime(v) },
        { key: 'admin', label: this.$t('general.updated_by'), formatter: (v, k, i) => (v && v.name ? v.name : i.admin_user_ud) },
      ],
      actionTarget: null,
      confirmMsg: null,
      confirmParams: {},
    }
  },
  computed: {
    ...mapGetters({ cashbackTrans: 'request/cashbackTrans', allowMultiStores: 'allowMultiStores', selfStore: 'store' }),
    fields() {
      const tmp = [...this.fieldsWithoutAction]
      tmp.splice(7, 0, { key: 'action', label: this.$tc('general.action', 1), class: 'text-center px-2' })
      return tmp
    },
  },
  methods: {
    getList() {
      this.isLoading = true
      const params = {}
      for (const key in this.localParams) {
        if (this.localParams[key] !== undefined && this.localParams[key] !== null && this.localParams[key] !== '') {
          params[key] = key == 'rate' ? this.localParams.rate / 100 : this.localParams[key]
        }
      }
      return this.$store
        .dispatch('request/getCashbackTrans', params)
        .then(() => {
          this.queryParams = { ...params }
          this.isLoading = false
        })
        .catch(() => (this.isLoading = false))
    },
    generalMutator() {
      if (!this.confirmMsg) {
        this.hideModal()
        return
      }
      const { url, ...rest } = this.confirmParams
      this.$store.dispatch(url, rest).then((res) => {
        if (res.status === 200) {
          this.getList()
          this.$bvModal.hide('cashback-trans')
        }
      })
    },
    hideModal() {
      this.actionTarget = null
      this.confirmMsg = null
      this.confirmParams = {}
    },
    viewDetails(obj) {
      this.actionTarget = obj
      this.confirmMsg = null
      this.confirmParams = {}
      this.$bvModal.show('cashback-trans')
    },
    onApprove() {
      // search for pending cashback trans
      this.localParams.sourceable_type = 'App\\Models\\CashbackConfig'
      this.localParams.status = 0
      this.localParams.page = 1
      this.getList().then(() => {
        if (this.cashbackTrans?.data?.length > 0) {
          this.confirmMsg = this.$t('cashback.approve_all_notice[0]')
          this.confirmParams = {
            url: 'request/approveCashback',
            successMsg: this.$t('cashback.approve_all_notice[1]'),
            errorMsg: this.$t('cashback.approve_all_notice[2]'),
          }
          this.$bvModal.show('cashback-trans')
        } else {
          this.$notify({ group: 'root', type: 'warn', text: this.$t('cashback.approve_all_notice[3]') })
        }
      })
    },
    onAudit(row) {
      this.actionTarget = row
      this.confirmMsg = this.$t('cashback.audit_notice[0]', { id: row.id })
      this.confirmParams = {
        url: 'request/auditCashback',
        id: row.id,
        status: 2,
        successMsg: this.$t('cashback.audit_notice[1]', { id: row.id }),
        errorMsg: this.$t('cashback.audit_notice[2]', { id: row.id }),
      }
      this.$bvModal.show('cashback-trans')
    },
    onReject(row) {
      this.actionTarget = row
      this.confirmMsg = this.$t('cashback.reject_notice[0]', { id: row.id })
      this.confirmParams = {
        url: 'request/auditCashback',
        id: row.id,
        status: -2,
        successMsg: this.$t('cashback.reject_notice[1]', { id: row.id }),
        errorMsg: this.$t('cashback.reject_notice[2]', { id: row.id }),
      }
      this.$bvModal.show('cashback-trans')
    },
  },
  beforeRouteLeave(to, from, next) {
    this.$set(from.params, 'preservedParams', this.queryParams)
    return next()
  },
}
</script>
<style>
.cashback-trans-modal {
  width: max-content !important;
  min-width: 56rem !important;
  max-width: 90vw !important;
}
</style>
