<template>
  <card body-classes="p-0 d-flex flex-column" class="table-custom">
    <loading :active="isLoading" :can-cancel="false" :is-full-page="false" />
    <ReportSearchInput type="sales" :queryParams="queryParams" showStore showCurrency showDatePicker :onExport="onExport" :onSearch="onSearch" :onReset="onReset" />
    <b-table :fields="fields" :items="items" v-model="visibleRows" show-empty :empty-text="$t('notify.table_no_records')" hover responsive head-variant="light" sticky-header="100%" class="m-0 p-0">
      <template #head(cash_received)="{ label }">
        {{ label }}
        <b-icon-question-circle-fill
          class="ml-1"
          v-b-tooltip.hover.right="
            $t('report.sales.bank_received') +
            ' = ' +
            $t('report.sales.axcess_received') +
            ' + ' +
            $t('report.sales.barclaycard_received') +
            ' + ' +
            $t('report.sales.applepay_received') +
            ' + ' +
            $t('report.sales.googlepay_received') +
            ' + ' +
            $t('report.sales.paypal_received')
          " />
      </template>
      <template #head(cash_wallet_used)="{ label }">
        {{ label }}
        <b-icon-question-circle-fill class="ml-1" v-b-tooltip.hover.right="$t('report.sales.cash_used_notice')" />
      </template>
      <template #bottom-row v-if="items.length > 0">
        <td class="table-b-table-default b-table-sticky-column">{{ $t('general.total') }}</td>
        <td colspan="2" class="text-right">{{ sumTotal(visibleRows, 'orders') }}</td>
        <td v-for="(val, index) in sumKeys" :key="'bottom-cell-' + index" :class="`text-right ${index == sumKeys.length - 1 ? 'pr-5' : ''}`">
          {{ formatWithCurrency(sumTotal(visibleRows, val.key), queryParams.currency) }}
        </td>
      </template>
    </b-table>
    <div class="card-footer" v-if="items.length > 0"></div>
  </card>
</template>
<script>
import ReportSearchInput from '@/components/Reports/ReportSearchInput.vue'
import { exportAsExcel } from '@/utils/fileUtils'
import { formatWithCurrency, sumTotal } from '@/utils/numberUtils'
import { mapGetters } from 'vuex'

export default {
  components: { ReportSearchInput },
  data() {
    return {
      isLoading: false,
      visibleRows: [],
      fields: [
        { key: 'date', label: this.$t('report.sales.date'), stickyColumn: true, sortable: true },
        { key: 'weekday', label: this.$t('report.sales.weekday') },
        { key: 'orders', label: this.$t('report.sales.orders'), sortable: true, thClass: 'text-center', tdClass: 'text-right' },
        // other keys will be added when component mounted
      ],
      queryParams: {
        store_id: null,
        start_date: null,
        end_date: null,
        currency: null,
        timezone: null,
      },
      initialParams: {},
      sumKeys: [
        { key: 'gross_sales', value: 'gross_sales' },
        { key: 'coupons', value: 'coupons' },
        { key: 'net_sales', value: 'net_sales' },
        { key: 'cashback_used', value: 'credit_used' },
        { key: 'cash_wallet_used', value: 'cash_used' },
        { key: 'cash_received', value: 'bank_received' },
        { key: 'axcess_received', value: 'axcess_received' },
        { key: 'barclaycard_received', value: 'barclaycard_received' },
        { key: 'applepay_received', value: 'applepay_received' },
        { key: 'googlepay_received', value: 'googlepay_received' },
        { key: 'paypal_payment', value: 'paypal_received' },
        { key: 'returns', value: 'returns' },
        { key: 'cashback_accrued', value: 'cashback_accrued' },
        { key: 'topup_from_cash_wallet', value: 'topup_cash' },
        { key: 'topup', value: 'topup_bank' },
        { key: 'topup_reward', value: 'topup_bonus' },
      ],
    }
  },
  computed: {
    ...mapGetters({ salesReport: 'request/salesReport' }),
    items() {
      return this.salesReport?.daily ?? []
    },
  },
  methods: {
    getSalesReport() {
      this.isLoading = true
      this.$store
        .dispatch('request/getSalesReport', this.queryParams)
        .then(() => (this.isLoading = false))
        .catch(() => (this.isLoading = false))
    },
    onSearch() {
      this.getSalesReport()
    },
    onReset() {
      this.queryParams = { ...this.initialParams }
      this.onSearch()
    },
    onExport() {
      if (this.items.length > 0) {
        const headings = ['date', 'weekday', 'orders']
        const headingsFormat = [[this.$t('report.sales.date'), this.$t('report.sales.weekday'), this.$t('report.sales.orders')]]
        const bottomRow = { date: 'Total', weekday: '', orders: sumTotal(this.visibleRows, 'orders') }
        this.sumKeys.map((val) => {
          headings.push(val.key)
          headingsFormat[0].push(this.$t('report.sales.' + val.value))
          bottomRow[val.key] = sumTotal(this.visibleRows, val.key)
        })
        const data = this.items.map((item) => {
          delete item.credit_card_payment
          return item
        })
        data.push(bottomRow)
        return exportAsExcel(
          this.$t('report.sales.list_filename') + `_${this.queryParams.start_date}-${this.queryParams.end_date}_${this.queryParams.timezone}`, // filename
          null, // no need to fetch data from url because no pagination
          data, // pass data as params because no pagination
          this.items.length, // total
          null, // no pre-process
          headings,
          headingsFormat,
          (bool) => (this.isLoading = bool)
        )
      } else {
        this.$notify({ group: 'root', type: 'error', text: this.$t('notify.no_records') })
      }
    },
    formatWithCurrency,
    sumTotal,
  },
  // queryParams will be initialized when ReportSearchInput created
  mounted() {
    this.sumKeys.forEach((val, i) => {
      const isLast = i == this.sumKeys.length - 1
      this.fields.push({
        key: val.key,
        label: this.$t('report.sales.' + val.value),
        sortable: true,
        formatter: (v) => formatWithCurrency(v, this.queryParams.currency),
        class: isLast ? 'pr-5' : '',
        thStyle: isLast ? 'background-position-x: 162px !important' : '',
        thClass: isLast ? '' : 'text-center',
        tdClass: 'text-right',
      })
    })
    this.initialParams = { ...this.queryParams }
    this.getSalesReport()
  },
}
</script>
