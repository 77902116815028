var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('card',{staticClass:"table-custom",attrs:{"body-classes":"p-0 d-flex flex-column"}},[_c('loading',{attrs:{"active":_vm.isLoading,"can-cancel":false,"is-full-page":false}}),_c('OrderSearchInput',{attrs:{"queryParams":_vm.queryParams,"onSearch":_vm.onSearch,"extraCls":"px-4 pt-3 pb-2"}}),_c('b-table',{staticClass:"m-0 p-0",attrs:{"fields":_vm.fields,"items":_vm.items,"show-empty":"","empty-text":_vm.$t('notify.table_no_records'),"small":"","hover":"","responsive":"","head-variant":"light"},scopedSlots:_vm._u([{key:"cell(id)",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_c('span',{staticClass:"action",on:{"click":function($event){return _vm.$router.push({
            name: 'Order Details',
            query: { orderDetailsId: item.id, userId: item.user.id, display_name: item.user.display_name ? item.user.display_name : '-' },
          })}}},[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"cell(status)",fn:function(ref){
          var value = ref.value;
return [_c('OrderStatus',{attrs:{"value":value}})]}},{key:"cell(payments)",fn:function(ref){
          var value = ref.value;
return _vm._l((value),function(item,key){return _c('b-badge',{key:key,class:("d-block " + (value.length > 1 ? 'mr-1 my-1' : '')),attrs:{"pill":"","variant":{ failed: 'danger', succeeded: 'success', pending: 'primary' }[item.status]}},[_vm._v(" "+_vm._s(item.gateway.includes('google') ? 'barclay gpay' : item.gateway.includes('apple') ? 'barclay apple pay' : item.gateway.replaceAll('barclaycard', 'barclay card').replaceAll('_', ' '))+" ")])})}},{key:"cell(user)",fn:function(ref){
          var value = ref.value;
return [_c('span',{staticClass:"action",on:{"click":function($event){return _vm.openUserDetails(value)}}},[_vm._v(_vm._s(value.display_name))])]}},{key:"cell(store_id)",fn:function(ref){
          var value = ref.value;
return [_c('store-badge',{attrs:{"store_id":value}})]}},{key:"cell(type)",fn:function(ref){
          var value = ref.value;
return [_c('TicketType',{attrs:{"value":value}})]}}])}),(_vm.items.length > 0)?_c('paginate',{staticClass:"card-footer",attrs:{"queries":_vm.queryParams,"fetcher":_vm.getOrderList,"total":_vm.orderList.total}}):_vm._e(),_c('b-modal',{attrs:{"lazy":"","centered":"","id":"user-details","title":_vm.$t('user.user_details', { id: '' }),"title-class":"w-100 d-flex justify-content-center align-self-center","header-class":"w-100 py-2","body-class":"pt-0","hide-footer":""}},[(_vm.userDetails)?_c('UserDetails',{attrs:{"user":_vm.userDetails},scopedSlots:_vm._u([{key:"address",fn:function(ref){
          var address = ref.address;
return [(address)?_c('br'):_vm._e(),(address)?_c('AddressDetails',{attrs:{"userStore":_vm.userDetails.store_id,"address":address}}):_vm._e()]}}],null,false,2334129651)}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }