var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('card',{staticClass:"table-custom",attrs:{"body-classes":"p-0 d-flex flex-column"}},[_c('loading',{attrs:{"active":_vm.isLoading,"can-cancel":false,"is-full-page":false}}),_c('ReportSearchInput',{attrs:{"type":"multi-pay","queryParams":_vm.queryParams,"showDatePicker":"First Payment Date","onSearch":_vm.getList,"onReset":_vm.onReset},scopedSlots:_vm._u([{key:"other-search",fn:function(){return [_c('b-form-input',{staticClass:"mb-2 mr-2",staticStyle:{"width":"140px"},attrs:{"type":"search","placeholder":_vm.$t('competition.comp_id')},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.getList.apply(null, arguments)}},model:{value:(_vm.queryParams['competition_id']),callback:function ($$v) {_vm.$set(_vm.queryParams, 'competition_id', $$v)},expression:"queryParams['competition_id']"}}),_c('b-form-input',{staticClass:"mb-2 mr-2",staticStyle:{"width":"160px"},attrs:{"type":"search","placeholder":_vm.$t('competition.comp_title')},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.getList.apply(null, arguments)}},model:{value:(_vm.queryParams['title']),callback:function ($$v) {_vm.$set(_vm.queryParams, 'title', $$v)},expression:"queryParams['title']"}}),_c('b-form-select',{staticClass:"mr-2 mb-2",staticStyle:{"width":"110px"},model:{value:(_vm.queryParams['multi_cash_progress']),callback:function ($$v) {_vm.$set(_vm.queryParams, 'multi_cash_progress', $$v)},expression:"queryParams['multi_cash_progress']"}},[_c('b-form-select-option',{staticClass:"text-placeholder",attrs:{"value":null}},[_vm._v("All Status")]),_vm._l((_vm.statusOptions),function(ref,index){
var value = ref.value;
var text = ref.text;
return _c('b-form-select-option',{key:index,attrs:{"value":value}},[_vm._v(_vm._s(text))])})],2),_c('b-form-input',{staticClass:"mb-2 mr-2",staticStyle:{"width":"110px"},attrs:{"type":"search","placeholder":_vm.$t('general.username')},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.getList.apply(null, arguments)}},model:{value:(_vm.queryParams['user_name']),callback:function ($$v) {_vm.$set(_vm.queryParams, 'user_name', $$v)},expression:"queryParams['user_name']"}})]},proxy:true}])}),_c('b-table',{staticClass:"m-0 p-0",attrs:{"fields":_vm.fields,"items":_vm.items,"show-empty":"","empty-text":_vm.$t('notify.table_no_records'),"hover":"","responsive":"","head-variant":"light","sticky-header":"100%"},scopedSlots:_vm._u([{key:"head()",fn:function(ref){
var label = ref.label;
var field = ref.field;
return [_vm._v(_vm._s(label)),(field.tooltip)?_c('b-icon-question-circle-fill',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.topright",value:(field.tooltip),expression:"field.tooltip",modifiers:{"hover":true,"topright":true}}],staticClass:"ml-1"}):_vm._e()]}},{key:"cell(competition_name)",fn:function(ref){
var value = ref.value;
return [_c('tooltip-span',{attrs:{"content":value}})]}},{key:"cell(paid_times)",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Paid Times / Pending Times / Total Times'),expression:"'Paid Times / Pending Times / Total Times'",modifiers:{"hover":true,"top":true}}]},[_c('span',{staticClass:"text-success"},[_vm._v(_vm._s(value))]),_vm._v(" / "),_c('span',{staticClass:"text-warn"},[_vm._v(_vm._s(item.pending_times))]),_vm._v(" / "),_c('span',[_vm._v(_vm._s(item.total_times))])])]}},{key:"cell(multi_cash_progress)",fn:function(ref){
var value = ref.value;
return [_c('b-badge',{attrs:{"variant":value.badge}},[_vm._v(_vm._s(value.text))])]}},(_vm.items.length > 0)?{key:"bottom-row",fn:function(){return [_c('td',{staticClass:"text-left b-table-sticky-column",attrs:{"colspan":"1"}},[_vm._v("Total")]),_c('td',{attrs:{"colspan":"3"}}),_vm._l((['prize_amount', 'convert_amount', 'amount_per_time', 'paid_amount']),function(key){return _c('td',{key:key,staticClass:"text-right",attrs:{"colspan":"1"}},[_vm._v(_vm._s(_vm.formatWithGBP(_vm.sumTotal(_vm.items, key))))])}),_c('td',{attrs:{"colspan":"3"}})]},proxy:true}:null],null,true)}),(_vm.items.length > 0)?_c('div',{staticClass:"card-footer"}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }