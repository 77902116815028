<template>
  <card body-classes="p-0 d-flex flex-column" class="table-custom">
    <loading :active="isLoading" :can-cancel="false" :is-full-page="false" />
    <div class="px-4 pt-3 pb-2 d-flex flex-row flex-nowrap align-items-center justify-content-between">
      <div v-if="$route.query">
        {{ $t('coupon.coupon_title') }}:<span class="font-weight-bold ml-2">{{ title }}</span>
        <bool-badge :value="status" :text="status == 1 ? $t('status.active') : $t('status.inactive')" class="ml-3" />
      </div>
      <b-button class="ml-2" variant="basic" size="md" v-b-modal.coupon-code-upsert :disabled="status == 0"><b-icon-plus class="mr-1" />{{ $t('coupon.create_coupon_code') }}</b-button>
    </div>
    <b-table :fields="fields" :items="items" show-empty :empty-text="$t('notify.table_no_records')" small hover responsive head-variant="light" class="m-0 p-0">
      <template #cell(status)="{ value }">
        <b-badge :variant="['info', 'success', 'warning', 'primary'][value + 1]">{{ $t('coupon.code_status_list')[value + 1] }}</b-badge>
      </template>
      <template #cell(username)="{ item }">
        <span v-if="item.user" @click="viewUser(item.user)" class="action">{{ item.user.name }}</span>
        <b-button v-else @click="onUpsert(item)" variant="primary">{{ $t('action.assign') }}</b-button>
      </template>
    </b-table>
    <paginate v-if="items.length > 0" :queries="queries" :fetcher="getList" :total="codes.total" showBack class="card-footer" />

    <CouponCodeUpsert :coupon="{ id: $route.query.id }" :couponCode.sync="actionTarget" :getList="getList" :viewUser="viewUser" />
    <b-modal
      lazy
      centered
      id="user-details"
      :title="$t('user.user_details', { id: user ? '#' + user.id : '' })"
      title-class="w-100 d-flex justify-content-center align-self-center"
      header-class="py-2"
      body-class="pt-0"
      hide-footer
      ><UserDetails v-if="user" :user="user" :key="user.id">
        <template #address="{ address }"><br /><AddressDetails :userStore="user.store_id" :address="address" /></template>
      </UserDetails>
    </b-modal>
  </card>
</template>
<script>
import CouponCodeUpsert from '@/components/Coupon/CouponCodeUpsert.vue'
import AddressDetails from '@/components/Users/AddressDetails.vue'
import UserDetails from '@/components/Users/UserDetails.vue'
import { formatLocalDateTime } from '@/utils/dateTimeUtils'
import { mapGetters } from 'vuex'

export default {
  components: { CouponCodeUpsert, UserDetails, AddressDetails },
  data() {
    return {
      isLoading: false,
      status: parseInt(this.$route.query.status),
      title: decodeURIComponent(this.$route.query.title),
      queries: {
        coupon_id: parseInt(this.$route.query.id),
        page: 1,
        perPage: 10,
      },
      fields: [
        { key: 'code', label: this.$t('coupon.coupon_code') },
        { key: 'status', label: this.$t('general.status') },
        { key: 'username', label: this.$t('coupon.assigned_user') },
        { key: 'used_amount', label: this.$t('coupon.used_amount'), sortable: true },
        { key: 'created_at', label: this.$t('general.created_at'), formatter: (v) => formatLocalDateTime(v), sortable: true },
        { key: 'updated_at', label: this.$t('general.updated_at'), formatter: (v) => formatLocalDateTime(v), sortable: true },
      ],
      actionTarget: null,
      user: null,
    }
  },
  computed: {
    ...mapGetters({ codes: 'request/couponCodeList' }),
    items() {
      return this.codes?.data ?? []
    },
    key() {
      return this.actionTarget && this.actionTarget.userDetails ? this.actionTarget.userDetails.id : 'user-details-nodata'
    },
  },
  methods: {
    getList(_params) {
      this.isLoading = true
      const params = { ...this.queries, ..._params }
      this.$store
        .dispatch('request/getCouponCode', params)
        .then(() => (this.isLoading = false))
        .catch(() => (this.isLoading = false))
    },
    onUpsert(obj) {
      this.actionTarget = obj
      // this.actionTarget['coupon_id'] = this.queries.coupon_id
      this.$bvModal.show('coupon-code-upsert')
    },
    viewUser(obj) {
      this.user = obj
      this.$bvModal.show('user-details')
    },
  },
  created() {
    this.getList()
  },
}
</script>
