<template>
  <div style="display: contents">
    <div class="mt-3 d-flex align-items-center">
      <span class="font-weight-bold">Instant Win Info</span>
      <b-form-input v-model="search['product_name']" placeholder="Product Name" type="search" @keyup.enter="onSearch" style="width: 136px" class="ml-2" />
      <b-form-input v-model="search['ticket_number']" placeholder="Ticket Number" type="search" @keyup.enter="onSearch" style="width: 136px" class="ml-2" />
      <b-button variant="reset" class="ml-2" @click="exportSelectedProducts">
        <b-icon-file-spreadsheet class="mr-1" />
        Export Won Products ({{ selectedProducts.length }})
      </b-button>
    </div>
    <loading :active="isLoading" :can-cancel="false" :is-full-page="false" />
    <b-table
      show-empty
      :empty-text="$t('notify.table_no_records')"
      small
      hover
      striped
      responsive
      sticky-header="600px"
      head-variant="light"
      class="mx-0 mt-3 mb-0 p-0 flex-grow-1"
      tbody-class="h-100"
      :fields="instantWinnerFields"
      :items="filteredInstantWin"
      :busy="isLoading">
      <template #cell(product_name)="{ item }">
        <template v-if="item.product">
          <div v-if="item.product.nameLang" class="w-auto d-flex flex-row flex-nowrap align-items-center" :style="item.prizeConverted ? 'text-decoration: line-through' : ''">
            <div class="action" @click="viewProductDetails(item.product)" style="white-space: normal; min-width: 8rem">
              {{ item.product.name }}
            </div>
            <icon-translate :onClick="() => pushProductEdit(item.product)" classes="pl-1" />
          </div>
          <div v-else class="action" @click="viewProductDetails(item.product)" :style="`white-space: normal; min-width: 12rem${item.prizeConverted ? '; text-decoration: line-through' : ''}`">
            {{ item.product.name }}
          </div>
        </template>
        <template v-else>-</template>
      </template>
      <template #cell(selected)="{ value }">
        <bool-badge :value="value" :text="value == 1 ? $t('yes') : $t('no')" />
      </template>
      <template #bottom-row>
        <td colspan="4" class="font-weight-bold text-left">{{ $t('order.subtotal') }}</td>
        <td colspan="1" class="font-weight-bold text-right">{{ productCostSubtotal }}</td>
        <td colspan="1" class="font-weight-bold text-right">{{ cashSubtotal }}</td>
        <td colspan="1" class="font-weight-bold text-right">{{ creditSubtotal }}</td>
      </template>
    </b-table>
  </div>
</template>
<script>
import routerMixin from '@/mixins/router-mixin'
import { formatUtcDateTime } from '@/utils/dateTimeUtils'
import { exportAsExcel } from '@/utils/fileUtils'
import { noTransFormatter } from '@/utils/index'
import { formatWithCurrency, sumTotal } from '@/utils/numberUtils'

export default {
  name: 'InstantWinInfo',
  mixins: [routerMixin],
  props: { competition: Object, instantWins: Array, viewProductDetails: Function },
  data() {
    return {
      isLoading: false,
      search: {},
      filteredInstantWin: this.instantWins,
      selectedProducts: this.instantWins?.filter((item) => !!item.product_id && item.selected == 1) || [],
    }
  },
  computed: {
    instantWinnerFields() {
      return [
        { key: 'id', label: this.$t('general.id'), sortable: true },
        { key: 'ticket_number', label: this.$tc('order.ticket_number', 1), sortable: true },
        { key: 'product_name', label: this.$t('product.product_name'), sortable: true, formatter: noTransFormatter, thStyle: 'width: 12rem' },
        { key: 'selected', label: 'Won', sortable: true, class: 'text-center' },
        {
          key: 'product.cost',
          label: this.$t('general.cost'),
          sortable: true,
          formatter: this.formatter,
          class: 'text-right',
          tdAttr: (v, k, i) => (i.prizeConverted ? { style: 'text-decoration: line-through' } : null),
        },
        { key: 'alternative_cash_amount', label: this.$t('transaction.cash_amount'), sortable: true, formatter: this.formatter, class: 'text-right' },
        { key: 'alternative_credit_amount', label: this.$t('transaction.credit_amount'), sortable: true, formatter: this.formatter, class: 'text-right' },
      ]
    },
    cashSubtotal() {
      return this.instantWins.length > 0 ? `${formatWithCurrency(sumTotal(this.instantWins, 'alternative_cash_amount'), this.competition.rewards[0].currency)}` : ''
    },
    creditSubtotal() {
      return this.instantWins.length > 0 ? `${formatWithCurrency(sumTotal(this.instantWins, 'alternative_credit_amount'), this.competition.rewards[0].currency)}` : ''
    },
    productCostSubtotal() {
      return this.instantWins.length > 0
        ? `${formatWithCurrency(
            sumTotal(
              this.instantWins.filter((v) => v.cost),
              'cost'
            ),
            this.competition.rewards[0].currency
          )}`
        : ''
    },
  },
  watch: {
    'search.product_name'(newVal, oldVal) {
      if (!!oldVal && !newVal) {
        this.onSearch()
      }
    },
    'search.ticket_number'(newVal, oldVal) {
      if (!!oldVal && !newVal) {
        this.onSearch()
      }
    },
  },
  methods: {
    formatter(v) {
      return v ? formatWithCurrency(v, this.competition.rewards[0].currency) : '-'
    },

    csvPreProcess(data) {
      const neededKeys = ['product_id', 'amount', 'updated_at']
      for (let i in data) {
        for (const key in data[i]) {
          if (!neededKeys.includes(key)) {
            delete data[i][key]
          }
        }
      }
    },

    exportSelectedProducts() {
      if (this.selectedProducts.length < 1) {
        this.$notify({ group: 'root', type: 'warn', text: 'No won products' })
        return
      } else {
        return exportAsExcel(
          `InstantWinners_${this.competition.title}_${formatUtcDateTime(undefined, 2, true)}`,
          null,
          this.selectedProducts,
          this.selectedProducts.length,
          this.csvPreProcess,
          ['product_id', 'amount', 'updated_at'],
          [[this.$t('product.product_id'), this.$tc('general.amount', 1), this.$t('general.updated_at')]],
          (bool) => (this.isLoading = bool)
        )
      }
    },

    onSearch() {
      this.filteredInstantWin = this.instantWins.filter((item) => {
        const bool = this.search.product_name ? item.name && item.name == this.search.product_name : true
        const bool2 = this.search.ticket_number ? item.ticket_number && item.ticket_number == this.search.ticket_number : true
        return bool && bool2
      })
    },
  },

  created() {
    this.instantWins.forEach((item) => {
      this.$set(item, 'prizeConverted', !!item.product_id && item.selected == -1)
    })
  },
}
</script>
