<template>
  <b-modal
    lazy
    centered
    id="rank-competitions"
    :title="$t('competition.rank_comp')"
    title-class="w-100 d-flex justify-content-center align-self-center"
    header-class="py-2"
    footer-class="py-2"
    body-class="py-0"
    dialog-class="rank-competitions-modal"
    :busy="isLoading"
    @shown="onShown"
    @hidden="onHidden">
    <loading :active="isLoading" :can-cancel="false" :is-full-page="false" />

    <p class="mt-1 mb-0">
      ⚠️ Currently,
      <span class="font-weight-bold text-success">{{ items.reduce((acc, item) => (acc += item.inRank ? 1 : 0), 0) }}</span>
      competitions are ranked
      <span class="ml-2 action" v-b-toggle.rank-competitions-instruction>
        <b-icon-question-circle-fill class="mr-1" />
        How to rank the competitions?
      </span>
    </p>
    <b-collapse id="rank-competitions-instruction">
      <p class="mt-1 mb-0 text-sm pl-4">1. Check the box next to the competitions you want to rank.</p>
      <p class="mt-1 mb-0 text-sm pl-4">2. Drag and drop the checked competitions to your desired order.</p>
      <p class="mt-1 mb-0 text-sm pl-4">3. To remove a competition from the ranking, simply uncheck the box.</p>
    </b-collapse>

    <CatalogSelect storeId="1" :catalogId.sync="catalogId" class="mt-2" />
    <b-button @click="selectAll" :disabled="items.every((item) => item['inRank'])" variant="primary" class="mt-2 mb-2 mr-2">{{ $t('action.select_all') }}</b-button>

    <b-table-simple :busy="isLoading" show-empty :empty-text="$t('notify.table_no_records')" small hover responsive sticky-header="calc(100vh - 320px)" class="mt-2">
      <b-thead head-variant="light">
        <b-tr>
          <b-th v-for="field in fields" :key="field.key">{{ field.label || field.key }}</b-th>
        </b-tr>
      </b-thead>
      <draggable :list="items" group="rankComps" tag="tbody" :move="checkInRank">
        <b-tr v-for="(item, index) in items" :key="item.id" :variant="item.inRank ? 'basic' : 'light'">
          <b-td scope="row">
            <b-form-checkbox
              :key="'checkbox' + item.id"
              :name="'checkbox' + item.id"
              :value="true"
              :unchecked-value="false"
              v-model="item['inRank']"
              @change="($event) => toggleInRank($event, item, index)"
              class="sort-checkbox d-inline-block ml-3" />
          </b-td>
          <b-td>
            <span style="pointer-events: none">{{ index + 1 }}</span>
          </b-td>
          <b-td>
            <tooltip-span :content="item.title" />
          </b-td>
          <b-td>
            <b-img-lazy :key="'img' + item.id" :src="item.image" fluid block rounded class="hover-image position-relative" style="min-width: 74px; min-height: auto; max-width: 74px" />
          </b-td>
          <b-td>
            <template v-if="item.prices && item.prices.length > 0">
              <span
                v-for="price in item.prices.filter((x) => x.store_id == 1)"
                :key="'comp-price-' + price.id"
                :style="item.prices.filter((x) => x.store_id == 1).length > 1 && price.type == 0 ? `text-decoration: line-through` : ''">
                {{ formatWithCurrency(price.amount, price.currency_code) }}
              </span>
            </template>
            <template v-else>{{ $t('no_data') }}</template>
          </b-td>
          <b-td>
            <bool-badge :value="item.visible ? 1 : 0" :text="item.visible ? $t('status.shown') : $t('status.hidden')" />
          </b-td>
          <b-td>
            <span style="pointer-events: none">{{ formatLocalDateTime(item.start_time) }}</span>
          </b-td>
          <b-td>
            <span style="pointer-events: none">{{ formatLocalDateTime(item.end_time) }}</span>
          </b-td>
        </b-tr>
      </draggable>
    </b-table-simple>

    <template #modal-footer="{ hide }">
      <b-button variant="outline-secondary" @click="hide">{{ $t('action.cancel') }}</b-button>
      <b-button variant="reset" @click="onReset">{{ $t('action.reset') }}</b-button>
      <b-button variant="outline-primary" @click="onSubmit">{{ $t('action.submit') }}</b-button>
    </template>

    <confirm-modal
      id="confirm-rank-competitions"
      title="Rank Competitions Confirmation"
      message="Are you sure to save the new ranking for the competitions?"
      :isLoading="isLoading"
      :onSubmit="onConfirm" />
  </b-modal>
</template>
<script>
import CatalogSelect from '@/components/Catalog/CatalogSelect.vue'
import axios from '@/plugins/axios'
import moment from '@/plugins/moment-timezone'
import { API_LIST } from '@/utils/consts'
import { formatLocalDateTime } from '@/utils/dateTimeUtils'
import { notifyError } from '@/utils/index'
import { formatWithCurrency } from '@/utils/numberUtils.js'
import draggable from 'vuedraggable'

export default {
  name: 'RankCompetitions',

  components: { draggable, CatalogSelect },

  data() {
    return {
      isLoading: false,
      catalogId: 15,
      items: this.processList(this.$store.getters['request/rankCompetitions']?.data || []),
    }
  },

  computed: {
    total: {
      get() {
        return this.$store.getters['request/rankCompetitions']?.total || 0
      },
      set: (v) => v,
    },
    fields() {
      return [
        { key: 'checkbox' },
        { key: 'rank', label: this.$t('competition.rank') },
        { key: 'title', label: this.$t('general.title') },
        { key: 'image', label: this.$t('general.top_image') },
        { key: 'prices', label: this.$tc('competition.price', 1) },
        { key: 'visible', label: this.$t('catalog.visibility') },
        { key: 'start_time', label: this.$t('competition.opening_date') },
        { key: 'end_time', label: this.$t('competition.closing_date') },
      ]
    },
  },

  watch: {
    catalogId(newVal) {
      this.onReset()
    },
  },

  methods: {
    getList() {
      this.isLoading = true
      axios
        .get(API_LIST.get.competitionList, { params: { store_ids: '[1]', status: 1, page: 1, perPage: 1000 } })
        .then((res) => {
          const data = res?.data?.data
          this.items = data?.data?.length > 0 ? this.processList(data.data) : []
          this.total = data?.total || 0
          this.$store.dispatch('request/mutateState', { property: 'rankCompetitions', with: data })
        })
        .catch((err) => notifyError(err, this.$t('notify.unknown_err')))
        .finally(() => (this.isLoading = false))
    },
    processList(raw) {
      let processed = []
      for (let i = 0; i < raw.length; i++) {
        const pivot = raw[i].catalogs?.find?.(({ id }) => id == this.catalogId)?.pivot
        raw[i]['inRank'] = pivot?.position >= 0
        raw[i]['rank'] = pivot?.position || 0
        processed.push(raw[i])
      }
      processed.sort(this.sortFn(true))
      return processed
    },
    sortFn(isInit) {
      return (a, b) => (a.inRank && b.inRank ? (isInit ? a.rank - b.rank : 0) : a.inRank ? -1 : b.inRank ? 1 : this.defaultSortFn(a, b))
    },
    defaultSortFn(a, b) {
      const aTime = moment.utc(a.end_time).valueOf()
      const bTime = moment.utc(b.end_time).valueOf()
      if (aTime - bTime == 0) {
        return b.id - a.id
      } else {
        return aTime - bTime
      }
    },
    onShown(evt) {
      this.getList()
      // init rankings
    },
    onHidden(evt) {
      //
    },
    toggleInRank(evt, item, index) {
      this.items.sort(this.sortFn(false))
    },
    selectAll() {
      this.items.forEach((item) => (item['inRank'] = true))
      this.items.sort((a, b) => this.defaultSortFn(a, b))
    },
    checkInRank(evt, originalEvent) {
      const item = evt?.draggedContext?.element || this.items[evt?.oldIndex]
      const targetItem = evt?.relatedContext?.element || this.items[evt?.newIndex]
      // console.log('### check ', item?.inRank, targetItem?.inRank)
      if (!item?.inRank || !targetItem?.inRank) {
        // console.log('### cancel ')
        evt?.preventDefault?.()
        originalEvent?.preventDefault?.()
        return false
      }
    },
    onReset() {
      this.items = this.processList(this.$store.getters['request/rankCompetitions']?.data || [])
    },
    onSubmit() {
      this.$bvModal.show('confirm-rank-competitions')
    },
    onConfirm() {
      this.$bvModal.hide('confirm-rank-competitions')
      this.isLoading = true
      let payload = []
      this.items.forEach((item, index) => {
        const pivot = item.catalogs?.find?.(({ id }) => id == this.catalogId)?.pivot
        if (item.inRank) {
          payload.push(pivot ? { ...pivot, position: index + 1 } : { catalog_id: this.catalogId, competition_id: item.id, is_active: 1, position: index + 1 })
        } else if (pivot) {
          payload.push({ ...pivot, catalog_id: 0, competition_id: 0, position: 0 })
        }
      })
      // console.log('### payload ', payload)
      this.$store
        .dispatch('request/updateCatalogCompetitions', {
          payload: payload,
          successMsg: this.$t('catalog.change_position_notice[0]'),
          errorMsg: this.$t('catalog.change_position_notice[1]'),
        })
        .then((res) => {
          if (res?.status === 200) {
            this.getList()
            this.$bvModal.hide('rank-competitions')
          }
        })
        .finally(() => (this.isLoading = false))
    },
    formatLocalDateTime,
    formatWithCurrency,
  },
}
</script>
<style>
.rank-competitions-modal {
  width: max-content !important;
  min-width: 56rem !important;
  max-width: 90vw !important;
}
</style>
