<template>
  <b-modal
    lazy
    centered
    id="user-orders"
    :title="$t('user.user_orders') + (userDetails ? ' #' + userDetails.id : '')"
    title-class="w-100 d-flex justify-content-center align-self-center"
    header-class="py-2"
    body-class="pt-0"
    hide-footer
    @shown="onShown"
    @hidden="onHidden">
    <loading :active="isLoading" :can-cancel="false" :is-full-page="false" />
    <template v-if="userDetails">
      <OrderSearchInput :queryParams="queryParams" :onSearch="onSearch" />
      <b-table
        show-empty
        :empty-text="$t('notify.table_no_records')"
        small
        hover
        responsive
        sticky-header="16rem"
        head-variant="light"
        :fields="[
          { key: 'id', label: $t('order.order_id'), sortable: true },
          { key: 'store_id', label: $tc('general.store', 1), sortable: true },
          { key: 'status', label: $t('general.status'), sortable: true },
          { key: 'created_at', label: $t('general.created_at'), formatter: (v) => formatLocalDateTime(v), sortable: true },
          { key: 'grand_total', label: $t('order.grand_total'), formatter: (v, k, i) => formatWithCurrency(v, i.currency_code), class: 'text-right', sortable: true },
          { key: 'use_balance_amount', label: $t('user.balance_used'), formatter: (v, k, i) => formatWithCurrency(v, i.currency_code), class: 'text-right', sortable: true },
        ]"
        :items="items">
        <template #cell(id)="{ value, item }">
          <router-link
            :to="{
              name: 'Order Details',
              query: { orderDetailsId: value, display_name: item.user.display_name },
            }"
            class="text-decoration-none action"
            >{{ '#' + value }}</router-link
          >
        </template>
        <template #cell(store_id)="{ value }"><store-badge :store_id="value" /></template>
        <template #cell(status)="{ value }">
          <OrderStatus :value="value" />
        </template>
      </b-table>
      <paginate v-if="items.length > 0" :queries="queryParams" :fetcher="getOrderList" :total="orders.total" class="card-footer" />
    </template>
  </b-modal>
</template>
<script>
import OrderSearchInput from '@/components/Orders/OrderSearchInput.vue'
import { formatLocalDateTime } from '@/utils/dateTimeUtils'
import { formatWithCurrency } from '@/utils/numberUtils'
import { mapGetters } from 'vuex'
import OrderStatus from '../Orders/OrderStatus.vue'
export default {
  name: 'UserOrders',
  components: {
    OrderSearchInput,
    OrderStatus,
  },
  props: {
    userDetails: Object,
  },
  data() {
    return {
      isLoading: true,
      queryParams: { status: null, store_id: null, start: null, end: null, perPage: 10, page: 1 },
    }
  },
  computed: {
    ...mapGetters({ orders: 'request/orderList' }),
    items() {
      return this.orders?.data ?? []
    },
  },
  methods: {
    onShown() {
      if (this.userDetails?.id) {
        this.getOrderList()
      }
    },
    getOrderList() {
      this.isLoading = true
      this.queryParams['store_id'] = this.userDetails.store_id
      const params = {
        ...this.queryParams,
        currency: ['GBP', 'MXN'][this.userDetails.store_id - 1],
        user_id: this.userDetails.id,
      }
      this.$store
        .dispatch('request/getOrderList', params)
        .then(() => (this.isLoading = false))
        .catch(() => (this.isLoading = false))
    },
    onSearch() {
      this.queryParams.page = 1
      this.getOrderList()
    },
    onHidden(evt) {
      this.$emit('update:userDetails', null)
    },
    formatLocalDateTime,
    formatWithCurrency,
  },
}
</script>
