<template>
  <b-modal
    id="comp-approval-logs"
    :title="`Competition Approval Info ${competitionId ? ' (#' + competitionId + ')' : ''}`"
    title-class="w-100 d-flex justify-content-center align-self-center"
    header-class="py-2"
    body-class="py-0"
    footer-class="py-2"
    :hide-footer="!formType"
    ok-title="Submit"
    lazy
    centered
    @ok="onOk"
    :ok-disabled="!form.id"
    @cancel="_onHide"
    @close="_onHide"
    @change="onChange">
    <loading :active="loading" :can-cancel="false" :is-full-page="false" />
    <b-table :fields="fields" :items="items" :table-busy="loading" show-empty head-variant="light" sticky-header="60vh" bordered striped hover>
      <template #cell(competition_status)="{ value }">
        <CompetitionStatus :value="value" />
      </template>
      <template #cell(is_approval)="{ value }">
        <CompetitionApprovalStatus :value="value" />
      </template>
      <template #cell(triggers)="{ index, value }">
        <template v-if="Array.isArray(value)">
          <div v-for="(trigger, j) in value" :key="`trigger-${index}-${j}`" :class="`w-100 d-flex justify-content-between flex-nowrap px-3${j == 0 ? '' : ' border-top mt-1 pt-1'}`">
            <div class="text-uppercase">{{ trigger.key ? trigger.key.replaceAll('_', ' ') + (trigger.key.includes('margin') ? ' (%)' : ' (£)') : '-' }}</div>
            <div class="ml-4">{{ trigger.value }}</div>
          </div>
        </template>
        <template v-else-if="typeof value == 'object'">
          <div
            v-for="([key, val], j) in Object.entries(value)"
            :key="`trigger-${index}-${j}`"
            :class="`w-100 d-flex justify-content-between flex-nowrap px-3${j == 0 ? '' : ' border-top mt-1 pt-1'}`">
            <div class="text-uppercase">{{ key ? key.replaceAll('_', ' ') + (key.includes('margin') ? ' (%)' : ' (£)') : '-' }}</div>
            <div class="ml-4">{{ val }}</div>
          </div>
        </template>
      </template>
      <template #cell(descr)="{ index, item }">
        <template v-if="Array.isArray(item.triggers)">
          <div v-for="(trigger, j) in item.triggers" :key="`trigger-descr-${index}-${j}`" :class="`px-3 ${j == 0 ? '' : ' border-top mt-1 pt-1'}`">
            {{ trigger.description }}
          </div>
        </template>
      </template>
    </b-table>
    <b-form-group v-if="!!formType" :label="`Comment${formType == 'log' ? ' (Log #' + form.id + ')' : formType == 'approval' ? ' for New Approval Log' : ''}`">
      <b-form-textarea v-model="form['comments']" placeholder="Leave a comment for the new approval log" />
    </b-form-group>
  </b-modal>
</template>
<script>
import { formatLocalDateTime } from '@/utils/dateTimeUtils'
import { notifyError } from '@/utils/index'
import axios from '../../plugins/axios'
import { API_LIST } from '../../utils/consts'
import CompetitionApprovalStatus from './CompetitionApprovalStatus.vue'
import CompetitionStatus from './CompetitionStatus.vue'

export default {
  name: 'CompetitionApprovalLogs',
  props: {
    competitionId: { type: String },
    formType: { type: String }, // log, approval
    onHide: { type: Function, default: () => {} },
  },
  components: { CompetitionStatus, CompetitionApprovalStatus },
  data() {
    return {
      show: false,
      loading: false,
      fields: [
        { key: 'id', label: 'Log ID', sortable: true },
        { key: 'action', class: 'text-uppercase' },
        { key: 'competition_status', thStyle: 'max-width: 124px; white-space: break-spaces' },
        { key: 'is_approval', label: 'Approval Status' },
        { key: 'comments', tdAttr: { style: 'min-width: 240px; max-width: 360px; white-space: break-spaces' } },
        { key: 'triggers', class: 'w-100 px-0 border-left', thClass: 'px-3' },
        { key: 'descr', label: 'Conditions', class: 'px-0 border-right border-left', thClass: 'px-3' },
        { key: 'updated_at', formatter: (v) => formatLocalDateTime(v), sortable: true },
        { key: 'admin.name', label: 'Updated By' },
      ],
      items: null,
      form: { id: null, comments: null },
    }
  },
  watch: {
    competitionId(newVal, oldVal) {
      if (newVal !== oldVal && newVal) {
        this.getItems()
      }
    },
  },
  methods: {
    getItems() {
      if (this.competitionId && this.show && !this.loading) {
        this.loading = true
        axios
          .get(API_LIST.get.compApprovalLogs, { params: { id: this.competitionId } })
          .then((res) => {
            if (res?.data?.data?.length > 0) {
              this.items = res.data.data
              this.$store.dispatch('request/mutateState', { property: 'compApprovalLogs', with: res.data.data })
              if (this.formType == 'log') {
                this.form['id'] = res.data.data.reduce((accumulator, currentValue) => {
                  if (!accumulator) {
                    return currentValue.id
                  } else {
                    return Math.max(accumulator, currentValue.id)
                  }
                }, null)
              } else if (this.formType == 'approval') {
                this.form['id'] = this.competitionId
              }
            } else {
              this.items = []
            }
          })
          .catch((err) => notifyError(err, this.$t('notify.unknown_err')))
          .finally(() => (this.loading = false))
      }
    },
    onChange(e) {
      this.show = e
      if (e && !this.items) {
        this.getItems()
      }
    },
    onOk() {
      if (this.formType == 'log') {
        this.isLoading = true
        axios
          .post(API_LIST.post.approvalLog, this.form)
          .then((res) => {
            if (res?.status == 200) {
              this.onHide()
              this.form = { id: null, comments: null }
              this.$notify({ group: 'root', type: 'success', title: 'Success', text: 'Change competition approval log successfully!' })
            }
          })
          .finally(() => (this.isLoading = false))
      } else if (this.formType == 'approval') {
        this.isLoading = true
        axios
          .post(API_LIST.post.approveCompetition, this.form)
          .then((res) => {
            if (res?.status == 200) {
              this.onHide()
              this.form = { id: null, comments: null }
              this.$notify({ group: 'root', type: 'success', title: 'Success', text: 'Approve competition successfully!' })
            }
          })
          .finally(() => (this.isLoading = false))
      }
    },
    _onHide() {
      if (this.formType != 'approval') {
        this.onHide()
      } else {
        return
      }
    },
  },
  created() {
    this.getItems()
  },
}
</script>
