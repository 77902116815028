<template>
  <card body-classes="p-0 d-flex flex-column" class="table-custom">
    <loading :active="isLoading" :can-cancel="false" :is-full-page="false" />
    <WinnerSearchInput :queryParams="queryParams" :onSearch="onSearch" :isLoading.sync="isLoading" />
    <b-table
      :fields="fields"
      :items="items"
      :busy="isLoading"
      show-empty
      :empty-text="$t('notify.table_no_records')"
      small
      hover
      responsive
      sticky-header="100%"
      head-variant="light"
      class="m-0 p-0"
      primary-key="id">
      <template #head()="{ label, field }">{{ label }}<b-icon-question-circle-fill v-if="field.tooltip" class="ml-1" v-b-tooltip.hover.topright="field.tooltip" /></template>
      <template #cell(id)="{ value, item }">
        <router-link v-if="value" :to="{ name: 'Winner Details', query: { id: item.id }, params: { winner: item } }" class="action text-decoration-none d-block">
          {{ value }}
        </router-link>
        <template v-else>-</template>
      </template>
      <template #cell(title)="{ value }"><tooltip-span :content="value" /></template>
      <template #cell(competition_reward)="{ value }">
        <tooltip-span v-if="value.product && value.product.name" :content="value.product.name" />
        <template v-else>
          <b-badge v-if="Number(value.alternative_cash_amount) > 0" pill variant="success" class="d-block" style="max-width: max-content">CASH</b-badge>
          <b-badge v-if="Number(value.alternative_credit_amount) > 0" pill variant="primary" class="d-block" style="max-width: max-content">CREDIT</b-badge>
        </template>
      </template>
      <template #cell(tracking_status)="{ value, item }">
        <b-badge pill :variant="['secondary', 'success'][value]">{{ $t('winner.tracking_status_list')[value] }}</b-badge>
        <i
          v-if="remindCreateInvoice(item)"
          @click="viewEdit(item)"
          :class="`fa fa-receipt text-xl ${overTwoWeeks(item)} flex-grow-0 ml-2`"
          style="cursor: pointer; width: 20px"
          v-b-tooltip.hover.right="$t('winner.no_receipt')"></i>
      </template>
      <template #cell(image)="{ value, item }">
        <b-img-lazy v-if="value" :src="value" :alt="shortenString(value, 13, true)" block rounded class="hover-image" style="max-height: 60px" v-b-tooltip.hover.left="value" />
        <b-badge v-else variant="danger" @click="viewEdit(item)" class="font-weight-bold" style="cursor: pointer">⚠ {{ $t('notify.no_image') }}</b-badge>
      </template>
      <template #cell(competition)="{ value }">
        <template v-for="(val, index) in value">
          <store-badge v-if="val" :key="'status-store-' + index" :class="`d-block${index > 0 ? ' mt-1' : ''}`" :store_id="val" />
        </template>
      </template>
      <template #cell(order_id)="{ value, item }">
        <router-link v-if="value" :to="{ name: 'Order Details', query: { orderDetailsId: value, user: item.user } }" class="action text-decoration-none">
          {{ '#' + value }}
        </router-link>
        <b-badge v-else pill variant="danger">{{ $t('order.no_order') }}</b-badge>
      </template>
      <template #cell(alternative_cash_amount)="{ value, item }">
        <span v-if="value != '-'" class="action" @click="viewConvertPrize(item, 'alternative_cash_amount')">{{ value }}</span>
        <template v-else>-</template>
      </template>
      <template #cell(alternative_credit_amount)="{ value, item }">
        <span v-if="value != '-'" class="action" @click="viewConvertPrize(item, 'alternative_credit_amount')">{{ value }}</span>
        <template v-else>-</template>
      </template>
      <template #cell(user)="{ value, item }">
        <span v-if="value" class="action" @click="viewUser(item)">{{ value.name }}</span>
        <span v-else>{{ item.user_id ? '#' + item.user_id : '' }}</span>
        <store-badge :store_id="value.store_id" class="ml-1" />
      </template>
      <template #cell(status)="{ value }">
        <b-badge v-if="value !== 2" pill :variant="['danger', 'secondary', 'primary', 'info', 'success'][value + 1]">{{ $t(`winner.status_list[${value + 1}]`) }}</b-badge>
      </template>
      <template #cell(action)="{ item }">
        <b-button @click="viewEdit(item)" v-b-tooltip.hover.topleft="$t('action.edit')">
          <b-icon-pencil />
        </b-button>
        <b-button @click="viewConvertPrize(item)" :disabled="disableConvertPrize(item)" v-b-tooltip.hover.topright="`Convert Prize`">
          <b-icon-cash-coin />
        </b-button>
      </template>
    </b-table>
    <paginate v-if="items.length > 0" :queries="queryParams" :fetcher="getWinnerList" :total="winnerList.total" class="card-footer" />

    <WinnerEdit v-if="items.length > 0" :winner.sync="actionTarget" :getList="getWinnerList" />
    <ConvertPrize v-if="items.length > 0" :winner.sync="actionTarget" :getList="getWinnerList" :readonly="readonly" />

    <b-modal
      lazy
      centered
      id="user-details"
      :title="$t('user.user_details', { id: '' })"
      title-class="w-100 d-flex justify-content-center align-self-center"
      header-class="py-2"
      body-class="pt-0"
      hide-footer
      @hidden="actionTarget = null">
      <UserDetails v-if="actionTarget && actionTarget.user" :user="actionTarget.user">
        <template #address="{ address }">
          <br v-if="address" />
          <AddressDetails v-if="address" :userStore="actionTarget.store_id" :address="address" />
        </template>
      </UserDetails>
    </b-modal>
  </card>
</template>
<script>
import AddressDetails from '@/components/Users/AddressDetails.vue'
import UserDetails from '@/components/Users/UserDetails.vue'
import WinnerEdit from '@/components/Winners/WinnerEdit.vue'
import WinnerSearchInput from '@/components/Winners/WinnerSearchInput.vue'
import { formatLocalDateTime } from '@/utils/dateTimeUtils'
import { shortenString } from '@/utils/index'
import { formatWithCurrency, formatWithGBP } from '@/utils/numberUtils'
import { mapGetters } from 'vuex'
import ConvertPrize from '../../../components/Winners/ConvertPrize.vue'

export default {
  components: { WinnerSearchInput, WinnerEdit, UserDetails, AddressDetails, ConvertPrize },
  data() {
    return {
      isLoading: false,
      queryParams: {
        store_id: null,
        reward_type: 'winner',
        status: null,
        tracking_status: null,
        start: null,
        end: null,
        page: 1,
        perPage: 10,
        ...this.$route.params?.prev,
      },
      actionTarget: null,
      readonly: '',
      keysToConvert: ['title', 'display_name', 'price', 'image', 'alternative_cash_amount', 'alternative_credit_amount', 'cash_weekly', 'month_weekly'],
      keysToFormat1: [1, 3, 17],
      keysToFormat2: [4, 6, 7, 8],
    }
  },
  computed: {
    ...mapGetters({ winnerList: 'request/winnerList', users: 'request/userList', allowMultiStores: 'allowMultiStores', selfStore: 'store' }),
    fields() {
      const tmp = [
        { key: 'id', label: 'Winner ID', sortable: true },
        { key: 'title', stickyColumn: true },
        { key: 'image', label: this.$tc('general.image', 1) },
        { key: 'display_name', label: this.$t('winner.winner_name') },
        { key: 'price', label: this.$t('winner.won_for') },
        { key: 'competition_reward', label: this.$t('competition.prize') },
        { key: 'prize_amount', tdAttr: (v, k, i) => ({ style: Number(i.alternative_cash_amount) > 0 || Number(i.alternative_credit_amount) > 0 ? 'text-decoration: line-through' : '' }) },
        { key: 'alternative_cash_amount', label: 'Converted Cash', tooltip: 'Clicking the amount to see Convert Prize Details' },
        { key: 'alternative_credit_amount', label: 'Converted Credit', tooltip: 'Clicking the amount to see Convert Prize Details' },
        { key: 'prize_claim_frequency', label: 'Claiming Frequency', formatter: (v, k, i) => (i.cash_weekly ? 'weekly' : i.cash_monthly ? 'monthly' : 'one-off'), tdClass: 'text-capitalize' },
        { key: 'prize_claim_amount', label: 'Claimed Amount', formatter: (v) => (v ? formatWithGBP(v) : '-') },
        { key: 'user', label: this.$t('general.username') },
        { key: 'tracking_status', label: this.$t('winner.tracking_status'), sortable: true, tdClass: 'd-flex align-items-center', tdAttr: { style: 'height: inherit' } },
        { key: 'status', label: this.$t('general.status'), sortable: true },
        { key: 'action', label: this.$tc('general.action', 1), class: 'text-center' },
        { key: 'competition', label: this.$tc('general.store', 1), formatter: (v) => (v ? [v.store_id, v.stores?.[0]?.store_id ?? null] : null), class: 'text-center' },
        { key: 'order_id', label: this.$t('order.order_id') },
        { key: 'ticket_number', label: 'Ticket Number' },
        { key: 'created_at', label: this.$t('general.created_at'), sortable: true, formatter: (v) => formatLocalDateTime(v) },
      ]
      this.keysToFormat1.forEach((i) => (tmp[i].formatter = (v) => v || '-'))
      this.keysToFormat2.forEach((i) => {
        tmp[i].sortable = true
        tmp[i].formatter = (v, k, i) => (v ? formatWithCurrency(v, ['GBP', 'MXN'][i.user.store_id - 1]) : '-')
        tmp[i].class = 'text-right'
      })
      return tmp
    },
    items: {
      get() {
        return this.winnerList?.data?.length > 0 ? this.winnerList.data.map((winner) => this.initWinnerItem(winner)) : []
      },
      set: (v) => v,
    },
  },
  methods: {
    remindCreateInvoice(item) {
      return item.tracking_status === 0 && item.competition_reward.type === 'winner'
    },
    overTwoWeeks(item) {
      const date = new Date(item.created_at)
      // After two weeks to remind
      date.setDate(date.getDate() + 14)
      const today = new Date(formatLocalDateTime(null, 0, true))
      return today > date ? 'text-danger' : 'text-info'
    },
    initWinnerItem(winner) {
      if (winner.profiles?.length > 0) {
        let claimedAmt = 0
        winner.profiles.forEach((item) => {
          if (item['type'] === 'prize_claim_amount') {
            claimedAmt += Number(item['content'])
          } else if (this.keysToConvert.includes(item['type'])) {
            this.$set(winner, item['type'], item['content'])
          }
        })
        this.$set(winner, 'prize_claim_amount', claimedAmt)
      }
      return winner
    },
    getWinnerList() {
      this.isLoading = true
      const params = {}
      for (const key in this.queryParams) {
        if (this.queryParams[key] || typeof this.queryParams[key] == 'number') {
          params[key] = this.queryParams[key]
        }
      }
      this.$store
        .dispatch('request/getWinnerList', params)
        .then((res) => {
          this.items = res?.data?.data?.data?.map?.((winner) => this.initWinnerItem(winner)) || []
          this.isLoading = false
        })
        .catch(() => (this.isLoading = false))
    },
    onSearch() {
      this.queryParams.page = 1
      this.getWinnerList()
    },
    viewUser(row) {
      this.actionTarget = row
      this.$bvModal.show('user-details')
    },
    viewEdit(row) {
      this.actionTarget = row
      this.$bvModal.show('edit-winner')
    },
    viewConvertPrize(row, readonly) {
      this.actionTarget = row
      this.readonly = readonly
      this.$bvModal.show('convert-prize')
    },
    disableConvertPrize(item) {
      const rwd = item.competition_reward
      return Boolean(!rwd || !rwd.product?.name || Number(item.alternative_credit_amount) > 0 || Number(item.alternative_cash_amount) > 0 || rwd.selected == -1)
    },
    shortenString,
  },
  mounted() {
    this.queryParams.store_id = this.allowMultiStores ? null : this.selfStore
    this.getWinnerList()
  },
  beforeRouteLeave(to, from, next) {
    this.$set(from.params, 'preservedParams', this.queryParams)
    return next()
  },
}
</script>
