var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('card',{staticClass:"table-custom",attrs:{"body-classes":"p-0 d-flex flex-column"}},[_c('loading',{attrs:{"active":_vm.isLoading,"can-cancel":false,"is-full-page":false}}),_c('EntrySearchInput',{attrs:{"queryParams":_vm.queryParams,"type":"video","onSearch":_vm.onSearch}}),_c('b-table',{staticClass:"m-0 p-0",attrs:{"fields":_vm.fields,"items":_vm.items,"show-empty":"","empty-text":_vm.$t('notify.table_no_records'),"small":"","hover":"","striped":"","responsive":"","head-variant":"light","sticky-header":"100%"},scopedSlots:_vm._u([{key:"cell(store_id)",fn:function(ref){
var value = ref.value;
return [_c('store-badge',{attrs:{"store_id":value}})]}},{key:"cell(url)",fn:function(ref){
var value = ref.value;
return [_c('b-link',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.topright",value:(_vm.$t('entry.upload_notice[0]')),expression:"$t('entry.upload_notice[0]')",modifiers:{"hover":true,"topright":true}}],class:{ 'text-muted': !_vm.isValidHttpUrl(value) },attrs:{"disabled":!_vm.isValidHttpUrl(value),"href":value,"target":"_blank"}},[_c('b-icon-link45deg',{staticClass:"mr-1"}),_vm._v(_vm._s(_vm.$t('entry.link'))+" ")],1)]}},{key:"cell(status)",fn:function(ref){
var value = ref.value;
return [_c('b-badge',{attrs:{"variant":['secondary', 'success'][value]}},[_vm._v(_vm._s(_vm.$t('entry.status_list')[value]))])]}},{key:"cell(id)",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_c('b-button',{attrs:{"variant":"basic"},on:{"click":function($event){return _vm.onEdit(item)}}},[_c('b-icon-pencil',{staticClass:"mr-1"}),_vm._v(_vm._s(_vm.$t('action.edit')))],1),_c('b-button',{attrs:{"disabled":item.status != 0,"variant":"basic"},on:{"click":function($event){return _vm.handleStatus(value, item.status)}}},[_vm._v(_vm._s(_vm.$t('action.publish'))),_c('b-icon-cloud-upload',{staticClass:"ml-1"})],1)]}}])}),(_vm.items.length > 0)?_c('paginate',{staticClass:"card-footer",attrs:{"queries":_vm.queryParams,"fetcher":_vm.getVideoDrawList,"total":_vm.videoDrawList.total}}):_vm._e(),_c('confirm-modal',{attrs:{"id":"confirm-publish-vid","title":_vm.$t('entry.confirm_publish'),"message":_vm.$t('entry.publish_notice[0]', { status: _vm.$t(("entry.status_list[" + (_vm.statusParams.status == 1 ? 1 : 0) + "]")) }),"isLoading":_vm.modalLoading,"onSubmit":_vm.onUpdate}}),_c('VideoDrawUpsert',{attrs:{"title":_vm.$t('draw.edit_vid_draw'),"drawDetails":_vm.actionTarget,"getList":_vm.getVideoDrawList},on:{"update:drawDetails":function($event){_vm.actionTarget=$event},"update:draw-details":function($event){_vm.actionTarget=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }