var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"font-weight-normal"},[_vm._v(" "+_vm._s(_vm.$tc('competition.price', 2))),_c('span',{staticClass:"text-danger ml-1"},[_vm._v("*")]),(_vm.isEditRoute)?_c('b-button',{staticClass:"ml-2",attrs:{"variant":"outline-primary"},on:{"click":function($event){return _vm.confPriceChangeable(_vm.index)}}},[_c('b-icon-pencil',{staticClass:"mr-1"}),_vm._v(_vm._s(_vm.$t('competition.edit_price'))+" ")],1):_vm._e()],1),_c('b-table',{staticClass:"mt-2 mb-0 p-0",attrs:{"fields":['type', 'amount', 'start_at', 'expired_at', 'status'],"items":_vm.store.prices,"small":"","striped":"","hover":"","responsive":"","head-variant":"light"},scopedSlots:_vm._u([{key:"cell(type)",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.$t('competition.price_type')[value])),(value == 0)?_c('span',{staticClass:"text-danger ml-1"},[_vm._v("*")]):_vm._e()]}},{key:"head(amount)",fn:function(ref){
var label = ref.label;
return [_vm._v(_vm._s(label)+" ("+_vm._s(['GBP', 'MXN'][_vm.store.value - 1])+")"),_c('span',{staticClass:"text-danger ml-1"},[_vm._v("*")])]}},{key:"cell(amount)",fn:function(ref){
var item = ref.item;
return [_c('b-form-input',{attrs:{"disabled":_vm.isEditRoute && !_vm.store.isPriceChangeable,"required":item.type === 0,"state":_vm.store.priceValid,"number":"","type":"number","step":"0.01","min":"0","placeholder":_vm.$tc('general.amount', 1)},on:{"input":function($event){return _vm.priceValidation(_vm.store, _vm.index)}},model:{value:(item['amount']),callback:function ($$v) {_vm.$set(item, 'amount', $$v)},expression:"item['amount']"}})]}},{key:"cell(start_at)",fn:function(ref){
var item = ref.item;
return [(item.type === 0)?[_vm._v(_vm._s(item.start_at || _vm.$t('competition.same_opening_date')))]:_c('b-form-input',{staticStyle:{"width":"200px"},attrs:{"type":"datetime-local","placeholder":_vm.$t('input.choose_date'),"required":!!item.amount,"disabled":_vm.isEditRoute && !_vm.store.isPriceChangeable},model:{value:(item['start_at']),callback:function ($$v) {_vm.$set(item, 'start_at', $$v)},expression:"item['start_at']"}})]}},{key:"cell(expired_at)",fn:function(ref){
var item = ref.item;
return [(item.type === 0)?[_vm._v(_vm._s(item.expired_at || _vm.$t('competition.same_closing_date')))]:_c('b-form-input',{staticStyle:{"width":"200px"},attrs:{"type":"datetime-local","placeholder":_vm.$t('input.choose_date'),"required":!!item.amount,"disabled":_vm.isEditRoute && !_vm.store.isPriceChangeable},model:{value:(item['expired_at']),callback:function ($$v) {_vm.$set(item, 'expired_at', $$v)},expression:"item['expired_at']"}})]}},{key:"cell(status)",fn:function(ref){
var value = ref.value;
return [_c('bool-badge',{attrs:{"value":value,"text":value == 1 ? _vm.$t('status.active') : _vm.$t('status.inactive')}})]}}])}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.store.priceValid}},[_vm._v(_vm._s(_vm.$t('competition.price_validation')))])],1)}
var staticRenderFns = []

export { render, staticRenderFns }