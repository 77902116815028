<template>
  <b-modal
    lazy
    centered
    :id="id ? id : 'user-add-credit'"
    :title="$t('user.add_credit[0]')"
    title-class="w-100 d-flex justify-content-center align-self-center"
    header-class="py-2"
    footer-class="py-2"
    body-class="py-0"
    @hidden="onHidden">
    <b-form @submit.prevent="onSubmit" id="user-credit-form" v-if="userDetails">
      <b-form-group label-for="user-credit-currency" label-class="text-sm" class="m-0">
        <template #label>{{ $t('general.currency_code') }}<span class="text-danger ml-1">*</span></template>
        <b-form-select id="user-credit-currency" :value="userDetails['store_id']" disabled>
          <b-form-select-option selected disabled hidden :value="null">{{ $t('input.select_currency') }}</b-form-select-option>
          <b-form-select-option value="1">{{ $t('gbp') }}</b-form-select-option>
          <b-form-select-option value="2">{{ $t('mxn') }}</b-form-select-option>
        </b-form-select>
      </b-form-group>
      <b-form-group label-class="text-sm" class="mt-3 mb-0">
        <template #label>{{ $t('transaction.credit_amount') }}<span class="text-danger ml-1">*</span></template>
        <b-form-input v-model="form['amount']" number type="number" step="0.01" min="0.01" :placeholder="$t('user.enter_points')" required />
      </b-form-group>
      <b-form-group label-class="text-sm" class="mt-3 mb-0">
        <template #label>{{ $t('general.reason') }}<span class="text-danger ml-1">*</span></template>
        <b-form-textarea v-model="form['comment']" :placeholder="$t('cashback.leave_comment')" rows="5" no-resize required />
      </b-form-group>
    </b-form>
    <template #modal-footer="{ hide }">
      <b-button variant="outline-secondary" @click="hide">{{ $t('action.cancel') }}</b-button>
      <b-button variant="outline-primary" type="submit" form="user-credit-form" :disabled="!userDetails || !userDetails['id']">{{ $t('action.submit') }}</b-button>
    </template>
  </b-modal>
</template>
<script>
export default {
  name: 'UserAddCredit',
  props: {
    id: String,
    userDetails: Object,
    getList: Function,
  },
  data() {
    return {
      form: { amount: null, comment: null },
    }
  },
  methods: {
    onHidden(evt) {
      this.form = { amount: null, comment: null }
      this.$emit('update:userDetails', null)
    },
    onSubmit(evt) {
      const params = {
        currency: ['GBP', 'MXN'][this.userDetails['store_id'] - 1],
        amount: this.form.amount,
        comment: this.form.comment,
        user_id: this.userDetails['id'],
        successMsg: this.$t('user.add_credit[1]'),
        errorMsg: this.$t('user.add_credit[2]'),
      }
      this.$store.dispatch('request/addUserBalance', params).then((res) => {
        if (res.status === 200) {
          this.getList()
          this.$bvModal.hide('user-add-credit')
        }
      })
    },
  },
}
</script>
