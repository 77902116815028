<template>
  <b-card class="p-0" no-body>
    <b-table :fields="fields" :items="order.items" show-empty :empty-text="$t('notify.table_no_records')" head-variant="light" class="m-0 p-0" responsive>
      <template #cell(competition)="{ item }">
        <span class="action mr-1" @click="$router.push({ name: 'Competition Details', query: { id: item.competition.id } })">
          {{ item.competition.title }}
        </span>
        <b-badge variant="primary" pill>{{ item.competition.id }}</b-badge>
        <div class="d-flex align-items-center">
          <span class="text-gray font-weight-bold">{{ $t('order.chosen_answer') }}:</span>
          <span class="ml-1 text-gray">{{ item.answer ? item.answer.answer : '-' }}</span>
          <bool-badge :value="item.answer_correct" :text="Boolean(item.answer_correct) ? 'CORRECT' : 'WRONG'" class="ml-1" />
          <span class="ml-1 action" @click="openQuiz(item.quiz)">{{ $t('order.check_quiz') }}→</span>
        </div>

        <div class="d-flex align-items-center">
          <span class="text-gray font-weight-bold"> {{ $tc('order.ticket_number', 0) }}: </span>
          <span class="ml-1 text-gray">{{ item.ticket_numbers }}</span>
        </div>
      </template>
      <template #cell(price)="{ value, item }">
        {{ value }}
        <span v-if="Number(item.discount_amount) > 0" class="mr-1">
          <del>{{ formatWithCurrency(item.normal_price, currency) }}</del>
        </span>
      </template>
      <template #cell(qty)="{ value }"><span class="font-weight-semibold">x</span>{{ value }}</template>
    </b-table>

    <div v-if="useCash || useCredit" class="pt-3 bg-secondary border-top border-bottom">
      <div v-if="useCredit" class="py-2 px-4 bg-white border-top d-flex justify-content-end">
        <span>{{ $t('order.via_wallet') }}</span>
        <span class="ml-auto text-right text-gray text-sm">{{ $t('competition.credit') }}:</span>
        <span class="text-right" style="width: 100%; max-width: 120px">-{{ formatWithCurrency(order.use_balance_amount, currency) }}</span>
      </div>
      <div v-if="useCash" :class="`pb-2 px-4 bg-white d-flex justify-content-end ${useCredit ? '' : 'pt-2 border-top'}`">
        <span v-if="!useCredit">{{ $t('order.via_wallet') }}</span>
        <span class="ml-auto text-right text-gray text-sm">{{ $t('competition.cash') }}:</span>
        <span class="text-right" style="width: 100%; max-width: 120px">-{{ formatWithCurrency(order.use_wallet_amount, currency) }}</span>
      </div>
      <div class="pb-2 px-4 bg-white" v-if="order.user_balance_transaction || order.user_wallet_transaction">
        <b-button v-if="_showRefund" @click="_openWalletRefund(order)" variant="reset">
          {{ $t('order.refund') }}
        </b-button>
        <div v-if="_isRefunded" class="ml-auto text-danger text-sm text-right">{{ $t('order.refunded') }} {{ _comment }}</div>
      </div>
    </div>

    <div v-if="Number(order.coupon_amount) != 0" class="pt-3 bg-secondary border-top">
      <div class="py-2 px-4 bg-white border-top border-bottom d-flex justify-content-between">
        <span>{{ $t('order.via_coupon') }} {{ order.coupon_code }}</span>
        <span>-{{ formatWithCurrency(order.coupon_amount, currency) }}</span>
      </div>
    </div>

    <div v-if="order.status === -2" class="pt-3 bg-secondary border-top">
      <div class="pt-2 px-4 bg-white border-top d-flex justify-content-between">
        <span>
          <b-icon-arrow-counterclockwise class="mr-1" />
          {{ $tc('general.order', 1) }} #{{ order.id }} - {{ formatLocalDateTime(order.updated_at, 'DD/MM/YYYY HH:mm:ss') }}
        </span>
        <span>-{{ isPayEmpty ? '-' : formatWithCurrency(successPayment.amount_refunded, currency) }}</span>
      </div>
      <div class="py-2 pl-5 pr-4 bg-white border-bottom text-right text-sm text-gray">
        {{ $t('order.fully_refunded') }}
      </div>
    </div>

    <div class="bg-secondary text-right pt-3">
      <div class="px-4 d-flex justify-content-end">
        <span class="text-gray text-sm">{{ $t('order.items_subtotal') }}: </span>
        <span class="font-weight-bold" style="width: 100%; max-width: 120px">{{ formatWithCurrency(order.subtotal, currency) }}</span>
      </div>
      <div class="px-4 d-flex justify-content-end">
        <span class="text-gray text-sm">{{ $t('order.order_total') }}: </span>
        <span class="font-weight-bold" style="width: 100%; max-width: 120px">{{ formatWithCurrency(order.subtotal, currency) }}</span>
      </div>
      <div class="px-4 d-flex justify-content-end" v-if="[-2, 3].includes(order.status)">
        <span class="text-gray text-sm">{{ $t('order.bank_payment') }}: </span>
        <span class="font-weight-bold" style="width: 100%; max-width: 120px">{{ formatWithCurrency(order.grand_total, currency) }}</span>
      </div>
      <div class="px-4 d-flex justify-content-end" v-if="order.status === 3 || order.status === -2">
        <span class="text-gray text-sm text-capitalize">
          {{ formatLocalDateTime(order.updated_at) }} {{ $t('order.via') }} {{ isPayEmpty ? '-' : successPayment.method }} / {{ isPayEmpty ? '-' : successPayment.gateway }} /
          {{ isPayEmpty ? '-' : successPayment.txn_type }}
        </span>
      </div>

      <template v-if="order.status === -2">
        <div class="px-4 d-flex justify-content-end">
          <span class="text-danger text-sm">{{ $t('order.refunded') }}: </span>
          <span class="font-weight-bold" style="width: 100%; max-width: 120px">
            {{ formatWithCurrency(isPayEmpty ? 0 : successPayment.amount_refunded, currency) }}
          </span>
        </div>
        <div class="px-4 d-flex justify-content-end">
          <span class="text-gray text-sm"> {{ $t('payment.net_payment') }}: </span>
          <span class="font-weight-bold" style="width: 100%; max-width: 120px">
            {{ formatWithCurrency(isPayEmpty ? 0 : successPayment.amount_paid, currency) }}
          </span>
        </div>
      </template>

      <div class="mt-2 py-2 px-4 bg-white border-top d-flex justify-content-between align-items-center">
        <b-button variant="reset" @click="openRefund(order.payments[0].id)" v-if="showRefund && [2, 3, 4, -3].includes(order.status) && order.payments && Number(order.grand_total) > 0">
          {{ $t('order.refund') }}
        </b-button>
        <span class="ml-auto text-gray text-sm">
          {{ $t('order.order_no_longer_editable') }}
        </span>
      </div>
    </div>

    <b-modal
      lazy
      centered
      id="order-quiz"
      :title="$t('order.quiz_details')"
      title-class="w-100 d-flex justify-content-center align-self-center"
      header-class="w-100 py-2"
      body-class="pt-0"
      dialog-class="quiz"
      hide-footer
      ><template v-if="quizDetails">
        <p class="font-weight-bold m-0 text-lg text-capitalize">{{ $t('competition.question') }} ( {{ quizDetails.type }} ):</p>
        <p class="font-weight-light mt-2 mb-0">{{ quizDetails.question }}</p>
        <QuizAnswer
          v-for="(item, index) in quizDetails.answers"
          :key="'answers-' + index"
          :value="item.is_correct"
          :class="`w-25 mt-3 mr-3 ${item.is_correct ? 'font-weight-bold' : ''}`"
          style="font-size: 0.85rem"
          :text="item.answer" />
      </template>
    </b-modal>
  </b-card>
</template>
<script>
import { formatLocalDateTime } from '@/utils/dateTimeUtils'
import { formatWithCurrency } from '@/utils/numberUtils'
import { isEmpty } from 'lodash'
import { mapGetters } from 'vuex'
import QuizAnswer from '../QuizAnswer.vue'

export default {
  name: 'entry-list',
  components: { QuizAnswer },
  props: { openWalletRefund: Function, openRefund: Function, successPayment: Object },
  data() {
    return {
      fields: [
        { key: 'competition', label: this.$t('order.order_items'), stickyColumn: true, class: 'text-wrap width' },
        { key: 'price', label: this.$t('general.cost'), tdClass: 'align-top', class: 'text-center', formatter: (v) => formatWithCurrency(v, this.currency) },
        { key: 'qty', label: this.$t('order.qty'), tdClass: 'align-top', class: 'text-center' },
        { key: 'row_total', label: this.$t('order.total'), tdClass: 'align-top', class: 'text-right', formatter: (v) => formatWithCurrency(v, this.currency) },
      ],
      quizDetails: null,
      isLoading: false,
    }
  },
  computed: {
    ...mapGetters({ order: 'request/orderDetails' }),
    currency() {
      return this.order?.currency_code
    },
    showRefund() {
      return this.order?.type == 0 && this.order?.store_id !== 2 && this.$store.getters['store'] !== 2
    },
    isPayEmpty() {
      return isEmpty(this.successPayment)
    },
    useCash() {
      return Number(this.order?.use_wallet_amount) > 0
    },
    useCredit() {
      return Number(this.order?.use_balance_amount) > 0
    },
    _showRefund() {
      return this.showRefund && (this.order?.user_balance_transaction?.status === 2 || this.order?.user_wallet_transaction?.status === 'succeeded')
    },
    _isRefunded() {
      return this.order?.user_balance_transaction?.status === 3 || this.order?.user_wallet_transaction?.status === 'canceled'
    },
    _comment() {
      return this.order?.user_balance_transaction?.comment ?? ''
    },
  },
  methods: {
    openQuiz(obj) {
      this.quizDetails = obj
      this.$bvModal.show('order-quiz')
    },
    _openWalletRefund(item) {
      this.openWalletRefund(item?.user_balance_transaction?.id, item?.user_wallet_transaction?.id)
    },
    formatLocalDateTime,
    formatWithCurrency,
  },
}
</script>
