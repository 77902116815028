<template>
  <b-modal
    lazy
    centered
    id="assign-free"
    :title="$t('ticket.assign_free_tickets')"
    title-class="w-100 d-flex justify-content-center align-self-center"
    header-class="py-2"
    footer-class="py-2"
    body-class="py-0"
    @hide="hideModal"
    :busy="isLoading">
    <loading :active="isLoading" :can-cancel="false" :is-full-page="false" />
    <b-form @submit.prevent="assignFreeTickets" id="free-tickets-form" class="m-0">
      <b-form-group label-for="tickets-username" label-class="text-sm" class="d-inline-block w-50 pr-2 mb-0">
        <template #label>{{ $t('general.user') }}<span class="ml-1 text-danger">*</span></template>
        <b-form-input id="tickets-username" v-model="form['username']" :placeholder="$t('input.select_user')" disabled required />
      </b-form-group>

      <b-form-group label-for="tickets-type" label-class="text-sm" class="d-inline-block w-50 pl-2 mb-0">
        <template #label>{{ $t('general.type') }}<span class="ml-1 text-danger">*</span></template>
        <b-form-select
          id="tickets-type"
          v-model="form['type']"
          :options="[
            { value: 1, text: $t('ticket.ticket_types[1]') },
            { value: 2, text: $t('ticket.ticket_types[2]') },
          ]"
          required />
      </b-form-group>

      <b-form-group label-for="tickets-tickets" label-class="text-sm" class="mt-2">
        <template #label>{{ $tc('general.ticket', 2) }}<span class="ml-1 text-danger">*</span></template>
        <b-form-input id="tickets-tickets" v-model="form['ticket_ids']" :placeholder="$t('ticket.select_tickets_number')" disabled required />
      </b-form-group>

      <b-tabs v-model="tabIndex" lazy>
        <b-tab :title="$t('ticket.step1')" active title-item-class="text-sm">
          <UserList
            :fields="[
              { key: 'id', label: $t('user.user_id'), sortable: true },
              { key: 'name', label: $t('general.username'), sortable: true },
              { key: 'profile.first_name', label: $t('user.first_name'), sortable: true },
              { key: 'profile.last_name', label: $t('user.last_name'), sortable: true },
              { key: 'is_active', label: $t('general.status') },
              { key: 'actions', label: $tc('general.action', 1) },
            ]"
            :queries="{ store_id: $store.getters['store'], name: null, page: 1, perPage: 10 }"
            :noInitOnMount="true">
            <template #name="{ user }">
              <span class="action" @click="viewUser(user)">{{ user.name }}</span>
            </template>
            <template #actions="{ user }">
              <b-button v-if="user.profile && user.profile.first_name && user.profile.last_name" variant="outline-primary" @click="selectFreeUser(user)">{{ $t('action.select') }} </b-button>
              <b-badge v-else pill variant="danger" v-b-tooltip.hover.left="$t('ticket.no_names_notice')">{{ $t('user.no_names') }}</b-badge>
            </template>
          </UserList>
        </b-tab>
        <b-tab :title="$t('ticket.step2')" title-item-class="text-sm" ref="tab1" :disabled="!form['user']" :active="tabIndex === 1">
          <p class="mt-3 mb-0 text-slack text-sm">⚠ {{ $t('ticket.user_limit_notice', { num: $route.query.limit }) }}.</p>
          <b-input-group class="w-50 mt-3">
            <b-input-group-prepend>
              <b-button variant="outline-primary" disabled>{{ $t('ticket.quantity') }}</b-button>
            </b-input-group-prepend>
            <b-form-input v-model="form['numbers']" type="number" min="1" step="1" :max="$route.query.limit" :placeholder="$t('ticket.quantity_notice')[0]" class="pl-2" />
            <b-input-group-append>
              <b-button variant="outline-primary" @click="getShuffleTickets">{{ $t('ticket.shuffle_tickets[0]') }}</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-tab>
      </b-tabs>
    </b-form>
    <template #modal-footer="{ cancel }">
      <b-button variant="outline-secondary" @click="cancel">{{ $t('action.cancel') }}</b-button>
      <b-button variant="outline-primary" type="submit" form="free-tickets-form">{{ $t('action.submit') }}</b-button>
    </template>
  </b-modal>
</template>
<script>
import UserList from '@/components/Users/UserList.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'AssignFreeTickets',
  components: { UserList },
  props: {
    competitionId: Number,
    viewUser: Function,
    getList: Function,
  },
  data() {
    return {
      isLoading: false,
      form: { user: null, username: null, type: 1, ticket_ids: null, numbers: 1 },
      tabIndex: 0,
    }
  },
  computed: {
    ...mapGetters({ shuffleTickets: 'request/shuffleTickets' }),
  },
  methods: {
    selectFreeUser(user) {
      this.form.user = user
      this.form.username = user.name
      this.tabIndex = 1
    },

    getShuffleTickets() {
      this.isLoading = true
      this.$store
        .dispatch('request/getShuffleTickets', {
          competition_id: this.competitionId,
          qty: this.form.numbers && this.form.numbers.length > 0 ? this.form.numbers : 1,
          successMsg: this.$t('ticket.shuffle_tickets[1]'),
          errorMsg: this.$t('ticket.shuffle_tickets[2]'),
        })
        .then(() => (this.isLoading = false))
        .catch(() => (this.isLoading = false))
    },

    assignFreeTickets() {
      if (!this.form.user || !this.form.ticket_ids) {
        this.$notify({ group: 'root', type: 'warn', text: this.$t('ticket.assign_free_tickets_notice[0]') })
        return
      }
      this.isLoading = true
      this.$store
        .dispatch('request/freeEntries', {
          competition_id: this.competitionId,
          user_id: this.form.user.id,
          type: this.form.type,
          ticket_ids: this.shuffleTickets.map((v) => v.id),
          successMsg: this.$t('ticket.assign_free_tickets_notice[1]'),
          errorMsg: this.$t('ticket.assign_free_tickets_notice[2]'),
        })
        .then((res) => {
          this.isLoading = false
          if (res.status === 200) {
            this.getList()
            this.$bvModal.hide('assign-free')
          }
        })
        .catch(() => (this.isLoading = false))
    },

    hideModal() {
      this.form = { user: null, type: 1, ticket_ids: null, numbers: 1 }
    },
  },
  watch: {
    shuffleTickets(val) {
      this.form.ticket_ids = val.map((v) => '🎟 ' + v.number).join('  ')
    },
  },
}
</script>
