var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"comp-approval-logs","title":("Competition Approval Info " + (_vm.competitionId ? ' (#' + _vm.competitionId + ')' : '')),"title-class":"w-100 d-flex justify-content-center align-self-center","header-class":"py-2","body-class":"py-0","footer-class":"py-2","hide-footer":!_vm.formType,"ok-title":"Submit","lazy":"","centered":"","ok-disabled":!_vm.form.id},on:{"ok":_vm.onOk,"cancel":_vm._onHide,"close":_vm._onHide,"change":_vm.onChange}},[_c('loading',{attrs:{"active":_vm.loading,"can-cancel":false,"is-full-page":false}}),_c('b-table',{attrs:{"fields":_vm.fields,"items":_vm.items,"table-busy":_vm.loading,"show-empty":"","head-variant":"light","sticky-header":"60vh","bordered":"","striped":"","hover":""},scopedSlots:_vm._u([{key:"cell(competition_status)",fn:function(ref){
var value = ref.value;
return [_c('CompetitionStatus',{attrs:{"value":value}})]}},{key:"cell(is_approval)",fn:function(ref){
var value = ref.value;
return [_c('CompetitionApprovalStatus',{attrs:{"value":value}})]}},{key:"cell(triggers)",fn:function(ref){
var index = ref.index;
var value = ref.value;
return [(Array.isArray(value))?_vm._l((value),function(trigger,j){return _c('div',{key:("trigger-" + index + "-" + j),class:("w-100 d-flex justify-content-between flex-nowrap px-3" + (j == 0 ? '' : ' border-top mt-1 pt-1'))},[_c('div',{staticClass:"text-uppercase"},[_vm._v(_vm._s(trigger.key ? trigger.key.replaceAll('_', ' ') + (trigger.key.includes('margin') ? ' (%)' : ' (£)') : '-'))]),_c('div',{staticClass:"ml-4"},[_vm._v(_vm._s(trigger.value))])])}):(typeof value == 'object')?_vm._l((Object.entries(value)),function(ref,j){
var key = ref[0];
var val = ref[1];
return _c('div',{key:("trigger-" + index + "-" + j),class:("w-100 d-flex justify-content-between flex-nowrap px-3" + (j == 0 ? '' : ' border-top mt-1 pt-1'))},[_c('div',{staticClass:"text-uppercase"},[_vm._v(_vm._s(key ? key.replaceAll('_', ' ') + (key.includes('margin') ? ' (%)' : ' (£)') : '-'))]),_c('div',{staticClass:"ml-4"},[_vm._v(_vm._s(val))])])}):_vm._e()]}},{key:"cell(descr)",fn:function(ref){
var index = ref.index;
var item = ref.item;
return [(Array.isArray(item.triggers))?_vm._l((item.triggers),function(trigger,j){return _c('div',{key:("trigger-descr-" + index + "-" + j),class:("px-3 " + (j == 0 ? '' : ' border-top mt-1 pt-1'))},[_vm._v(" "+_vm._s(trigger.description)+" ")])}):_vm._e()]}}])}),(!!_vm.formType)?_c('b-form-group',{attrs:{"label":("Comment" + (_vm.formType == 'log' ? ' (Log #' + _vm.form.id + ')' : _vm.formType == 'approval' ? ' for New Approval Log' : ''))}},[_c('b-form-textarea',{attrs:{"placeholder":"Leave a comment for the new approval log"},model:{value:(_vm.form['comments']),callback:function ($$v) {_vm.$set(_vm.form, 'comments', $$v)},expression:"form['comments']"}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }