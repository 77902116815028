<template>
  <b-modal
    lazy
    centered
    :id="id ? id : 'user-change-pass'"
    :title="$t('user.reset_password[0]', { id: userDetails ? ' #' + userDetails.id : '' })"
    title-class="w-100 d-flex justify-content-center align-self-center"
    header-class="py-2"
    footer-class="py-2"
    body-class="py-0"
    @hidden="onHidden"
  >
    <form id="user-change-pass-form" @submit.prevent="onSubmit" v-if="userDetails">
      <!-- <b-form-group label-for="user-email" class="m-0">
        <template #label>
          <p class="mb=0 font-weight-bold" style="font-size: 0.65rem">Email Address <span class="text-danger">*</span></p>
        </template>
        <b-form-input id="user-email" v-model="form.email" disabled required />
      </b-form-group> -->
      <b-form-group label-for="user-name" label-class="text-sm" class="m-0">
        <template #label>{{ $t('general.username') }}<span class="text-danger ml-1">*</span></template>
        <b-form-input id="user-name" :value="userDetails['name']" disabled required />
      </b-form-group>
      <b-form-group label-for="user-pass-new" label-class="text-sm" class="mt-3 mb-0">
        <template #label>{{ $t('auth.new_password') }}<span class="text-danger ml-1">*</span></template>
        <b-form-input id="user-pass-new" v-model="form['pass']" :placeholder="$t('auth.new_password')" required @change="validatePass" />
      </b-form-group>
      <b-form-group label-for="user-pass-confirm" label-class="text-sm" class="mt-3 mb-0">
        <template #label>{{ $t('auth.confirm_new_password') }}<span class="text-danger ml-1">*</span></template>
        <b-form-input id="user-pass-confirm" v-model="form['confirm_pass']" :placeholder="$t('auth.confirm_new_password')" required @change="validatePass" />
      </b-form-group>
    </form>
    <template #modal-footer="{ hide }">
      <b-button variant="outline-secondary" @click="hide">{{ $t('action.close') }}</b-button>
      <b-button variant="outline-primary" type="submit" form="user-change-pass-form" :disabled="!userDetails || !userDetails['id']">{{ $t('action.submit') }}</b-button>
    </template>
  </b-modal>
</template>
<script>
export default {
  name: 'UserPassword',
  props: {
    id: String,
    userDetails: Object,
  },
  data() {
    return {
      form: {},
    }
  },
  methods: {
    onHidden() {
      this.form = {}
      this.$emit('update:userDetails', null)
    },
    validatePass() {
      const pass = document.getElementById('user-pass-new'),
        confirm_pass = document.getElementById('user-pass-confirm')
      confirm_pass.setCustomValidity(pass.value != confirm_pass.value ? this.$t('auth.passwords_not_match') : '')
    },
    onSubmit() {
      this.$store
        .dispatch('request/resetUserPass', {
          user_id: this.userDetails['id'],
          password: this.form['confirm_pass'],
          successMsg: this.$t('user.reset_password[1]'),
          errorMsg: this.$t('user.reset_password[2]'),
        })
        .then((res) => {
          if (res.status === 200) {
            this.$bvModal.hide('user-password')
          }
        })
    },
  },
}
</script>
