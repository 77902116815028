<template>
  <div>
    <div class="d-flex flex-row align-items-center mb-3">
      <div class="font-weight-normal m-0">{{ $t('competition.question') }}<span class="ml-1 text-danger">*</span></div>
      <b-button v-if="!noEditAfterPublish" v-b-modal.quiz-lookup variant="outline-primary" class="ml-3">{{ $t('competition.quiz_lookup') }}</b-button>
      <b-button v-if="!noEditAfterPublish && form.quiz.id" @click="onRemoveQuiz" variant="outline-danger" class="ml-2">{{ $t('action.remove') }}</b-button>
    </div>
    <div v-if="form.quiz.id" class="mb-1">
      <p class="font-weight-light m-0">{{ form.quiz.question }}</p>
      <QuizAnswer
        v-for="(answer, index) in form.answers"
        :key="'answers-' + index"
        :value="answer.is_correct"
        :class="`w-25 mt-3 mr-3 ${answer.is_correct ? 'font-weight-bold' : ''}`"
        style="font-size: 0.85rem"
        :text="answer.answer" />
    </div>

    <b-modal
      v-if="!noEditAfterPublish"
      lazy
      centered
      id="quiz-lookup"
      :title="$t('competition.quiz_lookup')"
      title-class="w-100 d-flex justify-content-center align-self-center"
      header-class="py-2"
      footer-class="py-2"
      body-class="py-0"
      dialog-class="quiz-lookup-modal"
      :ok-title="$t('action.confirm')"
      ok-variant="outline-primary"
      :ok-disabled="okDisabled"
      :cancel-title="$t('action.close')"
      cancel-variant="outline-secondary"
      @show="onShow"
      @hide="onHide"
      ><loading :active="isLoading" :can-cancel="false" :is-full-page="false" />
      <b-tabs lazy v-model="tabIndex">
        <b-tab :title="$t('competition.select_a_quiz')" active title-item-class="text-sm">
          <div class="px-0 py-3 d-flex flex-row align-items-start justify-content-xl-between">
            <b-form inline>
              <b-form-input v-model="queries.question" type="search" :placeholder="$t('competition.question')" @keyup.enter="onSearch" />
              <b-form-select v-model="queries.type" :options="quizTypeOpts" class="ml-2" style="width: max-content">
                <b-form-select-option selected disabled hidden :value="null">{{ $t('general.type') }}</b-form-select-option>
              </b-form-select>
              <button-group :onSearch="onSearch" :onReset="onReset" class="ml-2" />
            </b-form>
            <b-button variant="basic" @click="onCreateQuiz"><b-icon-plus class="mr-1" />{{ $t('competition.create_quiz') }}</b-button>
          </div>
          <b-table
            :fields="fields"
            :items="items"
            @row-clicked="rowClicked"
            :busy="isLoading"
            show-empty
            :empty-text="$t('notify.table_no_records')"
            hover
            responsive
            sticky-header="50vh"
            head-variant="light"
            class="m-0 p-0"
            sort-by-formatted>
            <template #cell(question)="{ value }">
              <span v-b-tooltip.hover.top="value">{{ value }}</span>
            </template>
            <template #cell(is_active)="{ value }">
              <bool-badge :value="value" :text="value == 1 ? $t('status.active') : $t('status.inactive')" />
            </template>
            <template #cell(action)="{ item }">
              <b-button variant="outline-primary" @click="onSelectQuiz(item)">{{ $t('action.select') }}</b-button>
            </template>
          </b-table>
          <paginate v-if="items.length > 0" :queries="queries" :fetcher="getList" :total="quizList.total" class="card-footer" />
        </b-tab>

        <b-tab
          v-for="(opt, index) in [$t('competition.update_quiz'), $t('competition.create_quiz')]"
          :key="'quiz-form-' + index"
          :title="opt"
          title-item-class="text-sm"
          @click="initForm(index == 0 ? selected : null)">
          <b-overlay :show="index == 0 && !form.quiz.id" variant="secondary" opacity="0.75" rounded="sm" class="w-100">
            <template #overlay>
              <div class="text-center">⚠ {{ $t('competition.create_quiz_notice') }}</div>
            </template>

            <p v-if="index == 0" class="mt-3 mb-0 text-slack text-sm">⚠ {{ $t('competition.update_quiz_notice') }}</p>

            <b-form id="quiz-upsert-form" @submit.prevent="onSubmit" class="m-0">
              <b-form-group label-for="quiz-input-id" label-class="text-sm" class="mt-3 mb-0">
                <template #label>{{ $t('competition.quiz_id') }}<b-icon-question-circle-fill v-b-tooltip.hover.topright="$t('competition.create_quiz_notice')" class="ml-1" /></template>
                <b-form-input id="quiz-input-id" :value="form.quiz.id > 0 ? form.quiz.id : null" :placeholder="$t('competition.create_quiz_notice')" disabled />
              </b-form-group>

              <b-form-group label-for="quiz-input-que" label-class="text-sm" class="mt-3 mb-0">
                <template #label>{{ $t('competition.quiz_question') }}<span class="text-danger ml-1">*</span></template>
                <b-form-input id="quiz-input-que" v-model="form.quiz['question']" type="text" :placeholder="$t('competition.enter_question')" required />
              </b-form-group>

              <div class="w-100 d-flex align-items-start mt-3">
                <b-form-group label-class="text-sm" class="d-inline-block w-50 m-0">
                  <template #label>{{ $t('competition.answers') }}<span class="text-danger ml-1">*</span></template>
                  <b-form-radio id="answer-0" v-model="correctIndex" :value="0">
                    <b-form-input id="quiz-input-ans-0" v-model="form.answers[0]['answer']" type="text" :placeholder="$t('competition.first_answer')" required />
                  </b-form-radio>
                  <b-form-radio id="answer-1" v-model="correctIndex" :value="1" class="mt-2 mb-0">
                    <b-form-input id="quiz-input-ans-1" v-model="form.answers[1]['answer']" type="text" :placeholder="$t('competition.second_answer')" required />
                  </b-form-radio>
                  <b-form-radio id="answer-2" v-model="correctIndex" :value="2" class="mt-2 mb-0">
                    <b-form-input id="quiz-input-ans-2" v-model="form.answers[2]['answer']" type="text" :placeholder="$t('competition.third_answer')" required />
                  </b-form-radio>
                </b-form-group>

                <div class="d-inline-block w-50 m-0">
                  <b-form-group label-for="quiz-correct-answer" :label="$t('competition.correct_answer')" label-class="text-sm" class="m-0">
                    <b-form-input id="quiz-correct-answer" :value="correctAnswer" disabled />
                  </b-form-group>

                  <b-form-group label-for="quiz-input-type" label-class="text-sm" class="mt-3 mb-0">
                    <template #label>{{ $t('competition.quiz_type') }}<span class="text-danger ml-1">*</span></template>
                    <b-form-select id="quiz-input-type" v-model="form.quiz['type']" :options="quizTypeOpts" required />
                  </b-form-group>
                </div>
              </div>

              <div class="mt-3 w-100 d-flex flex-row flex-nowrap align-items-center">
                <b-button variant="outline-primary" type="submit">{{ $t('action.save_changes') }}</b-button>
                <b-button variant="outline-secondary" @click="initForm(index == 0 ? selected : null)">{{ $t('action.reset_form') }}</b-button>
              </div>
            </b-form>
          </b-overlay>
        </b-tab>
      </b-tabs>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import QuizAnswer from '../QuizAnswer.vue'

export default {
  name: 'QuizLookup',
  components: { QuizAnswer },
  props: {
    noEditAfterPublish: Boolean,
    quiz_id: Number,
    initVal: Object,
  },
  // initialization before created()
  data() {
    const fields = [
      { key: 'id', label: this.$t('general.id') },
      { key: 'question', label: this.$t('competition.question'), tdClass: 'text-truncate' },
      { key: 'type', label: this.$t('general.type') },
      { key: 'is_active', label: this.$t('general.status') },
      { key: 'action', label: this.$tc('general.action', 1) },
    ]
    for (let i = 0; i < fields.length - 1; i++) {
      fields[i]['tdAttr'] = { style: 'cursor: pointer' }
    }
    const initialForm = {
      quiz: { id: null, question: '', type: 'common' },
      answers: [
        { id: null, answer: '', is_correct: 0 },
        { id: null, answer: '', is_correct: 0 },
        { id: null, answer: '', is_correct: 0 },
      ],
    }
    return {
      isLoading: false,
      queries: {
        type: null,
        question: null,
        page: 1,
        perPage: 10,
      },
      tabIndex: 0,
      initialForm: { ...initialForm },
      form: { ...initialForm },
      fields: fields,
      selected: null,
    }
  },
  computed: {
    ...mapGetters({ quizTypes: 'request/quizTypes', quizList: 'request/quizList' }),
    quizTypeOpts() {
      return this.quizTypes ? this.quizTypes.map((x) => ({ value: x.type, text: this.$t(`competition.quiz_types.${x.type}`) })) : []
    },
    items() {
      return this.quizList?.data ?? []
    },
    okDisabled() {
      return this.tabIndex == 2 && !this.form.quiz.id
    },
    correctIndex: {
      get() {
        const i = this.form.answers.findIndex((x) => x.is_correct === 1)
        const val = i > -1 ? i : null
        this.hasCorrectAns(val)
        return val
      },
      set(newVal) {
        if (newVal > -1) {
          this.form.answers.forEach((answer, i) => {
            this.form.answers[i]['is_correct'] = newVal === i ? 1 : 0
          })
        }
        const val = newVal > -1 ? newVal : null
        this.hasCorrectAns(val)
        return val
      },
    },
    correctAnswer() {
      return this.form.answers.find((x) => x.is_correct === 1)?.answer ?? null
    },
  },
  methods: {
    initForm(val) {
      this.form = {
        quiz: {
          id: val?.id ?? this.initialForm.id,
          type: val?.type ?? this.initialForm.type,
          question: val?.question ?? this.initialForm.question,
        },
        answers: val?.answers.map((x) => ({ ...x })) ?? this.initialForm.answers.map((x) => ({ ...x })),
      }
      const i = val?.answers.findIndex((x) => x.is_correct === 1) ?? -1
      this.correctIndex = i > -1 ? i : null
      this.hasCorrectAns(this.correctIndex)
    },

    getTypes() {
      this.isLoading = true
      this.$store
        .dispatch('request/getQuizTypes')
        .then(() => (this.isLoading = false))
        .catch(() => (this.isLoading = false))
    },

    getList(_params) {
      this.isLoading = true
      const params = { ...this.queries, ..._params }
      this.$store
        .dispatch('request/getQuizList', params)
        .then(() => (this.isLoading = false))
        .catch(() => (this.isLoading = false))
    },

    onShow() {
      this.getList()
      if (!this.quizTypes) {
        this.getTypes()
      }
      this.initForm(this.selected)
    },

    onHide() {
      this.initForm(this.selected)
    },

    onSearch() {
      this.queries.page = 1
      this.getList({ errorMsg: this.$t('competition.search_quiz_failed') })
    },

    onReset() {
      this.queries.question = null
      this.queries.type = null
      this.onSearch()
    },

    rowClicked(item, index, evt) {
      if (evt.target.cellIndex !== 4) {
        this.onSelectQuiz(item)
      }
    },

    onRemoveQuiz(evt) {
      this.initForm()
      this.selected = null
      this.$bvModal.hide('quiz-lookup')
    },

    onCreateQuiz() {
      this.initForm()
      this.tabIndex = 2
    },

    onSelectQuiz(row) {
      this.selected = row
      this.initForm(row)
      this.tabIndex = 1
    },

    hasCorrectAns(i) {
      const bool = [0, 1, 2].indexOf(i) > -1
      document.getElementById('answer-0')?.setCustomValidity(bool ? '' : this.$t('competition.correct_answer_required'))
      return bool
    },

    onSubmit(e) {
      // validation before submit
      if (!this.hasCorrectAns(this.correctIndex)) {
        e.preventDefault()
        return
      }
      this.isLoading = true
      const params = { ...this.form, successMsg: this.$t('competition.quiz_saved'), errorMsg: this.$t('competition.quiz_not_saved') }
      this.$store
        .dispatch('request/upsertQuiz', params)
        .then((res) => {
          this.isLoading = false
          if (res?.status === 200) {
            this.selected = res.data.data
            this.initForm(res.data.data)
            this.getList()
          }
        })
        .catch(() => (this.isLoading = false))
    },
  },

  watch: {
    'form.quiz.id'(val) {
      this.$emit('update:quiz_id', val ?? -1)
    },
  },

  // initialization after created()
  mounted() {
    if (this.initVal) {
      this.selected = this.initVal
      this.initForm(this.initVal)
    }
  },
}
</script>
<style>
.quiz-lookup-modal {
  width: max-content !important;
  min-width: 56rem !important;
  max-width: 90vw !important;
}
</style>
