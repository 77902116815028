<template>
  <b-card class="px-1 py-3">
    <b-card-title>{{ $t('order.payment_transactions') }}</b-card-title>
    <div class="mt-4 overflow-auto">
      <b-table :items="orderDetails ? orderDetails.payments : []" :fields="fields" outlined class="paymentTable">
        <!-- <template #cell(txn_id)="data">
          <span>{{ data.value }}</span>
          <h5 class="my-1" style="color: #8898aa">
            <span class="mr-1">{{ $t("general.created_at") }} {{ formatLocalDateTime(data.item.created_at) }}</span>
          </h5>
        </template> -->
        <template #cell(status)="{ value }">
          <b-badge variant="info">{{ value }}</b-badge>
        </template>
        <template #cell(show_details)="{ toggleDetails, detailsShowing }">
          <b-button variant="reset" @click="toggleDetails">{{ detailsShowing ? $t('status.hide') : $t('status.show') }} {{ $t('general.details') }} </b-button>
        </template>
        <template #row-details="{ item, toggleDetails }">
          <b-row class="mb-2">
            <b-col sm="3">
              <b>{{ $t('payment.amount_authorized') }}:</b>
            </b-col>
            <b-col>{{ Number(item.amount_authorized).toFixed(2) }}</b-col>
          </b-row>

          <b-row class="mb-2">
            <b-col sm="3">
              <b>{{ $t('payment.amount_canceled') }}:</b>
            </b-col>
            <b-col>{{ Number(item.amount_canceled).toFixed(2) }}</b-col>
          </b-row>

          <b-row class="mb-2">
            <b-col sm="3">
              <b>{{ $t('payment.amount_ordered') }}:</b>
            </b-col>
            <b-col>{{ Number(item.amount_ordered).toFixed(2) }}</b-col>
          </b-row>

          <b-row class="mb-2">
            <b-col sm="3">
              <b>{{ $t('payment.amount_refunded') }}:</b>
            </b-col>
            <b-col>{{ Number(item.amount_refunded).toFixed(2) }}</b-col>
          </b-row>

          <b-row class="mb-2">
            <b-col sm="3">
              <b>{{ $t('order.shipping_amount') }}:</b>
            </b-col>
            <b-col>{{ Number(item.shipping_amount).toFixed(2) }}</b-col>
          </b-row>

          <b-row class="mb-2">
            <b-col sm="3">
              <b>{{ $t('general.currency_code') }}:</b>
            </b-col>
            <b-col>{{ item.currency_code }}</b-col>
          </b-row>

          <b-row class="mb-2">
            <b-col sm="3">
              <b>{{ $t('order.gateway') }}:</b>
            </b-col>
            <b-col class="text-capitalize">{{ item.gateway.includes('barclay') ? 'Barclay' : item.gateway.includes('wallet') ? 'RKings' : item.gateway }}</b-col>
          </b-row>

          <b-row class="mb-2">
            <b-col sm="3">
              <b>{{ $t('general.status') }}:</b>
            </b-col>
            <b-col class="text-capitalize">{{ item.status }}</b-col>
          </b-row>

          <b-row class="mb-2">
            <b-col sm="3">
              <b>{{ $tc('general.comment', 2) }}:</b>
            </b-col>
            <b-col class="text-capitalize">{{ item.comments ? item.comments : '-' }}</b-col>
          </b-row>
          <b-button variant="reset" @click="openRefund(item.id)" v-if="showRefund && item.status === 'succeeded'">{{ $t('order.refund') }}</b-button>
          <b-button variant="reset" @click="toggleDetails">{{ $t('order.hide_details') }}</b-button>
        </template>
      </b-table>
    </div>
  </b-card>
</template>

<script>
import { formatLocalDateTime } from '@/utils/dateTimeUtils'
import { mapGetters } from 'vuex'
export default {
  name: 'payment-list',
  props: { openRefund: Function },
  data() {
    return {
      fields: [
        { key: 'id', label: this.$t('payment.payment_id') },
        { key: 'txn_id', label: this.$t('transaction.transaction_id'), class: 'text-wrap', tdAttr: { style: 'width:140px' } },
        { key: 'customer_token', label: 'Token', class: 'text-wrap', tdAttr: { style: 'width:140px' } },
        {
          key: 'method',
          label: this.$t('payment.payment_method'),
          formatter: (v) =>
            v.includes('card') ? 'Card' : v.includes('paypal') ? 'PayPal' : v.includes('wallet') ? 'Wallet' : v.includes('apple') ? 'Apple Pay' : v.includes('google') ? 'Google Pay' : v,
          tdClass: 'text-capitalize',
        },
        { key: 'amount_paid', label: this.$t('payment.amount_paid'), formatter: (value) => Number(value).toFixed(2) },
        { key: 'status', label: this.$t('general.status') },
        { key: 'created_at', label: this.$t('general.created_at'), formatter: (v) => formatLocalDateTime(v), tdAttr: { style: 'width: 140px' } },
        { key: 'show_details', label: '', tdAttr: { style: 'width: 110px' } },
      ],
    }
  },
  computed: {
    ...mapGetters({ orderDetails: 'request/orderDetails' }),
    showRefund() {
      return this.orderDetails?.store_id !== 2 && this.$store.getters['store'] !== 2 && [-3, -1, 2, 3, 4].indexOf(this.orderDetails?.status) > -1
    },
  },
  methods: {
    formatLocalDateTime,
  },
}
</script>
