<template>
  <card body-classes="p-0 d-flex flex-column" class="table-custom">
    <loading :active="isLoading" :can-cancel="false" :is-full-page="false" />
    <ReportSearchInput :queryParams="queryParams" showCurrency showDatePicker :onExport="onExport" :onSearch="onSearch" :onReset="onReset">
      <template #other-search2>
        <b-form-input v-model="queryParams['competition_id']" type="search" placeholder="Competition ID" @keyup.enter="onSearch" class="mb-2 mr-2" style="width: 150px" />
        <b-form-checkbox v-model="queryParams['show_competition_ids']" :value="true" :unchecked-value="false" class="impact-checkbox d-flex align-items-center mb-2 mr-2" style="padding-top: 6px">
          Show Competition IDs
        </b-form-checkbox>
        <SortBySelect ref="SortBySelect" :fields="fields" :queryParams="queryParams" :onSearch="onSearch" :disabled="!!queryParams.competition_id" />
      </template>
    </ReportSearchInput>
    <b-table show-empty small hover responsive head-variant="light" :fields="fields" :items="items" sticky-header="100%">
      <template #head()="{ label, field }">{{ label }}<b-icon-question-circle-fill v-if="field.tooltip" class="ml-1" v-b-tooltip.hover.right="field.tooltip" /></template>
      <template #cell(user_id)="{ value, item }">
        <span class="action" @click="viewUser({ id: item.user_id, store_id: ['GBP', 'MXN'].indexOf(queryParams.currency) + 1 })">{{ value }}</span>
      </template>
      <template #cell(user_name)="{ value, item }">
        <span class="action" @click="viewOrders({ id: item.user_id })">{{ value }}</span>
      </template>
    </b-table>
    <paginate v-if="items.length > 0" :queries="queryParams" :fetcher="getReport" :total="usersWithPurchaseReport.total" class="card-footer" />

    <UserOrders v-if="items.length > 0" id="user-orders" :userDetails.sync="actionTarget" />

    <b-modal
      lazy
      centered
      id="user-details"
      :title="$t('user.user_details', { id: actionTarget ? ' #' + actionTarget.id : '' })"
      title-class="w-100 d-flex justify-content-center align-self-center"
      header-class="py-2"
      body-class="pt-0"
      hide-footer>
      <UserDetails v-if="actionTarget" :user="actionTarget" :key="actionTarget.id">
        <template #address="{ address }">
          <br />
          <AddressDetails :userStore="actionTarget.store_id" :address="address" />
        </template>
      </UserDetails>
    </b-modal>
  </card>
</template>
<script>
import ReportSearchInput from '@/components/Reports/ReportSearchInput.vue'
import SortBySelect from '@/components/SortBySelect.vue'
import AddressDetails from '@/components/Users/AddressDetails.vue'
import UserDetails from '@/components/Users/UserDetails.vue'
import UserOrders from '@/components/Users/UserOrders.vue'
import { API_LIST } from '@/utils/consts'
import { exportAsExcel } from '@/utils/fileUtils'
import { formatWithCurrency } from '@/utils/numberUtils'
import { mapGetters } from 'vuex'

export default {
  name: 'UserPurchaseReport',
  components: {
    ReportSearchInput,
    UserOrders,
    UserDetails,
    AddressDetails,
    SortBySelect,
  },
  data() {
    return {
      isLoading: false,
      queryParams: {
        start: null,
        end: null,
        currency: null, // TODO: add store_id in the future
        timezone: null,
        page: 1,
        perPage: 10,
        show_competition_ids: false,
      },
      initialParams: {},
      localParams: {},
      actionTarget: null,
    }
  },
  computed: {
    ...mapGetters({
      usersWithPurchaseReport: 'request/usersWithPurchaseReport',
    }),
    fields() {
      return [
        { key: 'user_id', sortable: true, tooltip: this.$t('notify.click_for', { name: this.$t('user.user_details') }), sortKey: 'user_id' },
        { key: 'user_name', tooltip: this.$t('notify.click_for', { name: this.$t('user.user_orders') }) },
        { key: 'email' },
        { key: 'phone', label: this.$t('user.phone') },
        { key: 'register_date', tooltip: this.$t('notify.using_tz', { tz: this.queryParams.timezone }) },
        { key: 'postcode', sortable: true },
        { key: 'country', sortable: true },
        { key: 'order_numbers', label: this.localParams['competition_id'] ? 'Ticket Numbers' : 'Order Numbers', sortable: true, sortKey: 'orders' },
        { key: 'purchase_amount', formatter: (v) => formatWithCurrency(v, this.queryParams.currency), sortKey: 'pay_total' },
        this.localParams['show_competition_ids'] ? { key: 'competition_ids' } : null,
      ]
    },
    items() {
      return this.usersWithPurchaseReport ? this.usersWithPurchaseReport.data : []
    },
  },
  methods: {
    getReport() {
      this.isLoading = true
      this.$store
        .dispatch('request/getUsersWithPurchaseReport', this.queryParams)
        .then(() => {
          this.isLoading = false
          this.localParams = { ...this.queryParams }
        })
        .catch(() => (this.isLoading = false))
    },
    onSearch() {
      this.getReport()
    },
    onReset() {
      this.queryParams = { ...this.initialParams }
      this.$refs.SortBySelect.reset()
      this.onSearch()
    },
    onExport() {
      if (this.items.length > 0) {
        return exportAsExcel(
          `${this.$t('sidebar.users_with_purchase')}_${this.queryParams.start}-${this.queryParams.end}_${this.queryParams.timezone}`,
          API_LIST.get.usersWithPurchaseReport,
          { ...this.queryParams, page: 1, perPage: 1000 },
          this.usersWithPurchaseReport.total,
          null,
          ['user_id', 'user_name', 'email', 'phone', 'register_date', 'postcode', 'country', 'order_numbers', 'purchase_amount'],
          [['User ID', 'Username', 'Email', this.$t('user.phone'), 'Registration Date', 'Postcode', 'Country', 'Order Numbers', 'Purchase Amount']],
          (bool) => (this.isLoading = bool)
        )
      } else {
        this.$notify({ group: 'root', type: 'error', text: this.$t('notify.no_records') })
      }
    },
    viewUser(obj) {
      this.actionTarget = obj
      this.$bvModal.show('user-details')
    },
    viewOrders(obj) {
      this.actionTarget = obj
      this.$bvModal.show('user-orders')
    },
  },
  // queryParams will be initialized when ReportSearchInput created
  mounted() {
    this.initialParams = { ...this.queryParams }
    this.getReport()
  },
}
</script>
<style lang="scss">
.impact-checkbox {
  padding-left: 24px;
  .custom-control-label::before,
  .custom-control-label::after {
    left: -24px;
  }
}
</style>
