<template>
  <card type="default" header-classes="bg-transparent">
    <loading :active="isLoading" :can-cancel="false" :is-full-page="false" />
    <TimeSelect :displayInfo="{ name: $t('dashboard.chart_name[3]') }" :initBigChart="initBigChart" type="user" :queryParams="queryParams" />
    <LineChart :height="350" ref="bigChart" :chart-data="chartModel.chartData" :options="chartModel.extraOptions" />
  </card>
</template>
<script>
import { lineChartOptionsBlue } from '@/components/Charts/config'
import LineChart from '@/components/Charts/LineChart'
import TimeSelect from '@/components/Charts/TimeSelect.vue'

export default {
  name: 'RegisteredUsers',
  components: { TimeSelect, LineChart },
  data() {
    return {
      isLoading: false,
      queryParams: {},
      chartModel: { chartData: {}, extraOptions: lineChartOptionsBlue },
      rawData: [],
      colors: ['#FF6384', '#fb6340'],
    }
  },
  methods: {
    async getRegisteredUsers() {
      this.isLoading = true
      await this.$store
        .dispatch('request/getRegisteredUsers', this.queryParams)
        .then(() => (this.isLoading = false))
        .catch(() => (this.isLoading = false))
      this.rawData = this.$store.getters['request/registeredUsers']
    },

    initBigChart() {
      this.getRegisteredUsers().then(
        () =>
          (this.chartModel.chartData = {
            datasets: [
              {
                label: this.$t('dashboard.registered_users[0]'),
                data: this.rawData.map((val) => val._newUser),
                borderColor: this.colors[0],
                pointBackgroundColor: this.colors[0],
                tension: 0.1,
              },
              {
                label: this.$t('dashboard.registered_users[1]'),
                data: this.rawData.map((val) => val._newUserWithPurchase),
                tension: 0.1,
              },
              {
                label: this.$t('dashboard.registered_users[2]'),
                data: this.rawData.map((val) => val.allPurchaseUsers),
                tension: 0.1,
                borderColor: this.colors[1],
                pointBackgroundColor: this.colors[1],
              },
            ],
            labels: this.rawData.map((val) => this.$moment(val.day).format('DD/MM')),
          })
      )
    },
  },
  // queryParams will be initialized when TimeSelect created
  mounted() {
    this.initBigChart()
  },
}
</script>
