<template>
  <b-modal
    lazy
    centered
    :id="id ? id : 'user-activation'"
    :title="$t('user.activate_acc') + (userDetails ? ' #' + userDetails.id : '')"
    title-class="w-100 d-flex justify-content-center align-self-center"
    header-class="py-2"
    footer-class="py-2"
    body-class="py-0"
    @hidden="onHidden"
    @shown="onShown"
  >
    <b-form @submit.prevent="onSubmit" id="user-activation-form" v-if="userDetails">
      <b-form-group label-for="user-activation-name" label-class="text-sm" class="m-0">
        <template #label>{{ $t('general.username') }}</template>
        <b-form-input id="user-activation-name" :value="userDetails.name" disabled />
      </b-form-group>

      <b-form-group label-for="user-activation-status" label-class="text-sm" class="d-inline-block w-35 mt-3 mb-0 pr-1">
        <template #label>{{ $t('user.is_active') }}<span class="text-danger ml-1">*</span></template>
        <b-form-checkbox id="user-activation-status" v-model="form['is_active']" :value="1" :unchecked-value="0" switch />
      </b-form-group>

      <b-form-group label-for="user-activation-reactivate" label-class="text-sm" class="d-inline-block w-65 mt-3 mb-0 pl-1">
        <template #label>{{ $t('user.reactive_date') }}</template>
        <b-form-input id="user-activation-reactivate" :value="reactiveDate" disabled />
      </b-form-group>

      <b-form-group v-if="form.is_active == 0" label-for="user-activation-days" label-class="text-sm" class="mt-3 mb-0">
        <template #label>{{ $t('user.suspend_days') }}<span class="text-danger ml-1">*</span></template>
        <b-input-group id="user-activation-days">
          <b-form-select v-model="suspendDaysOpt" :options="suspendDaysOpts" required :disabled="form.is_active == 1" class="m-0 w-35" />
          <b-form-input v-if="suspendDaysOpt == -2" v-model="form['suspend_days']" number type="number" step="1" min="1" :placeholder="$t('user.suspend_days')" required class="m-0 w-65 pl-1" />
        </b-input-group>
      </b-form-group>
    </b-form>

    <template #modal-footer="{ hide }">
      <b-button variant="outline-secondary" @click="hide">{{ $t('action.cancel') }}</b-button>
      <b-button variant="outline-primary" type="submit" form="user-activation-form" :disabled="!userDetails || !userDetails['id']">{{ $t('action.submit') }}</b-button>
    </template>
  </b-modal>
</template>
<script>
import { formatLocalDateTime } from '@/utils/dateTimeUtils'
export default {
  name: 'UserActivation',
  props: {
    id: String,
    userDetails: Object,
    getList: Function,
  },
  data() {
    return {
      form: { is_active: 1, suspend_days: null },
      suspendDaysOpt: -2, // custom (manual input)
      suspendDaysOpts: [
        { value: -2, text: this.$t('user.suspend_days_opts[2]') }, // custom (manual input)
        { value: 30, text: this.$t('user.suspend_days_opts[1]', { num: 30 }) },
        { value: 60, text: this.$t('user.suspend_days_opts[1]', { num: 60 }) },
        { value: 90, text: this.$t('user.suspend_days_opts[1]', { num: 90 }) },
        { value: -1, text: this.$t('user.suspend_days_opts[0]') }, // permanent
      ],
    }
  },
  computed: {
    reactiveDate() {
      var val = null
      if (this.userDetails?.suspend_days == -1) {
        val = this.$t('user.suspend_days_opts[0]')
      } else if (this.userDetails?.suspend_days > 0) {
        val = formatLocalDateTime(this.$moment.utc(this.userDetails.updated_at).add(this.userDetails.suspend_days, 'd'))
      }
      return val
    },
  },

  watch: {
    suspendDaysOpt(newVal) {
      if (newVal == -2) {
        this.form['suspend_days'] = null
      } else {
        this.form['suspend_days'] = newVal
      }
    },
  },
  methods: {
    onShown(evt) {
      this.form['is_active'] = this.userDetails?.is_active ?? 1
    },
    onHidden(evt) {
      this.suspendDaysOpt = -2
      this.form = { is_active: 1, suspend_days: null }
      this.$emit('update:userDetails', null)
    },
    onSubmit(evt) {
      const params = {
        is_active: this.form.is_active,
        user_id: this.userDetails.id,
        successMsg: this.$t('user.activate_acc_notice[0]'),
        errorMsg: this.$t('user.activate_acc_notice[1]'),
      }
      if (this.form.is_active == 1) {
        params['suspend_days'] = 0
      } else {
        params['suspend_days'] = this.form.suspend_days
      }
      this.$store.dispatch('request/activateUser', params).then((res) => {
        if (res?.status === 200) {
          this.getList()
          this.$bvModal.hide('user-activation')
        }
      })
    },
  },
}
</script>
