<template>
  <div style="display: contents">
    <loading :active="isLoading" :can-cancel="false" :is-full-page="false" />
    <card body-classes="m-0 px-2 px-xl-4 py-4 d-flex flex-column" class="m-2" v-if="details">
      <CompetitionDetails :competition="details" :showTables="true" />
      <b-button @click="$router.back()" class="mt-3" style="width: fit-content"><b-icon-chevron-left class="mr-1" />{{ $t('action.back_to_prev') }}</b-button>
    </card>
  </div>
</template>
<script>
import CompetitionDetails from '@/components/Competitions/CompetitionDetails'
import { mapGetters } from 'vuex'
export default {
  components: {
    CompetitionDetails,
  },
  data() {
    return {
      isLoading: true,
      details: null,
    }
  },
  computed: {
    ...mapGetters({ competitionDetails: 'request/competitionDetails' }),
  },
  created() {
    this.$store
      .dispatch('request/getCompetitionDetails', { id: this.$route.query.id })
      .then(() => {
        this.details = { ...this.$route.params.competition, ...this.competitionDetails }
        this.isLoading = false
      })
      .catch(() => (this.isLoading = false))
  },
}
</script>
