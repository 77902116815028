<template>
  <b-modal
    lazy
    centered
    id="comp-daily-report"
    :title="$t('report.competition.competition_daily_report')"
    title-class="w-100 d-flex justify-content-center align-self-center"
    header-class="py-2"
    body-class="pt-0"
    hide-footer
    @shown="onShown"
    @hidden="onHidden"
    v-model="show">
    <loading :active="isLoading" :can-cancel="false" :is-full-page="false" />
    {{ competitionTitle }}
    <ReportSearchInput v-if="show" :queryParams="queryParams" showStore showCurrency showTimezone :onSearch="onSearch" :onReset="onReset" :onExport="onExport" />
    <b-table v-if="show" show-empty :empty-text="$t('notify.table_no_records')" small hover responsive head-variant="light" class="m-0 p-0" :fields="fields" :items="items" v-model="visibleRows">
      <template #bottom-row v-if="items.length > 0">
        <td class="table-b-table-default b-table-sticky-column">{{ $t('general.total') }}</td>
        <td class="text-center">{{ totalTicketsSold }}</td>
        <td colspan="2"></td>
        <td class="text-right">{{ formatWithCurrency(totalSales, queryParams.currency) }}</td>
        <td class="text-right">{{ formatWithCurrency(totalCoupons, queryParams.currency) }}</td>
        <td class="text-right">{{ formatWithCurrency(totalNetSales, queryParams.currency) }}</td>
      </template>
    </b-table>
  </b-modal>
</template>
<script>
import ReportSearchInput from '@/components/Reports/ReportSearchInput.vue'
import { exportAsExcel } from '@/utils/fileUtils'
import { formatWithCurrency, sumTotal } from '@/utils/numberUtils'
import { mapGetters } from 'vuex'

export default {
  name: 'CompetitionDailyReport',
  components: {
    ReportSearchInput,
  },
  props: {
    competitionId: Number,
    competitionTitle: String,
  },
  data() {
    return {
      title: null,
      isLoading: false,
      visibleRows: [],
      queryParams: {
        store_id: null,
        competition_id: null,
        currency: null,
        timezone: null,
      },
      initialParams: {},
      show: false,
    }
  },
  computed: {
    ...mapGetters({ compDailyReport: 'request/competitionDailyReport' }),
    fields() {
      var tmp = [
        { key: 'date', label: this.$t('report.competition.date'), sortable: true },
        { key: 'sold_tickets', label: this.$t('report.competition.ticket_sold'), sortable: true, class: 'text-center' },
        { key: 'ticket_price', label: this.$t('report.competition.ticket_price') },
        { key: 'ticket_sale_price', label: 'Ticket Sales Price' },
        { key: 'total_sales', label: this.$t('report.competition.total_sales') },
        { key: 'coupon_amount', label: this.$t('report.sales.coupons') },
        { key: 'net_sales', label: this.$t('report.sales.net_sales') },
      ]
      for (let i = 2; i < tmp.length; i++) {
        tmp[i]['formatter'] = (v) => formatWithCurrency(v, this.queryParams.currency)
        tmp[i]['sortable'] = true
        tmp[i]['class'] = 'text-right'
      }
      return tmp
    },
    items() {
      return this.compDailyReport ? this.compDailyReport : []
    },
    totalTicketsSold() {
      return sumTotal(this.visibleRows, 'sold_tickets')
    },
    totalNetSales() {
      return sumTotal(this.visibleRows, 'net_sales')
    },
    totalSales() {
      return sumTotal(this.visibleRows, 'total_sales')
    },
    totalCoupons() {
      return sumTotal(this.visibleRows, 'coupon_amount')
    },
  },
  methods: {
    onShown() {
      this.show = true
      if (this.competitionId) {
        this.queryParams['competition_id'] = this.competitionId
        this.getList()
      }
    },
    getList() {
      this.isLoading = true
      this.$store
        .dispatch('request/getCompetitionDailyReport', this.queryParams)
        .then(() => {
          this.isLoading = false
          this.title = this.compDailyReport?.[0]?.competition_title
        })
        .catch(() => (this.isLoading = false))
    },
    onSearch() {
      this.getList()
    },
    onReset() {
      this.queryParams = { ...this.initialParams, competition_id: this.competitionId }
      this.onSearch()
    },
    csvPreProcess(data) {
      const keys = this.fields.map((x) => x.key)
      for (let i in data) {
        for (const key in data[i]) {
          if (!keys.includes(key)) {
            delete data[i][key]
          }
        }
      }
    },
    onExport() {
      const headings = ['date', 'sold_tickets', 'ticket_price', 'ticket_sale_price', 'total_sales', 'coupon_amount', 'net_sales']
      const headingsFormat = [
        [
          this.$t('report.competition.date'),
          this.$t('report.competition.ticket_sold'),
          this.$t('report.competition.ticket_price'),
          'Ticket Sales Price',
          this.$t('report.competition.total_sales'),
          this.$t('report.sales.coupons'),
          this.$t('report.sales.net_sales'),
        ],
      ]
      // bottom-row
      const data = [...this.items]
      data.push({ date: 'Total', sold_tickets: this.totalTicketsSold, total_sales: this.totalSales, coupon_amount: this.totalCoupons, net_sales: this.totalNetSales })

      return exportAsExcel(`${this.title}`, null, data, this.items.length, this.csvPreProcess, headings, headingsFormat, (bool) => (this.isLoading = bool))
    },
    onHidden() {
      this.show = false
      this.queryParams = { ...this.initialParams }
      this.$emit('update:competitionId', null)
      this.$emit('update:competitionTitle', null)
    },
    formatWithCurrency,
  },
  // queryParams will be initialized when ReportSearchInput created
  mounted() {
    this.initialParams = { ...this.queryParams }
  },
}
</script>
