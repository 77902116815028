<template>
  <div style="display: contents">
    <loading :active="isLoading" :can-cancel="false" :is-full-page="false" />
    <WinnerDetails v-if="!isLoading && !!details" :winner="details" />
    <template v-else-if="!isLoading">{{ $t('winner.winner_details_not_found') }}</template>
    <b-button @click="$router.back()" style="width: fit-content" class="m-4"> <b-icon-chevron-left class="mr-1" />{{ $t('action.back_to_prev') }} </b-button>
  </div>
</template>
<script>
import WinnerDetails from '@/components/Winners/WinnerDetails'
export default {
  components: { WinnerDetails },
  data() {
    return {
      isLoading: true,
      details: null,
    }
  },
  created() {
    this.$store
      .dispatch('request/getWinnerDetails', { id: this.$route.query.id })
      .then(() => {
        this.details = { ...this.$route.params.winner, ...this.$store.getters['request/winnerDetails'] }
        this.isLoading = false
      })
      .catch(() => (this.isLoading = false))
  },
}
</script>
