<template>
  <card body-classes="p-0 d-flex flex-column" class="table-custom">
    <loading :active="isLoading" :can-cancel="false" :is-full-page="false" />
    <ProductSearchInput :queries="queries" :getList="getList" :productList="productList" :isLoading.sync="isLoading" extraCls="px-4 pt-3 pb-2" />
    <b-table :fields="fields" :items="items" show-empty :empty-text="$t('notify.table_no_records')" small hover responsive head-variant="light" sticky-header="100%" class="m-0 p-0">
      <template #cell(name)="{ value, item }">
        <div v-if="item.nameLang" class="w-auto d-flex flex-row flex-nowrap align-items-center">
          <div class="action" @click="onViewDetails(item)" style="white-space: normal; min-width: 8rem">{{ value }}</div>
          <icon-translate :onClick="() => pushProductEdit(item)" classes="pl-1" />
        </div>
        <div v-else class="action" @click="onViewDetails(item)" style="white-space: normal; min-width: 12rem">{{ value }}</div>
      </template>
      <template #cell(topImage)="{ value, item }">
        <b-img-lazy v-if="value" :src="value" :alt="shortenString(value, 13, true)" block rounded class="hover-image" style="max-height: 4rem" />
        <b-badge v-else variant="danger" @click="pushProductEdit(item)" class="font-weight-bold" style="cursor: pointer">⚠ {{ $t('notify.no_image') }}</b-badge>
      </template>
      <template #cell(store_id)="{ value }"><store-badge :store_id="value" /></template>
      <template #head(stock_amount)="{ label }">
        {{ label }}
        <b-icon-question-circle-fill class="ml-1" v-b-tooltip.hover.right="$t('product.initial_amount') + ' / ' + $t('product.stock_amount') + ' / ' + $t('product.available_amount')" />
      </template>
      <template #cell(stock_amount)="{ value, item }">
        <div class="w-100 d-flex flex-row align-items-center justify-content-end">
          <span class="action flex-grow-1" @click="onViewHistory(item)">{{ value }}</span>
          <i
            v-if="!item.has_invoice"
            class="fa fa-receipt text-xl text-danger flex-grow-0 ml-1"
            @click="pushProductEdit(item)"
            style="cursor: pointer; width: 20px"
            v-b-tooltip.hover.right="$t('notify.no_invoice')"></i>
          <div v-else style="width: 20px"></div>
        </div>
      </template>
      <template #cell(status)="{ value }">
        <bool-badge :value="value" :text="$t('product.stock_status_list')[value]" />
      </template>
      <template #cell(action)="{ item }">
        <b-button @click="pushProductEdit(item)" v-b-tooltip.hover.topleft="$t('action.edit')"><b-icon-pencil /></b-button>
        <b-button @click="$router.push({ name: 'Product Create', params: item })" v-b-tooltip.hover.topright="$t('action.duplicate')"><b-icon-plus /></b-button>
      </template>
    </b-table>
    <paginate v-if="items.length > 0" :queries="queries" :fetcher="getList" :total="productList.total" class="card-footer" />

    <b-modal
      v-if="items.length > 0"
      lazy
      centered
      id="product-details"
      :title="$t('product.product_details') + (actionTarget ? ' #' + actionTarget.id : '')"
      title-class="w-100 d-flex justify-content-center align-self-center"
      header-class="py-2"
      body-class="pt-0"
      dialog-class="product-details-modal"
      hide-footer
      ><ProductDetails v-if="actionTarget" :product="actionTarget" />
    </b-modal>
    <ProductInventory v-if="items.length > 0" :product.sync="actionTarget" />
  </card>
</template>
<script>
import ProductDetails from '@/components/Product/ProductDetails.vue'
import ProductInventory from '@/components/Product/ProductInventory.vue'
import ProductSearchInput from '@/components/Product/ProductSearchInput.vue'
import routerMixin from '@/mixins/router-mixin'
import { formatLocalDateTime } from '@/utils/dateTimeUtils'
import { noTransFormatter, shortenString } from '@/utils/index'
import { formatWithCurrency } from '@/utils/numberUtils'
import { mapGetters } from 'vuex'

export default {
  mixins: [routerMixin],
  components: { ProductSearchInput, ProductDetails, ProductInventory },
  data() {
    return {
      isLoading: false,
      queries: {
        store_ids: null,
        name: null,
        type: null,
        status: null,
        has_invoice: null,
        available: null,
        page: 1,
        perPage: 10,
        ...this.$route.params?.prev,
      },
      fields: [
        { key: 'id', label: this.$t('general.id') },
        { key: 'name', label: this.$t('product.product_name'), formatter: noTransFormatter, thStyle: 'width: 12rem', stickyColumn: true },
        { key: 'topImage', label: this.$t('general.top_image') },
        { key: 'store_id', label: this.$tc('general.store', 1), class: 'text-center' },
        { key: 'status', label: this.$t('product.stock_status'), tdClass: 'text-uppercase' },
        { key: 'type', label: this.$t('general.type'), formatter: (v) => (!v ? this.$t('no_data') : this.$t(`product.types.${v.replace('-', '_')}`)), tdClass: 'text-capitalize' },
        {
          key: 'stock_amount',
          label: this.$t('product.amounts_label'),
          formatter: (v, k, i) => i.initial_amount + ' / ' + v + ' / ' + i.available_amount,
          tdClass: 'text-center px-1',
        },
        { key: 'cost', label: this.$t('general.cost'), sortable: true, formatter: (v, k, i) => formatWithCurrency(v, i.currency), thClass: 'text-center', tdClass: 'text-right' },
        { key: 'action', label: this.$tc('general.action', 1), class: 'text-center px-2' },
        { key: 'storing_time', label: this.$t('product.storing_time'), sortable: true, formatter: (v) => formatLocalDateTime(v) },
        { key: 'delivering_time', label: this.$t('product.delivering_time'), sortable: true, formatter: (v) => formatLocalDateTime(v) },
        { key: 'created_at', label: this.$t('general.created_at'), sortable: true, formatter: (v) => formatLocalDateTime(v) },
        { key: 'updated_at', label: this.$t('general.updated_at'), sortable: true, formatter: (v) => formatLocalDateTime(v) },
        { key: 'admin.name', label: this.$t('general.updated_by') },
      ],
      actionTarget: null,
    }
  },
  computed: {
    ...mapGetters({ productList: 'request/productList', allowMultiStores: 'allowMultiStores', selfStore: 'store' }),
    items() {
      return this.productList?.data ?? []
    },
  },
  methods: {
    getList() {
      if (!this.allowMultiStores) {
        this.queries.store_ids = `[${this.selfStore}]`
      }
      const params = {}
      for (const key in this.queries) {
        if (this.queries[key] || typeof this.queries[key] == 'number') {
          params[key] = this.queries[key]
        }
      }
      this.isLoading = true
      this.$store
        .dispatch('request/getProductList', params)
        .then(() => (this.isLoading = false))
        .catch(() => (this.isLoading = false))
    },
    onViewDetails(row) {
      this.actionTarget = row
      this.$bvModal.show('product-details')
    },
    onViewHistory(row) {
      this.actionTarget = row
      this.$bvModal.show('product-inventory')
    },
    shortenString,
  },
  created() {
    this.getList()
  },
  beforeRouteLeave(to, from, next) {
    this.$set(from.params, 'preservedParams', this.queries)
    return next()
  },
}
</script>
<style scoped>
.product-details-modal {
  width: max-content !important;
  min-width: 56rem !important;
  max-width: 90vw !important;
}
</style>
