<template>
  <card type="default" header-classes="bg-transparent">
    <loading :active="isLoading" :can-cancel="false" :is-full-page="false" />
    <TimeSelect :displayInfo="{ name: $t('dashboard.chart_name[0]') }" :initBigChart="initBigChart" type="sales" :queryParams="queryParams" />
    <LineChart :height="350" ref="bigChart" :chart-data="chartModel.chartData" :options="chartModel.extraOptions" />
  </card>
</template>
<script>
import { blueChartOptions } from '@/components/Charts/config'
import LineChart from '@/components/Charts/LineChart'
import TimeSelect from '@/components/Charts/TimeSelect.vue'

export default {
  name: 'DailySales',
  components: { LineChart, TimeSelect },
  data() {
    return {
      isLoading: false,
      queryParams: {},
      chartModel: { chartData: {}, extraOptions: blueChartOptions },
      rawData: [],
      colors: ['#FF6384', '#2dce89', '#F4B400'],
      keys: ['paid', 'useBalanceAmount', 'useWalletAmount', 'couponAmount'],
    }
  },
  methods: {
    async getDailySales() {
      this.isLoading = true
      await this.$store
        .dispatch('request/getDailySales', this.queryParams)
        .then(() => (this.isLoading = false))
        .catch(() => (this.isLoading = false))
      this.rawData = this.$store.getters['request/dailySales']
    },
    initBigChart() {
      const salesCurr = ['£', 'Mex$'][this.queryParams['store_id'] - 1]
      this.getDailySales().then(() => {
        const datasets = this.keys.map((key, index) => ({
          label: this.$t('dashboard.daily_sales')[index],
          currency: salesCurr,
          data: this.rawData.map((val) => val[key]),
          borderColor: this.colors[index],
          pointBackgroundColor: this.colors[index],
          tension: 0.1,
        }))
        this.chartModel.chartData = {
          datasets: datasets,
          labels: this.rawData.map((val) => this.$moment(val.day).format('DD/MM')),
        }
      })
    },
  },
  // queryParams will be initialized when TimeSelect created
  mounted() {
    this.initBigChart()
  },
}
</script>
