<template>
  <div :class="`d-flex flex-row flex-wrap align-items-start ${extraCls ? extraCls : ''}`">
    <b-form-select v-if="showStore" v-model="queryParams.store_id" :disabled="!allowMultiStores" class="mb-2 mr-2" style="width: 90px">
      <b-form-select-option selected disabled hidden :value="null">{{ $tc('general.store', 1) }}</b-form-select-option>
      <b-form-select-option :value="''">{{ $t('general.all') + ' ' + $tc('general.store', 2) }}</b-form-select-option>
      <b-form-select-option :value="1">{{ $t('uk') }}</b-form-select-option>
      <b-form-select-option :value="2">{{ $t('address.mexico') }}</b-form-select-option>
    </b-form-select>
    <b-form-input v-if="showOrderId" v-model="queryParams.id" @keyup.enter="onSearch" type="search" placeholder="Order ID" class="mb-2 mr-2" style="width: 100px" />
    <b-form-input v-model="queryParams['payment_external_id']" @keyup.enter="onSearch" type="search" placeholder="Transaction ID / Token" class="mb-2 mr-2" style="width: 186px" />
    <b-form-input v-if="showEmail" v-model="queryParams.email" @keyup.enter="onSearch" type="search" placeholder="Email / Username" class="mb-2 mr-2" style="width: 170px" />
    <b-form-select v-if="showType" v-model="queryParams.type" class="mb-2 mr-2" style="width: 100px">
      <b-form-select-option :value="null">All Types</b-form-select-option>
      <b-form-select-option v-for="value in [0, 1, 2, 3]" :key="value" :value="value">{{ $t('ticket.ticket_types')[value] }}</b-form-select-option>
    </b-form-select>
    <b-form-select v-if="showStatus" v-model="queryParams.status" class="mb-2 mr-2" style="width: 128px">
      <b-form-select-option :value="null">All Status</b-form-select-option>
      <b-form-select-option v-for="value in [-3, -2, -1, 0, 1, 2, 3, 4]" :key="value" :value="value">{{ $t('order.status_list')[value + 3] }}</b-form-select-option>
    </b-form-select>
    <date-range-picker
      ref="picker"
      v-if="showDateRange"
      v-model="dateRange"
      :locale-data="{ format: 'yyyy-mm-dd' }"
      :auto-apply="true"
      :ranges="ranges"
      :timePicker24Hour="true"
      :show-dropdowns="true"
      class="mb-2 mr-2"
      style="width: max-content; min-width: 220px"
      :max-date="maxDate"
      v-b-tooltip.hover.top="$t('notify.date_range_notice', { name: $t('order.order_date') })" />
    <b-form-input v-if="showUid" v-model="queryParams['user_id']" @keyup.enter="onSearch" type="search" :placeholder="$t('user.user_id')" style="width: 146px" class="mr-2 mb-2" />
    <button-group :onSearch="onSearch" :onReset="onReset" />
  </div>
</template>
<script>
import { formatDayEndUtc, formatDayStartUtc, formatLocalDateTime, setupDateRanges } from '@/utils/dateTimeUtils'
import { mapGetters } from 'vuex'

export default {
  name: 'OrderSearchInput',
  props: { queryParams: Object, onSearch: Function, extraCls: String },
  data() {
    return {
      ranges: setupDateRanges(),
      showDateRange: false,
      showStatus: false,
      showType: false,
      showStore: false,
      showEmail: false,
      showOrderId: false,
      showUid: false,
    }
  },
  computed: {
    ...mapGetters({ orderList: 'request/orderList', allowMultiStores: 'allowMultiStores', selfStore: 'store' }),
    isListRoute() {
      return this.$route.path.includes('/orders')
    },
    dateRange: {
      get() {
        return {
          startDate: formatLocalDateTime(this.queryParams.start, 1, false, true),
          endDate: formatLocalDateTime(this.queryParams.end, 1, false, true),
        }
      },
      set(newVal) {
        this.queryParams['start'] = formatDayStartUtc(newVal.startDate, 0, false, true)
        this.queryParams['end'] = formatDayEndUtc(newVal.endDate, 0, false, true)
        return newVal
      },
    },
    maxDate() {
      return formatLocalDateTime(null, 3, true, true)
    },
  },
  methods: {
    onReset() {
      this.dateRange['startDate'] = null
      this.dateRange['endDate'] = null
      this.queryParams.user_id = null
      this.queryParams.id = null
      this.queryParams.payment_external_id = null
      this.queryParams.email = null
      this.queryParams.store_id = this.allowMultiStores ? null : this.selfStore
      this.queryParams.type = null
      this.queryParams.status = null
      this.queryParams.start = null
      this.queryParams.end = null
      this.onSearch()
    },
    // onExport() {
    //   if (this.orderList.total === 0) {
    //     this.$notify({ group: 'root', type: 'error', text: this.$t('notify.no_records') })
    //   }
    //   return exportAsExcel('OrderList_' + formatUtcDateTime(undefined, 2, true), API_LIST.get.orderList, { ...this.queryParams, page: 1, perPage: 1000 }, this.orderList.total)
    // },
  },
  created() {
    this.showUid = this.queryParams.user_id !== undefined
    this.showOrderId = this.queryParams.id !== undefined
    this.showEmail = this.queryParams.email !== undefined
    this.showStore = this.queryParams.store_id !== undefined
    this.showType = this.queryParams.type !== undefined
    this.showStatus = this.queryParams.status !== undefined
    this.showDateRange = this.queryParams.start !== undefined || this.queryParams.end !== undefined
  },
}
</script>
