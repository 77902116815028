<template>
  <card body-classes="p-0 d-flex flex-column" class="table-custom">
    <loading :active="isLoading" :can-cancel="false" :is-full-page="false" />
    <EntrySearchInput :queryParams="queryParams" type="entry" :onSearch="onSearch" />
    <b-table :fields="fields" :items="items" show-empty :empty-text="$t('notify.table_no_records')" small striped hover responsive head-variant="light" class="m-0 p-0">
      <template #head()="{ label, field }">{{ label }}<b-icon-question-circle-fill v-if="field.tooltip" class="ml-1" v-b-tooltip.hover.topright="field.tooltip" /></template>
      <template #cell(title)="{ value, item }">
        <div class="w-auto flex-grow-1" style="white-space: normal">{{ value }}</div>
        <icon-translate v-if="item.titleLang" :onClick="() => pushCompEdit(item.competition)" />
      </template>
      <template #cell(store_ids)="{ value }">
        <template v-for="(val, index) in value"><store-badge v-if="val" :key="'entry-store-' + index" :class="`d-block${index > 0 ? ' mt-1' : ''}`" :store_id="val" /></template>
      </template>
      <template #cell(url)="{ value, item }">
        <b-link :disabled="!isValidHttpUrl(value)" :href="value" target="_blank" :class="{ 'text-muted': !isValidHttpUrl(value) }"><b-icon-link45deg class="mr-1" />{{ $t('entry.link') }}</b-link>
        <b-button @click="openEditLink(item)" v-b-tooltip.hover.top="$t('entry.edit_link')" class="ml-2"><b-icon-pencil /></b-button>
        <b-button @click="openUploadArea(item)" v-b-tooltip.hover.topright="$t('entry.upload_entry_list')"><b-icon-upload /></b-button>
      </template>
      <template #cell(status)="{ value }">
        <b-badge :variant="['secondary', 'success'][value]">{{ $t('entry.status_list')[value] }}</b-badge>
      </template>
      <template #cell(competition)="{ value, item }">
        <b-button @click="openPublish(item)" :disabled="item.status == 1 || value.status < 2 || !item.url || item.url == ' '" variant="basic">
          <b-icon-cloud-upload class="mr-1" />{{ $t('action.publish') }}
        </b-button>
        <b-button @click="onExportExcel(value.id, item.title)" variant="basic">{{ $t('entry.export_excel') }}<b-icon-file-spreadsheet class="ml-1" /></b-button>
      </template>
    </b-table>
    <paginate v-if="items.length > 0" :queries="queryParams" :fetcher="getEntryList" :total="entryList.total" class="card-footer" />

    <b-modal
      lazy
      centered
      id="entry-list-link"
      :title="$t('entry.edit_link')"
      title-class="w-100 d-flex justify-content-center align-self-center"
      header-class="py-2"
      footer-class="py-2"
      body-class="py-0"
      @hidden="actionTarget = null">
      <b-form v-if="actionTarget" @submit.prevent="submitLink" id="entry-list-link-form">
        <p class="text-slack text-sm m-0">⚠ {{ $t('entry.edit_link_notice[0]') }}</p>
        <b-form-group label-for="entry-list-link-input" label-class="text-sm" class="mt-3 mb-0">
          <template #label>{{ $t('entry.link') }}<span class="ml-1 text-danger">*</span></template>
          <b-form-input id="entry-list-link-input" v-model="actionTarget['url']" required :state="linkInputValid" />
        </b-form-group>
        <b-form-invalid-feedback :state="linkInputValid">⚠ {{ $t('entry.edit_link_notice[1]') }}</b-form-invalid-feedback>
      </b-form>
      <template #modal-footer="{ cancel }">
        <b-button variant="outline-secondary" @click="cancel">{{ $t('action.cancel') }}</b-button>
        <b-button variant="outline-primary" type="submit" form="entry-list-link-form" :disabled="linkInputValid === false">{{ $t('action.save') }}</b-button>
      </template>
    </b-modal>

    <confirm-modal id="confirm-publish" :title="$t('entry.confirm_publish')" :message="$t('entry.publish_notice[1]')" :isLoading="isLoading" :onSubmit="submitPublish">
      <template #form>
        <b-form-group label-class="text-sm" label-for="draw-date" class="mt-3">
          <template #label>{{ $t('draw.draw_date') }}<span class="text-danger ml-1">*</span></template>
          <b-form-input id="draw-date" v-model="statusParams['draw_date']" type="datetime-local" :placeholder="$t('input.choose_date')" disabled />
        </b-form-group>
      </template>
    </confirm-modal>

    <file-upload :form="excelParams" :onSubmit="submitUpload" :disabled.sync="isLoading" />
  </card>
</template>
<script>
import EntrySearchInput from '@/components/EntrySearchInput.vue'
import routerMixin from '@/mixins/router-mixin'
import { formatLocalDateTime, formatUtcDateTime } from '@/utils/dateTimeUtils'
import { isValidHttpUrl, noTransFormatter, notifyError, notifySuccess } from '@/utils/index'
import { mapGetters } from 'vuex'

export default {
  components: { EntrySearchInput },
  mixins: [
    routerMixin, // pushCompEdit
  ],
  data() {
    return {
      fields: [
        {
          key: 'title',
          label: this.$t('general.title'),
          stickyColumn: true,
          formatter: noTransFormatter,
          tdClass: 'd-flex flex-row flex-nowrap align-items-center',
        },
        { key: 'store_ids', label: this.$tc('general.store', 1), class: 'text-center' },
        { key: 'draw_date', label: this.$t('draw.draw_date'), formatter: (v) => formatLocalDateTime(v, 0), sortable: true },
        { key: 'url', label: this.$t('entry.google_drive_link'), tooltip: this.$t('entry.upload_notice[0]'), class: 'text-center px-2' },
        { key: 'status', label: this.$t('general.status'), class: 'text-center', tdClass: 'text-uppercase' },
        { key: 'competition', label: this.$tc('general.action', 1), tooltip: this.$t('entry.publish_notice[0]'), class: 'text-center px-2' },
        { key: 'updated_at', label: this.$t('general.updated_at'), formatter: (v) => formatLocalDateTime(v, 0), sortable: true },
      ],
      isLoading: true,
      queryParams: {
        store_id: null,
        status: null,
        title: null,
        start: null,
        end: null,
        page: 1,
        perPage: 10,
        ...this.$route.params?.prev,
      },
      statusParams: { draw_date: '' },
      excelParams: {},
      actionTarget: null,
    }
  },
  computed: {
    ...mapGetters({ entryList: 'request/entryList', allowMultiStores: 'allowMultiStores', selfStore: 'store' }),
    items() {
      return this.entryList?.data ?? []
    },
    linkInputValid() {
      return this.actionTarget ? isValidHttpUrl(this.actionTarget.url) : null
    },
  },
  methods: {
    isValidHttpUrl,

    getEntryList(_params) {
      this.isLoading = true
      const params = { ..._params }
      for (const key in this.queryParams) {
        if (this.queryParams[key] || typeof this.queryParams[key] == 'number') {
          params[key] = this.queryParams[key]
        }
      }
      this.$store
        .dispatch('request/getEntryList', params)
        .then(() => (this.isLoading = false))
        .catch(() => (this.isLoading = false))
    },
    onSearch() {
      this.queryParams['page'] = 1
      this.getEntryList({ errorMsg: this.$t('notify.fetch_err', { name: this.$t(['entry.entry_list']) }) })
    },

    // export entry list
    csvPreProcess(data) {
      for (let i in data) {
        delete data[i].number
        delete data[i].created_at
        delete data[i].updated_at
        delete data[i].competition_id
        // delete data[i].id
        delete data[i].admin_user_id
        delete data[i].id
        delete data[i].order_item_id
        delete data[i].user_id
        delete data[i].free
        delete data[i].status
        data[i].order_id = '#' + String(data[i].order_id)
        data[i].ticket = Number(i) + 1
        data[i].user = data[i].user.display_name
      }
    },
    async onExportExcel(id, title) {
      const res = await this.$store.dispatch('request/exportEntryList', { competition_id: id, errorMsg: this.$t('notify.export_excel_failed') })
      if (res.status == 200) {
        const url = window.URL.createObjectURL(new Blob([res.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${title}_${formatUtcDateTime(undefined, 'YYYY-MM-DD-HH-mm-ss', true)}.xlsx`)
        document.body.appendChild(link)
        link.click()
      }
    },

    // publish entry list
    openPublish(obj) {
      this.statusParams = {
        id: obj.id,
        status: obj.status === 1 ? 0 : 1,
        draw_date: formatLocalDateTime(obj.draw_date, 1),
      }
      this.$bvModal.show('confirm-publish')
    },
    submitPublish() {
      this.isLoading = true
      this.$store
        .dispatch('request/updateEntryStatus', {
          id: this.statusParams.id,
          status: this.statusParams.status,
          draw_date: formatUtcDateTime(this.statusParams.draw_date, 0, false, false),
          successMsg: this.$t('entry.publish_notice[2]'),
          errorMsg: this.$t('entry.publish_notice[3]'),
        })
        .then((res) => {
          this.isLoading = false
          this.$bvModal.hide('confirm-publish')
          if (res.status === 200) this.getEntryList()
        })
        .catch(() => (this.isLoading = false))
    },

    // edit google drive link
    openEditLink(obj) {
      this.actionTarget = { ...obj }
      this.$bvModal.show('entry-list-link')
    },
    submitLink() {
      this.isLoading = true
      this.$store
        .dispatch('request/updateEntryLink', {
          competition_id: this.actionTarget.competition_id,
          url: this.actionTarget.url,
          successMsg: this.$t('entry.edit_link_notice[2]'),
          errorMsg: this.$t('entry.edit_link_notice[3]'),
        })
        .then((res) => {
          this.isLoading = false
          if (res.status === 200) {
            this.$bvModal.hide('entry-list-link')
            this.getEntryList()
          }
        })
        .catch(() => (this.isLoading = false))
    },

    // upload excel file
    openUploadArea(obj) {
      this.excelParams['competition_id'] = obj.competition_id
      this.$bvModal.show('file-upload')
    },
    submitUpload(callback) {
      this.isLoading = true
      let formData = new FormData()
      formData.append('competition_id', JSON.stringify(this.excelParams.competition_id))
      formData.append('entry_list', this.excelParams.file) // 'file' from file-upload component
      this.$store
        .dispatch('request/updateEntryList', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then((res) => {
          this.isLoading = false
          notifySuccess(res, this.$t('entry.upload_notice[1]'))
          if (res.status === 200) {
            this.$bvModal.hide('file-upload')
            this.getEntryList()
          }
        })
        .catch((e) => {
          this.isLoading = false
          notifyError(e, this.$t('entry.upload_notice[2]'))
          if (callback) callback()
        })
    },
  },
  created() {
    this.queryParams.store_id = this.allowMultiStores ? null : this.selfStore
    this.getEntryList()
  },
  beforeRouteLeave(to, from, next) {
    this.$set(from.params, 'preservedParams', this.queryParams)
    return next()
  },
}
</script>
<style scoped>
::v-deep .modal-dialog {
  width: 24rem;
}
</style>
