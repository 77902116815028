<template>
  <div style="display: contents">
    <b-card v-if="winner['profiles']" class="mx-4 mt-3" body-class="py-3 px-0">
      <p class="mb-3 px-4 font-weight-bold">{{ $t('winner.all_profiles') }}</p>
      <b-table v-if="winner['profiles'].length > 0" :fields="fields" :items="winner['profiles']" small hover responsive sticky-header="15rem" head-variant="light" class="mt-3 winner-profile">
        <template #cell(content)="{ index, value, item }">
          <div :id="`tooltip-content-${index}`">
            <b-img-lazy v-if="['image', 'receipt', 'poa', 'photoID'].includes(item.type)" :src="item.content" :alt="shortenString(value, 13, true)" fluid block rounded style="max-height: 3rem" />
            <span v-else>
              {{ shortenString(value, 40, false) }}
            </span>
            <b-tooltip v-if="value.length > 40" :target="`tooltip-content-${index}`" placement="right">{{ value }}</b-tooltip>
          </div>
        </template>
      </b-table>
      <p class="m-0 p-0" v-else>{{ $t('winner.winner_profiles_not_found') }}</p>
    </b-card>
    <b-card class="mx-4 mt-3" body-class="py-3">
      <UserDetails v-if="winner.user" :user="winner.user" />
      <p v-else>{{ $t('winner.user_details_not_found') }}</p>
    </b-card>
    <b-card class="mx-4 mt-3" body-class="py-3">
      <AddressDetails v-if="winner.address" :userStore="winner.store_id" :address="winner.address" />
      <p v-else>{{ $t('winner.address_details_not_found') }}</p>
    </b-card>
    <b-card class="mx-4 mt-3" body-class="py-3">
      <p class="font-weight-bold mb-3">
        {{ $t('winner.competition_details') }}
        <CompetitionStatus v-if="winner.competition" :value="winner.competition['status']" class="ml-2" />
      </p>
      <CompetitionDetails v-if="winner.competition" :competition="winner.competition">
        <template #title="{ competition }">
          <span class="font-weight-bold text-sm">{{ $t('general.title') }}</span>
          <span class="ml-3 font-weight-light">{{ competition.title }}</span>
        </template>
      </CompetitionDetails>
      <p v-else>{{ $t('winner.competition_details_not_found') }}</p>
    </b-card>
    <b-card class="mx-4 mt-3" body-class="py-3">
      <p class="font-weight-bold mb-3">{{ $t('winner.prize_details') }}</p>
      <ProductDetails v-if="winner.product" :product="winner.product" />
      <p v-else>{{ $t('winner.prize_details_not_found') }}</p>
    </b-card>
  </div>
</template>
<script>
import UserDetails from '@/components/Users/UserDetails.vue'
import { AWSSecurityWithURL } from '@/store/services/aws'
import { formatLocalDateTime } from '@/utils/dateTimeUtils'
import { shortenString } from '@/utils/index'
import CompetitionDetails from '../Competitions/CompetitionDetails.vue'
import CompetitionStatus from '../Competitions/CompetitionStatus.vue'
import ProductDetails from '../Product/ProductDetails.vue'
import AddressDetails from '../Users/AddressDetails.vue'

export default {
  name: 'WinnerDetails',
  components: { UserDetails, AddressDetails, CompetitionDetails, ProductDetails, CompetitionStatus },
  props: {
    winner: Object,
  },
  computed: {
    competitionDescription() {
      let description = this.winner.competition.description.replace(/<[^>]+>/g, '')
      return description
    },
    productDescription() {
      return this.winner.product.description.replace(/<[^>]+>/g, '')
    },
    fields() {
      return [
        { key: 'type', label: this.$t('general.type') },
        {
          key: 'content',
          label: this.$t('general.content'),
          formatter: (v, k, i) => {
            if (v.includes('rkings-security') && !i.done && (i.type == 'receipt' || i.type == 'poa' || i.type == 'photoID')) {
              // prevent image loaded repeatedly
              i.done = true
              AWSSecurityWithURL({ name: v.slice(51) }).then(({ blob }) => (i.content = URL.createObjectURL(blob)))
            } else {
              return v
            }
          },
        },
        { key: 'admin_user_id', label: this.$t('general.created_by') },
        { key: 'created_at', label: this.$t('general.created_at'), formatter: (v) => formatLocalDateTime(v) },
        { key: 'updated_at', label: this.$t('general.updated_at'), formatter: (v) => formatLocalDateTime(v) },
      ]
    },
  },
  methods: {
    shortenString,
    formatLocalDateTime,
  },
}
</script>
<style>
.winner-profile tr {
  height: 58px !important;
}
</style>
