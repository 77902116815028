<template>
  <div>
    <loading :active="isLoading" :can-cancel="false" :is-full-page="false" />
    <b-input-group class="my-0 w-100 d-flex align-items-center">
      <label class="font-weight-normal m-0">{{ $tc('general.image', items.length) }}<span class="text-danger ml-1">*</span></label>
      <b-form-file
        v-model="store['imgFiles']"
        ref="imgFiles"
        accept=".png, .jpg, .jpeg, .gif"
        :multiple="true"
        :placeholder="$tc('input.file_selected', filesLen, { num: filesLen })"
        :file-name-formatter="(files) => $tc('input.file_selected', files ? filesLen : 0, { num: filesLen })"
        :state="imgSizeValid"
        :required="items.length < 1"
        :disabled="!title"
        size="sm"
        class="ml-2"
        style="max-width: 240px"
        @input="inputImages"
        :browse-text="$t('action.browse')" />
      <b-button variant="outline-danger" @click="initImages(false)" class="ml-2"><b-icon-x class="mr-1" />{{ $t('action.reset') }}</b-button>
    </b-input-group>
    <b-form-invalid-feedback :state="filesLen <= 0" class="text-slack">⚠ {{ $t('image.ratio_notice') }}</b-form-invalid-feedback>
    <b-form-invalid-feedback :state="imgSizeValid">⚠ {{ $t('image.size_notice') }}</b-form-invalid-feedback>
    <b-form-invalid-feedback :state="imgPosValid">⚠ {{ $t('image.position_notice') }}</b-form-invalid-feedback>
    <b-table
      show-empty
      :empty-text="`⚠ ${$t('image.at_least_one')}`"
      small
      hover
      responsive
      sticky-header="340px"
      head-variant="light"
      class="mt-3 mb-0 mx-0 p-0 flex-grow-1 flex-shrink-1"
      tbody-class="h-100"
      :fields="fields"
      :items="items"
      :busy="isLoading">
      <template #head()="{ label, field }">{{ label }}<b-icon-question-circle-fill v-if="field.tooltip" class="ml-1" v-b-tooltip.hover.topright="field.tooltip" /></template>
      <template #cell(src)="{ value, item }">
        <a :href="`${value}`" target="_blank" v-if="item.uploaded">
          <b-img-lazy :src="value" :alt="value" fluid block rounded style="max-height: 3rem" class="hover-image" />
        </a>
        <b-img-lazy v-else :src="value" :alt="value" fluid block rounded style="max-height: 3rem" class="hover-image" />
      </template>
      <template #cell(position)="{ value, index }">
        <b-form-input :value="value" @input="onInputPos(index, $event)" :state="imgPosValid" :placeholder="$t('general.position')" class="w-100" style="max-width: 3.5rem" />
      </template>
      <template #cell(file)="{ value, index }">
        <b-button variant="outline-danger" @click="deleteImage(index, value)"><b-icon-trash /></b-button>
      </template>
      <template #bottom-row v-if="items.length >= 1">
        <td colspan="2" class="font-weight-bold">{{ $t('general.total') }}</td>
        <td colspan="1" class="font-weight-bold">{{ items.length + ' ' + $tc('general.image', items.length) }}</td>
        <td colspan="2"></td>
      </template>
    </b-table>
  </div>
</template>
<script>
import { base64Encode } from '@/utils/fileUtils'
import { formatBytes } from '@/utils/numberUtils'

export default {
  name: 'CompetitionImages',
  props: {
    title: String,
    initVal: Array,
    store: Object,
    imgItems: Array,
  },
  data() {
    return {
      isLoading: false,
    }
  },
  computed: {
    showQuiz() {
      return this.store?.value == 1
    },
    otherStoreImgLen() {
      return this.imgItems?.filter((x) => x.store_id !== this.store.value).length ?? 0
    },
    fields() {
      return [
        { key: 'src', label: this.$t('general.preview'), tooltip: this.$t('image.ratio_notice') },
        { key: 'position', label: this.$t('general.position'), tooltip: this.$t('image.position_notice') },
        this.filesLen > 0
          ? {
              key: 'size',
              label: this.$t('general.size'),
              formatter: (v) => formatBytes(v),
              tdClass: (v, k, i) => ((i.file && i.file.type === 'application/pdf' && v >= 2097152) || v >= 614400 ? 'text-danger' : ''),
              tooltip: this.$t('image.size_notice'),
            }
          : null,
        { key: 'file', label: this.$t('action.delete'), formatter: (v) => (v ? v.name : null) },
        // { key: 'store_id', label: this.$tc('general.store', 1) },
        { key: 'name', label: this.$t('image.formatted_name') },
        // 'parentIndex',
        // 'uploaded',
      ]
    },
    existingImg() {
      const tmp = this.initVal?.filter((x) => x.store_id == this.store.value) ?? []
      if (tmp.length > 0) tmp.sort((a, b) => a.position - b.position)
      return tmp
    },
    hasFile() {
      let tmp = false
      this.items.forEach((x) => (tmp = tmp || !!x.file))
      return tmp
    },
    filesLen() {
      return this.store?.imgFiles?.length ?? 0
    },
    imgSizeValid() {
      if (this.items.length < 1) {
        return null
      }
      let tmp = true
      this.store.imgFiles.forEach((x) => (tmp = tmp && x.size < 614400))
      return tmp
    },
    imgPosValid() {
      let tmp = true
      const pos = this.items.map((x) => x.position)
      pos.forEach((x, i) => (tmp = tmp && pos.indexOf(x) === i))
      return tmp
    },
    formattedName() {
      return (
        this.$moment.utc().format('YYYYMMDDHHmmss') +
        '-' +
        this.title
          .toLowerCase()
          .split(' ')
          .splice(0, 3)
          .join('-')
          .replace(/[^a-z0-9-]/gi, '') +
        '-' +
        this.store.value
      )
    },
    items: {
      get() {
        return this.getItems()
      },
      set(val) {
        return val
      },
    },
  },
  methods: {
    getItems() {
      const tmp = []
      this.imgItems.forEach((x, i) => {
        if (x.store_id == this.store.value) {
          tmp.push({ ...x, parentIndex: i })
        }
      })
      // tmp.sort((a, b) => Number(a.uploaded) - Number(b.uploaded))
      return tmp
    },
    initImages(hasFile) {
      // replace existing
      this.existingImg.forEach((x, i) => {
        const obj = {
          id: x.id,
          store_id: x.store_id,
          status: x.status,
          src: x.value,
          position: x.position,
          size: NaN,
          uploaded: true,
          file: null,
          name: x.value.substring(x.value.lastIndexOf('/') + 1),
        }
        this.imgItems.splice(this.items[i]?.parentIndex ?? this.imgItems.length, 1, obj)
      })
      // init file input and local items
      if (!hasFile) {
        this.store.imgFiles = []
      }
      this.inputImages(this.store.imgFiles)
    },
    inputImages(files) {
      this.isLoading = true
      // remove old selected image
      const len = this.items.filter((x) => !x.uploaded).length
      for (let i = 0; i < len; i++) {
        const toRemove = this.items.findIndex((x) => !x.uploaded)
        if (toRemove > -1) {
          this.imgItems.splice(this.items[toRemove].parentIndex, 1)
        }
      }
      this.items.forEach((x, i) => (this.imgItems[x.parentIndex]['position'] = i + files.length + 1))
      // push new selected image
      if (files.length > 0) {
        files.forEach((file, i) => {
          base64Encode(file).then((res) => {
            const obj = {
              id: null,
              store_id: this.store.value,
              status: null,
              src: res,
              position: i + 1,
              size: file.size,
              uploaded: false,
              file: file,
              name: this.formattedName + '-' + (this.items.length + 1) + file.name.slice(file.name.lastIndexOf('.')),
            }
            this.imgItems.splice(this.imgItems.length, 0, obj)
            if (i == files.length - 1) {
              this.isLoading = false
            }
          })
        })
      } else {
        this.isLoading = false
      }
    },
    onInputPos(i, evt) {
      const j = this.items.findIndex((x) => x.position == parseInt(evt))
      if (j > -1) {
        this.imgItems[this.items[j].parentIndex]['position'] = this.items[i].position
      }
      this.imgItems[this.items[i].parentIndex]['position'] = parseInt(evt)
    },
    deleteImage(i, filename) {
      if (filename) {
        const j = this.store.imgFiles.findIndex((x) => x.name === filename)
        this.store.imgFiles.splice(j, 1)
      }
      this.imgItems.splice(this.items[i].parentIndex, 1)
      this.items.forEach((x, index) => {
        this.imgItems[x.parentIndex]['position'] = index + 1
      })
    },
  },
  created() {
    if (this.initVal?.length > 0 && this.imgItems?.length < 1) {
      this.initVal.forEach((x, i) => {
        this.imgItems.splice(i, 1, {
          id: x.id, // existing image id is needed
          store_id: x.store_id,
          status: x.status,
          src: x.value,
          position: x.position,
          size: NaN,
          uploaded: true,
          file: null,
          name: x.value.substring(x.value.lastIndexOf('/') + 1),
        })
      })
    }
  },
  mounted() {
    this.initImages(this.store.imgFiles?.length > 0)
  },
}
</script>
