<template>
  <div style="display: contents">
    <loading :active="isLoading" :can-cancel="false" :is-full-page="false" />

    <!-- Search Input -->
    <div class="px-4 pt-3 pb-2 d-flex flex-row flex-wrap align-items-start">
      <b-form-select v-model="queries.store_id" :disabled="!allowMultiStores" class="mb-2 mr-2" style="width: 90px">
        <b-form-select-option selected disabled hidden :value="null">{{ $tc('general.store', 1) }}</b-form-select-option>
        <b-form-select-option :value="''">{{ $t('general.all') + ' ' + $tc('general.store', 2) }}</b-form-select-option>
        <b-form-select-option :value="1">{{ $t('uk') }}</b-form-select-option>
        <b-form-select-option :value="2">{{ $t('address.mexico') }}</b-form-select-option>
      </b-form-select>

      <b-form-input v-model="queries.id" type="search" placeholder="User ID" @keyup.enter="onSearch" class="mb-2 mr-2" style="width: 100px" />

      <b-form-input v-model="queries.name" type="search" :placeholder="$t('user.name_or_email')" @keyup.enter="onSearch" class="mb-2 mr-2" style="width: 180px" />

      <b-form-select v-model="queries['is_active']" class="mb-2 mr-2" style="width: 90px">
        <b-form-select-option selected disabled hidden :value="null">{{ $t('general.status') }}</b-form-select-option>
        <b-form-select-option :value="''">{{ $t('general.all') }}</b-form-select-option>
        <b-form-select-option :value="1">{{ $t('status.active') }}</b-form-select-option>
        <b-form-select-option :value="0">{{ $t('status.inactive') }}</b-form-select-option>
      </b-form-select>

      <div class="d-flex flex-row flex-wrap align-items-center justify-content-xl-between flex-grow-1">
        <button-group :onSearch="onSearch" :onReset="onReset" class="mb-2 mr-2" />
        <slot name="create"></slot>
      </div>
    </div>

    <!-- Table -->
    <b-table :fields="fields" :items="users ? users.data : []" show-empty :empty-text="$t('notify.table_no_records')" small hover responsive head-variant="light" class="m-0 p-0">
      <template #head()="{ label, field }">{{ label }}<b-icon-question-circle-fill v-if="field.tooltip" class="ml-1" v-b-tooltip.hover.right="field.tooltip" /></template>

      <template #cell(user_id)="{ item }">{{ item.id }}</template>
      <template #cell(name)="{ value, item }">
        <template v-if="hasSlot('name')"><slot name="name" :user="item"></slot></template>
        <template v-else>{{ value }}</template>
      </template>
      <template #cell(store_id)="{ value }"><store-badge :store_id="value" /></template>
      <template #cell(admin)="{ value }">
        <template v-if="value">{{ value }}</template>
        <b-badge v-else variant="danger">{{ $t('admin.no_admin') }}</b-badge>
      </template>
      <template #cell(is_active)="{ value, item }">
        <template v-if="hasSlot('is_active')"><slot name="is_active" :user="item"></slot></template>
        <bool-badge v-else :value="value" :text="value == 1 ? $t('status.active') : $t('status.inactive')" />
      </template>
      <template #cell(balance_amount)="{ value, item }">
        <template v-if="hasSlot('credit')"><slot name="credit" :user="item"></slot></template>
        <template v-else>{{ value }}</template>
      </template>
      <template #cell(cash_wallet)="{ value, item }">
        <template v-if="hasSlot('cash')"><slot name="cash" :user="item"></slot></template>
        <template v-else>{{ value.amount }}</template>
      </template>
      <template #cell(actions)="{ item }">
        <slot name="actions" :user_id="item.id" :username="item.name" :user="item"></slot>
      </template>
    </b-table>

    <paginate v-if="users" :queries="queries" :fetcher="getUsers" :total="users.total" class="card-footer" />
  </div>
</template>
<script>
import utilsMixin from '@/mixins/utils-mixin'
import { mapGetters } from 'vuex'

export default {
  name: 'UserList',
  mixins: [utilsMixin],
  props: { fields: Array, queries: Object, getter: Function, noInitOnMount: { type: Boolean, default: false } },
  data() {
    return {
      isLoading: true,
      initialParams: {},
    }
  },
  computed: {
    ...mapGetters({ users: 'request/userList', allowMultiStores: 'allowMultiStores', selfStore: 'store' }),
  },
  methods: {
    localGetter(callback) {
      const params = {}
      for (const key in this.queries) {
        if (this.queries[key] || typeof this.queries[key] == 'number') {
          params[key] = this.queries[key]
        }
      }
      this.$store.dispatch('request/getUsers', params).then(() => {
        if (callback) {
          callback()
        }
      })
    },
    getUsers() {
      this.isLoading = true
      if (this.getter) {
        return this.getter(() => (this.isLoading = false))
      } else {
        return this.localGetter(() => (this.isLoading = false))
      }
    },
    onSearch() {
      this.queries['page'] = 1
      this.getUsers()
    },
    onReset() {
      for (const key in this.queries) {
        this.queries[key] = this.initialParams[key]
      }
      this.onSearch()
    },
  },
  created() {
    this.queries['store_id'] = this.allowMultiStores ? null : this.selfStore

    this.initialParams = { ...this.queries }

    if (!this.noInitOnMount) {
      this.getUsers()
    } else {
      this.isLoading = false
    }
  },
}
</script>
