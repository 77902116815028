<template>
  <b-modal
    lazy
    centered
    :id="id ? id : 'product-inventory'"
    :title="title ? title : $t('product.product_inventory', { id: product ? ' #' + product.id : '' })"
    title-class="w-100 d-flex justify-content-center align-self-center"
    header-class="py-2"
    :body-class="`pt-0${confirmMsg ? ' pb-0' : ''}`"
    dialog-class="product-inventory-modal"
    :hide-footer="!confirmMsg"
    footer-class="py-2"
    @shown="onShown"
    @hidden="onHidden">
    <loading :active="isLoading" :can-cancel="false" :is-full-page="false" />
    <template v-if="product">
      <p v-if="confirmMsg">{{ confirmMsg }}</p>
      <b-row v-else>
        <b-col cols="12" class="d-flex flex-row flex-nowrap align-items-center">
          <span class="font-weight-bold">{{ product.name }}</span>
          <icon-translate v-if="product.nameLang" :onClick="() => pushProductEdit(product)" />
          <bool-badge :value="product.status" :text="$t('product.stock_status_list')[product.status]" class="ml-2" />
        </b-col>
        <b-col
          v-for="({ label, key, name, tip }, index) in [
            { label: $t('product.initial_amount'), key: 'initial_amount', name: null, tip: null },
            {
              label: $t('product.stock_amount'),
              key: 'stock_amount',
              name: 'StockAmt',
              tip: $t('product.stock_amount_notice'),
            },
            {
              label: $t('product.available_amount'),
              key: 'available_amount',
              name: 'AvailableAmt',
              tip: $t('product.available_amount_notice'),
            },
          ]"
          :key="'amount-' + index"
          class="d-inline-flex align-items-center mt-3">
          <span class="font-weight-normal text-sm">{{ label }}</span>
          <b-icon-question-circle-fill v-if="name" class="ml-1" v-b-tooltip.hover.right="tip" />
          <span class="ml-3 font-weight-light">{{ product[key] }}</span>
        </b-col>
      </b-row>
      <!-- v-if="inventory && inventory.id === product.id" -->
      <b-table show-empty :empty-text="$t('notify.table_no_records')" small hover responsive head-variant="light" class="mt-4 mb-0 p-0" :fields="fields" :items="inventory ? inventory.inventory : []">
        <template #cell(competition_id)="{ value, item }">
          <router-link :to="{ name: 'Competition Details', query: { id: value } }" class="text-decoration-none" style="white-space: normal; width: max-content; min-width: 8rem">
            {{ item.competition_title }}
          </router-link>
        </template>
        <template #cell(selected)="{ value }">
          <b-badge :variant="['primary', 'info', 'success'][value + 1]" class="text-uppercase" v-b-tooltip.hover="$t(`product.inventory_status_tips.${value + 1}`)">
            {{ $t('product.inventory_status')[value + 1] }}
          </b-badge>
        </template>
      </b-table>
      <!-- <p v-else-if="!isLoading" class="mt-3 text-danger text-sm">⚠ {{ $t('notify.fetch_err', { name: $t('product.product_inventory', { id: '' }) }) }}</p> -->
    </template>
    <template #modal-footer="{ hide }" v-if="confirmMsg">
      <b-button class="px-4" variant="secondary" @click="hide">{{ $t('no') }}</b-button>
      <b-button class="px-4" variant="primary" type="submit" form="product-upsert-form">{{ $t('yes') }}</b-button>
    </template>
  </b-modal>
</template>
<script>
import routerMixin from '@/mixins/router-mixin'
import { formatLocalDateTime } from '@/utils/dateTimeUtils'
import { noTransFormatter } from '@/utils/index'
import { mapGetters } from 'vuex'
export default {
  name: 'ProductInventory',
  mixins: [routerMixin],
  props: { product: Object, id: String, title: String, confirmMsg: String },
  data() {
    return {
      isLoading: false,
    }
  },
  computed: {
    fields() {
      return [
        { key: 'id', label: this.$t('general.id') },
        { key: 'competition_id', label: this.$t('competition.comp_title'), tdClass: 'mw-100' },
        { key: 'type', label: this.$t('general.type'), formatter: (v) => this.$t(`product.types.${v.replace('-', '_')}`), tdClass: 'text-capitalize' },
        { key: 'selected', label: this.$t('general.status') },
        { key: 'amount', label: this.$tc('general.amount', 1), sortable: true, thStyle: 'background-position: 84px center', tdClass: 'text-right' },
        // {
        //   key: 'alternative_cash_amount',
        //   label: 'Cost',
        //   sortable: true,
        //   formatter: (v, k, i) => formatWithCurrency(v, i.currency ?? 'GBP'),
        //   thStyle: 'background-position: 56px center',
        //   tdClass: 'text-right',
        // },
        { key: 'winner_name', label: this.$t('winner.winner_name') },
        { key: 'created_at', label: this.$t('general.created_at'), formatter: (v) => formatLocalDateTime(v) },
        { key: 'updated_at', label: this.$t('general.updated_at'), formatter: (v) => formatLocalDateTime(v) },
        { key: 'admin_name', label: this.$t('general.updated_by') },
      ]
    },
    ...mapGetters({ inventory: 'request/productInventory' }),
  },
  // watch: {
  //   product(val) {
  //     // if (val && val.id && val.id !== this.inventory?.id) {
  //     if (val && val.id) {
  //       this.isLoading = true
  //       this.$store.dispatch('request/getProductInventory', { id: val.id }).then(() => (this.isLoading = false))
  //     }
  //   },
  // },
  methods: {
    onShown() {
      if (this.product) {
        this.isLoading = true
        this.product['name'] = noTransFormatter(this.product.name, 'name', this.product)
        this.$store
          .dispatch('request/getProductInventory', { id: this.product.id })
          .then(() => (this.isLoading = false))
          .catch(() => (this.isLoading = false))
      }
    },
    onHidden() {
      if (this.confirmMsg) {
        this.$emit('update:confirmMsg', '')
      } else {
        this.$emit('update:product', null)
      }
    },
  },
}
</script>
<style>
.product-inventory-modal {
  width: max-content !important;
  min-width: 66vw !important;
  max-width: 96vw !important;
}
</style>
