var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"display":"contents"}},[_c('loading',{attrs:{"active":_vm.isLoading,"can-cancel":false,"is-full-page":false}}),_c('CompetitionSearchInput',{attrs:{"queries":_vm.queries,"fields":_vm.fields,"getList":_vm.getList,"isLoading":_vm.isLoading,"afterSearch":_vm.afterSearch},on:{"update:isLoading":function($event){_vm.isLoading=$event},"update:is-loading":function($event){_vm.isLoading=$event}}}),_c('b-table',{staticClass:"m-0 p-0",attrs:{"fields":_vm.fields,"items":_vm.items,"busy":_vm.isLoading,"show-empty":"","empty-text":_vm.$t('notify.table_no_records'),"hover":"","responsive":"","head-variant":"light","sort-by-formatted":"","sticky-header":"100%"},on:{"row-clicked":_vm.rowClicked},scopedSlots:_vm._u([{key:"cell(title)",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [(item.titleLang)?_c('div',{staticClass:"w-auto d-flex flex-row flex-nowrap align-items-center",staticStyle:{"width":"14rem"}},[_c('router-link',{staticClass:"text-decoration-none action d-block w-auto",staticStyle:{"white-space":"normal"},attrs:{"to":{ name: 'Competition Details', query: { id: item.id }, params: { competition: item } }}},[_vm._v(_vm._s(value)+" ")]),_c('icon-translate',{attrs:{"onClick":function () { return _vm.pushCompEdit(item); }}})],1):_c('router-link',{staticClass:"text-decoration-none action d-block",staticStyle:{"white-space":"normal","width":"14rem"},attrs:{"to":{ name: 'Competition Details', query: { id: item.id }, params: { competition: item } }}},[_vm._v(_vm._s(value)+" ")])]}},{key:"head(image)",fn:function(ref){
var label = ref.label;
return [_vm._v(_vm._s(label)),_c('b-icon-question-circle-fill',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.topright",value:(_vm.$t('competition.top_image_notice')),expression:"$t('competition.top_image_notice')",modifiers:{"hover":true,"topright":true}}],staticClass:"ml-1"})]}},{key:"cell(image)",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [(value)?_c('a',{attrs:{"href":("" + (item.href)),"target":"_blank"}},[_c('b-img-lazy',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.left",value:(item.href),expression:"item.href",modifiers:{"hover":true,"left":true}}],staticClass:"hover-image position-relative",staticStyle:{"min-width":"148px","min-height":"auto","max-width":"148px"},attrs:{"src":value,"alt":_vm.shortenString(value, 13, true),"fluid":"","block":"","rounded":""}})],1):_c('b-badge',{staticClass:"font-weight-bold",staticStyle:{"cursor":"pointer"},attrs:{"variant":"danger"},on:{"click":function($event){return _vm.pushCompEdit(item)}}},[_vm._v(" ⚠ "+_vm._s(_vm.$t('notify.no_image'))+" ")])]}},{key:"head(store_id)",fn:function(ref){
var label = ref.label;
return [_vm._v(_vm._s(label)),(_vm.allowMultiStores)?_c('b-icon-question-circle-fill',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.topright",value:(_vm.$t('competition.multi_store_notice')),expression:"$t('competition.multi_store_notice')",modifiers:{"hover":true,"topright":true}}],staticClass:"ml-1"}):_vm._e()]}},{key:"cell(store_id)",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_vm._l(([value, item.stores[0] ? item.stores[0].store_id : null]),function(val,index){return [(val)?_c('store-badge',{key:'status-store-' + index,class:("d-block " + (index > 0 ? 'mt-1' : '') + " " + (val == _vm.afterSearch ? 'font-weight-bold' : '')),attrs:{"store_id":val}}):_vm._e()]})]}},{key:"cell(status)",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_c('CompetitionStatus',{staticClass:"d-block",staticStyle:{"min-width":"fit-content","width":"fit-content"},attrs:{"value":value}}),(item.stores[0] && item.stores[0].status !== value)?_c('CompetitionStatus',{staticClass:"d-block mt-1",staticStyle:{"min-width":"fit-content","width":"fit-content"},attrs:{"value":item.stores[0].status}}):_vm._e(),_vm._t("is_approval",null,{"competition":item})]}},{key:"cell(tag)",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [(_vm.hasSlot('tag'))?[_vm._t("tag",null,{"competition":item})]:[_vm._v(_vm._s(value))]]}},{key:"cell(prices)",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [(_vm.hasSlot('prices'))?[_vm._t("prices",null,{"competition":item})]:(value.length > 0)?_vm._l((_vm.storeOpts),function(store){return [((store.value == _vm.selfStore || _vm.allowMultiStores) && value.filter(function (x) { return x.store_id == store.value; }).length > 0)?_c('p',{key:'comp-price-store-' + store.value,staticClass:"text-sm m-0"},_vm._l((value.filter(function (x) { return x.store_id == store.value; })),function(price){return _c('span',{key:'comp-price-' + price.id,style:(value.filter(function (x) { return x.store_id == store.value; }).length > 1 && price.type == 0 ? "text-decoration: line-through" : '')},[_vm._v(_vm._s(_vm.formatWithCurrency(price.amount, price.currency_code))+" ")])}),0):_vm._e()]}):[_vm._v(_vm._s(_vm.$t('no_data')))]]}},{key:"cell(cash_alt)",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [(_vm.hasSlot('cash'))?[_vm._t("cash",null,{"competition":item})]:(value.length > 0)?[_vm._l((value),function(cash,index){return [(['GBP', 'MXN'].indexOf(cash.currency) == _vm.selfStore - 1 || _vm.allowMultiStores)?_c('span',{key:'cash-alt-' + index,staticClass:"d-block"},[_vm._v(" "+_vm._s((index > 0 && _vm.allowMultiStores ? 'or ' : '') + _vm.formatWithCurrency(cash.amount, cash.currency))+" ")]):_vm._e()]})]:[_vm._v(_vm._s(_vm.$t('no_data')))]]}},{key:"cell(tickets)",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [(_vm.hasSlot('tickets'))?[_vm._t("tickets",null,{"competition":item})]:[_vm._v(_vm._s(value))]]}},{key:"cell(reward_amount)",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [(_vm.hasSlot('reward_amount'))?[_vm._t("reward_amount",null,{"competition":item})]:[_vm._v(_vm._s(value))]]}},{key:"cell(start_time)",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_c('div',{class:['text-warning', 'text-info'][item.store_id - 1]},[_vm._v(_vm._s(_vm.formatLocalDateTime(value)))]),(item.stores[0])?_c('div',{class:['text-warning', 'text-info'][item.stores[0].store_id - 1]},[_vm._v(_vm._s(_vm.formatLocalDateTime(item.stores[0].start_time)))]):_vm._e()]}},{key:"cell(end_time)",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_c('div',{class:['text-warning', 'text-info'][item.store_id - 1]},[_vm._v(_vm._s(_vm.formatLocalDateTime(value)))]),(item.stores[0])?_c('div',{class:['text-warning', 'text-info'][item.stores[0].store_id - 1]},[_vm._v(_vm._s(_vm.formatLocalDateTime(item.stores[0].end_time)))]):_vm._e()]}},{key:"cell(created_at)",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_c('div',{class:['text-warning', 'text-info'][item.store_id - 1]},[_vm._v(_vm._s(_vm.formatLocalDateTime(value)))]),(item.stores[0])?_c('div',{class:['text-warning', 'text-info'][item.stores[0].store_id - 1]},[_vm._v(_vm._s(_vm.formatLocalDateTime(item.stores[0].created_at)))]):_vm._e()]}},(_vm.hasSlot('actions'))?{key:"cell(action)",fn:function(ref){
var item = ref.item;
return [_vm._t("actions",null,{"competition":item})]}}:null],null,true)}),(_vm.items.length > 0)?_c('paginate',{staticClass:"card-footer",attrs:{"queries":_vm.queries,"fetcher":_vm.getList,"total":_vm.competitions.total}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }