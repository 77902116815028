<template>
  <b-modal
    lazy
    centered
    id="admin-create"
    :title="$t('admin.add_admin')"
    title-class="w-100 d-flex justify-content-center align-selfStore-center"
    header-class="py-2"
    footer-class="py-2"
    body-class="py-0"
    @hidden="onReset"
  >
    <b-form @submit.prevent="onSubmit" @reset.prevent="onReset" id="admin-create-form">
      <b-form-group label-for="admin-email" class="m-0">
        <template #label>{{ $t('auth.email') }}<span class="text-danger ml-1">*</span></template>
        <b-form-input id="admin-email" v-model="form.email" type="email" :placeholder="$t('input.enter_email')" required />
      </b-form-group>

      <b-form-group label-for="admin-password" class="mt-3 mb-0">
        <template #label>{{ $t('auth.set_password') }}<span class="text-danger ml-1">*</span></template>
        <b-form-input id="admin-password" v-model="form.password" :placeholder="$t('auth.set_password')" required />
      </b-form-group>

      <b-form-group label-for="admin-name" class="mt-3 mb-0">
        <template #label>{{ $t('general.name') }}<span class="text-danger ml-1">*</span></template>
        <b-form-input id="admin-name" v-model="form.name" :placeholder="$t('input.enter_name')" required />
      </b-form-group>

      <b-form-group label-for="admin-role" class="mt-3 mb-0 d-inline-block w-50 pr-1">
        <template #label>{{ $t('admin.admin_role') }}<span class="text-danger ml-1">*</span></template>
        <b-form-select id="admin-role" v-model="form.role" :options="roles" required />
      </b-form-group>

      <b-form-group label-for="admin-store" class="mt-3 mb-0 d-inline-block w-50 pl-1">
        <template #label>{{ $tc('general.store', 1) }}<span class="text-danger ml-1">*</span></template>
        <b-form-select id="admin-store" :options="storeOpts" :value="selfStore" required disabled />
      </b-form-group>
    </b-form>
    <template #modal-footer="{ hide }">
      <b-button variant="outline-secondary" @click="hide">{{ $t('action.close') }}</b-button>
      <b-button variant="outline-primary" type="submit" form="admin-create-form">{{ $t('action.submit') }}</b-button>
    </template>
  </b-modal>
</template>
<script>
import { mapGetters } from 'vuex'
import storeMixin from '@/mixins/store-mixin'

export default {
  name: 'AdminCreate',
  mixins: [storeMixin],
  props: {
    getList: Function,
  },
  data() {
    return {
      form: {
        email: '',
        password: '',
        name: '',
        role: '',
        store_id: this.$store.getters['store'],
      },
    }
  },
  computed: {
    ...mapGetters({ roleList: 'request/roleList' }),
    roles() {
      return this.roleList?.map((el) => el.name) ?? []
    },
  },
  methods: {
    onReset() {
      this.form.email = ''
      this.form.password = ''
      this.form.name = ''
      this.form.role = ''
      this.form.store_id = this.selfStore
    },
    onSubmit() {
      const params = {
        ...this.form,
        successMsg: this.$t('admin.add_admin_notice[0]'),
        errorMsg: this.$t('admin.add_admin_notice[1]'),
      }
      this.$store.dispatch('request/createAdmin', params).then((res) => {
        if (res.status === 200) {
          this.getList()
          this.$bvModal.hide('admin-create')
        }
      })
    },
  },
  created() {
    this.form.store_id = this.selfStore
  },
}
</script>
<style scoped>
.form-group label {
  font-size: 0.8rem;
}
</style>
