<template>
  <card type="default" header-classes="bg-transparent">
    <loading :active="isLoading" :can-cancel="false" :is-full-page="false" />
    <TimeSelect :displayInfo="{ name: $t('dashboard.chart_name[4]') }" :initBigChart="initBigChart" type="userChannels" :queryParams="queryParams" />
    <BarChart :height="350" ref="bigChart" :chart-data="chartModel.chartData" :options="chartModel.extraOptions" />
  </card>
</template>
<script>
import { barChartOptions } from '@/components/Charts/config'
import BarChart from '@/components/Charts/BarChart'
import TimeSelect from '@/components/Charts/TimeSelect.vue'

export default {
  name: 'UserChannels',
  components: { TimeSelect, BarChart },
  data() {
    return {
      isLoading: false,
      queryParams: {},
      chartModel: { chartData: {}, extraOptions: barChartOptions },
      rawData: [],
      colors: ['#4267B2', '#DB4437', '#F4B400'],
    }
  },
  methods: {
    async getUserChannels() {
      this.isLoading = true
      await this.$store
        .dispatch('request/getUserChannels', this.queryParams)
        .then(() => (this.isLoading = false))
        .catch(() => (this.isLoading = false))
      this.rawData = this.$store.getters['request/userChannels']
    },

    initBigChart() {
      this.getUserChannels().then(() => {
        this.chartModel.chartData = {
          datasets: [
            {
              label: this.$t('dashboard.registered_user_channels[0]'),
              data: this.rawData.map((val) => val.fb),
              borderColor: this.colors[0],
              backgroundColor: this.colors[0],
            },
            {
              label: this.$t('dashboard.registered_user_channels[1]'),
              data: this.rawData.map((val) => val.google),
              borderColor: this.colors[1],
              backgroundColor: this.colors[1],
            },
            {
              label: this.$t('dashboard.registered_user_channels[2]'),
              data: this.rawData.map((val) => val.website),
              borderColor: this.colors[2],
              backgroundColor: this.colors[2],
            },
          ],
          labels: this.rawData.map((val) => this.$moment(val.day).format('DD/MM')),
        }
      })
    },
  },
  // queryParams will be initialized when TimeSelect created
  mounted() {
    this.initBigChart()
  },
}
</script>
