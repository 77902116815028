<template>
  <b-form @submit.prevent="onSubmit" id="banner-create-form" class="m-2 px-4 py-3 d-flex flex-column">
    <loading :active="isLoading" :can-cancel="false" :is-full-page="false" />
    <b-row cols="1" cols-xl="2" class="pb-4 rounded bg-white">
      <b-col class="mt-4">
        <div class="d-flex flex-row flex-nowrap align-items-center">
          <label class="text-sm align-self-center form-label-required">{{ $t('banner.ref_type') }}</label>
          <b-form-select v-model="configurable_type" required :disabled="isEditRoute" class="d-inline-block ml-2" style="min-width: 5rem; width: fit-content">
            <b-form-select-option selected hidden disabled :value="null">{{ $t('input.empty') }}</b-form-select-option>
            <b-form-select-option :value="1">{{ $t('banner.ref_options[0]') }}</b-form-select-option>
            <b-form-select-option :value="2" :disabled="configurable_type !== 2">{{ $t('banner.ref_options[1]') }}</b-form-select-option>
          </b-form-select>
        </div>
        <p class="m-0 text-danger text-sm" v-if="!configurable_type">⚠ {{ $t('banner.ref_notice') }}</p>
        <b-form-group :label="$t('general.start_time')" label-class="text-sm form-label-required" class="mt-3 mb-0 mx-0">
          <b-form-input type="datetime-local" :placeholder="$t('input.choose_date')" required :state="timeValid" :disabled="!configurable_type" v-model="start_at" />
        </b-form-group>
        <b-form-group :label="$t('general.end_time')" label-class="text-sm form-label-required" class="mt-3 mb-0 mx-0">
          <b-form-input type="datetime-local" :placeholder="$t('input.choose_date')" required :state="timeValid" :disabled="!configurable_type" v-model="end_at" />
        </b-form-group>
        <b-form-invalid-feedback :state="timeValid">{{ $t('banner.time_validation') }}</b-form-invalid-feedback>

        <b-form-group :label="$t('banner.link')" label-class="text-sm" class="mt-3 mb-0 mx-0">
          <b-form-input type="url" :placeholder="$t('banner.link_notice')" :disabled="configurable_type !== 2" v-model="details" />
        </b-form-group>

        <div class="mt-3 w-100 d-flex flex-column flex-xl-row justify-content-xl-between">
          <b-form-group :label="$tc('general.store', 1)" label-class="text-sm form-label-required" class="flex-grow-1 d-inline-block m-0">
            <b-form-select v-model="store_id" :options="storeOpts" required :disabled="!configurable_type || !allowMultiStores || (configurable && configurable['allow_multi_stores'] !== 1)" />
          </b-form-group>
          <b-form-group :label="$t('general.position')" label-class="text-sm" class="flex-grow-1 d-inline-block mx-0 mb-0 mt-3 mt-xl-0 ml-xl-2">
            <b-form-input type="number" step="1" min="0" max="30" :placeholder="$t('input.by_default', { val: 0 })" :disabled="!configurable_type" v-model="position" />
          </b-form-group>
          <b-form-group label-class="text-sm" class="flex-grow-1 d-inline-block mx-0 mb-0 mt-3 mt-xl-0 ml-xl-2">
            <template #label>{{ $t('general.status') }}</template>
            <b-form-select v-model="status" :disabled="!configurable_type">
              <b-form-select-option selected :value="null">{{ $t('input.empty') }}</b-form-select-option>
              <b-form-select-option v-for="value in [-1, 0, 1]" :key="value" :value="value">{{ $t('banner.status_list')[value + 1] }}</b-form-select-option>
            </b-form-select>
          </b-form-group>
        </div>
      </b-col>
      <b-col class="mt-4">
        <label v-if="configurable_type === 1" class="text-sm align-self-center" style="font-size: 0.8rem">
          {{ $tc('general.competition', 1) }}<span class="text-danger ml-1">*</span>
          <template v-if="!isEditRoute">
            <b-button variant="outline-primary" class="ml-3" v-b-modal.competition-lookup>{{ $t('competition.comp_lookup') }}</b-button>
            <b-button variant="outline-danger" class="ml-2" @click="onRemove">{{ $t('action.remove') }}</b-button>
          </template>
        </label>
        <CompetitionDetails v-if="configurable_type === 1 && configurable" :competition="configurable" class="mt-4">
          <template #title>
            <span class="action" @click="pushCompDetails(configurable)">{{ configurable.title }} </span>
            <CompetitionStatus :value="configurable['status']" class="ml-2" />
          </template>
        </CompetitionDetails>
      </b-col>
    </b-row>

    <b-row class="mt-4">
      <b-tabs v-model="tabIndex" lazy small class="w-100" active-nav-item-class="bg-white text-primary" nav-wrapper-class="bg-transparent" nav-class="text-lg font-weight-bold" ref="storeTabs">
        <template v-for="(store, index) in stores">
          <b-tab
            v-if="index === 0 || (configurable && configurable.allow_multi_stores)"
            :disabled="!allowMultiStores && store.store_id !== selfStore"
            :active="store.store_id == store_id"
            :key="'store-tab-' + index"
            :class="`m-0 py-3 bg-white ${store.store_id ? 'rounded-right rounded-bottom' : 'rounded'}`"
            title-link-class="d-flex flex-row align-items-center"
            :title-item-class="store.store_id ? '' : 'd-none'"
            :title="store_id ? [$t('uk'), $t('address.mexico')][store.store_id - 1] : null">
            <div class="w-100 px-3 d-flex flex-row align-items-center justify-content-between">
              <span class="text-sm">{{ $t('image.image_file') }}</span>
              <div v-if="hasFile">
                <b-button variant="outline-primary" class="ml-2" @click="uploadAll" :disabled="allUploaded || !allSizeValid"> <b-icon-upload class="mr-1" />{{ $t('image.upload_all') }} </b-button>
                <b-button variant="outline-danger" class="ml-2" @click="resetAll"><b-icon-x class="mr-1" />{{ $t('action.reset') }}</b-button>
              </div>
            </div>
            <template v-if="hasFile">
              <p class="m-0 px-3 text-danger text-sm" v-if="!allSizeValid">⚠ {{ $t('image.size_notice') }}</p>
              <p class="m-0 px-3 text-slack text-sm">⚠ {{ $t('image.dimension_notice') }}</p>
              <p class="m-0 px-3 text-slack text-sm">⚠ {{ $t('image.replace_notice') }}</p>
            </template>
            <b-table
              :fields="fields"
              :items="banners"
              :busy="isLoading"
              ref="banners-table"
              show-empty
              :empty-text="$t('notify.table_no_records')"
              small
              hover
              responsive
              head-variant="light"
              class="mt-2 mx-0 p-0">
              <template #head()="{ label, field }">{{ label }}<b-icon-question-circle-fill v-if="field.tooltip" class="ml-1" v-b-tooltip.hover.topright="field.tooltip" /></template>
              <template #cell(preview)="{ index, item }">
                <b-img-lazy
                  :ref="item.device + 'Img'"
                  :src="item.image_url ? item.image_url : ''"
                  @load.native="updateDimensions(item, index)"
                  @unload.native="updateDimensions(item, index)"
                  fluid
                  block
                  rounded
                  style="max-height: 4rem" />
              </template>
              <template #cell(image_url)="{ value }">
                <span v-b-tooltip.hover.right="value">{{ value }}</span>
              </template>
              <template #cell(file)="{ item }">
                <b-form-file size="sm" accept="image/*" v-model="item.file" :disabled="!configurable_type" class="d-inline-block text-truncate" style="width: 240px" />
              </template>
              <template #cell(action)="{ index: i, item }">
                <b-button variant="outline-primary" size="xs" @click="uploadFile(i)" :disabled="item.uploaded || !item.file || item.file.size >= 614400"><b-icon-upload /></b-button>
                <b-button variant="outline-danger" size="xs" @click="initFile(item, existingBanners)" :disabled="item.uploaded || !item.file"><b-icon-trash /></b-button>
              </template>
            </b-table>
          </b-tab>
        </template>
      </b-tabs>
    </b-row>
    <div class="row d-flex justify-content-end mt-3">
      <b-button variant="primary" type="submit" :disabled="!isReady">{{ $t('action.submit') }}</b-button>
      <b-button variant="basic" @click="$router.back()">{{ $t('action.cancel') }}</b-button>
    </div>

    <b-modal
      lazy
      centered
      id="competition-lookup"
      :title="$t('competition.comp_lookup')"
      title-class="w-100 d-flex justify-content-center align-self-center"
      header-class="py-2"
      body-class="pt-0"
      hide-footer>
      <CompetitionList :fields="compFields" :queries="{ store_ids: null, title: null, status: 1, page: 1, perPage: 10 }" :rowClicked="rowClicked"
        ><template #actions="{ competition }">
          <b-button variant="outline-primary" @click="onSelect(competition)" :disabled="configurable ? competition.id === configurable.id : false">
            {{ $t('action.select') }}
          </b-button>
        </template>
      </CompetitionList>
    </b-modal>
  </b-form>
</template>
<script>
import CompetitionDetails from '@/components/Competitions/CompetitionDetails.vue'
import CompetitionList from '@/components/Competitions/CompetitionList.vue'
import routerMixin from '@/mixins/router-mixin'
import storeMixin from '@/mixins/store-mixin'
import utilsMixin from '@/mixins/utils-mixin'
import { AwsCreateInvalidation, AwsUpdate } from '@/store/services/aws'
import { formatLocalDateTime, formatUtcDateTime } from '@/utils/dateTimeUtils'
import { base64Encode } from '@/utils/fileUtils'
import { getRandomStr } from '@/utils/index'
import { formatBytes } from '@/utils/numberUtils'
import { isEqual } from 'lodash'
import { mapGetters } from 'vuex'
import CompetitionStatus from '../Competitions/CompetitionStatus.vue'

export default {
  name: 'BannerUpsert',
  mixins: [
    utilsMixin,
    routerMixin,
    storeMixin, // selfStore, allowMultiStores
  ],
  components: { CompetitionList, CompetitionDetails, CompetitionStatus },
  props: {
    initVal: {
      configurable: { type: Object, default: null },
      banners: { type: Array, default: null },
    },
  },
  data() {
    // use unicode \u00D7 to represent multiplication sign × to support HTML render and JS string value comparison
    const template = [
      { device: 'desktop', image_url: null, file: null, uploaded: false, pixel: null, required: { width: 3000, height: 750 }, start_at: null, end_at: null },
      { device: 'tablet', image_url: null, file: null, uploaded: false, pixel: null, required: { width: 1920, height: 675 }, start_at: null, end_at: null },
      { device: 'mobile', image_url: null, file: null, uploaded: false, pixel: null, required: { width: 1035, height: 475 }, start_at: null, end_at: null },
    ]
    return {
      ranStr: null,
      isLoading: false,
      configurable_type: null,
      details: null,
      configurable: null,
      start_at: null,
      end_at: null,
      position: null,
      status: null, // -1 removed, 0 draft, 1 published
      store_id: null, // 1 uk, 2 mex
      tabIndex: null,
      banners: template.map((x) => ({ ...x })),
      template: template,
    }
  },
  computed: {
    ...mapGetters({ bannerDetails: 'request/bannerDetails', compDetails: 'request/competitionDetails' }),
    existingBanners() {
      return this.bannerDetails?.banners ?? this.bannerDetails ?? null
    },
    isEditRoute() {
      return this.$route.path.indexOf('/edit') > -1
    },
    files() {
      let tmp = []
      this.banners.map((x) => tmp.push({ device: x.device, file: x.file, filename: x.file?.name }))
      return tmp
    },
    hasFile() {
      return !this.banners.every((x) => !x.file) // not every banner has empty file
    },
    allSizeValid() {
      return this.banners.filter((x) => x.file).every((x) => x.file.size < 614400) // every banner that has a file has valid file size
    },
    allUploaded() {
      return this.banners.every((x) => x.uploaded) // every banner is uploaded
    },
    isReady() {
      // validate competition lookup
      if (this.configurable_type === 1) {
        return this.configurable && this.allUploaded
      } else {
        return this.allUploaded
      }
    },
    timeValid() {
      if (this.start_at && this.end_at) {
        return formatUtcDateTime(this.end_at) > formatUtcDateTime(this.start_at) && (this.configurable ? formatUtcDateTime(this.end_at) <= this.configurable.end_time : true)
      } else if (!this.start_at && !this.end_at) {
        return null
      } else {
        return false
      }
    },
    stores() {
      const arr = [{ store_id: this.store_id, banners: this.banners }]
      const otherStore = this.template.map((x) => ({ ...x }))
      const tmp = this.existingBanners?.filter((banner) => banner.store_id !== this.store_id) ?? []
      if (tmp.length > 0) {
        otherStore.forEach((banner) => this.initFile(banner, tmp))
        arr.push({ store_id: [1, 2].find((x) => x !== this.store_id), banners: otherStore })
        tmp.sort((a, b) => Math.abs(a.store_id - this.configurable?.store_id) - Math.abs(b.store_id - this.configurable?.store_id))
      }
      return arr
    },
    fields() {
      return [
        { key: 'file', label: this.$t('input.choose_file'), tooltip: this.$t('image.replace_notice'), class: 'pl-3' },
        { key: 'device', label: this.$t('image.formatted_name'), formatter: (v) => v + (this.configurable ? '-' + this.configurable.id : '') + '-' + this.store_id + '-' + this.ranStr },
        { key: 'preview', label: this.$t('general.preview'), class: 'text-center' },
        this.hasFile
          ? {
              key: 'pixel',
              label: this.$t('image.current_dimensions'),
              tooltip: this.$t('image.dimension_notice'),
              formatter: (v, k, i) => (i.loaded ? `${v.width}\u00D7${v.height}px\n(${parseFloat(v.width / v.height).toFixed(2)}:1)` : '...'),
              class: 'text-center',
              thStyle: 'width: 140px; white-space: normal',
              tdAttr: { style: 'white-space: break-spaces' },
            }
          : null,
        this.hasFile
          ? {
              key: 'file.size',
              label: this.$t('general.size') + ' (<600kb)',
              tooltip: this.$t('image.size_notice'),
              formatter: (v) => formatBytes(v),
              tdClass: (v) => (v >= 614400 ? 'text-danger' : ''),
            }
          : null,
        { key: 'image_url', label: this.$t('url'), tdClass: 'text-truncate' },
        { key: 'action', label: this.$tc('general.action', 1), class: 'pr-3' },
      ]
    },
    compFields() {
      return [
        { key: 'title', label: this.$t('general.title'), stickyColumn: true },
        { key: 'image', label: this.$t('general.top_image') },
        { key: 'store_id', label: this.$tc('general.store', 1), class: 'text-center' },
        { key: 'status', label: this.$t('general.status') },
        { key: 'start_time', label: this.$t('general.start_time'), tdAttr: { style: 'cursor: pointer' }, sortable: true, sortKey: 'start_time', formatter: (v) => formatLocalDateTime(v) },
        { key: 'end_time', label: this.$t('general.end_time'), tdAttr: { style: 'cursor: pointer' }, sortable: true, sortKey: 'end_time', formatter: (v) => formatLocalDateTime(v) },
        { key: 'action', label: this.$tc('general.action', 1) },
      ]
    },
  },
  methods: {
    init(val) {
      this.configurable = val.configurable // competition
      this.store_id = this.allowMultiStores ? val.configurable?.store_id ?? val.banners[0]?.store_id ?? this.selfStore : this.selfStore

      const tmpBanners = val.banners.filter((x) => x.store_id === this.store_id)
      this.configurable_type = tmpBanners[0]?.configurable_type ? 1 : this.configurable_type ?? 2

      const tmpStart = tmpBanners[0]?.start_at ?? val.configurable?.start_time
      this.start_at = formatLocalDateTime(tmpStart, 1, false, true)

      const tmpEnd = tmpBanners[0]?.end_at ?? val.configurable?.end_time
      this.end_at = formatLocalDateTime(tmpEnd, 1, false, true)

      this.details = tmpBanners[0]?.details ?? val.configurable?.url
      this.position = tmpBanners[0]?.position
      this.status = tmpBanners[0]?.status

      // formatted name
      if (tmpBanners[0]?.image_url) {
        this.ranStr = tmpBanners[0].image_url.substring(tmpBanners[0].image_url.lastIndexOf('.') - 4, tmpBanners[0].image_url.lastIndexOf('.'))
      } else {
        this.ranStr = getRandomStr(4)
      }

      this.banners.forEach((banner, i) => this.initFile(banner, tmpBanners))
    },

    // call when init single banner, switch store, on remove one banner
    initFile(banner, banners) {
      banner['file'] = null
      banner['pixel'] = null
      banner['loaded'] = false
      if (!banners) {
        banner['image_url'] = null
        banner['uploaded'] = false
        return
      } else {
        const tmp = banners.filter((x) => x.store_id == this.store_id).find((x) => x.image_url.toLowerCase().includes(banner.device))
        banner['id'] = tmp?.id ?? null
        banner['image_url'] = tmp?.image_url ?? null
        banner['uploaded'] = !!tmp?.image_url
        return
      }
    },

    // <img> onload
    updateDimensions(item, index) {
      if (item.image_url) {
        const domEl = this.$refs[`${item.device}Img`]?.[0]?.$el
        item['pixel'] = domEl ? { width: domEl.naturalWidth, height: domEl.naturalHeight } : null
        item['loaded'] = true
        const table = this.$refs[`banners-table`]?.[this.tabIndex]
        if (table) {
          table.refresh()
        }
      }
    },

    getComp(comp) {
      const data = { ...comp }
      this.isLoading = true
      this.$store
        .dispatch('request/getCompetitionDetails', { id: comp.id })
        .then(() => {
          this.isLoading = false
          for (const key in this.compDetails) {
            if (key !== 'banners') {
              data[key] = this.compDetails[key]
            }
          }
        })
        .catch(() => (this.isLoading = false))
      return data
    },

    // only call on banner create page because comp lookup modal is disabled on banner edit page
    onSelect(obj) {
      // has banner details of the selected comp in state tree already
      if (obj.id === this.bannerDetails?.id) {
        let data = { ...obj } // basic comp info
        for (const key in this.bannerDetails) {
          if (key !== 'banners') {
            data[key] = this.bannerDetails[key]
          }
        }
        data = this.getComp(data) // detailed comp info
        this.init({ configurable: data, banners: this.bannerDetails.banners ?? [] })
      } else {
        // doesn't have banner details of the selected comp in state tree
        this.isLoading = true
        this.$store
          .dispatch('request/getCompetitionBanners', { competition_id: obj.id })
          .then(() => {
            this.isLoading = false
            let data = { ...obj }
            for (const key in this.bannerDetails) {
              if (key !== 'banners') {
                data[key] = this.bannerDetails[key]
              }
            }
            data = this.getComp(data)
            this.init({ configurable: data, banners: this.bannerDetails.banners ?? [] })
          })
          .catch(() => (this.isLoading = false))
      }
    },

    // remove selected competition
    onRemove() {
      this.init({ configurable: null, banners: [] })
    },

    rowClicked(item, index, evt) {
      if (evt.target.cellIndex > 3) {
        this.onSelect(item)
      }
    },

    onUpload(banner) {
      const rename = banner.device + (this.configurable ? '-' + this.configurable.id : '') + '-' + this.store_id + '-' + this.ranStr + banner.file.name.substring(banner.file.name.lastIndexOf('.'))
      return AwsUpdate('rk-banners', banner.file, rename).then((res) => {
        if (res && res.Location) {
          banner.image_url = res.Location
          banner.uploaded = true
          this.$notify({ group: 'root', type: 'success', title: 'Success', text: this.$t('image.upload_notice[0]') })
        } else {
          this.$notify({ group: 'root', type: 'error', title: 'Error', text: this.$t('notify.unknown_err') })
        }
      })
    },

    // handler to upload single file
    uploadFile(i) {
      if (this.banners[i].file && !this.banners[i].uploaded) {
        this.isLoading = true
        this.onUpload(this.banners[i])
          .then(() => (this.isLoading = false))
          .catch(() => (this.isLoading = false))
      } else {
        this.$notify({ group: 'root', type: 'warn', text: this.$t('image.file_notice') })
        return
      }
    },

    // handler to upload all files
    uploadAll() {
      this.isLoading = true
      this.banners
        .filter((banner) => banner.file && !banner.uploaded)
        .forEach((banner) => {
          this.onUpload(banner)
            .then(() => (this.isLoading = !this.allUploaded))
            .catch(() => (this.isLoading = false))
        })
    },

    resetAll() {
      this.banners.forEach((banner) => this.initFile(banner, this.existingBanners))
    },

    async onSubmit() {
      this.$bvModal.hide('confirm')
      let isNew = true
      const payload = []
      const cache = []
      this.banners
        .filter((banner) => banner.uploaded)
        .forEach(({ device, file, uploaded, ...rest }, i) => {
          // test if cache clearing is needed: has a new file uploaded and an existing image from the server at the same time
          const tmp = this.existingBanners?.find((x) => x.id === rest.id)?.image_url ?? null
          if (file && uploaded && tmp) {
            cache.push(tmp.slice(tmp.indexOf('/rk-banners')))
          }
          // payload formatting
          payload[i] = rest
          if (this.configurable_type === 1) {
            payload[i].configurable_type = 'App\\Models\\Competitions\\Competition'
            payload[i].configurable_id = this.configurable.id
          }
          if (this.store_id) {
            payload[i].store_id = this.store_id
          }
          if (this.position) {
            payload[i].position = this.position
          }
          if (this.status !== null) {
            payload[i].status = this.status
          }
          if (this.details !== null) {
            payload[i].details = this.details
          }
          payload[i].start_at = formatUtcDateTime(this.start_at)
          payload[i].end_at = formatUtcDateTime(this.end_at)
          // is new banners to be created if no id exists
          isNew = isNew && !payload[i].id
        })
      // clear cache if needed
      let successMsg = this.$t('banner.update_banner_notice[1]')
      if (cache.length > 0) {
        const res = await AwsCreateInvalidation(cache)
        if (res) successMsg = this.$t('banner.update_banner_notice[0]')
      }
      // do not update image from other store
      const otherStore = this.existingBanners?.filter((banner) => banner.store_id !== this.store_id) ?? []
      if (otherStore.length > 0) {
        otherStore.forEach((item) => payload.push(item))
      }
      // post
      this.isLoading = true
      this.$store
        .dispatch('request/upsertBanner', {
          payload: payload,
          successMsg: isNew ? this.$t('banner.create_banner_notice[0]') : successMsg,
          errorMsg: isNew ? this.$t('banner.create_banner_notice[1]') : this.$t('banner.update_banner_notice[2]'),
        })
        .then((res) => {
          this.isLoading = false
          if (res.status === 200) {
            this.$router.push({ name: 'Banners', params: { refresh: cache.length > 0 } }) // boolean
          }
        })
        .catch(() => (this.isLoading = false))
    },
  },
  watch: {
    configurable_type(newVal, oldVal) {
      // only banner create page can enable reference type select
      if (newVal === 2 && !this.isEditRoute) {
        this.onRemove()
      }
    },
    store_id(newVal, oldVal) {
      const tmp = this.existingBanners?.filter((banner) => banner.store_id == newVal)
      this.banners.forEach((banner) => this.initFile(banner, tmp))
      this.$refs.storeTabs.tabs[this.stores.findIndex((x) => x.store_id == newVal)]?.activate()
      // this.tabIndex = this.stores.findIndex((x) => x.store_id == newVal)
    },
    tabIndex(newVal, oldVal) {
      this.store_id = this.stores[newVal]?.store_id
    },
    files(newVal, oldVal) {
      const i = newVal.findIndex((x, i) => !isEqual(oldVal[i].file, x.file))
      if (i > -1 && this.banners[i].file) {
        this.banners[i]['loaded'] = false
        base64Encode(this.banners[i].file).then((res) => {
          this.banners[i]['image_url'] = res
          this.banners[i]['uploaded'] = false
        })
      }
    },
  },
  mounted() {
    if (this.initVal) {
      this.init(this.initVal)
    } else {
      this.store_id = this.selfStore
      this.ranStr = getRandomStr(4)
      this.configurable_type = 1
    }
    this.isLoading = false
  },
}
</script>
<style scoped>
::v-deep .modal-dialog {
  width: fit-content;
  min-width: 45%;
  max-width: 65%;
}

@media screen and (max-width: 1024px) {
  ::v-deep .modal-dialog {
    min-width: 60%;
    max-width: 80%;
  }
}
</style>
