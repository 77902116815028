var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('card',{staticClass:"table-custom",attrs:{"body-classes":"p-0 d-flex flex-column"}},[_c('loading',{attrs:{"active":_vm.isLoading,"can-cancel":false,"is-full-page":false}}),_c('BannerSearchInput',{attrs:{"queryParams":_vm.queryParams,"getter":_vm.getBannerList,"extraCls":"px-4 pt-3 pb-2"}}),_c('b-table',{ref:"banners-table",staticClass:"m-0 p-0",attrs:{"show-empty":"","empty-text":_vm.$t('notify.table_no_records'),"small":"","hover":"","responsive":"","head-variant":"light","primary-key":"id","fields":_vm.fields,"items":_vm.items,"busy":_vm.isLoading,"sticky-header":"100%"},scopedSlots:_vm._u([{key:"cell(image_url)",fn:function(ref){
var index = ref.index;
var value = ref.value;
var item = ref.item;
return [_c('b-img-lazy',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.left",value:(value),expression:"value",modifiers:{"hover":true,"left":true}}],ref:'banner' + item.id,staticStyle:{"height":"3rem","object-fit":"contain"},attrs:{"src":value,"alt":_vm.shortenString(value, 13, true),"fluid":"","block":""},nativeOn:{"load":function($event){return _vm.updateDimension(item, index)}}})]}},{key:"cell(details)",fn:function(ref){
var value = ref.value;
return [(value)?_c('b-link',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",value:(value),expression:"value",modifiers:{"hover":true,"right":true}}],attrs:{"href":("" + value),"target":"_blank"}},[_c('b-icon-link45deg',{staticClass:"mr-1"}),_vm._v(_vm._s(value))],1):_vm._e()]}},{key:"cell(store_id)",fn:function(ref){
var value = ref.value;
return [_c('store-badge',{attrs:{"store_id":value}})]}},{key:"cell(status)",fn:function(ref){
var value = ref.value;
return [_c('b-badge',{attrs:{"pill":"","variant":['danger', 'info', 'success'][value + 1]}},[_vm._v(_vm._s(_vm.$t('banner.status_list')[value + 1]))])]}},{key:"cell(reference_name)",fn:function(ref){
var item = ref.item;
return [(item.configurable)?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",value:(item.configurable.title),expression:"item.configurable.title",modifiers:{"hover":true,"right":true}}],staticClass:"action",on:{"click":function($event){return _vm.pushCompDetails(item.configurable)}}},[_vm._v(" "+_vm._s(item.configurable.title)+" ")]):_vm._e()]}},{key:"cell(configurable)",fn:function(ref){
var value = ref.value;
return [(value)?_c('CompetitionStatus',{attrs:{"value":value.status}}):_vm._e()]}},{key:"cell(action)",fn:function(ref){
var item = ref.item;
return [_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(_vm.$t('action.edit')),expression:"$t('action.edit')",modifiers:{"hover":true,"top":true}}],on:{"click":function($event){_vm.$router.push({
            name: 'Banner Edit',
            query: item.configurable_id ? { competition_id: item.configurable_id, store_id: item.store_id } : { refer: encodeURIComponent(item.refer) },
            params: { configurable: item.configurable },
          })}}},[_c('b-icon-pencil')],1)]}}])}),(_vm.items.length > 0)?_c('paginate',{staticClass:"card-footer",attrs:{"queries":_vm.queryParams,"fetcher":_vm.getBannerList,"total":_vm.bannerList.total}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }