<template>
  <b-badge pill :variant="['danger', 'secondary', 'success', 'info', 'primary', 'dark'][value + 1]">
    {{ $t('competition.status_list')[value + 1] }}
  </b-badge>
</template>
<script>
export default {
  name: 'CompetitionStatus',
  props: {
    value: Number,
  },
}
</script>
