<template>
  <div style="display: contents">
    <loading :active="isLoading" :can-cancel="false" :is-full-page="false" />

    <UserInfo class="mx-4 my-3 pt-4 card-custom" v-if="orderDetails" :getOrderDetails="getOrderDetails" :successPayment="successPayment" />

    <EntryList class="mt-3 mx-4" v-if="orderDetails" :openWalletRefund="openWalletRefund" :openRefund="openRefund" :successPayment="successPayment" />

    <PaymentList class="mt-3 mx-4" v-if="orderDetails && orderDetails.payments.length > 0" :openRefund="openRefund" />

    <EmailList class="mt-3 mx-4" v-if="emailList && emailList.data.length > 0" />

    <b-button class="my-3 ml-4" @click="$router.back()"><b-icon-chevron-left class="mr-1" />{{ $t('action.back_to_prev') }}</b-button>

    <confirm-modal id="confirm-refund" :title="$t('order.refund_confirm')" :message="$t('order.refund_transaction')" :isLoading="modalLoading" :onSubmit="onRefund" />
  </div>
</template>
<script>
import EmailList from '@/components/Orders/EmailList.vue'
import EntryList from '@/components/Orders/EntryList.vue'
import PaymentList from '@/components/Orders/PaymentList.vue'
import UserInfo from '@/components/Orders/UserInfo.vue'
import { notifyError } from '@/utils/index'
import { mapGetters } from 'vuex'
import axios from '../../plugins/axios'
import { API_LIST } from '../../utils/consts'

export default {
  components: {
    UserInfo,
    EntryList,
    PaymentList,
    EmailList,
  },
  data() {
    return {
      isLoading: true,
      modalLoading: false,
      refundTarget: null,
      orderDetailsId: this.$route.query.orderDetailsId,
    }
  },
  computed: {
    ...mapGetters({ orderDetails: 'request/orderDetails', emailList: 'request/emailList' }),

    successPayment() {
      if (this.orderDetails && this.orderDetails.payments.length === 1) {
        return this.orderDetails.payments[0]
      } else if (this.orderDetails && this.orderDetails.payments.length > 1) {
        if (this.orderDetails.status === -1 || this.orderDetails.status === -3) {
          return this.orderDetails.payments.filter((payment) => payment['status'] === 'failed')[0]
        } else if (this.orderDetails.status === -2) {
          return this.orderDetails.payments.filter((payment) => payment['status'] === 'refunded')[0]
        } else if (this.orderDetails.status >= 3) {
          return this.orderDetails.payments.filter((payment) => payment['status'] === 'succeeded')[0]
        } else if (this.orderDetails.payments.status === 1) {
          return this.orderDetails.payments.filter((payment) => payment['status'] === 'pending')[0]
        } else {
          return {}
        }
      } else {
        return {}
      }
    },
  },
  methods: {
    getOrderDetails() {
      this.isLoading = true
      this.$store
        .dispatch('request/getOrderDetails', { id: this.orderDetailsId })
        .then(() => (this.isLoading = false))
        .catch(() => (this.isLoading = false))
    },

    getEmailList() {
      this.isLoading = true
      this.$store
        .dispatch('request/getEmailList', { order_id: this.orderDetailsId })
        .then(() => (this.isLoading = false))
        .catch(() => (this.isLoading = false))
    },

    openRefund(id) {
      this.$bvModal.show('confirm-refund')
      this.modalLoading = false
      this.refundTarget = { id, action: 'payment' }
    },

    openWalletRefund(credit_id, cash_id) {
      this.$bvModal.show('confirm-refund')
      this.modalLoading = false
      this.refundTarget = { credit_id, cash_id, action: 'wallet' }
    },

    onRefund() {
      this.modalLoading = true
      const { action, ...rest } = this.refundTarget
      console.log('### ', action, rest)
      axios
        .post(API_LIST.post[action == 'payment' ? 'refundPayment' : 'refundWallet'], rest)
        .then((res) => {
          if (res?.data?.code == 0) {
            this.getOrderDetails()
            this.$notify({ group: 'root', type: 'success', text: this.$t('notify.success') })
          } else {
            this.$notify({ group: 'root', type: 'error', text: this.$t('order.refund_notice') })
          }
        })
        .catch((error) => {
          notifyError(error, this.$t('order.refund_notice'))
        })
        .finally(() => {
          this.modalLoading = false
          this.$bvModal.hide('confirm-refund')
        })
    },
  },

  created() {
    this.getOrderDetails()
    this.getEmailList()
  },
}
</script>
<style scoped>
.badge {
  font-size: 0.7rem;
  vertical-align: middle;
}
.split {
  border: 0px;
}
p {
  font-size: 0.875rem;
}
::v-deep .modal-dialog {
  width: fit-content;
  min-width: 50%;
  max-width: 75%;
}
::v-deep .quiz {
  width: fit-content;
  min-width: 30% !important;
  max-width: 50% !important;
}
::v-deep .table .align-top {
  vertical-align: top !important;
}
::v-deep .detailTable .custom-td {
  padding-left: 0.5rem;
  width: 100px !important;
}
::v-deep .btn-reset {
  height: 28px;
}
@media screen and (max-width: 576px) {
  .line {
    width: 100%;
  }
}
@media (min-width: 577px) {
  .line {
    width: 50%;
  }
}
::v-deep .amount {
  width: 180px !important;
  display: inline-block;
}
</style>
