<template>
  <b-modal
    id="edit-winner"
    :title="$t('winner.edit_winner')"
    lazy
    centered
    title-class="w-100 d-flex justify-content-center align-self-center"
    header-class="py-2"
    footer-class="py-2"
    body-class="py-0"
    @shown="onShown"
    @hidden="onHidden"
    :busy="isLoading"
    ><b-form @submit.prevent="onSubmit" id="winner-edit-form">
      <b-form-group label-class="text-sm" class="m-0">
        <template #label>
          {{ $t('competition.comp_title') }}<span class="text-danger ml-1">*</span>
          <b-icon-question-circle-fill class="ml-1" v-b-tooltip.hover.topright="$t('notify.translate_notice[0]')" />
        </template>
        <b-form-input v-model="title" required />
      </b-form-group>

      <b-form-group label-class="text-sm" class="w-50 pr-2 mt-3 mb-0 d-inline-block">
        <template #label>
          {{ $t('winner.winner_name') }}<span class="text-danger ml-1">*</span>
          <b-icon-question-circle-fill class="ml-1" v-b-tooltip.hover.topright="$t('notify.translate_notice[0]')" />
        </template>
        <b-form-input v-model="display_name" required />
      </b-form-group>

      <b-form-group :label="$tc('competition.price', 1)" label-class="text-sm form-label-required" class="w-50 pl-2 mt-3 mb-0 d-inline-block">
        <b-input-group>
          <b-form-select :value="winner ? winner.user.store_id : 1" disabled style="width: 80px; max-width: 80px">
            <b-form-select-option value="1">{{ $t('gbp') }}</b-form-select-option>
            <b-form-select-option value="2">{{ $t('mxn') }}</b-form-select-option>
          </b-form-select>
          <b-form-input v-model="price" required number type="number" step="0.0001" min="0" />
        </b-input-group>
      </b-form-group>

      <b-form-group label-class="text-sm" class="mt-3 mb-0">
        <template #label>
          {{ $t('winner.winner_image') }}<span class="text-danger ml-1">*</span>
          <b-icon-question-circle-fill class="ml-1" v-b-tooltip.hover.topright="$t('image.url_notice')" />
        </template>
        <b-form-input v-model="image" :required="showConfirm && !existingImageSrc" :disabled="!!existingImageSrc" type="url" :placeholder="$t('image.url_notice')" />
        <b-form-file
          accept="image/*"
          v-model="file"
          :placeholder="$t('input.no_file_chosen')"
          :browse-text="$t('input.choose_file')"
          :file-name-formatter="(files) => shortenString(files[0]['name'], 30, false)"
          size="sm"
          class="mt-2 h-100" />
        <p class="mt-1 mb-0 text-sm">⚠ {{ $t('image.size_notice') }}</p>
        <p class="mt-1 mb-0 text-sm">⚠ {{ $t('image.ratio_notice') }}</p>
        <p class="mt-1 text-sm" v-if="hasImage">⚠ {{ $t('image.replace_notice') }}</p>

        <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="false" />
        <b-table
          v-if="hasImage"
          show-empty
          :empty-text="$t('notify.table_no_records')"
          small
          hover
          responsive
          head-variant="light"
          class="m-0 p-0"
          :fields="[
            'url',
            { key: 'preview', label: $t('general.preview') },
            file ? { key: 'size', label: $t('general.size') + '(<600kb)', formatter: (v) => formatBytes(v), class: 'text-danger' } : null,
            { key: 'action', label: $tc('general.action', 1) },
          ]"
          :items="imageArr"
          :busy="isLoading">
          <template #cell(url)="{ value, index }">
            <span :id="`tooltip-url-${index}`">
              {{ shortenString(value, 40, false) }}
              <b-tooltip v-if="value.length > 40" :target="`tooltip-url-${index}`" :title="value" placement="right" />
            </span>
          </template>
          <template #cell(preview)="{ item }">
            <b-img-lazy :src="item.url" fluid block rounded style="max-height: 3rem" />
          </template>
          <template #cell(action)="{ item }">
            <b-button variant="outline-primary" size="xs" @click="uploadFile" :disabled="item['uploaded']">
              <b-icon-upload />
            </b-button>
            <b-button variant="outline-danger" size="xs" @click="clearFile" :disabled="item['uploaded']">
              <b-icon-trash />
            </b-button>
          </template>
        </b-table>
      </b-form-group>

      <b-form-group :label="$t('winner.photo_id')" label-class="text-sm" class="mt-3 mb-0">
        <b-form-input v-model="photoIDInput" :disabled="!!existingPhotoID" type="url" :placeholder="$t('image.url_notice')" />
        <b-form-file
          accept="image/*"
          id="photoID"
          v-model="photoID"
          :placeholder="$t('input.no_file_chosen')"
          :browse-text="$t('input.choose_file')"
          :file-name-formatter="(files) => shortenString(files[0]['name'], 30, false)"
          size="sm"
          class="mt-2 h-100" />
      </b-form-group>

      <b-form-group label-class="text-sm" class="mt-3 mb-0">
        <template #label>
          {{ $t('winner.poa') }}
          <span v-if="isConsolation ? false : requirePOA" class="text-danger ml-1">⚠️ {{ $t('winner.cash_claim') }}</span>
        </template>
        <b-form-input v-model="poaInput" :disabled="!!existingPOA" type="url" :placeholder="$t('image.url_notice')" />
        <b-form-file
          accept="image/*"
          v-model="poa"
          :placeholder="$t('input.no_file_chosen')"
          :browse-text="$t('input.choose_file')"
          :file-name-formatter="(files) => shortenString(files[0]['name'], 30, false)"
          size="sm"
          class="mt-2 h-100" />
      </b-form-group>

      <b-form-group label-class="text-sm" class="mt-3 mb-0">
        <template #label>
          {{ $t('winner.receipt') }}
          <span v-if="isConsolation ? false : !requirePOA" class="text-danger ml-1">⚠️ {{ $t('winner.prize_claim') }}</span>
        </template>
        <b-form-input v-model="receiptInput" :disabled="!!existingReceipt" type="url" :placeholder="$t('image.url_notice')" />
        <b-form-file
          accept="image/*"
          v-model="receipt"
          :placeholder="$t('input.no_file_chosen')"
          :browse-text="$t('input.choose_file')"
          :file-name-formatter="(files) => shortenString(files[0]['name'], 30, false)"
          size="sm"
          class="mt-2 h-100" />
      </b-form-group>

      <b-table
        v-if="hasTrackingImages"
        show-empty
        :empty-text="$t('notify.table_no_records')"
        small
        hover
        responsive
        head-variant="light"
        class="m-0 p-0 mt-3"
        :fields="fields"
        :items="trackingImages">
        <template #cell(url)="{ index, value }">
          <span :id="`tooltip-reward-url-${index}`">
            {{ shortenString(value, 40, false) }}
            <b-tooltip v-if="value.length > 40" :target="`tooltip-reward-url-${index}`" :title="value" placement="right" />
          </span>
        </template>
        <template #cell(preview)="{ item }">
          <b-img-lazy v-if="!item.img.includes('rkings-security')" :src="item.img" fluid block rounded style="max-height: 3rem" />
        </template>
        <template #cell(action)="{ item }">
          <b-button variant="outline-primary" size="xs" @click="() => uploadTrackingImg(item)" :disabled="item['uploaded']">
            <b-icon-upload />
          </b-button>
          <b-button variant="outline-danger" size="xs" @click="() => clearTrackingImg(item)" :disabled="item['uploaded']">
            <b-icon-trash />
          </b-button>
        </template>
      </b-table>
    </b-form>

    <template #modal-footer="{ cancel }">
      <b-button variant="outline-secondary" @click="cancel">{{ $t('action.cancel') }}</b-button>
      <b-button variant="outline-primary" @click="$bvModal.show('confirm')" :disabled="!isReady">
        {{ $t('action.publish') }}
      </b-button>
      <b-button variant="outline-primary" type="submit" form="winner-edit-form">{{ $t('action.save') }}</b-button>
    </template>
    <confirm-modal id="confirm" :message="$t('winner.upsert_notice[0]', { action: $t('action.publish') })" :onShown="() => (showConfirm = true)" :onHidden="() => (showConfirm = false)">
      <template #footer="{ cancel }">
        <b-button variant="outline-secondary" @click="cancel">{{ $t('action.cancel') }}</b-button>
        <b-button variant="outline-primary" type="submit" form="winner-edit-form">{{ $t('action.confirm') }}</b-button>
      </template>
    </confirm-modal>
  </b-modal>
</template>
<script>
import { AWSSecurityWithURL, AwsSecurityUpdate, AwsUpdate } from '@/store/services/aws'
import { base64Encode } from '@/utils/fileUtils'
import { notifyError, shortenString } from '@/utils/index'
import { formatBytes } from '@/utils/numberUtils'

export default {
  name: 'WinnerEdit',
  props: { winner: Object, getList: Function },
  data() {
    return {
      title: null,
      display_name: null,
      price: null,
      image: null,
      file: null,
      imageArr: [],
      isLoading: false,
      showConfirm: false,
      photoID: null,
      photoIDInput: null,
      poa: null,
      poaInput: null,
      receipt: null,
      receiptInput: null,
      trackingImages: [],
      trackImg: ['photoID', 'receipt', 'poa'],
    }
  },
  computed: {
    profiles() {
      return this.winner?.profiles ?? []
    },
    isConsolation() {
      const reward = this.winner?.competition_reward
      return reward.type === 'consolation'
    },
    requirePOA() {
      const reward = this.winner?.competition_reward
      return this.winner.product && (this.winner.product.type === 'cash' || +reward.alternative_cash_amount > 0)
    },
    existingPhotoID() {
      return this.findContent(this.profiles, 'photoID') ?? null
    },
    existingPOA() {
      return this.findContent(this.profiles, 'poa') ?? null
    },
    existingReceipt() {
      return this.findContent(this.profiles, 'receipt') ?? null
    },
    existingImageSrc() {
      return this.findContent(this.profiles, 'image') ?? null
    },
    hasImage() {
      return !!this.file || !!this.existingImageSrc
    },
    hasTrackingImages() {
      return this.trackingImages.length > 0
    },
    isReady() {
      return this.title && this.display_name && this.price && this.image
    },
    fields() {
      return [
        'url',
        {
          key: 'preview',
          label: this.$t('general.preview'),
          formatter: (v, k, i) => {
            if (i.url.includes('rkings-security') && !i.done) {
              // prevent image loaded repeatedly
              i.done = true
              AWSSecurityWithURL({ name: i.url.slice(51) }).then(({ blob }) => (i.img = URL.createObjectURL(blob)))
            }
          },
        },
        { key: 'type', label: this.$t('general.type') },
        { key: 'size', label: this.$t('general.size'), formatter: (v) => formatBytes(v) },
        { key: 'action', label: this.$tc('general.action', 1) },
      ]
    },
  },
  watch: {
    file(newVal, oldVal) {
      if (newVal !== oldVal) {
        const len = this.imageArr.length
        if (newVal) {
          base64Encode(newVal).then((value) => {
            const tmp = { size: newVal.size, url: value, uploaded: false }
            if (!oldVal && len < 2) {
              this.imageArr.push(tmp)
            } else {
              this.imageArr.splice(len - 1, 1, tmp)
            }
          })
        } else {
          this.imageArr.splice(len - 1, 1)
        }
      }
    },
    photoID(newVal, oldVal) {
      if (newVal !== oldVal && newVal) {
        base64Encode(newVal).then((value) => {
          this.addImageToTrackingImages(newVal.size, value, 'photoID', newVal)
        })
      }
    },
    poa(newVal, oldVal) {
      if (newVal !== oldVal && newVal) {
        base64Encode(newVal).then((value) => {
          this.addImageToTrackingImages(newVal.size, value, 'poa', newVal)
        })
      }
    },
    receipt(newVal, oldVal) {
      if (newVal !== oldVal && newVal) {
        base64Encode(newVal).then((value) => {
          this.addImageToTrackingImages(newVal.size, value, 'receipt', newVal)
        })
      }
    },
  },
  methods: {
    formatBytes,
    shortenString,

    findContent(arr, name) {
      return arr.find((x) => x['type'] === name)?.content
    },

    clearFile() {
      this.file = null
    },

    clearTrackingImg(item) {
      this.trackingImages = this.trackingImages.filter((v) => v.type !== item.type)
      this[item.type] = null
    },

    addImageToTrackingImages(size, url, type, file) {
      const tmp = { size, url, file, uploaded: false, type, img: url }
      const newTmpList = this.trackingImages.filter((v) => v.type !== type)
      this.trackingImages = newTmpList
      this[type + 'Input'] = url
      this.trackingImages.push(tmp)
    },

    uploadTrackingImg(item) {
      this.isLoading = true
      const imgName = `${this.winner.order_id}_${this.winner.id}_${item.type}${item.file.name.slice(item.file.name.lastIndexOf('.'))}`
      AwsSecurityUpdate('rkings-receipts', item.file, imgName.replaceAll(' ', '_'))
        .then((data) => {
          const tmp = this.trackingImages.map((v) => (v.type === item.type ? { ...v, uploaded: true, url: data.Location, img: data.Location } : v))
          this[item.type + 'Input'] = data.Location
          this.trackingImages = tmp
          this.$notify({ group: 'root', type: 'success', title: 'Success', text: this.$t('image.upload_notice[0]') })
          this.isLoading = false
        })
        .catch((err) => {
          this.isLoading = false
          notifyError(err, this.$t('notify.unknown_err'))
        })
    },

    uploadFile() {
      if (!this.file) {
        this.$notify({ group: 'root', type: 'warn', text: this.$t('image.file_notice') })
        return
      } else {
        this.isLoading = true
        AwsUpdate('rk-winners', this.file)
          .then((res) => {
            if (res && res.Location) {
              this.image = res.Location
              if (this.imageArr[this.imageArr.length - 1]) {
                this.imageArr[this.imageArr.length - 1]['url'] = res.Location
                this.imageArr[this.imageArr.length - 1]['uploaded'] = true
              }
              this.$notify({ group: 'root', type: 'success', title: 'Success', text: this.$t('image.upload_notice[0]') })
            } else {
              this.$notify({ group: 'root', type: 'error', title: 'Error', text: this.$t('notify.unknown_err') })
            }
            this.isLoading = false
          })
          .catch((err) => {
            this.isLoading = false
            notifyError(err, this.$t('notify.unknown_err'))
          })
      }
    },

    initForm() {
      this.title = this.findContent(this.profiles, 'title')
      this.display_name = this.findContent(this.profiles, 'display_name')
      this.price = this.findContent(this.profiles, 'price')
      this.image = this.existingImageSrc
      for (const i of this.trackImg) {
        const url = this.findContent(this.profiles, i)
        if (url) {
          this[i + 'Input'] = url
          this.trackingImages.push({ type: i, url, uploaded: true, img: url })
        }
      }
    },

    onShown(evt) {
      this.initForm()
      if (this.existingImageSrc) {
        this.imageArr.push({ url: this.existingImageSrc, size: NaN, uploaded: true })
      }
    },

    onHidden(evt) {
      this.$emit('update:winner', null)
      this.initForm()
      this.imageArr = []
      this.poaInput = null
      this.receiptInput = null
      this.photoIDInput = null
      this.trackingImages = []
    },

    onSubmit(evt) {
      const tmp = !!this.showConfirm
      this.$bvModal.hide('confirm')
      let not_upload = false
      let trackingImg = this.trackingImages.map((v) => {
        if (!not_upload && v.uploaded === false) {
          not_upload = true
        }
        return { type: v.type, content: this[v.type + 'Input'], position: 0 }
      })
      if (not_upload) {
        trackingImg = this.winner.profiles.filter((v) => this.trackImg.indexOf(v.type) > -1)
      }
      const params = {
        profiles: [
          { type: 'title', content: this.title, position: 0 },
          { type: 'display_name', content: this.display_name, position: 0 },
          { type: 'price', content: this.price, position: 0 },
          ...trackingImg,
        ],
        id: this.winner.id,
        status: tmp ? 3 : undefined,
        successMsg: (tmp ? this.$t('winner.publish_winner') : this.$t('winner.update_profile')) + ' ' + this.$t('notify.success') + '!',
        errorMsg: (tmp ? this.$t('winner.publish_winner') : this.$t('winner.update_profile')) + ' ' + this.$t('notify.failed') + '!',
      }
      if (this.image) {
        params.profiles.push({ type: 'image', content: this.image, position: 0 })
      }
      this.$store.dispatch('request/updateWinnerDetail', params).then(() => {
        this.getList()
        this.$bvModal.hide('edit-winner')
      })
    },
  },
}
</script>
