<template>
  <b-modal
    lazy
    centered
    id="coupon-code-upsert"
    :title="isNew ? $t('coupon.create_new_coupon_code') : $t('coupon.edit_coupon_code', { id: ' #' + couponCode.id })"
    title-class="w-100 d-flex justify-content-center align-self-center"
    header-class="py-2"
    body-class="py-0"
    footer-class="py-2"
    @shown="onInit"
    @hidden="onHidden">
    <b-form @submit.prevent="onSubmit" id="coupon-code-upsert-form" @keydown.enter.prevent="() => {}">
      <template v-if="isNew">
        <b-form-group label-class="text-sm" label-for="coupon-code-codes" class="m-0">
          <template #label>
            {{ $tc('coupon.code', form.codes.length) }}<span class="text-danger ml-1">*</span>
            <b-icon-question-circle-fill class="ml-1" v-b-tooltip.hover.right="$t('coupon.code_notice')" />
          </template>
          <b-form-tags id="coupon-code-codes" v-model="form.codes" no-outer-focus>
            <template v-slot="{ tags, addTag, removeTag }">
              <ul v-if="tags && tags.length > 0" class="list-inline mt-2">
                <li v-for="tag in tags" :key="tag" class="list-inline-item">
                  <b-form-tag @remove="removeTag(tag)" :title="tag" variant="info" class="text-lowercase">
                    {{ tag }}
                  </b-form-tag>
                </li>
              </ul>
              <b-form-input v-model="input" :placeholder="$t('coupon.code_notice')" @keyup.prevent="addCodeName(addTag, $event)" @keydown.enter.prevent="addCodeName(addTag, $event)" class="my-2" />
            </template>
          </b-form-tags>
        </b-form-group>
        <b-form-group label-class="text-sm" label-for="coupon-code-quantity" class="mt-3 mb-0">
          <template #label>
            {{ $t('coupon.quantity') }}
            <span class="text-danger ml-1">*</span>
            <b-icon-question-circle-fill class="ml-1" v-b-tooltip.hover.right="$t('coupon.quantity_notice')" />
          </template>
          <b-form-input id="coupon-code-quantity" v-model="form.qty" type="number" min="1" step="1" required :disabled="form.codes.length > 0" :placeholder="$t('coupon.quantity_notice')" />
        </b-form-group>
      </template>
      <template v-else>
        <b-form-group label-class="text-sm" label-for="coupon-code-username" class="m-0 d-inline-block w-50 pr-2">
          <template #label>{{ $t('general.user') }}<span class="ml-1 text-danger">*</span></template>
          <b-form-input id="coupon-code-username" v-model="username" :placeholder="$t('input.select_user')" disabled required />
        </b-form-group>
        <b-form-group label-class="text-sm" label-for="coupon-code" class="m-0 d-inline-block w-50 pl-2">
          <template #label>{{ $tc('coupon.code', 1) }} </template>
          <!-- <b-input-group> -->
          <b-form-input id="coupon-code" v-model="form.code" disabled />
          <!-- <b-input-group-append>
              <b-button variant="outline-light" @click="form.code = couponCode.code">Reset</b-button>
            </b-input-group-append>
          </b-input-group> -->
        </b-form-group>
        <UserList
          :fields="[
            { key: 'id', label: $t('user.user_id'), sortable: true },
            { key: 'name', label: $t('general.username'), sortable: true },
            { key: 'profile.first_name', label: $t('user.first_name'), sortable: true },
            { key: 'profile.last_name', label: $t('user.last_name'), sortable: true },
            { key: 'is_active', label: $t('general.status') },
            { key: 'actions', label: $tc('general.action', 1) },
          ]"
          :queries="{ store_id: $store.getters['store'], name: null, page: 1, perPage: 10 }"
          :noInitOnMount="true">
          <template #name="{ user }">
            <span class="action" @click="viewUser(user)">{{ user.name }}</span>
          </template>
          <template #actions="{ user }">
            <b-button variant="outline-primary" @click="onSelect(user)">{{ $t('action.select') }}</b-button>
          </template>
        </UserList>
      </template>
    </b-form>
    <template #modal-footer="{ hide }">
      <b-button variant="outline-secondary" @click="hide">{{ $t('action.cancel') }}</b-button>
      <b-button variant="outline-primary" type="submit" form="coupon-code-upsert-form">{{ $t('action.submit') }}</b-button>
    </template>
  </b-modal>
</template>
<script>
import UserList from '@/components/Users/UserList.vue'

export default {
  name: 'CouponCodeUpsert',
  components: {
    UserList,
  },
  props: {
    coupon: Object,
    couponCode: Object, // initial value
    getList: Function,
    viewUser: Function,
  },
  data() {
    return {
      username: '',
      input: '',
      form: { codes: [], code: '', id: null, user_id: null, qty: null },
    }
  },
  computed: {
    isNew() {
      return !this.couponCode || !this.couponCode.id
    },
  },
  methods: {
    onInit() {
      this.username = ''
      this.input = ''
      this.form = { codes: [], code: this.couponCode?.code ?? '', id: this.couponCode?.id ?? null, user_id: null, qty: null }
    },
    onHidden() {
      this.username = ''
      this.input = ''
      this.form = { codes: [], code: '', id: null, user_id: null, qty: null }
      this.$emit('update:couponCode', null)
    },
    addCodeName(addTag, event) {
      event.preventDefault()
      // key code 188 -> comma
      // key code 13 -> enter
      if (event.keyCode === 188 || event.keyCode === 13) {
        addTag(this.input.replace(/,/gim, ''))
        this.input = ''
      }
    },
    onSelect(user) {
      this.form.user_id = user.id
      this.username = user.name
    },
    onSubmit(event) {
      event.preventDefault()
      if (!this.isNew && !this.form.user_id) return
      const params = !this.isNew
        ? { id: this.form.id, user_id: this.form.user_id }
        : this.form.codes.length > 0
        ? { coupon_id: this.coupon.id, codes: this.form.codes }
        : { coupon_id: this.coupon.id, qty: this.form.qty }
      params['successMsg'] = this.$t('coupon.upsert_code_notice[0]', { action: this.isNew ? this.$t('action.create') : this.$t('action.edit') })
      params['errorMsg'] = this.$t('coupon.upsert_code_notice[1]', { action: this.isNew ? this.$t('action.create') : this.$t('action.edit') })
      this.$store.dispatch(this.isNew ? 'request/createCouponCode' : 'request/assignCouponCodeUser', params).then((res) => {
        if (res?.status === 200) {
          this.$bvModal.hide('coupon-code-upsert')
          this.getList()
        }
      })
    },
  },
  watch: {
    // 'couponCode.id'() {
    //   this.onInit()
    // },
    'form.codes'(newVal, oldVal) {
      if (newVal && newVal.length > 0) {
        this.form.qty = newVal.length
      }
    },
  },
  mounted() {
    this.onInit()
  },
}
</script>
