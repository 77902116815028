<template>
  <b-modal
    id="convert-prize"
    :title="`Convert Prize${readonly ? ' Details' : ''}`"
    lazy
    centered
    title-class="w-100 d-flex justify-content-center align-self-center"
    header-class="py-2"
    footer-class="py-2"
    body-class="py-0"
    @shown="initForm"
    @hidden="initForm"
    :loading="isLoading">
    <b-form @submit.prevent="$bvModal.show('confirm-convert')" id="convert-prize-form">
      <b-form-group label="Ticket Number" label-class="form-label-required" class="d-inline-block w-50 pr-2">
        <b-form-input :value="winner ? winner.ticket_number : null" readonly :placeholder="$t('no_data')" />
      </b-form-group>
      <b-form-group label="Competition" label-class="form-label-required" class="d-inline-block w-50 pl-2">
        <b-form-input :value="competition ? competition.title : null" readonly />
      </b-form-group>

      <b-form-group label="Prize" label-class="form-label-required" class="d-inline-block w-50 pr-2">
        <b-form-input :value="prize" readonly />
      </b-form-group>
      <b-form-group :label="product ? 'Cost' : 'Amount'" label-class="form-label-required" class="d-inline-block w-50 pl-2">
        <b-form-input :value="formatWithGBP(product ? product.cost : cash ? cash : credit)" readonly />
      </b-form-group>

      <b-form-group label="Conversion (£)" label-class="form-label-required">
        <b-input-group>
          <b-form-select
            v-model="form['type']"
            required
            :disabled="form.readonly || prize == 'CASH'"
            :options="[
              { value: 'alternative_cash_amount', text: 'CASH' },
              { value: 'alternative_credit_amount', text: 'CREDIT' },
            ]">
          </b-form-select>
          <b-form-input v-model="form['amount']" number type="number" step="0.0001" min="0.0001" required :readonly="form.readonly" placeholder="Enter amount" class="pl-1" />
        </b-input-group>
      </b-form-group>
      <b-form-group label="Comment" label-class="form-label-required">
        <b-form-textarea v-model="form['comment']" required :readonly="form.readonly" :placeholder="$t('input.enter_reason')" />
      </b-form-group>

      <b-form-group v-if="!!readonly" label="Created By" class="d-inline-block w-50 pr-2">
        <b-form-input v-model="form['created_by']" readonly placeholder="Admin ID" />
      </b-form-group>
      <b-form-group v-if="!!readonly" label="Created At" class="d-inline-block w-50 pl-2">
        <b-form-input v-model="form['created_at']" type="datetime-local" readonly placeholder="Created At" />
      </b-form-group>
    </b-form>
    <template #modal-footer="{ cancel }">
      <b-button variant="outline-secondary" @click="cancel">{{ readonly ? $t('action.close') : $t('action.cancel') }}</b-button>
      <b-button v-if="!readonly" variant="outline-primary" type="submit" form="convert-prize-form">{{ $t('action.submit') }}</b-button>
    </template>

    <confirm-modal
      id="confirm-convert"
      :message="`Are you sure to convert the ${product ? 'PRODUCT (' + product.name + ')' : prize + ' ' + formatWithGBP(cash ? cash : credit)} to ${
        !form.type ? '' : form.type.includes('cash') ? 'CASH' : form.type.includes('credit') ? 'CREDIT' : ''
      } ${formatWithGBP(form.amount)}?`">
      <template #footer="{ cancel }">
        <b-button variant="outline-secondary" @click="cancel">{{ $t('action.cancel') }}</b-button>
        <b-button variant="outline-primary" @click="onSubmit">{{ $t('action.confirm') }}</b-button>
      </template>
    </confirm-modal>
  </b-modal>
</template>
<script>
import axios from '@/plugins/axios'
import { API_LIST } from '@/utils/consts'
import { formatLocalDateTime } from '@/utils/dateTimeUtils'
import { notifyError, notifySuccess } from '@/utils/index'
import { formatWithGBP } from '@/utils/numberUtils'

export default {
  name: 'ConvertPrize',
  props: { winner: Object, getList: Function, readonly: String },
  data() {
    return {
      form: {},
      isLoading: false,
    }
  },
  computed: {
    competition() {
      return this.winner?.competition
    },
    product() {
      return this.winner?.product
    },
    cash() {
      return Number(this.winner?.competition_reward?.alternative_cash_amount || 0)
    },
    credit() {
      return Number(this.winner?.competition_reward?.alternative_credit_amount || 0)
    },
    prize() {
      return this.product?.name || (this.cash > 0 ? 'CASH' : this.credit > 0 ? 'CREDIT' : '')
    },
  },
  methods: {
    initForm(evt) {
      if (evt.type == 'hidden') {
        this.form = { type: 'alternative_credit_amount', amount: '', comment: '' }
      } else {
        const comment = this.readonly ? this.winner?.profiles?.find((x) => x.type == 'comment') : null
        const converted = this.readonly ? this.winner?.profiles?.find((x) => x.type == this.readonly) : null
        this.form = {
          type: this.readonly || 'alternative_credit_amount',
          amount: converted?.content || '',
          comment: comment?.content || '',
          readonly: !!this.readonly,
          created_at: formatLocalDateTime(converted?.created_at, 1, false, true),
          created_by: converted?.admin_user_id || '',
        }
      }
    },

    onSubmit() {
      this.isLoading = true
      axios
        .post(API_LIST.post.convertPrize, { id: this.winner?.id, [this.form.type]: this.form.amount, comment: this.form.comment })
        .then((res) => {
          if (res?.status == 200) {
            notifySuccess(res, 'Convert prize successfully!')
            this.$bvModal.hide('convert-prize')
            this.getList()
          }
        })
        .catch((err) => notifyError(err, 'Convert prize failed!'))
        .finally(() => {
          this.isLoading = false
          this.$bvModal.hide('confirm-convert')
        })
    },

    formatWithGBP,
  },
}
</script>
