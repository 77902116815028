<template>
  <b-card style="border: 1px solid rgba(0, 0, 0, 0.15) !important; border-radius: 0.375rem">
    <b-card class="border-0 pt-3">
      <b-card-title style="font-size: 22px">
        {{ $tc('general.order', 1) }} #{{ orderDetails.id }} {{ $t('general.details') }}
        <span v-if="orderDetails.status === -3" @click="generateTicket(orderDetails.id)" class="action ml-1" style="font-size: 16px; text-align: center"> {{ $t('order.generate_ticket') }}→ </span>
      </b-card-title>

      <b-card-sub-title class="text-capitalize">
        {{ $t('payment.payment_via') }} {{ isPayEmpty ? '-' : successPayment.method }} / {{ isPayEmpty ? '-' : successPayment.gateway }} / {{ isPayEmpty ? '-' : successPayment.txn_type }} (
        {{ isPayEmpty ? '-' : successPayment.txn_id }} ). {{ $t('order.paid_on') }} {{ formatLocalDateTime(orderDetails.updated_at) }}. {{ $t('order.customer_ip') }}:
        {{ ip }}
      </b-card-sub-title>
    </b-card>

    <b-card-group deck v-if="orderDetails">
      <b-card v-if="orderDetails.addresses" class="border-0">
        <b-card-title> {{ $t('order.general') }} </b-card-title>
        <div>
          <h5>{{ $t('order.date_created') }}:</h5>
          <p>{{ orderDetails.created_at ? formatLocalDateTime(orderDetails.created_at) : '-' }}</p>
        </div>
        <div class="mt-2">
          <h5>{{ $t('general.status') }}:</h5>
          <OrderStatus :value="orderDetails.status" />
        </div>
        <div class="mt-2">
          <b-container fluid class="m-0 pl-0" style="padding-left: 0px !important">
            <b-row align-h="start">
              <b-col cols="auto" class="mr-1">
                <h5>{{ $t('order.customer') }}:</h5>
              </b-col>
              <b-col cols="auto" class="pr-0">
                <h5 class="action" @click="openUserProfile(orderDetails.user_id)">{{ $t('order.profile') }}→</h5>
              </b-col>
              <router-link :to="{ name: 'Orders' }">
                <b-col cols="auto" class="pr-0"
                  ><h5 class="action">{{ $t('order.view_other_orders') }}→</h5>
                </b-col>
              </router-link>
            </b-row>
          </b-container>
          <p>{{ displayName + ' (#' + orderDetails.user_id + ' - ' }} {{ orderDetails.addresses.length > 0 ? orderDetails.addresses[0].email : '-' }} {{ ')' }}</p>
        </div>
      </b-card>

      <!-- billing address start-->
      <b-card v-if="orderDetails" style="border: none">
        <b-card-title>{{ $t('address.billing') }}</b-card-title>
        <div>
          <p class="my-0" style="white-space: pre-wrap">{{ billingInfo }}</p>
        </div>
        <div class="mt-2">
          <h5>{{ $t('general.email') }}:</h5>
          <p class="mt-0">{{ orderDetails.addresses.length > 0 ? (orderDetails.addresses[0].email ? orderDetails.addresses[0].email : '-') : '-' }}</p>
        </div>
        <div>
          <h5>{{ $t('user.phone') }}:</h5>
          <p class="my-0">
            {{ orderDetails.addresses.length > 0 ? (orderDetails.addresses[0].phone ? orderDetails.addresses[0].phone : '-') : '-' }}
          </p>
        </div>
      </b-card>
      <!-- billing address end -->

      <!-- shipping address start -->
      <b-card v-if="orderDetails.addresses" style="border: none">
        <b-card-title> {{ $t('address.shipping') }}</b-card-title>
        <div>
          <h5>{{ $t('address.address') }}:</h5>
          <p style="white-space: pre-wrap">{{ shippingAddress }}</p>
        </div>
      </b-card>
      <!-- shipping address end -->
    </b-card-group>

    <b-modal
      lazy
      centered
      id="user-details"
      :title="$t('user.user_details', { id: '' })"
      title-class="w-100 d-flex justify-content-center align-self-center"
      header-class="w-100 py-2"
      body-class="pt-0"
      hide-footer
      ><UserDetails v-if="user" :user="user">
        <template #address="{ address }">
          <br v-if="address" />
          <AddressDetails v-if="address" :userStore="user.store_id" :address="address" />
        </template>
      </UserDetails>
    </b-modal>

    <confirm-modal id="confirm-generate" :title="$t('order.generate_ticket_confirm')" :message="$t('order.generate_ticket_message')" :isLoading="isLoading" :onSubmit="onGenerate" />
  </b-card>
</template>
<script>
import OrderStatus from '@/components/Orders/OrderStatus.vue'
import AddressDetails from '@/components/Users/AddressDetails.vue'
import UserDetails from '@/components/Users/UserDetails.vue'
import service from '@/store/services/service'
import { formatLocalDateTime } from '@/utils/dateTimeUtils'
import { notifyError } from '@/utils/index'
import { isEmpty } from 'lodash'
import { mapGetters } from 'vuex'

export default {
  name: 'user-info',
  components: { UserDetails, AddressDetails, OrderStatus },
  props: { getOrderDetails: Function, successPayment: Object },
  data() {
    return {
      isLoading: false,
      orderId: null,
      user: null,
    }
  },
  computed: {
    ...mapGetters({ orderDetails: 'request/orderDetails', userDetails: 'request/userDetails' }),
    ip() {
      return this.orderDetails.remote_ip ? this.orderDetails.remote_ip : '-'
    },
    displayName() {
      return this.$route.query.display_name ? this.$route.query.display_name : '-'
    },
    billingInfo() {
      let address = null
      if (this.orderDetails.addresses.length > 0) {
        let addressInfo = this.orderDetails.addresses
        address = this.addressDisplayRule(addressInfo[0])
      } else {
        address = null
      }
      return address ? address : this.$t('address.no_billing')
    },
    shippingAddress() {
      let address = null
      if (this.orderDetails.addresses.length > 0) {
        let addressInfo = this.orderDetails.addresses
        for (let i = 0; i < addressInfo.length; i++) {
          if (addressInfo[i].address_type === 'shipping') {
            address = this.addressDisplayRule(addressInfo[i])
          } else {
            address = this.$t('address.no_shipping')
          }
        }
      } else {
        address = this.$t('address.no_shipping')
      }
      return address
    },
    isPayEmpty() {
      return isEmpty(this.successPayment)
    },
  },
  methods: {
    formatLocalDateTime: formatLocalDateTime,
    addressDisplayRule(obj) {
      let address = ''
      if (obj['first_name'] != null) {
        address += obj['first_name'] + ' '
      }
      if (obj['middle_name'] != null) {
        address += obj['middle_name'] + ' '
      }
      if (obj['last_name'] != null) {
        address += obj['last_name']
      }
      if (obj['street'] != null) {
        address += '\n' + obj['street']
      }
      if (obj['street_2'] != null) {
        address += '\n' + obj['street_2']
      }
      if (obj['county'] != null) {
        address += '\n' + obj['county']
      }
      if (obj['county'] != null) {
        address += '\n' + obj['county']
      }
      if (obj['city'] != null) {
        address += '\n' + obj['city']
      }
      if (obj['country'] != null) {
        address += '\n' + obj['country']
      }
      if (obj['postcode'] != null) {
        address += '\n' + obj['postcode']
      }
      return address
    },
    openUserProfile(userId) {
      this.user = { id: userId }
      this.$bvModal.show('user-details')
    },
    generateTicket(orderId) {
      this.orderId = orderId
      this.$bvModal.show('confirm-generate')
    },
    async onGenerate() {
      try {
        this.isLoading = true
        await service.generateTicket({ id: this.orderId }).then(() => {
          this.isLoading = false
          this.$notify({ group: 'root', type: 'success', text: this.$t('notify.success') })
          this.getOrderDetails()
          this.$bvModal.hide('confirm-generate')
        })
      } catch (error) {
        this.isLoading = false
        this.$bvModal.hide('confirm-generate')
        notifyError(error, this.$t('order.generate_notice'))
      }
    },
  },
}
</script>
