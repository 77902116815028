var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('card',{staticClass:"table-custom",attrs:{"body-classes":"p-0 d-flex flex-column"}},[_c('loading',{attrs:{"active":_vm.isLoading,"can-cancel":false,"is-full-page":false}}),_c('ReportSearchInput',{attrs:{"type":"sales","queryParams":_vm.queryParams,"showStore":"","showCurrency":"","showDatePicker":"","onExport":_vm.onExport,"onSearch":_vm.onSearch,"onReset":_vm.onReset}}),_c('b-table',{staticClass:"m-0 p-0",attrs:{"fields":_vm.fields,"items":_vm.items,"show-empty":"","empty-text":_vm.$t('notify.table_no_records'),"hover":"","responsive":"","head-variant":"light","sticky-header":"100%"},scopedSlots:_vm._u([{key:"head(cash_received)",fn:function(ref){
var label = ref.label;
return [_vm._v(" "+_vm._s(label)+" "),_c('b-icon-question-circle-fill',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",value:(
          _vm.$t('report.sales.bank_received') +
          ' = ' +
          _vm.$t('report.sales.axcess_received') +
          ' + ' +
          _vm.$t('report.sales.barclaycard_received') +
          ' + ' +
          _vm.$t('report.sales.applepay_received') +
          ' + ' +
          _vm.$t('report.sales.googlepay_received') +
          ' + ' +
          _vm.$t('report.sales.paypal_received')
        ),expression:"\n          $t('report.sales.bank_received') +\n          ' = ' +\n          $t('report.sales.axcess_received') +\n          ' + ' +\n          $t('report.sales.barclaycard_received') +\n          ' + ' +\n          $t('report.sales.applepay_received') +\n          ' + ' +\n          $t('report.sales.googlepay_received') +\n          ' + ' +\n          $t('report.sales.paypal_received')\n        ",modifiers:{"hover":true,"right":true}}],staticClass:"ml-1"})]}},{key:"head(cash_wallet_used)",fn:function(ref){
        var label = ref.label;
return [_vm._v(" "+_vm._s(label)+" "),_c('b-icon-question-circle-fill',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",value:(_vm.$t('report.sales.cash_used_notice')),expression:"$t('report.sales.cash_used_notice')",modifiers:{"hover":true,"right":true}}],staticClass:"ml-1"})]}},(_vm.items.length > 0)?{key:"bottom-row",fn:function(){return [_c('td',{staticClass:"table-b-table-default b-table-sticky-column"},[_vm._v(_vm._s(_vm.$t('general.total')))]),_c('td',{staticClass:"text-right",attrs:{"colspan":"2"}},[_vm._v(_vm._s(_vm.sumTotal(_vm.visibleRows, 'orders')))]),_vm._l((_vm.sumKeys),function(val,index){return _c('td',{key:'bottom-cell-' + index,class:("text-right " + (index == _vm.sumKeys.length - 1 ? 'pr-5' : ''))},[_vm._v(" "+_vm._s(_vm.formatWithCurrency(_vm.sumTotal(_vm.visibleRows, val.key), _vm.queryParams.currency))+" ")])})]},proxy:true}:null],null,true),model:{value:(_vm.visibleRows),callback:function ($$v) {_vm.visibleRows=$$v},expression:"visibleRows"}}),(_vm.items.length > 0)?_c('div',{staticClass:"card-footer"}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }