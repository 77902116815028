<template>
  <div style="display: contents">
    <b-row cols="1">
      <b-col>
        <span class="font-weight-bold text-capitalize">{{ $t('address.address') }}</span>
      </b-col>
    </b-row>
    <b-row cols="1" cols-xl="2">
      <b-col class="pr-xl-4">
        <div class="mt-3">
          <span class="font-weight-normal text-sm">{{ $t('user.first_name') }}</span>
          <span class="ml-1 text-danger">*</span>
          <b-form-input v-if="form" v-model="form.first_name" required class="ml-3 d-inline-block w-50" style="font-size: 0.85rem" />
          <span v-else class="ml-3 font-weight-light">{{ address ? address.first_name : $t('no_data') }}</span>
        </div>
        <div class="mt-3">
          <span class="font-weight-normal text-sm">{{ $t('address.street') }}</span>
          <span class="ml-1 text-danger">*</span>
          <b-form-input v-if="form" v-model="form.street" required class="ml-3 d-inline-block w-50" style="font-size: 0.85rem" />
          <span v-else-if="!address" class="ml-3 font-weight-light">{{ $t('no_data') }}</span>
          <span v-else-if="address.street" class="ml-3 font-weight-light" :id="`street1-${address.id}`">
            {{ shortenString(address.street, 28, false) }}
            <b-tooltip v-if="address.street.length > 28" :target="`street1-${address.id}`" placement="right">{{ address.street }}</b-tooltip>
          </span>
        </div>
        <div class="mt-3">
          <span class="font-weight-normal text-sm">{{ $t('address.city') }}</span>
          <span class="ml-1 text-danger">*</span>
          <b-form-input v-if="form" v-model="form.city" required class="ml-3 d-inline-block w-50" style="font-size: 0.85rem" />
          <span v-else class="ml-3 font-weight-light">{{ address ? address.city : $t('no_data') }}</span>
        </div>
        <div class="mt-3">
          <span class="font-weight-normal text-sm">{{ $t('address.county') }}</span>
          <span v-if="(form && form.country === 'Republic of Ireland') || (!form && address && address.country === 'Republic of Ireland')" class="ml-1 text-danger">*</span>
          <template v-if="form">
            <b-form-input v-if="form.country === 'United Kingdom'" v-model="form.county" class="ml-3 d-inline-block w-50" style="font-size: 0.85rem" />
            <b-form-select v-else v-model="form.county" :required="form.country === 'Republic of Ireland'" class="ml-3 d-inline-block w-50" style="font-size: 0.85rem" :options="countyOpts" />
          </template>
          <span v-else class="ml-3 font-weight-light">{{ address ? address.county : $t('no_data') }}</span>
        </div>
        <div class="mt-3">
          <span class="font-weight-normal text-sm">{{ $t('address.country') }}</span>
          <span class="ml-1 text-danger">*</span>
          <b-form-select v-if="form" v-model="form.country" required class="ml-3 d-inline-block w-50" style="font-size: 0.85rem" :options="countryOpts" />
          <span v-else class="ml-3 font-weight-light">{{ address ? address.country : $t('no_data') }}</span>
        </div>
      </b-col>
      <b-col class="pl-xl-4">
        <div class="mt-3">
          <span class="font-weight-normal text-sm">{{ $t('user.last_name') }}</span>
          <span class="ml-1 text-danger">*</span>
          <b-form-input v-if="form" v-model="form.last_name" required class="ml-3 d-inline-block w-50" style="font-size: 0.85rem" />
          <span v-else class="ml-3 font-weight-light">{{ address ? address.last_name : $t('no_data') }}</span>
        </div>
        <div class="mt-3">
          <span class="font-weight-normal text-sm">{{ $t('address.street') }} 2</span>
          <b-form-input v-if="form" v-model="form.street_2" class="ml-3 d-inline-block w-50" style="font-size: 0.85rem" />
          <span v-else-if="!address" class="ml-3 font-weight-light">{{ $t('no_data') }}</span>
          <span v-else-if="address.street_2" class="ml-3 font-weight-light" :id="`street2-${address.id}`">
            {{ shortenString(address.street_2, 28, false) }}
            <b-tooltip v-if="address.street_2.length > 28" :target="`street2-${address.id}`" placement="right">{{ address.street_2 }}</b-tooltip>
          </span>
        </div>
        <div class="mt-3">
          <span class="font-weight-normal text-sm">{{ $t('user.phone') }}</span>
          <span class="ml-1 text-danger">*</span>
          <b-form-input v-if="form" v-model="form.phone" required class="ml-3 d-inline-block w-50" style="font-size: 0.85rem" />
          <span v-else class="ml-3 font-weight-light">{{ address ? address.phone : $t('no_data') }}</span>
        </div>
        <div class="mt-3">
          <span class="font-weight-normal text-sm">{{ $t('address.postcode') }}</span>
          <span class="ml-1 text-danger">*</span>
          <b-form-input v-if="form" v-model="form.postcode" required class="ml-3 d-inline-block w-50" style="font-size: 0.85rem" />
          <span v-else class="ml-3 font-weight-light">{{ address ? address.postcode : $t('no_data') }}</span>
        </div>
        <div class="mt-3">
          <span class="font-weight-normal text-sm">{{ $t('address.company') }}</span>
          <b-form-input v-if="form" v-model="form.company" class="ml-3 d-inline-block w-50" style="font-size: 0.85rem" />
          <span v-else class="ml-3 font-weight-light">{{ address ? address.company : $t('no_data') }}</span>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { IE_COUNTY_LIST, MX_COUNTY_LIST } from '@/utils/consts'
import { shortenString } from '@/utils/index'
export default {
  name: 'AddressDetails',
  props: {
    userStore: Number,
    address: Object,
    form: Object,
  },
  data() {
    let ieCountyList = [{ value: null, text: this.$t('input.empty') }]
    IE_COUNTY_LIST.forEach((x) => {
      ieCountyList.push({ value: x, text: x })
    })
    let mxCountyList = [{ value: null, text: this.$t('input.empty') }]
    MX_COUNTY_LIST.forEach((x) => {
      mxCountyList.push({ value: x, text: x })
    })
    return {
      ieCountyList: ieCountyList,
      mxCountyList: mxCountyList,
    }
  },
  computed: {
    countryOpts() {
      let tmp = []
      if (this.userStore == 1) {
        tmp = [
          { value: 'United Kingdom', text: 'United Kingdom' },
          { value: 'Republic of Ireland', text: 'Republic of Ireland' },
        ]
      } else if (this.userStore == 2) {
        tmp = [{ value: 'Mexico', text: 'Mexico' }]
      }
      return tmp
    },
    countyOpts() {
      let tmp = [{ value: null, text: this.$t('input.empty') }]
      if (this.form.country == 'Republic of Ireland') {
        tmp = this.ieCountyList
      } else if (this.form.country == 'Mexico') {
        tmp = this.mxCountyList
      }
      return tmp
    },
  },
  watch: {
    'form.country'(newVal, oldVal) {
      if (newVal === 'United Kingdom') {
        this.form.county = this.address?.country === newVal ? this.address.county : null
      }
      if (oldVal === 'United Kingdom' && newVal !== oldVal) {
        this.form.county = this.address?.country === newVal ? this.address.county : null
      }
    },
  },
  methods: {
    shortenString,
  },
  created() {
    if (this.form) {
      if (this.address) {
        for (const key in this.form) {
          this.form[key] = this.address[key]
        }
      } else {
        for (const key in this.form) {
          this.form[key] = key === 'id' ? null : key === 'address_type' ? 'billing' : ''
        }
      }
    }
  },
}
</script>
