var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('card',{staticClass:"table-custom",attrs:{"body-classes":"p-0 d-flex flex-column"}},[_c('loading',{attrs:{"active":_vm.isLoading,"can-cancel":false,"is-full-page":false}}),_c('div',{staticClass:"px-4 pt-3 pb-2 d-flex flex-row align-items-center justify-content-xl-between"},[_c('b-form-select',{staticClass:"mt-0 mb-0 ml-0 mr-2",staticStyle:{"width":"90px"},attrs:{"options":_vm.storeOpts,"required":"","disabled":!_vm.allowMultiStores},model:{value:(_vm.store_id),callback:function ($$v) {_vm.store_id=$$v},expression:"store_id"}}),_c('b-button',{attrs:{"variant":"basic"},on:{"click":function($event){return _vm.openUpsert(null)}}},[_c('b-icon-plus',{staticClass:"mr-1"}),_vm._v(_vm._s(_vm.$t('catalog.create_new_catalog')))],1)],1),_c('b-table',{staticClass:"m-0 p-0",attrs:{"fields":_vm.fields,"items":_vm.items,"busy":_vm.isLoading,"show-empty":"","empty-text":_vm.$t('notify.table_no_records'),"small":"","hover":"","responsive":"","head-variant":"light","primary-key":"id","sticky-header":"100%"},scopedSlots:_vm._u([{key:"cell(is_active)",fn:function(ref){
var value = ref.value;
return [_c('bool-badge',{attrs:{"value":value,"text":value == 1 ? _vm.$t('status.shown') : _vm.$t('status.hidden')}})]}},{key:"cell(display_all)",fn:function(ref){
var value = ref.value;
return [_c('bool-badge',{attrs:{"value":value,"text":value == 1 ? _vm.$t('yes') : _vm.$t('no')}})]}},{key:"cell(store_id)",fn:function(ref){
var value = ref.value;
return [_c('store-badge',{attrs:{"store_id":value}})]}},{key:"cell(action)",fn:function(ref){
var item = ref.item;
return [_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.topright",value:(_vm.$t('action.edit')),expression:"$t('action.edit')",modifiers:{"hover":true,"topright":true}}],attrs:{"disabled":item.name == 'Popup'},on:{"click":function($event){return _vm.openUpsert(item)}}},[_c('b-icon-pencil')],1)]}}])}),_c('CatalogUpsert',{attrs:{"catalog":_vm.actionTarget,"getCatalogs":_vm.getCatalogList},on:{"update:catalog":function($event){_vm.actionTarget=$event}}}),_c('CatalogCompetitions',{attrs:{"catalog":_vm.actionTarget,"getCatalogs":_vm.getCatalogList},on:{"update:catalog":function($event){_vm.actionTarget=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }