<template>
  <card body-classes="pb-3 d-flex flex-column mb-3" class="table-custom">
    <div class="container-fluid mt-0">
      <!-- daily orders chart start -->
      <div v-if="hasPermission(111) || hasPermission(109)" class="row">
        <div v-if="hasPermission(111)" :class="cardContainerClass"><DailySales /></div>
        <div v-if="hasPermission(109)" :class="cardContainerClass"><DailyOrders /></div>
      </div>

      <div v-if="hasPermission(114)" class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-3"><DailyCompetitionSales /></div>
      </div>

      <div v-if="hasPermission(108) || hasPermission(112)" class="row">
        <div v-if="hasPermission(108)" :class="cardContainerClass"><RegisteredUsers /></div>
        <div v-if="hasPermission(112)" :class="cardContainerClass"><UserChannels /></div>
      </div>

      <div v-if="hasPermission(110) || hasPermission(113)" class="row">
        <div v-if="hasPermission(110)" :class="cardContainerClass"><OrderPayments /></div>
        <div v-if="hasPermission(113)" :class="cardContainerClass"><FailedPayments /></div>
      </div>
    </div>
  </card>
</template>
<script>
import routerMixin from '@/mixins/router-mixin'
import DailySales from '@/components/Charts/DailySales.vue'
import DailyOrders from '@/components/Charts/DailyOrders.vue'
import DailyCompetitionSales from '@/components/Charts/DailyCompetitionSales.vue'
import RegisteredUsers from '@/components/Charts/RegisteredUsers.vue'
import UserChannels from '@/components/Charts/UserChannels.vue'
import OrderPayments from '@/components/Charts/OrderPayments.vue'
import FailedPayments from '@/components/Charts/FailedPayments.vue'

export default {
  mixins: [
    routerMixin, // hasPermission
  ],
  components: { DailySales, DailyCompetitionSales, RegisteredUsers, UserChannels, DailyOrders, OrderPayments, FailedPayments },
  data() {
    return {
      cardContainerClass: 'col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-3',
    }
  },
}
</script>
<style scoped>
.dashboardBg {
  background-color: transparent !important;
}

.table-custom {
  background-color: transparent !important;
  border: none;
}

@media (min-width: 768px) {
  .main-content .container-fluid,
  .main-content .container-sm,
  .main-content .container-md,
  .main-content .container-lg,
  .main-content .container-xl {
    padding-left: 4px !important;
    padding-right: 4px !important;
  }
}

.col,
.col-lg-6,
.col-md-12,
.col-sm-12,
.col-xs-12 {
  padding-left: 8px;
  padding-right: 8px;
}
</style>
