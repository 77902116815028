<template>
  <b-overlay :show="!enabled" variant="secondary" opacity="0.50" rounded="sm" class="w-100 mt-4 rounded">
    <template #overlay v-if="!enabled">
      <b-form-invalid-feedback :force-show="true" class="text-lg">⚠ {{ $t('entry.publish_notice')[4] }}</b-form-invalid-feedback>
    </template>

    <div class="w-100 py-2 d-flex align-items-center" style="cursor: pointer">
      <label class="font-weight-normal m-0">Instant Win Prize Settings</label>
      <b-icon-question-circle-fill class="ml-1" v-b-tooltip.hover.rightbottom="$t('instant_win.generate[3]')" />
      <span class="text-success ml-auto mr-2">{{ $t('order.subtotal') }}: {{ subtotal ? subtotal : 0 }}</span>
    </div>

    <b-form-group label="Reward Type" label-for="reward-type" class="d-inline-block w-50 pr-2 mt-2 mb-0">
      <b-form-select
        id="reward-type"
        v-model="rwdTypeInput"
        :options="[
          { value: 'credit', text: 'Credit' },
          { value: 'cash', text: 'Cash' },
          { value: 'product', text: 'Product (Lookup)' },
        ]"
        @change="(value) => (value == 'product' ? $bvModal.show('instant-win-lookup') : null)"
        :disabled="maxTickets < 1" />
    </b-form-group>
    <b-form-group label="Reward Amount" label-for="reward-amt" class="d-inline-block w-50 pl-2 mt-2 mb-0">
      <b-input-group>
        <b-form-select value="GBP" :options="[{ value: 'GBP', text: 'GBP' }]" disabled style="max-width: 72px" />
        <b-form-input id="reward-amt" v-model="rwdAmtInput" number type="number" step="0.01" min="0" :disabled="rwdTypeInput == 'product'" placeholder="Reward Amount" />
      </b-input-group>
    </b-form-group>

    <!-- Auto generate -->
    <b-form-group label="Generation Method" class="w-full mt-4 mb-0">
      <b-input-group class="form-control p-0 mx-0">
        <b-input-group-prepend>
          <b-button disabled class="text-left border-0" style="width: 148px; height: 34px">{{ $t('instant_win.generate[0]') }}</b-button>
        </b-input-group-prepend>
        <b-form-input
          v-model="shuffleInput"
          :disabled="maxTickets < 1 || rwdTypeInput == 'product' || rwdAmtInput <= 0"
          number
          type="number"
          min="1"
          step="1"
          :max="maxTickets"
          :placeholder="$t('ticket.quantity_notice')[0]"
          :state="shuffleInputState"
          aria-describedby="shuffleInputInvalid"
          class="m-0 py-0 px-2 border-top-0 border-bottom-0 border-left border-right"
          style="height: 34px" />
        <b-input-group-append>
          <b-button variant="outline-primary" :disabled="!shuffleInputState" @click="generateShuffleTicket" class="border-0" style="height: 34px">
            {{ $t('instant_win.generate[2]') }}
          </b-button>
        </b-input-group-append>
      </b-input-group>
      <b-form-invalid-feedback :state="shuffleInputState" id="shuffleInputInvalid">⚠ {{ $t('ticket.max_ticket_notice') }}</b-form-invalid-feedback>
    </b-form-group>

    <!-- Manual generate -->
    <b-form-group class="w-100 mt-2 mb-0">
      <b-form-tags v-model="manualTickets" no-outer-focus :class="manualTickets && manualTickets.length > 0 ? 'py-2 mt-2' : 'border-0 px-0'">
        <template v-slot="{ tags, addTag, removeTag }">
          <ul v-if="tags && tags.length > 0" class="list-inline m-0">
            <li v-for="tag in tags" :key="tag" class="list-inline-item mb-2">
              <b-form-tag @remove="removeTag(tag)" :title="tag" variant="info" class="text-capitalize">
                {{ formatTag(tag) }}
              </b-form-tag>
            </li>
          </ul>
          <b-input-group class="form-control p-0 m-0">
            <b-input-group-prepend>
              <b-button disabled class="text-left border-0" style="width: 148px; height: 34px">
                {{ $t('instant_win.generate[1]') }}
              </b-button>
            </b-input-group-prepend>
            <b-form-input
              v-model="manualInput"
              :disabled="maxTickets < 1 || rwdTypeInput == 'product' || rwdAmtInput <= 0"
              number
              type="number"
              min="1"
              :placeholder="$t('ticket.quantity_notice')[1]"
              @keyup.prevent="_addTag($event, addTag)"
              @keydown.enter.prevent="_addTag($event, addTag)"
              :state="manualInputState"
              aria-describedby="manualInputInvalid"
              class="m-0 py-0 px-2 border-top-0 border-bottom-0 border-left border-right"
              style="height: 34px" />
            <b-input-group-append>
              <b-button variant="outline-primary" :disabled="!tags || tags.length < 1" @click="generateManualTicket" class="border-0" style="height: 34px">
                {{ $t('instant_win.generate[2]') }}
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </template>
      </b-form-tags>
      <b-form-invalid-feedback :state="manualInputState" id="manualInputInvalid">⚠ {{ $t('ticket.invalid_ticket_number') }}</b-form-invalid-feedback>
    </b-form-group>

    <p class="text-xs mt-1 mb-0">⚠ {{ $t('ticket.max_ticket_amount') + ': ' + maxTickets }}</p>

    <!-- tickets table -->
    <div v-if="tickets && tickets.length > 0" class="w-100 mt-4 d-flex align-items-center">
      <label class="m-0">{{ $t('instant_win.tickets') }}</label>
      <b-button variant="outline-danger" class="ml-2" v-b-modal.confirm-clear-all>{{ $t('instant_win.clear_all[0]') }}</b-button>
    </div>
    <b-table
      v-if="tickets && tickets.length > 0"
      sticky-header="200px"
      small
      hover
      responsive
      head-variant="light"
      class="mt-2 mb-0 mx-0 p-0 mh-30"
      tbody-class="h-100"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :fields="ticketFields"
      :items="tickets">
      <template #cell(name)="{ value, item }">
        <div v-if="item.nameLang" class="w-auto d-flex flex-row flex-nowrap align-items-center">
          <div class="action" @click="onViewDetails(item)" style="white-space: normal; min-width: 8rem">{{ value }}</div>
          <icon-translate :onClick="() => pushProductEdit(item)" classes="pl-1" />
        </div>
        <div v-else class="action" @click="onViewDetails(item)" style="white-space: normal; min-width: 12rem">
          {{ value }}
        </div>
      </template>
      <template #cell(action)="{ item }">
        <b-button variant="outline-primary" size="xs" @click="onShowEdit(item)" v-b-tooltip.hover.topleft="$t('action.edit')"><b-icon-pencil /></b-button>
        <b-button variant="outline-danger" size="xs" @click="onRemoveTicket(item.ticket_number)"><b-icon-trash /></b-button>
      </template>
      <template #bottom-row>
        <td colspan="1" class="font-weight-bold text-left">{{ $t('order.subtotal') }}</td>
        <td colspan="1" class="font-weight-bold text-left">{{ tickets.length }} Tickets</td>
        <td colspan="1"></td>
        <td colspan="1" class="font-weight-bold text-right">{{ costSubtotal }}</td>
        <td colspan="1" class="font-weight-bold text-right">{{ cashSubtotal }}</td>
        <td colspan="1" class="font-weight-bold text-right">{{ creditSubtotal }}</td>
        <td colspan="1" class="font-weight-bold text-right text-success">{{ subtotal }}</td>
      </template>
    </b-table>

    <b-form-group class="mt-4 mb-0" label-class="d-flex align-items-center">
      <template #label>
        {{ $t('instant_win.descr') }}
        <b-icon-question-circle-fill class="mx-2" v-b-tooltip.hover.topright="$t('notify.translate_notice[0]')" />
        <b-button variant="outline-primary" @click="createTemplate">Apply Template</b-button>
      </template>
      <b-form-textarea v-model="description" :placeholder="$t('instant_win.descr')" size="lg" rows="4" no-resize style="font-size: 0.85rem" />
    </b-form-group>

    <!-- lookup -->
    <b-modal
      lazy
      centered
      id="instant-win-lookup"
      :title="$t('instant_win.lookup')"
      title-class="w-100 d-flex justify-content-center align-self-center"
      header-class="py-2"
      body-class="pt-0"
      hide-footer
      @show="onShowLookup"
      @hide="onHideLookup">
      <loading :active="isLoading" :can-cancel="false" :is-full-page="false" />
      <ProductSearchInput :queries="queries" :getList="getList" class="px-0 py-2" />
      <b-table :fields="lookupFields" :items="productList" show-empty :empty-text="$t('notify.table_no_records')" small hover responsive sticky-header="60vh" head-variant="light" class="m-0 p-0">
        <template #head()="{ label, field }">{{ label }}<b-icon-question-circle-fill v-if="field.tooltip" class="ml-1" v-b-tooltip.hover.topright="field.tooltip" /></template>
        <template #cell(name)="{ value, item }">
          <div v-if="item.nameLang" class="w-auto d-flex flex-row flex-nowrap align-items-center">
            <div class="action" @click="onViewDetails(item)" style="white-space: normal; min-width: 8rem">{{ value }}</div>
            <icon-translate :onClick="() => pushProductEdit(item)" classes="pl-1" />
          </div>
          <div v-else class="action" @click="onViewDetails(item)" style="white-space: normal; min-width: 12rem">{{ value }}</div>
        </template>
        <template #cell(store_id)="{ value }"><store-badge :store_id="value" /></template>
        <template #cell(action)="{ item }">
          <b-button :disabled="isUnavailable(item)" :variant="isSelected(item.id) ? 'outline-info' : 'outline-primary'" @click="onSelectProduct(item)">
            {{ isSelected(item.id) ? $t('action.add') : $t('action.select') }}
          </b-button>
        </template>
      </b-table>
      <paginate v-if="productList.length > 0" :queries="queries" :fetcher="getList" :total="products.total" class="card-footer" />
    </b-modal>

    <b-modal id="stock-amount-change" lazy centered dialog-class="w-30" header-class="pb-0" body-class="py-0" body-style @hidden="onStockAmountHidden" @ok="onStockAmountOk">
      {{ $t('instant_win.clear_all[3]') }}
    </b-modal>

    <b-modal id="confirm-clear-all" lazy centered dialog-class="w-30" header-class="pb-0" body-class="py-0" body-style @ok="clearAll">
      {{ $t('instant_win.clear_all[2]') }}
    </b-modal>

    <b-modal
      id="instant-win-edit-ticket"
      lazy
      centered
      :title="$t('instant_win.winner')"
      title-class="w-100 d-flex justify-content-center align-self-center"
      header-class="py-2"
      body-class="pt-0"
      @ok="onOkEdit">
      <b-form class="flex-row">
        <b-form-group :label="$tc('order.ticket_number', 1)" label-class="text-sm">
          <b-form-input number type="number" min="1" v-model="editForm['ticket_number']" />
        </b-form-group>
        <b-form-group :label="$t('product.inventory_status')[0]" label-class="text-sm">
          <b-form-input number type="number" min="1" v-model="editForm['alternative_cash_amount']" :disabled="editForm.isCredit" />
        </b-form-group>
        <b-form-group :label="$t('competition.credit')" label-class="text-sm">
          <b-form-input number type="number" min="1" v-model="editForm['alternative_credit_amount']" :disabled="!editForm.isCredit" />
        </b-form-group>
      </b-form>
    </b-modal>
  </b-overlay>
</template>
<script>
import ProductSearchInput from '@/components/Product/ProductSearchInput.vue'
import routerMixin from '@/mixins/router-mixin'
import { noTransFormatter } from '@/utils/index'
import { floatAddition, formatWithCurrency, sumTotal } from '@/utils/numberUtils'
import { mapGetters } from 'vuex'

export default {
  name: 'CompetitionInstantWin',
  mixins: [routerMixin],
  components: { ProductSearchInput },
  props: {
    enabled: Boolean,
    form: Object,
    productStoreValid: Boolean || null,
    onViewDetails: Function,
  },
  data() {
    return {
      // main form
      rwdTypeInput: 'credit',
      rwdAmtInput: 0,
      shuffleInput: '',
      manualInput: '',
      manualTickets: [],
      description: '',
      // tickets table
      tickets: this.form.instant_wins,
      sortBy: 'ticket_number',
      sortDesc: false,
      editForm: {
        index: 0,
        isCredit: false,
      },
      // lookup
      isLoading: false,
      queries: {
        name: '',
        store_id: this.form.store_id,
        store_ids: `[${this.form.store_id}]`,
        available: 1, // all in stock
        page: 1,
        perPage: 10,
      },
      liveDrawProducts: this.form.rewards.filter((v) => v.reward_type == 'winner'),
      initStockAmt: this.form.stock_amount,
    }
  },
  computed: {
    // main form
    maxTickets() {
      return this.form.stock_amount - this.tickets.length
    },
    shuffleInputState() {
      return !this.shuffleInput ? null : this.shuffleInput > 0 && this.shuffleInput <= this.form.stock_amount - this.tickets.length ? true : false
    },
    manualInputState() {
      return !this.manualInput ? null : this.manualInput > 0 && this.manualInput <= this.form.stock_amount && !this.isExistingTicket(this.manualInput) ? true : false
    },

    // tickets table
    ticketFields() {
      return [
        { key: 'id', label: this.$t('general.id'), sortable: true },
        { key: 'ticket_number', label: this.$tc('order.ticket_number', 1), sortable: true },
        { key: 'name', label: this.$t('product.product_name'), formatter: noTransFormatter, tdClass: 'text-truncate' },
        { key: 'cost', label: this.$t('general.cost'), formatter: (v) => formatWithCurrency(v, this.form.currency), class: 'text-right' },
        {
          key: 'alternative_cash_amount',
          label: this.$t('competition.cash'),
          formatter: (v) => (this.form.currency ? formatWithCurrency(v, this.form.currency) : this.form.cost.charAt(0) + v),
          class: 'text-right',
        },
        {
          key: 'alternative_credit_amount',
          label: this.$t('competition.credit'),
          formatter: (v) => (this.form.currency ? formatWithCurrency(v, this.form.currency) : this.form.cost.charAt(0) + v),
          class: 'text-right',
        },
        { key: 'action', label: this.$tc('general.action', 1), class: 'text-right' },
      ]
    },
    cashSubtotal() {
      return this.tickets.length > 0 ? `${formatWithCurrency(sumTotal(this.tickets, 'alternative_cash_amount'), this.form.currency)}` : ''
    },
    creditSubtotal() {
      return this.tickets.length > 0 ? `${formatWithCurrency(sumTotal(this.tickets, 'alternative_credit_amount'), this.form.currency)}` : ''
    },
    costSubtotal() {
      return this.tickets.length > 0
        ? `${formatWithCurrency(
            sumTotal(
              this.tickets.filter((v) => v.cost),
              'cost'
            ),
            this.form.currency
          )}`
        : ''
    },
    subtotal() {
      if (this.tickets.length > 0) {
        const credit = sumTotal(this.tickets, 'alternative_credit_amount')
        const cash = sumTotal(this.tickets, 'alternative_cash_amount')
        const products = sumTotal(
          this.tickets.filter((v) => v.cost),
          'cost'
        )
        return formatWithCurrency(floatAddition(floatAddition(cash, credit), products), this.form.currency)
      } else {
        return ''
      }
    },

    // lookup
    ...mapGetters({ products: 'request/productList' }),
    productList() {
      return this.products?.data ?? []
    },
    lookupFields() {
      return [
        { key: 'name', label: this.$t('product.product_name'), formatter: noTransFormatter, thStyle: 'width: 12rem' },
        { key: 'type', label: this.$t('general.type'), formatter: (v) => this.$t(`product.types.${v.replace('-', '_')}`), tdClass: 'text-capitalize' },
        { key: 'store_id', label: this.$tc('general.store', 1) },
        { key: 'cost', label: this.$t('general.cost'), formatter: (v, k, i) => formatWithCurrency(v, i.currency), class: 'text-right' },
        {
          key: 'stock_amount',
          label: this.$t('product.amounts_label'),
          formatter: (value, key, item) => {
            if (item?.initial_amount) {
              let tmp = 0
              this.liveDrawProducts.forEach((x) => {
                if (x.id == item.id) {
                  tmp = +x.amount
                }
              })
              this.tickets.forEach((x) => {
                if (x.product && x.product_id == item.id) {
                  tmp += +x.amount
                }
              })
              return item.initial_amount + ' / ' + value + ' / ' + (+item.available_amount - tmp)
            } else {
              return ''
            }
          },
          class: 'text-center',
          tooltip: this.$t('product.initial_amount') + ' / ' + this.$t('product.stock_amount') + ' / ' + this.$t('product.available_amount'),
        },
        { key: 'action', label: this.$tc('general.action', 1) },
      ]
    },
  },
  
  watch: {
    // show modal when form changed, differ to init amt and has instant tickets
    'form.stock_amount'(newVal, oldVal) {
      if (newVal != oldVal && newVal != this.initStockAmt && this.tickets.length > 0) {
        this.$bvModal.show('stock-amount-change')
      }
    },
    productStoreValid(val) {
      if (!val) {
        this.tickets = []
      }
    },
    tickets(val) {
      this.form['instant_wins'] = val
    },
    description(val) {
      this.form['consolation_description'] = val
    },
  },

  methods: {
    isExistingTicket(number) {
      return this.tickets.findIndex((x) => String(x.ticket_number) == String(number)) > -1
    },

    // main form
    generateShuffleTicket() {
      let count = +this.shuffleInput
      while (count > 0) {
        const num = Math.floor(Math.random() * this.form.stock_amount) + 1
        if (!this.isExistingTicket(num)) {
          this.tickets.push({
            reward_type: 'consolation',
            ticket_number: num,
            store_id: this.form.store_id,
            alternative_cash_amount: this.rwdTypeInput == 'cash' ? this.rwdAmtInput : 0,
            alternative_credit_amount: this.rwdTypeInput == 'credit' ? this.rwdAmtInput : 0,
          })
          count--
        }
      }
      this.shuffleInput = ''
    },
    _addTag(event, addTag) {
      event.preventDefault()
      // key code 188 -> comma
      // key code 13 -> enter
      if (this.manualInputState && (event.keyCode === 188 || event.keyCode === 13)) {
        let isExisted = false
        this.manualTickets.forEach((v) => {
          if (JSON.parse(v).ticket_number == this.manualInput) {
            isExisted = true
          }
        })
        if (!isExisted) {
          addTag(
            JSON.stringify({
              reward_type: 'consolation',
              ticket_number: this.manualInput,
              store_id: this.form.store_id,
              alternative_cash_amount: this.rwdTypeInput == 'cash' ? this.rwdAmtInput : 0,
              alternative_credit_amount: this.rwdTypeInput == 'credit' ? this.rwdAmtInput : 0,
            })
          )
        }
        this.manualInput = ''
      }
    },
    formatTag(tag) {
      const item = JSON.parse(tag)
      return `${item.ticket_number} 🎟️ ${
        item.alternative_cash_amount == '0.00' ? this.$t('competition.credit') + ' * ' + item.alternative_credit_amount : this.$t('product.inventory_status')[0] + ' * ' + item.alternative_cash_amount
      }`
    },
    generateManualTicket() {
      const tmp = this.manualTickets.map((v) => ({ ...JSON.parse(v), ticket_number: JSON.parse(v).ticket_number }))
      this.tickets = [...tmp, ...this.tickets]
      this.manualTickets = []
      this.manualInput = ''
    },

    // tickets table
    onShowEdit(item) {
      this.editForm = { ...item, oldNum: item.ticket_number, isCredit: +item.alternative_credit_amount != 0 }
      this.$bvModal.show('instant-win-edit-ticket')
    },
    onOkEdit() {
      const current = this.tickets.findIndex((x) => x.ticket_number == this.editForm.oldNum)
      const existing = this.tickets.findIndex((x, i) => i !== current && x.ticket_number == this.editForm.ticket_number)
      if (existing == -1) {
        this.tickets[current].ticket_number = this.editForm.ticket_number
        this.tickets[current].alternative_cash_amount = this.editForm.alternative_cash_amount
        this.tickets[current].alternative_credit_amount = this.editForm.alternative_credit_amount
      }
    },
    onRemoveTicket(number) {
      this.tickets = this.tickets.filter((v) => v.ticket_number != number)
    },
    clearAll() {
      this.tickets = []
    },

    // Lookup
    onShowLookup() {
      this.queries.store_id = this.form.store_id
      this.getList()
    },
    onHideLookup() {
      this.rwdTypeInput = 'credit'
    },
    getList(_params) {
      this.isLoading = true
      const params = { ..._params }
      for (const key in this.queries) {
        if (key !== 'store_id') {
          params[key] = this.queries[key]
        }
      }
      this.$store
        .dispatch('request/getProductList', params)
        .then(() => (this.isLoading = false))
        .catch(() => (this.isLoading = false))
    },
    isUnavailable(item) {
      let tmp = 0
      this.tickets.forEach((x) => {
        if (x.product && x.product_id == item.id) {
          tmp += +x.amount
        }
      })
      const liveDrawProduct = this.liveDrawProducts.find((v) => v.id == item.id)
      return liveDrawProduct ? item.available_amount - liveDrawProduct.amount - tmp < 1 : item.available_amount - tmp < 1
    },
    isSelected(id) {
      return this.tickets.findIndex((x) => x.product && x.product_id == id) > -1
    },
    onSelectProduct(obj) {
      let loop = true
      while (loop) {
        const num = Math.floor(Math.random() * this.form.stock_amount) + 1
        if (!this.isExistingTicket(num)) {
          const tmp = { ...obj, product: obj, ticket_number: num, amount: 1, product_id: obj.id, reward_type: 'consolation', alternative_credit_amount: 0, alternative_cash_amount: 0 }
          delete tmp['id']
          this.liveDrawProducts.forEach((x) => {
            if (x.id == obj.id) {
              tmp.available_amount -= x.amount
            }
          })
          this.tickets.push(tmp)
          loop = false
        }
      }
    },

    onStockAmountHidden() {
      this.form.stock_amount = this.initStockAmt // reset form to init amt
    },
    onStockAmountOk() {
      this.initStockAmt = this.form.stock_amount // get new init amt from from
      this.clearAll()
    },

    createTemplate() {
      const tmp = {}
      this.tickets.forEach((item) => {
        if (item.product) {
          const prizes = item.product.name.replaceAll(' ', '_')
          if (tmp[prizes] == undefined) {
            tmp[prizes] = 0
          }
          tmp[prizes] += 1
        } else if ((item.alternative_cash_amount == '0.00') | (item.alternative_cash_amount == 0)) {
          const creditPrizes = item.alternative_credit_amount + '_Credit_Prizes'
          if (tmp[creditPrizes] == undefined) {
            tmp[creditPrizes] = 0
          }
          tmp[creditPrizes] += 1
        } else {
          const cashPrizes = item.alternative_cash_amount + '_Cash_Prizes'
          if (tmp[cashPrizes] == undefined) {
            tmp[cashPrizes] = 0
          }
          tmp[cashPrizes] += 1
        }
      })
      const output = []
      for (const p in tmp) {
        output.push({ value: p.replaceAll('_', ' '), amount: tmp[p] })
      }
      this.description = ` <div><p>No need to wait for the draw date</p><p>We have <span style='font-weight:600'>${this.tickets.length} Freed Instant Win prizes</span> up for grabs <br> </p>${output
        .map((v) => `<p style='font-weight:600'>&#9733 ${v.amount} x ${v.value}</p>`)
        .toString()
        .replaceAll(',', '')}<p><br>Randomly draw any of the following tickets numbers and win one of the above prizes!</p></div>`
    },
  },
}
</script>
